import React, { PureComponent } from "react";
import { formatMoneyAmount } from "../HelperFunctions";

import "./ManagePage.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { IUnitWithTenants } from "../loadData";
import { RequestRentInfoCallback } from "../App";
library.add(faCheck);

interface Props {
  unit: IUnitWithTenants;
  canEdit?: boolean;
  showRent?: boolean;
  onRequestRentInfo: RequestRentInfoCallback;
}

interface State {}

class ManageUnitPreview extends PureComponent<Props, State> {
  render() {
    const vacant = this.props.unit.tenants.length === 0;
    const rentDue = this.props.unit.allTimeRentOwed - this.props.unit.rentPaid;

    let label = <FontAwesomeIcon icon={faCheck} onClick={() => this.props.onRequestRentInfo(this.props.unit)} />;
    if (rentDue > 0) {
      label = (
        <span onClick={() => this.props.onRequestRentInfo(this.props.unit)}>{formatMoneyAmount(rentDue) + " due"}</span>
      );
    }
    const color = rentDue <= 0 ? "#20bf6b" : "#eb3b5a";

    return (
      <div
        className="UnitPreview"
        style={{
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            padding: "5px 10px 5px 0",
          }}
        >
          {/* Name/rent of unit */}
          <div
            style={{
              position: "relative",
              width: "95px",
              flexShrink: 0,
              textAlign: "right",
              opacity: vacant && !this.props.canEdit ? "0.5" : "1.0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                minHeight: vacant ? "0" : "40px", // Allow vacant units to be more vertically compact
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "5px 10px 5px 0",
                textAlign: "right",
              }}
            >
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {this.props.unit.unit_name}
              </div>
              {
                // Only display rent if it's non-zero or can be edited
                (this.props.unit.rent || this.props.canEdit) && (
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: "12px",
                      marginTop: "3px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    {formatMoneyAmount(this.props.unit.rent)}
                  </div>
                )
              }
            </div>
          </div>
          <div
            style={{
              position: "relative",
              flexGrow: 1,
              display: "flex",
            }}
          >
            <div
              style={{
                flexGrow: 0,
                position: "relative",
                width: "100%",
              }}
            >
              {this.props.children}
            </div>
          </div>
          {/* Rent owed by this unit */}
          {!vacant && this.props.showRent && (
            <div
              style={{
                boxSizing: "border-box",
                position: "absolute",
                height: "50px",
                right: "5px",
                top: "5px",
                backgroundColor: "rgba(255,255,255,0.85)",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "5px 10px",
                  fontSize: "12px",
                  color: color,
                  transition: "color 200ms",
                }}
              >
                {label}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ManageUnitPreview;
