import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

interface Props {}
interface State {
  errorTitle: string;
  errorDescription: string;
  unhidable: boolean;
}

class ModalGeneric extends PureComponent<Props, State> {
  private modal = React.createRef<Modal>();

  constructor(props) {
    super(props);
    this.state = {
      errorTitle: "",
      errorDescription: "",
      unhidable: false,
    };
    this.modal = React.createRef();
  }

  show(errorTitle: string, errorDescription: string, unhidable?: boolean) {
    this.setState({
      errorTitle: errorTitle,
      errorDescription: errorDescription,
      unhidable: unhidable ? true : false,
    });
    this.modal.current.show();
  }

  showUnhidable(errorTitle, errorDescription, unhidable) {
    this.setState({
      errorTitle: errorTitle,
      errorDescription: errorDescription,
      unhidable: unhidable ? true : false,
    });
    this.modal.current.show();
  }
  hide() {
    this.modal.current.hide();
  }

  render() {
    return (
      <Modal unhidable={this.state.unhidable} ref={this.modal} title={this.state.errorTitle}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!this.state.unhidable) this.hide();
          }}
        >
          <div className="ModalText">{this.state.errorDescription}</div>
          {!this.state.unhidable && (
            <div className="ModalButtonWrapper">
              <CustomButton text={"Got It"} inverted={true} color={"#2A3950"} submit={true} />
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

export default ModalGeneric;
