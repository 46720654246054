import React, { PureComponent } from "react";
import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import CustomCheckbox from "../CustomComponents/CustomCheckbox/CustomCheckbox";
import Modal from "./Modal";

import { v4 as uuidv4 } from "uuid";

import { getCurrentUserUid } from "../auth";

import firebase from "firebase/app";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkerAlt,
  faEllipsisH,
  faHashtag,
  faUser,
  faEnvelope,
  faShieldAlt,
  faCandleHolder,
  faBriefcase,
  faBuilding,
  faFileContract,
  faUserHardHat,
} from "@fortawesome/pro-solid-svg-icons";
import { us_states } from "../constants";
import validator from "validator";
import { isMatch, parseISO } from "date-fns";
import { differenceInYears } from "date-fns/esm";

library.add(
  faMapMarkerAlt,
  faEllipsisH,
  faHashtag,
  faUser,
  faEnvelope,
  faShieldAlt,
  faCandleHolder,
  faBriefcase,
  faBuilding,
  faFileContract,
  faUserHardHat,
);

// const RENTAL_PROPERTY_MANAGEMENT = "9ed46bb6-7d6f-11e3-b9b2-5404a6144203";
const DEFAULT_BUSINESS_CLASSIFICATION_1 = 3;
const DEFAULT_BUSINESS_CLASSIFICATION_2 = 55;

interface Props {
  onVerificationResponse: (status) => void;
  dwollaCustomerType: null | "unverified" | "verified";
  dwollaCustomerId: null | string;
}
interface State {
  loading: boolean;
  retrying: boolean;
  businessClassifications: Array<any>; // Dwolla Response
  agreedToLegal: boolean; // Agreed to Dwolla TOS and PP
  input: {
    firstName: string;
    lastName: string;
    email: string;
    ssn: string;
    dateOfBirth: string;
    address1: string;
    address2: string;
    city: string;
    state: number;
    zipCode: string;
    accountType: number;
    businessName: string;
    businessType: number;
    ein: string;
    businessClassification1: number;
    businessClassification2: number;
    // Controller information
    controllerFirstName: string;
    controllerLastName: string;
    controllerTitle: string;
    controllerEmail: string;
    controllerSsn: string;
    // Controller DOB
    controllerDateOfBirth: string;
    // Controller address
    controllerAddress1: string;
    controllerAddress2: string;
    controllerCity: string;
    controllerState: number;
    controllerZipCode: string;
  };
  errors: {
    firstName: string;
    lastName: string;
    email: string;
    ssn: string;
    dateOfBirth: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
    accountType: string;
    businessName: string;
    businessType: string;
    ein: string;
    businessClassification1: string;
    businessClassification2: string;
    // Controller errors
    controllerFirstName: string;
    controllerLastName: string;
    controllerTitle: string;
    controllerEmail: string;
    controllerSsn: string;
    // Controller DOB
    controllerDateOfBirth: string;
    // Controller address
    controllerAddress1: string;
    controllerAddress2: string;
    controllerCity: string;
    controllerState: string;
    controllerZipCode: string;
  };
  showingAgreementError: boolean;
}

class ModalVerifyUser extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();
  form = React.createRef<HTMLFormElement>();

  InputFirstName = React.createRef<CustomInput>();
  InputLastName = React.createRef<CustomInput>();
  InputEmail = React.createRef<CustomInput>();
  InputSSN = React.createRef<CustomInput>();
  InputDateOfBirth = React.createRef<CustomInput>();
  InputAddress1 = React.createRef<CustomInput>();
  InputAddress2 = React.createRef<CustomInput>();
  SelectState = React.createRef<CustomSelect>();
  InputCity = React.createRef<CustomInput>();
  InputZipCode = React.createRef<CustomInput>();
  SelectAccountType = React.createRef<CustomSelect>();
  InputBusinessName = React.createRef<CustomInput>();
  SelectBusinessType = React.createRef<CustomSelect>();
  SelectBusinessClassification1 = React.createRef<CustomSelect>();
  SelectBusinessClassification2 = React.createRef<CustomSelect>();
  InputEIN = React.createRef<CustomInput>();

  InputControllerFirstName = React.createRef<CustomInput>();
  InputControllerLastName = React.createRef<CustomInput>();
  InputControllerTitle = React.createRef<CustomInput>();
  InputControllerEmail = React.createRef<CustomInput>();
  InputControllerSSN = React.createRef<CustomInput>();
  InputControllerDateOfBirth = React.createRef<CustomInput>();

  InputControllerAddress1 = React.createRef<CustomInput>();
  InputControllerAddress2 = React.createRef<CustomInput>();
  InputControllerCity = React.createRef<CustomInput>();
  SelectControllerState = React.createRef<CustomSelect>();
  InputControllerZipCode = React.createRef<CustomInput>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      retrying: false,
      input: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: -1,
        zipCode: "",
        accountType: 0,
        businessName: "",
        businessType: 0,
        ein: "",
        businessClassification1: DEFAULT_BUSINESS_CLASSIFICATION_1,
        businessClassification2: DEFAULT_BUSINESS_CLASSIFICATION_2,
        // Controller information
        controllerFirstName: "",
        controllerLastName: "",
        controllerTitle: "",
        controllerEmail: "",
        controllerSsn: "",
        // Controller DOB
        controllerDateOfBirth: "",
        // Controller address
        controllerAddress1: "",
        controllerAddress2: "",
        controllerCity: "",
        controllerState: 0,
        controllerZipCode: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        accountType: "",
        businessName: "",
        businessType: "",
        ein: "",
        businessClassification1: "",
        businessClassification2: "",
        // Controller errors
        controllerFirstName: "",
        controllerLastName: "",
        controllerTitle: "",
        controllerEmail: "",
        controllerSsn: "",
        // Controller DOB
        controllerDateOfBirth: "",
        // Controller address
        controllerAddress1: "",
        controllerAddress2: "",
        controllerCity: "",
        controllerState: "",
        controllerZipCode: "",
      },
      businessClassifications: [],
      agreedToLegal: false,
      showingAgreementError: false,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    // Clear input states and errors
    this.setState({
      input: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: -1,
        zipCode: "",
        accountType: 0,
        businessName: "",
        businessType: 0,
        ein: "",
        businessClassification1: DEFAULT_BUSINESS_CLASSIFICATION_1,
        businessClassification2: DEFAULT_BUSINESS_CLASSIFICATION_2,
        // Controller information
        controllerFirstName: "",
        controllerLastName: "",
        controllerTitle: "",
        controllerEmail: "",
        controllerSsn: "",
        // Controller DOB
        controllerDateOfBirth: "",
        // Controller address
        controllerAddress1: "",
        controllerAddress2: "",
        controllerCity: "",
        controllerState: 0,
        controllerZipCode: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        accountType: "",
        businessName: "",
        businessType: "",
        ein: "",
        businessClassification1: "",
        businessClassification2: "",
        // Controller errors
        controllerFirstName: "",
        controllerLastName: "",
        controllerTitle: "",
        controllerEmail: "",
        controllerSsn: "",
        // Controller DOB
        controllerDateOfBirth: "",
        // Controller address
        controllerAddress1: "",
        controllerAddress2: "",
        controllerCity: "",
        controllerState: "",
        controllerZipCode: "",
      },
      loading: false,
    });

    this.InputSSN.current.clear();
    this.InputAddress1.current.clear();
    this.InputAddress2.current.clear();
    this.InputCity.current.clear();
    this.SelectState.current.clear();
    this.InputZipCode.current.clear();
    this.InputDateOfBirth.current.clear();
    this.SelectAccountType.current.clear();

    // Make sure conditional fields exist before clearing them
    if (this.InputFirstName.current) this.InputFirstName.current.clear();
    if (this.InputLastName.current) this.InputLastName.current.clear();
    if (this.InputEmail.current) this.InputEmail.current.clear();
    if (this.InputBusinessName.current) this.InputBusinessName.current.clear();
    if (this.SelectBusinessType.current) this.SelectBusinessType.current.clear();
    if (this.InputEIN.current) this.InputEIN.current.clear();
    if (this.SelectBusinessClassification1.current) this.SelectBusinessClassification1.current.clear();
    if (this.SelectBusinessClassification2.current) this.SelectBusinessClassification2.current.clear();
    if (this.InputControllerFirstName.current) this.InputControllerFirstName.current.clear();
    if (this.InputControllerLastName.current) this.InputControllerLastName.current.clear();
    if (this.InputControllerTitle.current) this.InputControllerTitle.current.clear();
    if (this.InputControllerEmail.current) this.InputControllerEmail.current.clear();
    if (this.InputControllerSSN.current) this.InputControllerSSN.current.clear();
    if (this.InputControllerDateOfBirth.current) this.InputControllerDateOfBirth.current.clear();
    if (this.InputAddress1.current) this.InputAddress1.current.clear();
    if (this.InputAddress2.current) this.InputAddress2.current.clear();
    if (this.InputCity.current) this.InputCity.current.clear();
    if (this.SelectState.current) this.SelectState.current.clear();
    if (this.InputZipCode.current) this.InputZipCode.current.clear();
  }

  async show(retrying: boolean) {
    this.setState({ retrying: retrying });
    this.clear();
    this.modal.current.show();

    // Get data associated with this user
    const userData = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();
    const data = userData.data();

    // Make sure we're storing first and last names
    if (data.first_name && this.InputFirstName.current) this.InputFirstName.current.setValue(data.first_name);
    if (data.last_name && this.InputLastName.current) this.InputLastName.current.setValue(data.last_name);
    if (this.InputEmail.current) this.InputEmail.current.setValue(data.email);
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  getBusinessClassifications1() {
    const classifications1 = [];
    const businessClassifications = this.state.businessClassifications;

    for (let i = 0; i < businessClassifications.length; i++) {
      classifications1.push(businessClassifications[i]["name"]);
    }
    return classifications1;
  }

  getBusinessClassifications2(category) {
    const classifications2 = [];
    const industryClassifications = this.state.businessClassifications[category]["_embedded"][
      "industry-classifications"
    ];

    for (let i = 0; i < industryClassifications.length; i++) {
      classifications2.push(industryClassifications[i]["name"]);
    }
    return classifications2;
  }

  componentDidMount() {
    // TODO: Query Dwolla for data about business classifications in the following format
    // TODO: Update this.state.input.businessClassification1 and this.state.input.getBusinessClassifications2
    // to represent the default business type after querying Dwolla
    // i.e. right now the default is "Food retail and service" and "Gourmet foods" which is hilariously irrelevent

    const listBusinessClassifications = firebase.functions().httpsCallable("listBusinessClassifications");
    listBusinessClassifications().then((response) => {
      // this.state.input.businessClassification1 = 3;
      // this.state.input.businessClassifications2 = 55;
      this.setState({ businessClassifications: response.data._embedded["business-classifications"] });
    });
  }

  async handleVerifyUser() {
    this.setState({
      loading: true,
      showingAgreementError: false,
    });

    // TODO: Error handling (example code for SSN provided below)
    const input = this.state.input;
    const errors = this.state.errors;
    let encounteredError = false;

    // Information only required for users who aren't already unverified with Dwolla
    if (this.props.dwollaCustomerType === null) {
      if (input.firstName.length === 0) {
        errors.firstName = "Please provide your first name";
        encounteredError = true;
      }
      if (input.lastName.length === 0) {
        errors.lastName = "Please provide your last name";
        encounteredError = true;
      }
      if (!validator.isEmail(input.email)) {
        errors.email = "Please enter a valid email address";
        encounteredError = true;
      }
      if (!this.state.agreedToLegal) {
        this.setState({ showingAgreementError: true });
        encounteredError = true;
      }
    }

    // No SSN provided
    if (!input.ssn) {
      errors.ssn = "Please enter your SSN";
      encounteredError = true;
    }
    // Retrying (full SSN needed) and length is not 9
    else if (input.ssn.length !== 9 && this.state.retrying) {
      errors.ssn = "Please enter your full SSN";
      encounteredError = true;
    }
    // Not retrying (first 4 digits needed) and length is not 4
    else if (input.ssn.length !== 4 && !this.state.retrying) {
      errors.ssn = "Please enter the last 4 digits of your SSN";
      encounteredError = true;
    }

    if (input.address1.length === 0) {
      errors.address1 = "Please provide your address";
      encounteredError = true;
    }
    if (input.city.length === 0) {
      errors.city = "Please provide your city";
      encounteredError = true;
    }
    if (input.zipCode.length !== 5) {
      errors.zipCode = "Please enter a valid 5-digit zip code";
      encounteredError = true;
    }
    if (input.state === -1) {
      errors.state = "Please select a state";
      encounteredError = true;
    }

    if (!isMatch(input.dateOfBirth, "yyyy-MM-dd")) {
      errors.dateOfBirth = "Please enter a valid date of birth";
      encounteredError = true;
    }

    if (differenceInYears(new Date(), parseISO(input.dateOfBirth)) < 18) {
      errors.dateOfBirth = "You must be at least 18 years old to verify your identity";
      encounteredError = true;
    }

    // If an error was encountered, display and don't send any data to Dwolla
    if (encounteredError) {
      // Update errors and force re-render
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
      return;
    }

    // Name and email are added after switch, if not already an unverified user
    let customer;
    switch (this.state.input.accountType) {
      case 0: //'Personal':
        customer = {
          type: "personal",
          address1: this.state.input.address1,
          address2: this.state.input.address2,
          city: this.state.input.city,
          state: us_states[this.state.input.state],
          postalCode: this.state.input.zipCode,
          dateOfBirth: this.state.input.dateOfBirth,
          ssn: this.state.input.ssn,
          // phone: this.state.input.phone,
          correlationId: getCurrentUserUid(),
        };
        break;
      case 1: //'Business':
        const businessTypesDwolla = ["soleProprietorship", "corporation", "partnership", "llc"];
        customer = {
          type: "business",
          dateOfBirth: this.state.input.dateOfBirth,
          ssn: this.state.input.ssn,
          address1: this.state.input.address1,
          address2: this.state.input.address2,
          city: this.state.input.city,
          state: us_states[this.state.input.state],
          postalCode: this.state.input.zipCode,
          businessName: this.state.input.businessName,
          businessType: businessTypesDwolla[this.state.input.businessType],
          businessClassification: this.state.businessClassifications[this.state.input.businessClassification1][
            "_embedded"
          ]["industry-classifications"][this.state.input.businessClassification2].id,
          // phone: this.InputPhone.current,
          correlationId: getCurrentUserUid(),
        };
        if (
          this.state.input.businessType === 1 || // Corporation
          this.state.input.businessType === 2 || // Partnership
          this.state.input.businessType === 3
        ) {
          // LLC

          customer.ein = this.state.input.ein;
          customer.controller = {
            firstName: this.state.input.controllerFirstName,
            lastName: this.state.input.controllerLastName,
            title: this.state.input.controllerTitle,
            email: this.state.input.controllerEmail,
            ssn: this.state.input.controllerSsn,
            dateOfBirth: this.state.input.controllerDateOfBirth,
            address: {
              address1: this.state.input.controllerAddress1,
              address2: this.state.input.controllerAddress2,
              city: this.state.input.controllerCity,
              stateProvinceRegion: us_states[this.state.input.controllerState],
              postalCode: this.state.input.controllerZipCode,
              country: "US",
            },
          };
        }

        if (
          this.state.input.businessType === 1 || // Corporation
          this.state.input.businessType === 2 || // Partnership
          this.state.input.businessType === 3
        ) {
          // LLC

          customer.ein = this.state.input.ein;
          customer.controller = {
            firstName: this.state.input.controllerFirstName,
            lastName: this.state.input.controllerLastName,
            title: this.state.input.controllerTitle,
            email: this.state.input.controllerEmail,
            ssn: this.state.input.controllerSsn,
            dateOfBirth: this.state.input.controllerDateOfBirth,
            address: {
              address1: this.state.input.controllerAddress1,
              address2: this.state.input.controllerAddress2,
              city: this.state.input.controllerCity,
              stateProvinceRegion: us_states[this.state.input.controllerState],
              postalCode: this.state.input.controllerZipCode,
              country: "US",
            },
          };
        }

        break;
      default:
        console.error("Invalid value from dropdown.");
        break;
    }

    // If this user isn't already unverified, add name and email
    if (this.props.dwollaCustomerType === null) {
      customer.firstName = this.state.input.firstName;
      customer.lastName = this.state.input.lastName;
      customer.email = this.state.input.email;
      customer.correlationId = getCurrentUserUid();
      //customer.type = "verified";
      //customer.verifiedType = ["personal", "business"][this.state.input.accountType];
    }

    // Customer doesn't exist... Create a new customer
    if (this.props.dwollaCustomerType === null) {
      // Create customer and store their new ID
      const result = await firebase.functions().httpsCallable("createCustomer")({
        customer: customer,
        idempotencyKey: uuidv4(),
      });
      this.props.onVerificationResponse(result.data.status);
    }
    // Customer already exists... use updateCustomer cloud function instead
    else {
      // Update customer information to verify them
      const result = await firebase.functions().httpsCallable("verifyCustomer")({
        customer: customer,
        idempotencyKey: uuidv4(),
      });
      this.props.onVerificationResponse(result.data.status);
    }

    this.hide();
  }

  render() {
    const text = "Please verify your information to be able to send more than $5,000 per week.";

    return (
      <Modal ref={this.modal} title={"Verify Your Identity"}>
        <form
          ref={this.form}
          onSubmit={(e) => {
            e.preventDefault();
            this.handleVerifyUser();
          }}
        >
          <div className="ModalText">{text}</div>
          <div className="ModalSectionHeader">Personal information</div>
          {
            // If the user is already unverified, there's no need to re-collect name and email
            this.props.dwollaCustomerType === null && (
              <div>
                <div className="ModalInputWrapper">
                  <CustomInput
                    ref={this.InputFirstName}
                    icon={faUser}
                    placeholder="Legal first name"
                    type={"text"}
                    name={"fname"}
                    autoComplete={"given-name"}
                    errorText={this.state.errors.firstName}
                    inverted={true}
                    readOnly={false}
                    onChange={(text) => {
                      this.setState((state) => {
                        const input = state.input;
                        input.firstName = text;
                        return { input: input };
                      });
                    }}
                  />
                </div>
                <div className="ModalInputWrapper">
                  <CustomInput
                    ref={this.InputLastName}
                    icon={faUser}
                    placeholder="Legal last name"
                    type={"text"}
                    name={"lname"}
                    autoComplete={"family-name"}
                    errorText={this.state.errors.lastName}
                    inverted={true}
                    readOnly={false}
                    onChange={(text) => {
                      this.setState((state) => {
                        const input = state.input;
                        input.lastName = text;
                        return { input: input };
                      });
                    }}
                  />
                </div>
                <div className="ModalInputWrapper">
                  <CustomInput
                    ref={this.InputEmail}
                    icon={faEnvelope}
                    placeholder="Email"
                    type={"email"}
                    name={"email"}
                    autoComplete={"email"}
                    errorText={this.state.errors.email}
                    inverted={true}
                    readOnly={false}
                    onChange={(text) => {
                      this.setState((state) => {
                        const input = state.input;
                        input.email = text;
                        return { input: input };
                      });
                    }}
                  />
                </div>
              </div>
            )
          }
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputSSN}
              icon={faShieldAlt}
              placeholder={this.state.retrying ? "9 Digit SSN" : "Last 4 digits of SSN"}
              type={"tel"}
              name={"ssn"}
              autoComplete={"off"}
              errorText={this.state.errors.ssn}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.ssn = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalSectionHeader">Address</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputAddress1}
              icon={faMapMarkerAlt}
              placeholder="Address (e.g. 346 Elm Street)"
              type={"text"}
              name={"address"}
              autoComplete={"address-line1"}
              errorText={this.state.errors.address1}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.address1 = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputAddress2}
              icon={faEllipsisH}
              placeholder="Apartment, floor, suite, etc. (optional)"
              type={"text"}
              name={"address"}
              autoComplete={"address-line2"}
              errorText={this.state.errors.address2}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.address2 = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputCity}
              icon={faMapMarkerAlt}
              placeholder="City (e.g. Madison)"
              type={"text"}
              name={"city"}
              autoComplete={"address-level2"}
              errorText={this.state.errors.city}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.city = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomSelect
              ref={this.SelectState}
              options={us_states}
              icon={faMapMarkerAlt}
              placeholder="State"
              errorText={this.state.errors.state}
              inverted={true}
              readOnly={false}
              onChange={(value) => {
                this.setState((state) => {
                  const input = state.input;
                  input.state = value;
                  return { input: input };
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputZipCode}
              icon={faHashtag}
              placeholder="Zip code (e.g. 57042)"
              type={"tel"}
              name={"zip"}
              autoComplete={"postal-code"}
              errorText={this.state.errors.zipCode}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.zipCode = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalSectionHeader">Date of birth</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputDateOfBirth}
              icon={faCandleHolder}
              placeholder="MM-DD-YYYY"
              type={"date"}
              errorText={this.state.errors.dateOfBirth}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.dateOfBirth = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalSectionHeader">Account type</div>
          <div className="ModalInputWrapper">
            <CustomSelect
              ref={this.SelectAccountType}
              options={["Personal", "Business"]}
              icon={this.state.input.accountType === 0 ? faUser : faBuilding}
              placeholder="Account type"
              errorText={this.state.errors.accountType}
              inverted={true}
              readOnly={false}
              defaultValue={0} // Personal
              onChange={(value) => {
                this.setState((state) => {
                  const input = state.input;
                  input.accountType = value;
                  return { input: input };
                }, this.forceUpdate);
              }}
            />
          </div>
          {this.state.input.accountType === 1 && (
            <div>
              <div className="ModalSectionHeader">Business information</div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputBusinessName}
                  icon={faBriefcase}
                  placeholder="Business name"
                  type={"text"}
                  errorText={this.state.errors.businessName}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.businessName = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomSelect
                  ref={this.SelectBusinessType}
                  options={["Sole Proprietorship", "Corporation", "Partnership", "LLC"]}
                  icon={faFileContract}
                  placeholder="Business type"
                  errorText={this.state.errors.accountType}
                  inverted={true}
                  readOnly={false}
                  onChange={(value) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.businessType = value;
                      return { input: input };
                    }, this.forceUpdate);
                  }}
                />
              </div>
              {
                // Require EIN for non-sole-proprietorship
                (this.state.input.businessType === 1 ||
                  this.state.input.businessType === 2 ||
                  this.state.input.businessType === 3) && (
                  <div className="ModalInputWrapper">
                    <CustomInput
                      ref={this.InputEIN}
                      icon={faUserHardHat}
                      placeholder="Employer Identification Number (EIN)"
                      type={"text"}
                      errorText={this.state.errors.ein}
                      inverted={true}
                      readOnly={false}
                      onChange={(text) => {
                        this.setState((state) => {
                          const input = state.input;
                          input.ein = text;
                          return { input: input };
                        });
                      }}
                    />
                  </div>
                )
              }
              <div className="ModalSectionHeader">Business classification</div>
              <div className="ModalInputWrapper">
                <CustomSelect
                  ref={this.SelectBusinessClassification1}
                  options={this.getBusinessClassifications1()}
                  icon={faFileContract}
                  placeholder="Business category"
                  errorText={this.state.errors.businessClassification1}
                  defaultValue={DEFAULT_BUSINESS_CLASSIFICATION_1}
                  inverted={true}
                  readOnly={false}
                  onChange={(value) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.businessClassification1 = value;
                      return { input: input };
                    }, this.forceUpdate);
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomSelect
                  ref={this.SelectBusinessClassification2}
                  options={this.getBusinessClassifications2(this.state.input.businessClassification1)}
                  icon={faFileContract}
                  placeholder="Business classification"
                  errorText={this.state.errors.businessClassification1}
                  defaultValue={DEFAULT_BUSINESS_CLASSIFICATION_2}
                  inverted={true}
                  readOnly={false}
                  onChange={(value) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.businessClassification2 = value;
                      return { input: input };
                    }, this.forceUpdate);
                  }}
                />
              </div>
              {
                // Collect controller information for non-sole-proprietorship
                (this.state.input.businessType === 1 ||
                  this.state.input.businessType === 2 ||
                  this.state.input.businessType === 3) && (
                  <div>
                    <div className="ModalSectionHeader">Controller information</div>
                    <div className="ModalText" style={{ marginTop: "25px" }}>
                      A controller is any natural individual who holds significant responsibilities to control, manage,
                      or direct a company or other corporate entity.
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerFirstName}
                        icon={faUser}
                        placeholder="First name"
                        type={"text"}
                        errorText={this.state.errors.controllerFirstName}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerFirstName = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerLastName}
                        icon={faUser}
                        placeholder="Last name"
                        type={"text"}
                        errorText={this.state.errors.controllerLastName}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerLastName = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerTitle}
                        icon={faUser}
                        placeholder="Title (e.g. CEO, CFO, President, etc.)"
                        type={"text"}
                        errorText={this.state.errors.controllerTitle}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerTitle = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerEmail}
                        icon={faEnvelope}
                        placeholder="Email"
                        type={"text"}
                        errorText={this.state.errors.controllerEmail}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerEmail = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerSSN}
                        icon={faShieldAlt}
                        placeholder={"Last 4 digits of SSN"}
                        type={"text"}
                        errorText={this.state.errors.controllerSsn}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerSsn = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalSectionHeader">Controller date of birth</div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerDateOfBirth}
                        icon={faCandleHolder}
                        placeholder="MM-DD-YYYY"
                        type={"date"}
                        errorText={this.state.errors.controllerDateOfBirth}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerDateOfBirth = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalSectionHeader">Controller address</div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerAddress1}
                        icon={faMapMarkerAlt}
                        placeholder="Address (e.g. 346 Elm Street)"
                        type={"text"}
                        errorText={this.state.errors.controllerAddress1}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerAddress1 = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerAddress2}
                        icon={faEllipsisH}
                        placeholder="Apartment, floor, suite, etc. (optional)"
                        type={"text"}
                        errorText={this.state.errors.controllerAddress2}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerAddress2 = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerCity}
                        icon={faMapMarkerAlt}
                        placeholder="City (e.g. Madison)"
                        type={"text"}
                        errorText={this.state.errors.controllerCity}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerCity = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomSelect
                        ref={this.SelectControllerState}
                        options={us_states}
                        icon={faMapMarkerAlt}
                        placeholder="State"
                        errorText={this.state.errors.controllerState}
                        inverted={true}
                        readOnly={false}
                        onChange={(value) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerState = value;
                            return { input: input };
                          }, this.forceUpdate);
                        }}
                      />
                    </div>
                    <div className="ModalInputWrapper">
                      <CustomInput
                        ref={this.InputControllerZipCode}
                        icon={faHashtag}
                        placeholder="Zip code (e.g. 57042)"
                        type={"text"}
                        errorText={this.state.errors.controllerZipCode}
                        inverted={true}
                        readOnly={false}
                        onChange={(text) => {
                          this.setState((state) => {
                            const input = state.input;
                            input.controllerZipCode = text;
                            return { input: input };
                          });
                        }}
                      />
                    </div>
                  </div>
                )
              }
            </div>
          )}
          {
            // If this user is already unverified, they've already agreed to Dwolla PP and TOS
            this.props.dwollaCustomerType === null && (
              <div>
                <div
                  className="ModalText"
                  style={{
                    marginTop: "25px",
                    marginBottom: "10px",
                    color: "rgba(0,0,0,0.35)",
                  }}
                >
                  Transactions on Rentingway are processed by Dwolla, using military-grade encryption. Rentingway does
                  not store your payment information.
                </div>
                <div className="ModalText">
                  <CustomCheckbox
                    onChange={(checked) => {
                      this.setState({
                        agreedToLegal: checked,
                        showingAgreementError: false,
                      });
                    }}
                  >
                    <span>I acknowledge that I have read and agree to the Dwolla </span>
                    <span>
                      <a
                        className="ModalAnchor"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dwolla.com/legal/tos/"
                      >
                        Terms of Service
                      </a>
                    </span>
                    <span> and </span>
                    <span>
                      <a
                        className="ModalAnchor"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.dwolla.com/legal/privacy/"
                      >
                        Privacy Policy
                      </a>
                    </span>
                  </CustomCheckbox>
                </div>
                {this.state.showingAgreementError && (
                  <div className="ModalText">Please agree to the Dwolla Privacy Policy and Terms of Service above.</div>
                )}
              </div>
            )
          }
          <div className="ModalButtonWrapper">
            <CustomButton
              text={this.props.dwollaCustomerType === null ? "Agree & Continue" : "Continue"}
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalVerifyUser;
