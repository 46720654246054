import { getCurrentUserUid } from "../auth";

/**
 * Returns the difference of set A and B.
 * @param {*} setA
 * @param {*} setB
 */
export function difference(setA, setB) {
  const difference = new Set(setA);
  for (const elem of setB) {
    difference.delete(elem);
  }
  return difference;
}

/**
 * Returns the union of set A and B.
 * @param {*} setA
 * @param {*} setB
 */
export function union(setA, setB) {
  const unionSet = new Set();
  for (const elem of setA) {
    unionSet.add(elem);
  }
  for (const elem of setB) {
    unionSet.add(elem);
  }
  return unionSet;
}

export function isCurrentUser(userId: string): boolean {
  return userId === getCurrentUserUid();
}
