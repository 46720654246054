import React, { PureComponent } from "react";
import "./CustomRadio.css";

interface Props {
  id: string;
  options: string[];
  onChange: (x?: number) => any;
}
interface State {
  value: number;
}

class CustomRadio extends PureComponent<Props, State> {
  wrapper = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      value: undefined,
    };
  }

  clear() {
    this.setState({ value: undefined });
    if (typeof this.props.onChange === "function") {
      this.props.onChange();
    }

    const els = this.wrapper.current.getElementsByTagName("input");
    for (let i = 0; i < els.length; i++) {
      els[i].checked = false;
    }
  }

  setValue(value) {
    this.setState({ value: value });
    this.props.onChange(value);
  }

  getOptions() {
    return this.props.options.map((option, index) => {
      return (
        <div className="CustomRadio" key={this.props.id + "-" + index}>
          <input
            className="CustomRadioInput"
            type="radio"
            name={this.props.id}
            id={this.props.id + "-" + index}
            value={index}
            onChange={(e) => {
              if (e.target.checked) {
                this.setValue(index);
              }
            }}
          />
          <label className="CustomRadioLabel" htmlFor={this.props.id + "-" + index}>
            {option}
          </label>
        </div>
      );
    });
  }

  render() {
    return (
      <div ref={this.wrapper} className="CustomRadioWrapper">
        {this.getOptions()}
      </div>
    );
  }
}

export default CustomRadio;
