import React, { PureComponent } from "react";
import { Plugins, HapticsNotificationType, Capacitor } from "@capacitor/core";

import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";
import firebase from "firebase/app";
import "firebase/analytics";

import MessagingPage from "../MessagingPage/MessagingPage";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHomeAlt, faMoneyBillWave } from "@fortawesome/pro-solid-svg-icons";
import { IProperty } from "../conjure-api";
library.add(faHomeAlt, faMoneyBillWave);

const { Haptics } = Plugins;

interface Props {
  messagingPage: MessagingPage;
}

interface State {
  loading: boolean;
  message: string;
  property: IProperty;
}

class ModalMessageProperty extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // Message value
      message: "",
      // Passed data
      property: null,
    };

    this.handleMessageProperty = this.handleMessageProperty.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    this.setState({
      message: "",
    });
  }
  show(property: IProperty) {
    this.clear();
    this.setState({
      property: property,
    });
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async handleMessageProperty() {
    this.setState({ loading: true });
    await this.props.messagingPage.sendMessageToProperty(this.state.property.id, this.state.message);

    // Success haptic when property messaged successfully
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });

    firebase.analytics().logEvent("message_property_sent");
    firebase.analytics().setUserProperties({ messaged_property: true });
    this.setState({ loading: false });
    this.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Message Property"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleMessageProperty();
          }}
        >
          <div className="ModalText">Send message to all individual tenants in {this.state.property?.address}</div>
          <div className="ModalInputWrapper">
            <div style={{ display: "flex" }}>
              <textarea
                value={this.state.message}
                placeholder={"Type message..."}
                rows={3}
                maxLength={1000}
                style={{
                  display: "block",
                  resize: "none",
                  fontSize: "16px",
                  padding: "10px 15px",
                  outline: "none",
                  border: "none",
                  backgroundColor: "#f7f7f7",
                  borderRadius: "5px",
                  flexGrow: 1,
                }}
                onChange={(e) => {
                  const value = e.target.value;
                  this.setState({ message: value });
                }}
              />
            </div>
          </div>
          <div className="ModalButtonWrapper">
            <CustomButton
              text="Message Property"
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              disabled={this.state.message === ""}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalMessageProperty;
