import firebase from "firebase/app";
import "firebase/functions";
import { InitialData } from "./AuthenticationWindow/AuthenticationWindow";

// import { AddUnit, UpdateRentPreferences } from "../functions/src/properties";

export function addUnit(data: any) {
  return firebase.functions().httpsCallable("addUnit")(data);
}
export function updateRentPreferences(data: any) {
  return firebase.functions().httpsCallable("updateRentPreferences")(data);
}
export function createFirebaseUser(data: { email: string; password: string; initialData: InitialData }) {
  return firebase.functions().httpsCallable("createFirebaseUser")(data);
}
