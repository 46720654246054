import React, { PureComponent } from "react";
import { Plugins, HapticsNotificationType, Capacitor } from "@capacitor/core";

import CustomCurrencyInput from "../CustomComponents/CustomCurrencyInput/CustomCurrencyInput";
import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import AddableUserPreview from "../CustomComponents/AddableUserPreview/AddableUserPreview";
import Modal from "./Modal";

import ReactTooltip from "react-tooltip";

import { getCurrentUserUid } from "../auth";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faMoneyBillWave,
  faSuitcaseRolling,
  faCalendar,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProperty } from "../conjure-api";
import { IUnitWithTenants } from "../loadData";
import { format } from "date-fns";
import { dateMatchesAnyOrISO, formatUserList, parseWithAnyOrISO } from "../HelperFunctions";
library.add(faSearch, faMoneyBillWave, faSuitcaseRolling, faQuestionCircle);

const { Haptics } = Plugins;

interface Props {
  messagingPage: any;
  showCustomModal(title: string, body: string);
  onRefresh: () => void;
}

interface State {
  loading: boolean;
  searchValue: string;
  newTenants: Array<any>; //TODO
  searchUsers: Array<{ id: string; name: string; imageUrl: string }>;
  input: {
    rentAmount: number;
    // refundableAmount: number;
    nonRefundableAmount: number;
    firstMonth: string;
    leaseStartDate: string;
  };
  errors: {
    rentAmount: string;
    // refundableAmount:string;
    nonRefundableAmount: string;
    firstMonth: string;
    leaseStartDate: string;
  };
  notes: string;
  currentTenantIdsSet: Set<string>;
  unit: IUnitWithTenants;
  property: IProperty;
}

class ModalAddTenant extends PureComponent<Props, State> {
  InputSearch = React.createRef<CustomInput>();
  InputRentAmount = React.createRef<CustomCurrencyInput>();
  // InputRefundableAmount = React.createRef<CustomCurrencyInput>();
  InputNonRefundableAmount = React.createRef<CustomCurrencyInput>();
  InputFirstMonth = React.createRef<CustomInput>();
  InputLeaseStartDate = React.createRef<CustomInput>();
  InputNotes = React.createRef<HTMLTextAreaElement>();

  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchValue: "",
      currentTenantIdsSet: new Set(),
      newTenants: [],
      searchUsers: [],
      // Input and errors
      input: {
        rentAmount: 0,
        // refundableAmount: 0,
        nonRefundableAmount: 0,
        firstMonth: "",
        leaseStartDate: "",
      },
      errors: {
        rentAmount: "",
        // refundableAmount: "",
        nonRefundableAmount: "",
        firstMonth: "",
        leaseStartDate: "",
      },
      // Additional notes
      notes: "",

      // Passed values
      unit: null,
      property: null,
    };

    this.handleAddTenants = this.handleAddTenants.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  userIsCurrentMember(user) {
    for (let i = 0; i < this.state.newTenants.length; i++) {
      if (this.state.newTenants[i].id === user.id) return true;
    }
    return false;
  }

  addMember(user) {
    this.setState((state) => {
      const newTenants = state.newTenants;
      newTenants.push(user);
      return { newTenants: newTenants };
    });
  }

  removeMember(user) {
    this.setState((state) => {
      const newTenants = state.newTenants;
      for (let i = 0; i < newTenants.length; i++) {
        if (newTenants[i].id === user.id) {
          newTenants.splice(i, 1);
          break;
        }
      }
      return { newTenants: newTenants };
    });
  }

  getCurrentMemberPreviews() {
    return this.state.newTenants.map((user, index) => {
      return (
        <AddableUserPreview
          key={"CurrentMember" + user.id}
          id={user.id}
          name={user.name}
          imageUrl={user.imageUrl}
          adding={false}
          onClick={() => {
            this.removeMember(user);
            //this.Input.current.clear();
            this.forceUpdate();
          }}
        />
      );
    });
  }

  getSearchModalUserPreviews() {
    return this.state.searchUsers.map((user, index) => {
      const isMember = this.userIsCurrentMember(user);
      return (
        <AddableUserPreview
          key={"SearchUser" + user.id}
          id={user.id}
          name={user.name}
          imageUrl={user.imageUrl}
          adding={!isMember}
          onClick={() => {
            // User is a member, remove them
            if (isMember) {
              this.removeMember(user);
            }
            // User is not a member, add them
            else {
              this.addMember(user);
            }
            //this.Input.current.clear();
            this.forceUpdate();
          }}
        />
      );
    });
  }

  clear() {
    this.InputSearch.current.clear();
    this.InputRentAmount.current.clear();
    // this.InputRefundableAmount.current.clear();
    this.InputNonRefundableAmount.current.clear();
    this.InputFirstMonth.current.clear();
    this.InputLeaseStartDate.current.clear();
    this.InputNotes.current.value = "";
  }
  show(property: IProperty, unit: IUnitWithTenants) {
    this.clear();
    this.setState({
      currentTenantIdsSet: new Set(unit.tenants.map((t) => t.id)),
      newTenants: [],
      unit: unit,
      property: property,
    });
    this.InputRentAmount.current.setValue(unit.rent);
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async getSearchUsers(query) {
    //search for user
    if (query.length < 3) {
      this.setState({
        searchUsers: [],
      });
      return;
    }
    //TODO [long-term] : may need to use 3rd party searching frameworks to improve preformance
    const snapshot = await firebase.firestore().collection("users").where("verified", "==", true).get();
    const result = [];
    const newTenantIdSet = new Set(this.state.newTenants.map((t) => t.id));
    for (let i = 0; i < snapshot.docs.length; i++) {
      if (snapshot.docs[i].data().full_name === null) {
        //if there is one corrupted user, don't let it bring down entire search.
        continue;
      }
      const user_name = snapshot.docs[i].data()["full_name"].toString().toLowerCase();
      if (
        user_name.includes(query.toLowerCase()) &&
        snapshot.docs[i].id !== getCurrentUserUid() &&
        !this.state.currentTenantIdsSet.has(snapshot.docs[i].id) &&
        !newTenantIdSet.has(snapshot.docs[i].id)
      ) {
        result.push({
          id: snapshot.docs[i].id,
          name: snapshot.docs[i].data().full_name.toString(),
          imageUrl: snapshot.docs[i].data().profile_pic_url,
        });
      }
    }
    this.setState({
      searchUsers: result,
    });
  }

  updateErrors() {
    const errors = this.state.errors;
    let encounteredError = false;

    const leaseStartDate = this.state.input.leaseStartDate;

    if (
      leaseStartDate !== "" &&
      !dateMatchesAnyOrISO(leaseStartDate, ["yyyy-MM-dd", "MM/dd/yyyy", "M/d/yyyy", "M/dd/yyyy", "MM/d/yyyy"])
    ) {
      encounteredError = true;
      errors.leaseStartDate = "Please enter a valid lease start date";
    }

    const firstMonth = this.state.input.firstMonth;
    if (!firstMonth) {
      encounteredError = true;
      errors.firstMonth = "Please enter the first month that a full rent payment is due";
    }
    if (!dateMatchesAnyOrISO(firstMonth, ["yyyy-MM", "MM/yyyy", "M/yyyy"])) {
      errors.firstMonth = "Please enter a valid first month that a full rent payment is due";
    }

    // Update display of errors
    if (encounteredError) {
      this.setState(
        {
          errors: errors,
          loading: false,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  async handleAddTenants() {
    this.setState({ loading: true });
    const encounteredErrors = this.updateErrors();
    if (encounteredErrors) return;

    // Make sure they entered valid amounts and move-in date
    const rentAmount = this.state.input.rentAmount;
    // const refundableAmount = this.state.input.refundableAmount;
    const nonRefundableAmount = this.state.input.nonRefundableAmount;

    let parsedDate;

    if (this.state.input.leaseStartDate === "") {
      parsedDate = undefined;
    } else {
      parsedDate = format(
        parseWithAnyOrISO(this.state.input.leaseStartDate, [
          "yyyy-MM-dd",
          "MM/dd/yyyy",
          "M/d/yyyy",
          "M/dd/yyyy",
          "MM/d/yyyy",
        ]),
        "yyyy-MM-dd",
      );
    }

    const parsedFirstMonth = format(
      parseWithAnyOrISO(this.state.input.firstMonth, ["yyyy-MM", "MM/yyyy", "M/yyyy"]),
      "yyyy-MM",
    );
    for (const index in this.state.newTenants) {
      const data = {
        type: "invitation",
        unitId: this.state.unit.id,
        unitName: this.state.unit.unit_name,
        propertyId: this.state.property.id,
        propertyName: this.state.property.address,
        recipientName: this.state.newTenants[index].name,
        state: "pending",
        rentAmount: rentAmount,
        refundableAmount: 0, // refundableAmount,
        nonrefundableAmount: nonRefundableAmount,
        moveInDate: parsedDate,
        firstMonth: parsedFirstMonth,
        notes: this.state.notes,
      };

      // TODO: Change to function instead of passing a ref to this component
      const chatId = await this.props.messagingPage.createChatDraft(
        [this.state.newTenants[index].id],
        null,
        null,
        true,
      );
      await this.props.messagingPage.sendMessage(chatId, data);
    }
    this.setState({ loading: false });
    this.hide();

    // Success haptic when tenant(s) added successfully
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });

    this.props.showCustomModal("Invited!", "Check your inbox, an invitation was sent to all selected tenants.");

    this.props.onRefresh();
  }

  render() {
    const names = [];
    for (let i = 0; i < this.state.newTenants.length; i++) {
      names.push(this.state.newTenants[i].name);
    }
    const namesString = formatUserList(names);

    return (
      <Modal ref={this.modal} onHide={() => {}} onShow={() => {}} title={"Lease Summary"}>
        <div className="ModalSectionHeader">Rent</div>
        <div className="ModalInputWrapper">
          <CustomCurrencyInput
            ref={this.InputRentAmount}
            icon={faMoneyBillWave}
            errorText={this.state.errors.rentAmount}
            inverted={true}
            onChange={(amount) => {
              this.setState((state) => {
                state.input.rentAmount = amount;
                state.errors.rentAmount = "";
                return state;
              }, this.forceUpdate);
            }}
          />
        </div>

        {/* <div className="ModalSectionHeader">Security deposit (refundable)</div>
        <div className="ModalInputWrapper">
          <CustomCurrencyInput
            ref={this.InputRefundableAmount}
            icon={faMoneyBillWave}
            errorText={this.state.errors.refundableAmount}
            inverted={true}
            onChange={(amount) => {
              this.setState((state) => {
                state.input.refundableAmount = amount;
                state.errors.refundableAmount = "";
                return state;
              }, this.forceUpdate);
            }}
          />
        </div> */}

        <div className="ModalSectionHeader">
          {"Security deposit & other move-in fees"}
          <FontAwesomeIcon
            icon="question-circle"
            data-tip="This amount will not be included<br>in the amount of rent expected<br>from your tenant."
            style={{ paddingLeft: 5 }}
          ></FontAwesomeIcon>
        </div>
        <div className="ModalInputWrapper">
          <CustomCurrencyInput
            ref={this.InputNonRefundableAmount}
            icon={faMoneyBillWave}
            errorText={this.state.errors.nonRefundableAmount}
            inverted={true}
            onChange={(amount) => {
              this.setState((state) => {
                state.input.nonRefundableAmount = amount;
                state.errors.nonRefundableAmount = "";
                return state;
              }, this.forceUpdate);
            }}
          />
        </div>
        <div className="ModalSectionHeader">
          {"First month of rent"}
          <FontAwesomeIcon
            icon="question-circle"
            data-tip="This is the first month that<br>you expect your tenant to<br>pay a full month's rent."
            style={{ paddingLeft: 5 }}
          ></FontAwesomeIcon>
        </div>
        <div className="ModalInputWrapper">
          <CustomInput
            ref={this.InputFirstMonth}
            icon={faCalendar}
            placeholder="MM/YYYY"
            type={"month"}
            errorText={this.state.errors.firstMonth}
            inverted={true}
            readOnly={false}
            onChange={(text) => {
              this.setState((state) => {
                state.input.firstMonth = text;
                state.errors.firstMonth = "";
                return state;
              }, this.forceUpdate);
            }}
          />
        </div>

        <div className="ModalSectionHeader">
          {"Optional: Lease start date"}
          <FontAwesomeIcon
            icon="question-circle"
            data-tip="This date is sent to the tenant<br>as part of this invitation.<br>It has no impact on<br>when rent is due."
            style={{ paddingLeft: 5 }}
          ></FontAwesomeIcon>
        </div>
        <div className="ModalInputWrapper">
          <CustomInput
            ref={this.InputLeaseStartDate}
            icon={faSuitcaseRolling}
            placeholder="MM/DD/YYYY"
            type={"date"}
            errorText={this.state.errors.leaseStartDate}
            inverted={true}
            readOnly={false}
            onChange={(text) => {
              this.setState((state) => {
                state.input.leaseStartDate = text;
                state.errors.leaseStartDate = "";
                return state;
              }, this.forceUpdate);
            }}
          />
        </div>

        <div className="ModalSectionHeader">Other notes</div>
        <div style={{ display: "flex" }}>
          <textarea
            ref={this.InputNotes}
            value={this.state.notes}
            placeholder={"Any additional important lease details"}
            rows={3}
            maxLength={1000}
            style={{
              display: "block",
              resize: "none",
              fontSize: "16px",
              padding: "10px 15px",
              outline: "none",
              border: "none",
              backgroundColor: "#f7f7f7",
              borderRadius: "5px",
              flexGrow: 1,
            }}
            onChange={(e) => {
              const value = e.target.value;
              this.setState({ notes: value });
            }}
          />
        </div>

        <div className="ModalSectionHeader">Add people to this lease</div>
        <div className="ModalInputWrapper">
          <CustomInput
            ref={this.InputSearch}
            icon={faSearch}
            placeholder="Search people"
            type={"text"}
            inverted={true}
            readOnly={false}
            onChange={(text) => {
              this.setState({ searchValue: text });
              this.getSearchUsers(text);
            }}
          />
        </div>

        {this.state.searchValue === "" && this.getCurrentMemberPreviews()}
        {this.state.searchValue !== "" && this.getSearchModalUserPreviews()}

        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleAddTenants();
          }}
        >
          <div>
            <div className="ModalButtonWrapper">
              {this.state.newTenants.length > 0 && (
                <div className="ModalSectionHeader">
                  Inviting {namesString} to {this.state.unit.unit_name}
                </div>
              )}
              <CustomButton
                text={"Invite"}
                inverted={true}
                loading={this.state.loading}
                color={"#4b7bec"}
                submit={true}
                disabled={this.state.newTenants.length === 0}
              />
            </div>
          </div>
        </form>
        <ReactTooltip
          effect="solid"
          type="light"
          clickable={true}
          multiline={true}
          border={true}
          borderColor="#f7f7f7"
          backgroundColor="#fff"
        />
      </Modal>
    );
  }
}

export default ModalAddTenant;
