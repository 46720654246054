import React, { PureComponent } from "react";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import { getPhoneURI } from "../HelperFunctions";

import ManageUnitPreview from "./ManageUnitPreview";
import ManageUserPreview from "./ManageUserPreview";

import { getCurrentUserUid } from "../auth";

import "./ManagePage.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoneyBillWave, faTools, faKey, faCalendarAlt, faArrowFromRight } from "@fortawesome/pro-solid-svg-icons";
import { IPropertyWithUnits, IUnitWithTenants } from "../loadData";
import CustomRefreshButton from "../CustomComponents/CustomRefreshButton/CustomRefreshButton";
import RentalsPropertyList from "./RentalsPropertyList";
import TenantNotesField from "./TenantNotesField";
import {
  AddTenantCallback,
  AddUnitCallback,
  EditUnitCallback,
  MessagePropertyCallback,
  MessageUserCallback,
  RemovePropertyCallback,
  RemoveTenantCallback,
  RemoveUnitCallback,
  RequestRentInfoCallback,
  ShowCustomModalCallback,
  UpdateTenantNotesCallback,
} from "../App";
import { GetUnitsCallback } from "./ManagePage";
library.add(faMoneyBillWave, faTools, faKey, faCalendarAlt, faArrowFromRight);

interface Props {
  showing: boolean;
  loading: boolean;
  properties: IPropertyWithUnits[];
  fundingSources: any[];
  onAddUnit: AddUnitCallback;
  onRemoveTenant: RemoveTenantCallback;
  onMessageUser: MessageUserCallback;
  onUpdateTenantNotes: UpdateTenantNotesCallback;
  onAddTenant: AddTenantCallback;
  showCustomModal: ShowCustomModalCallback;
  onRemoveUnit: RemoveUnitCallback;
  onEditUnit: EditUnitCallback;
  onRemoveProperty: RemovePropertyCallback;
  onMessageProperty: MessagePropertyCallback;
  onAddProperty: () => void;
  onShowPaymentSchedule: () => void;
  onRefresh: () => void;
  onRequestRentInfo: RequestRentInfoCallback;
}
interface State {}

class RentalsSection extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.getUnits = this.getUnits.bind(this);
  }

  getTenants(unit: IUnitWithTenants, property, isManager) {
    return unit.tenants.map((tenant) => {
      const tenantIsSelf = tenant.id === getCurrentUserUid();
      return (
        <ManageUserPreview
          key={"rentals-" + tenant.id}
          id={tenant.id}
          name={tenant.user.full_name}
          imageUrl={tenant.user.profile_pic_url}
          isSelf={tenantIsSelf}
          hasContent={!tenantIsSelf}
        >
          {!tenantIsSelf && (
            <div
              style={{
                padding: "5px 5px",
              }}
            >
              {isManager && (
                <CustomButtonSmall
                  color={"#eb3b5a"}
                  inverted={false}
                  borderOnly={true}
                  text={"Remove"}
                  onClick={() => {
                    this.props.onRemoveTenant({ tenant, unit, property });
                  }}
                />
              )}
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Message"}
                onClick={() => {
                  this.props.onMessageUser({ userId: tenant.id, unitId: unit.id, propertyId: property.id });
                }}
              />
              <a
                href={getPhoneURI(tenant.user.phone)}
                style={{
                  position: "relative",
                  display: "inline-block",
                  color: "#4b7bec",
                  textDecoration: "none",
                }}
              >
                <CustomButtonSmall color={"#4b7bec"} inverted={false} borderOnly={true} text={"Call"} />
              </a>
              {isManager && (
                <TenantNotesField
                  notes={tenant.notes}
                  onUpdateTenantNotes={(notes) => {
                    return this.props.onUpdateTenantNotes({ property, unit, tenant, notes });
                  }}
                />
              )}
            </div>
          )}
        </ManageUserPreview>
      );
    });
  }

  getUnits: GetUnitsCallback = function (property) {
    const isOwner = property.owner_id === getCurrentUserUid();
    return property.units.map((unit) => {
      return (
        <ManageUnitPreview
          key={"rentals-unit-" + unit.id}
          unit={unit}
          canEdit={isOwner}
          showRent={true}
          onRequestRentInfo={this.props.onRequestRentInfo}
        >
          {this.getTenants(unit, property, isOwner)}
          {isOwner && (
            <div
              style={{
                padding: "5px 5px",
              }}
            >
              <CustomButtonSmall
                color={"#20bf6b"}
                inverted={false}
                borderOnly={true}
                text={"Edit Unit"}
                onClick={() => {
                  this.props.onEditUnit({ property, unit });
                }}
              />
              <CustomButtonSmall
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                text={"Invite Tenants"}
                onClick={() => {
                  this.props.onAddTenant({ unit, property });
                }}
              />
              <CustomButtonSmall
                color={"rgba(0,0,0,0.35)"}
                inverted={false}
                borderOnly={true}
                text={"Remove Unit"}
                onClick={() => {
                  this.props.onRemoveUnit({ unit, property });
                }}
              />
            </div>
          )}
        </ManageUnitPreview>
      );
    });
  };

  getProperties() {
    return this.props.properties.map((property, index) => {
      return (
        <RentalsPropertyList
          key={index}
          showing={this.props.showing}
          property={property}
          fundingSources={this.props.fundingSources}
          onMessageUser={this.props.onMessageUser}
          getUnits={this.getUnits}
          showCustomModal={this.props.showCustomModal}
          onAddUnit={this.props.onAddUnit}
          onRemoveProperty={this.props.onRemoveProperty}
          onMessageProperty={this.props.onMessageProperty}
        />
      );
    });
  }

  render() {
    // Whether this user manages at least one property
    // Used to decide whether to show payment schedule options
    const currentUserId = getCurrentUserUid();
    const isOwner = this.props.properties.findIndex((property) => property.owner_id === currentUserId) !== -1;

    return (
      <div
        style={{
          top: "0",
          zIndex: this.props.showing ? 1 : -1,
          position: this.props.showing ? "relative" : "absolute",
          height: this.props.showing ? "auto" : "0",
          overflow: "hidden",
          width: "100%",
          opacity: this.props.showing ? "1" : "0",
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          pointerEvents: this.props.showing ? "auto" : "none",
          transition: "opacity 200ms, transform 200ms",
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          <CustomButtonSmall
            color={"rgba(0,0,0,0.60)"}
            inverted={false}
            borderOnly={true}
            text={"Create New Property"}
            onClick={() => {
              this.props.onAddProperty();
            }}
          />
          {isOwner && (
            <CustomButtonSmall
              color={"rgba(0,0,0,0.60)"}
              inverted={false}
              borderOnly={true}
              text={"Configure Payment Schedule"}
              onClick={() => {
                this.props.onShowPaymentSchedule();
              }}
            />
          )}
          <div style={{ float: "right" }}>
            <CustomRefreshButton loading={this.props.loading} onClick={this.props.onRefresh} />
          </div>
        </div>
        {this.getProperties()}
        {/* {this.getOtherTenantsList()} */}
        {
          // No properties to display
          this.props.properties.length === 0 && (
            <div
              style={{
                fontSize: "14px",
                color: "rgba(0,0,0,0.85)",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              Your rentals will show up here
            </div>
          )
        }
      </div>
    );
  }
}

export default RentalsSection;
