import React, { PureComponent } from "react";
import CustomInput from "../../CustomComponents/CustomInput/CustomInput";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import AddableUserPreview from "../../CustomComponents/AddableUserPreview/AddableUserPreview";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";
import Clickable from "../../Clickable/Clickable";
import { getSearchUsers, formatUserList } from "../../HelperFunctions";
import "./ChatsMenu.css";

// Firebase
import "firebase/firestore";
import "firebase/auth";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Member } from "../MessagingInterfaces";
library.add(faSearch, faTimes);

interface Props {
  showing: boolean;
  loading: boolean;
  onHideCreateChat: () => void;
  onCreateChat: (members: Member[]) => void;
}

interface State {
  currentMembers: Member[];
  searchUsers: Member[];
  searchValue: string;
}

class CreateChat extends PureComponent<Props, State> {
  Input: React.RefObject<CustomInput>;

  constructor(props) {
    super(props);
    this.state = {
      searchValue: "",
      currentMembers: [],
      searchUsers: [],
    };

    this.Input = React.createRef();
  }

  focus() {
    this.Input.current.focus();
  }

  userIsCurrentMember(user) {
    for (let i = 0; i < this.state.currentMembers.length; i++) {
      if (this.state.currentMembers[i].id === user.id) return true;
    }
    return false;
  }

  addMember(user) {
    this.setState((state) => {
      const currentMembers = state.currentMembers;
      currentMembers.push(user);
      return { currentMembers: currentMembers };
    });
  }

  removeMember(user) {
    this.setState((state) => {
      const currentMembers = state.currentMembers;
      for (let i = 0; i < currentMembers.length; i++) {
        if (currentMembers[i].id === user.id) {
          currentMembers.splice(i, 1);
          break;
        }
      }
      return { currentMembers: currentMembers };
    });
  }

  getCurrentMemberPreviews() {
    return this.state.currentMembers.map((user, index) => {
      return (
        <AddableUserPreview
          key={"CurrentMember" + user.id}
          id={user.id}
          name={user.name}
          imageUrl={user.imageUrl}
          adding={false}
          onClick={() => {
            this.removeMember(user);
            this.forceUpdate();
          }}
        />
      );
    });
  }

  getSearchUserPreviews() {
    return this.state.searchUsers.map((user, index) => {
      const isMember = this.userIsCurrentMember(user);
      return (
        <AddableUserPreview
          key={"SearchUser" + user.id}
          id={user.id}
          name={user.name}
          imageUrl={user.imageUrl}
          adding={!isMember}
          onClick={() => {
            // User is a member, remove them
            if (isMember) {
              this.removeMember(user);
            }
            // User is not a member, add them
            else {
              this.addMember(user);
            }
            this.forceUpdate();
          }}
        />
      );
    });
  }

  // TODO [Gannon]: Get some amount of users (maybe cap at like 10-20?) given a search query
  // Update the searchUsers state accordingly (see example below)
  async getSearchUsers(query) {
    const result = await getSearchUsers(query);
    this.setState({ searchUsers: result });
  }

  render() {
    const members = this.state.currentMembers;
    const memberNames = [];
    for (let i = 0; i < members.length; i++) {
      memberNames.push(members[i].name);
    }

    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: this.props.showing || this.props.loading ? "100%" : "0",
          opacity: this.props.showing || this.props.loading ? 1 : 0,
          pointerEvents: this.props.showing || this.props.loading ? "auto" : "none",
          transform: "translateY(" + (this.props.showing || this.props.loading ? "0" : "20px") + ")",
          overflow: "hidden",
          transition: "height 200ms, opacity 200ms, transform 200ms",
          display: "flex",
          boxSizing: "border-box",
          backgroundColor: "#fff",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            boxSizing: "border-box",
            flexGrow: 0,
            padding: "20px 20px 0px 20px",
            position: "relative",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <div
            style={{
              width: "40px",
              height: "40px",
            }}
          >
            <Clickable onClick={this.props.onHideCreateChat}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "rgba(0,0,0,0.85)",
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </Clickable>
          </div>
        </div>
        <div
          style={{
            boxSizing: "border-box",
            padding: "0px 20px 10px 20px",
            position: "relative",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
            display: "block",
          }}
        >
          <CustomInput
            ref={this.Input}
            icon={faSearch}
            placeholder={"Add recipient"}
            inverted={true}
            onChange={(text) => {
              this.setState({ searchValue: text });
              this.getSearchUsers(text);
            }}
          />
        </div>
        <div
          style={{
            flexGrow: 1,
            position: "relative",
            overflow: "scroll",
          }}
        >
          {this.state.searchValue === "" && this.getCurrentMemberPreviews()}
          {this.state.searchValue !== "" && this.getSearchUserPreviews()}
          {this.props.loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "24px",
                color: "rgba(0,0,0,0.85)",
              }}
            >
              <LoadingIcon />
            </div>
          )}
        </div>
        <div
          style={{
            padding: "10px 10px 20px 10px",
            flexShrink: 0,
            borderTop: "1px solid rgba(0, 0, 0, 0.05)",
          }}
        >
          <div
            style={{
              padding: "0px 10px 10px 10px",
              color: "rgba(0,0,0,0.50)",
              fontSize: "12px",
              textAlign: "right",
            }}
          >
            {this.state.currentMembers.length} {this.state.currentMembers.length === 1 ? "recipient" : "recipients"}
          </div>
          {this.state.currentMembers.length > 0 && (
            <div
              style={{
                padding: "0px 10px 10px 10px",
                color: "rgba(0,0,0,0.85)",
                fontSize: "14px",
                textAlign: "left",
              }}
            >
              {formatUserList(memberNames)}
            </div>
          )}
          <CustomButton
            text="Create Chat"
            color={"#4b7bec"}
            inverted={true}
            disabled={this.state.currentMembers.length === 0}
            loading={this.props.loading}
            onClick={() => {
              if (typeof this.props.onCreateChat === "function") {
                this.props.onCreateChat(this.state.currentMembers);
                this.setState({
                  searchValue: "",
                  currentMembers: [],
                  searchUsers: [],
                });
                this.Input.current.clear();
              }
            }}
          />
        </div>
      </div>
    );
  }
}

export default CreateChat;
