import React, { PureComponent } from "react";

import AuthenticationWindow from "../AuthenticationWindow/AuthenticationWindow";
import ChangingWord from "./ChangingWord";
import { ReactComponent as Logo } from "../assets/logo_white.svg";

interface Props {
  onSuccessfulLogin: () => void;
  onSuccessfulSignOut: () => void;
  recievedAuthState: () => void;
  onShowPrivacyPolicy: () => void;
  onShowTermsOfService: () => void;
  showGenericModal: (title: string, body: string, unhidable?: boolean) => void;
  onUpdateProgress: (progress: number) => void;
}

class Header extends PureComponent<Props> {
  render() {
    return (
      <div
        style={{
          width: "100%",
          minHeight: "500px",
          position: "relative",
          background: "linear-gradient(175deg, #2A64AD 0%, #2A64AD 80%, #fff 80%, #fff 100%)",
          padding: "35px 45px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "800px",
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <div
            className="LandingHeaderLeft"
            style={{
              boxSizing: "border-box",
              position: "relative",
              padding: "45px 10px",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "inline-block",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div
                style={{
                  fontSize: "32px",
                  color: "#fff",
                  fontFamily: '"Roboto Slab", sans-serif',
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  fontWeight: 500,
                }}
              >
                <Logo className="LandingLogo" />
                <div>Rentingway</div>
              </div>
              <div
                className="LandingHeaderSubtitle"
                style={{
                  color: "#fff",
                  fontFamily: '"Roboto Slab", sans-serif',
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    flexShrink: 0,
                    opacity: "0.7",
                    height: "1em",
                    lineHeight: "1",
                    marginBottom: "3px",
                  }}
                >
                  The best way to&nbsp;
                </div>
                <ChangingWord words={["rent", "connect", "manage", "meet", "pay", "communicate", "repair"]} />
              </div>
            </div>
          </div>
          <div
            className="LandingHeaderRight"
            style={{
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "15px 10px",
                display: "inline-block",
              }}
            >
              <AuthenticationWindow
                onSuccessfulLogin={this.props.onSuccessfulLogin}
                onSuccessfulSignOut={this.props.onSuccessfulSignOut}
                recievedAuthState={this.props.recievedAuthState}
                onShowPrivacyPolicy={this.props.onShowPrivacyPolicy}
                onShowTermsOfService={this.props.onShowTermsOfService}
                showGenericModal={this.props.showGenericModal}
                onUpdateProgress={this.props.onUpdateProgress}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
