import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

interface Props {
  onDeleteChat: (chatId: string) => void;
}

interface State {
  chatId: string | null;
}

class ModalDeleteChat extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      chatId: null,
    };

    this.handleDeleteChat = this.handleDeleteChat.bind(this);
  }

  show(chatId: string) {
    this.setState({
      chatId: chatId,
    });
    this.modal.current.show();
  }
  hide() {
    this.modal.current.hide();
  }

  handleDeleteChat() {
    this.props.onDeleteChat(this.state.chatId);
    this.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Remove Chat?"}>
        <div className="ModalText">
          Are you sure you want to remove this chat? Your message history will be saved, and this chat will be removed
          from your inbox.
        </div>
        <div className="ModalButtonWrapper">
          <CustomButton text={"Remove"} inverted={true} color={"#eb3b5a"} onClick={this.handleDeleteChat} />
        </div>
      </Modal>
    );
  }
}

export default ModalDeleteChat;
