import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import { Plugins, HapticsImpactStyle, Capacitor } from "@capacitor/core";
import "./OptionSwitch.css";
const { Haptics } = Plugins;

interface Props {
  options: string[];
  onChange: (index: number) => any;
}
interface State {
  currentOption: number;
}

class OptionSwitch extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentOption: 0,
    };
  }

  setValue(value) {
    this.setState({
      currentOption: value,
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(value);
    }
  }

  getOptions() {
    return this.props.options.map((option, index) => {
      return (
        <div
          key={index}
          className="OptionSwitchItem"
          style={{
            color: this.state.currentOption === index ? "#fff" : "rgba(0,0,0,0.85)",
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: this.state.currentOption === index ? "100%" : "0",
              background: "#4b7bec",
              transition: "width 200ms",
              borderRadius: "12px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          ></div>
          <Clickable
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              this.setState({ currentOption: index });
              if (typeof this.props.onChange === "function") {
                this.props.onChange(index);
              }
            }}
          >
            <div
              style={{
                padding: "4px 12px",
                fontSize: "12px",
              }}
            >
              {option}
            </div>
          </Clickable>
        </div>
      );
    });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          height: "auto",
          borderRadius: "12px",
          backgroundColor: "#f7f7f7",
          display: "inline-flex",
          flexWrap: "wrap",
        }}
      >
        {this.getOptions()}
      </div>
    );
  }
}

export default OptionSwitch;
