import React, { PureComponent } from "react";

interface Props {
  words: string[];
}
interface State {
  currentWord: number;
  interval: NodeJS.Timeout;
}

class ChangingWord extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      currentWord: 0,
      interval: undefined,
    };
  }

  getWords() {
    return this.props.words.map((word, index) => {
      return (
        <div
          key={word}
          style={{
            opacity: this.state.currentWord === index ? "1" : "0",
            position: "relative",
          }}
        >
          {word}
        </div>
      );
    });
  }

  componentDidMount() {
    const interval = setInterval(() => {
      const newWord = (this.state.currentWord + 1) % this.props.words.length;
      this.setState({ currentWord: newWord });
    }, 2000);
    this.setState({ interval: interval });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-block",
          flexShrink: 0,
          height: "1em",
          lineHeight: "1",
        }}
      >
        <div
          style={{
            height: "100%",
            position: "relative",
            display: "inline-block",
            overflow: "visible",
          }}
        >
          {/* sliding */}
          <div
            style={{
              position: "relative",
              top: "-" + this.state.currentWord * 100 + "%",
              transition: "top 200ms",
              // fontWeight: '500',
            }}
          >
            {this.getWords()}
          </div>
        </div>
      </div>
    );
  }
}

export default ChangingWord;
