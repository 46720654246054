import React, { PureComponent } from "react";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import "./SettingsPage.css";

interface Props {
  id: string;
  bankAccountType: "checking" | "savings" | "general-ledger" | "loan";
  primary: boolean;
  name: string;
  status: string;
  type: string;
  bankName: string;
  loadingPrimaryFundingSource: boolean;
  microDepositPending: boolean;

  onRemove(): void;
  onMakePrimary(): void;
  onVerifyMicroDeposits(): void;
}

class FundingSourcePreview extends PureComponent<Props> {
  render() {
    let typeString = "Funding source";
    if (this.props.bankAccountType === "checking") typeString = "Checking";
    if (this.props.bankAccountType === "savings") typeString = "Savings";
    if (this.props.bankAccountType === "general-ledger") typeString = "General ledger";
    if (this.props.bankAccountType === "loan") typeString = "Loan";

    return (
      <div
        style={{
          border: "1px solid " + (this.props.primary ? "#4b7bec" : "#dadce0"),
          backgroundColor: this.props.primary ? "rgba(75, 123, 236,0.05)" : "transparent",
          borderRadius: "8px",
          marginTop: "10px",
          padding: "15px 15px",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            marginBottom: "5px",
            color: this.props.primary ? "#3867d6" : "rgba(0,0,0,0.85)",
          }}
        >
          {this.props.name}
        </div>
        <div
          style={{
            fontSize: "12px",
            marginBottom: "5px",
          }}
        >
          {this.props.primary && (
            <span
              style={{
                color: "#4b7bec",
              }}
            >
              Primary Account ·&nbsp;
            </span>
          )}
          <span
            style={{
              color: "rgb(142, 142, 147)",
            }}
          >
            {typeString} · {this.props.status === "verified" ? "Verified" : "Unverified"}
          </span>
        </div>
        <div
          style={{
            fontWeight: 500,
            fontSize: "12px",
            color: "rgb(142, 142, 147)",
            marginBottom: "10px",
          }}
        >
          {this.props.type === "bank" ? this.props.bankName : "BALANCE"}
        </div>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <CustomButtonSmall
            color={"rgba(0, 0, 0, 0.35)"}
            inverted={false}
            borderOnly={true}
            text={"Remove"}
            onClick={() => {
              this.props.onRemove();
            }}
          />
          {!this.props.primary && (
            <CustomButtonSmall
              color={"#4b7bec"}
              inverted={false}
              borderOnly={true}
              text={"Make Primary"}
              loading={this.props.loadingPrimaryFundingSource}
              onClick={() => {
                this.props.onMakePrimary();
              }}
            />
          )}
          {this.props.status === "unverified" && (
            <CustomButtonSmall
              color={"#14c70e"}
              inverted={false}
              borderOnly={true}
              text={this.props.microDepositPending ? "Verify Deposits" : "Waiting For Deposits"}
              onClick={() => {
                this.props.onVerifyMicroDeposits();
              }}
              disabled={!this.props.microDepositPending}
            />
          )}
          {this.props.status === "unverified" && !this.props.microDepositPending && (
            <div
              style={{
                color: "rgba(0,0,0,0.35)",
                fontSize: "12px",
                paddingTop: "10px",
              }}
            >
              We're waiting for micro-deposits to be made to this account. Please check back in 1-3 business days to
              verify the amounts.
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default FundingSourcePreview;
