import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

class ModalVerificationSuccessful extends PureComponent {
  modal = React.createRef<Modal>();

  show() {
    this.modal.current.show();
  }
  hide() {
    this.modal.current.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Verification Successful"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.hide();
          }}
        >
          <div className="ModalText">
            Congratulations, your identity has been verified! You can now send and receieve payments.
          </div>
          <div className="ModalButtonWrapper">
            <CustomButton text={"Got it"} inverted={true} color={"#2A3950"} submit={true} />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalVerificationSuccessful;
