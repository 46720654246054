import React, { PureComponent } from "react";

import "./Modal.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
library.add(faTimes);

interface Props {
  title: string;
  unhidable?: boolean;
  onHide?: () => void;
  onShow?: () => void;
}

interface State {
  showing: boolean;
}

class Modal extends PureComponent<Props, State> {
  modal = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      showing: false,
    };
  }

  hide() {
    this.setState({ showing: false });
    if (typeof this.props.onHide === "function") this.props.onHide();
  }
  show() {
    this.setState({ showing: true });
    this.forceUpdate();
    if (typeof this.props.onShow === "function") this.props.onShow();
  }

  /* Render */
  render() {
    return (
      <div
        ref={this.modal}
        className="ModalOverlay"
        style={{
          pointerEvents: this.state.showing ? "auto" : "none",
          top: this.state.showing ? "0" : "-100%",
          touchAction: this.state.showing ? "auto" : "none",
          boxSizing: "border-box",
          willChange: "opacity",
          opacity: this.state.showing ? 1 : 0,
          transition: "opacity 200ms, top 0ms linear " + (this.state.showing ? "0" : "200") + "ms",
        }}
        onClick={() => {
          if (!this.props.unhidable) this.hide();
        }}
      >
        <div
          style={{
            position: "relative",
            transform: "translateY(" + (this.state.showing ? "0" : "-20") + "px)",
            willChange: "transform",
            transition: "transform 200ms",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "30px",
              marginBottom: "30px",
              backgroundColor: "#fff",
              width: "90%",
              boxSizing: "border-box",
              maxWidth: "480px",
              left: "50%",
              transform: "translateX(-50%)",
              borderRadius: "10px",
              padding: this.props.unhidable ? "40px 25px 30px 25px" : "50px 25px 35px 25px",
              boxShadow: "0 0 5px rgba(0,0,0,0.15)",
              overflowX: "hidden",
            }}
            onTouchStart={(e) => {
              e.stopPropagation();
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!this.props.unhidable && (
              <div
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  right: "10px",
                  top: "5px",
                  padding: "10px",
                  fontSize: "22px",
                  lineHeight: "1.0",
                  color: "rgba(0,0,0,0.85)",
                }}
                onTouchStart={(e) => {
                  e.stopPropagation();
                  this.hide();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                }}
                onClick={(e) => {
                  if (!this.props.unhidable) this.hide();
                }}
              >
                <FontAwesomeIcon icon="times" />
              </div>
            )}
            {this.props.title && (
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: 500,
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                {this.props.title}
              </div>
            )}
            <div>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
