import React, { PureComponent } from "react";
import { Capacitor, Plugins } from "@capacitor/core";
import { FCM } from "@capacitor-community/fcm";

import Clickable from "../Clickable/Clickable";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import { formatMoneyAmount } from "../HelperFunctions";
import FundingSourcePreview from "./FundingSourcePreview";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";
import PaymentsSetup from "./PaymentsSetup";
import { getBioAuthMethodName } from "../BioAuth";
import "./SettingsPage.css";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Storage } = Plugins;

const fcm = new FCM();

const VERSION_STRING = process.env.REACT_APP_COMMIT
  ? `v${process.env.REACT_APP_VERSION} (${process.env.REACT_APP_COMMIT.slice(0, 7)})`
  : `v${process.env.REACT_APP_VERSION}`;

interface Props {
  position: number;
  dwollaCustomerType: null | "unverified" | "verified";
  dwollaCustomerId: null | string;
  loadingDwolla: boolean;
  fundingSources: Array<any>;
  balance: number;
  balanceId: null | string;

  primaryFundingSourceId: null | string;
  onMakePrimary: (fundingSourceId: string) => void;

  /**
   * @param id Funding source id to remove
   * @param name Name of funding source to remove
   */
  onRemoveFundingSource(id: string, name: string): void;
  onVerifyMicroDeposits(id: string): void;
  onCertifyBusiness(): void;
  onSuccessfulSignOut(): void;
  onSuccessfulExport: () => void;
  onAddBank(): void;
  onAddProperty(): void;
  onShowTermsOfService(): void;
  onShowPrivacyPolicy(): void;

  paymentsSetupComplete: boolean;
  paymentSent: boolean;
  onUpdatePaymentsSetupComplete: (complete: boolean) => void;
}

interface State {
  loadingPrimaryFundingSource: boolean;
  loadingFundingSourceId: string | null;
  loadingSignOut: boolean;
  // Whether this user manages at least one property
  managesProperty: boolean;
  loadingExport: boolean;
  //exportRequested: boolean;

  dwollaCustomerId: string;
  dwollaCustomerType: string;
  dwollaVerificationStatus: string;

  bioAuthAvailable: boolean;
  // undefined: user hasn't been asked whether they'd like to use bio-auth
  // false: user opted to set up bio-auth later in settings
  // true: user already opted to set up bio-auth
  bioAuthPreference: null | boolean;
  bioAuthMethodName: string;

  lastCompletedStep: number;
  currentStep: number;
  currentStepSubtitle: string;
  currentStepDescription: string;
  paymentsSetupComplete: boolean;
}

class SettingsPage extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loadingPrimaryFundingSource: false,
      loadingFundingSourceId: null,
      loadingSignOut: false,
      managesProperty: false,
      loadingExport: false,
      //exportRequested: false,
      dwollaCustomerId: undefined,
      dwollaCustomerType: undefined,
      dwollaVerificationStatus: undefined,
      // Bio-auth
      bioAuthAvailable: false,
      bioAuthPreference: null,
      bioAuthMethodName: "Biometic Authentication",
      // Payments setup
      lastCompletedStep: -1,
      currentStep: -1,
      currentStepSubtitle: "",
      currentStepDescription: "",
      paymentsSetupComplete: false,
    };

    this.handleRemove = this.handleRemove.bind(this);
    this.handleVerifyMicroDeposits = this.handleVerifyMicroDeposits.bind(this);
    this.handleUpdateBioAuthPreference = this.handleUpdateBioAuthPreference.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.transferBalance = this.transferBalance.bind(this);
    this.handleUpdateManagerStatus = this.handleUpdateManagerStatus.bind(this);
    this.requestDataExport = this.requestDataExport.bind(this);
  }

  handleUpdateManagerStatus(managesProperty) {
    this.setState({
      managesProperty: managesProperty,
    });
  }

  transferBalance() {
    firebase.functions().httpsCallable("transferBalanceToPrimaryFundingSource")();
  }

  handleRemove(id, name) {
    this.props.onRemoveFundingSource(id, name);
  }

  handleVerifyMicroDeposits(id) {
    this.props.onVerifyMicroDeposits(id);
  }

  getFundingSourcePreviews() {
    return this.props.fundingSources.map((fundingSource) => {
      return (
        <FundingSourcePreview
          key={fundingSource.id}
          id={fundingSource.id}
          name={fundingSource.name}
          type={fundingSource.type}
          bankName={fundingSource.bankName}
          bankAccountType={fundingSource.bankAccountType}
          status={fundingSource.status}
          primary={this.props.primaryFundingSourceId === fundingSource.id}
          loadingPrimaryFundingSource={
            this.state.loadingPrimaryFundingSource && this.state.loadingFundingSourceId === fundingSource.id
          }
          onMakePrimary={async () => {
            this.setState({ loadingPrimaryFundingSource: true, loadingFundingSourceId: fundingSource.id });
            await this.props.onMakePrimary(fundingSource.id);
            this.setState({ loadingPrimaryFundingSource: false, loadingFundingSourceId: null });
          }}
          onRemove={() => {
            this.handleRemove(fundingSource.id, fundingSource.name);
          }}
          microDepositPending={fundingSource._links["verify-micro-deposits"] ? true : false}
          onVerifyMicroDeposits={() => {
            this.handleVerifyMicroDeposits(fundingSource.id);
          }}
        />
      );
    });
  }

  async handleSignOut() {
    // @ts-ignore
    if ("activeElement" in document) document.activeElement.blur();

    this.setState({ loadingSignOut: true });

    if (Capacitor.isNative) {
      const tokenToRemove = await fcm.getToken();
      await firebase.functions().httpsCallable("unregisterFCMToken")({ token: tokenToRemove });
    }
    await firebase.auth().signOut();

    this.setState({ loadingSignOut: false });
    if (typeof this.props.onSuccessfulSignOut === "function") {
      this.props.onSuccessfulSignOut();
    }
  }

  async requestDataExport() {
    this.setState({ loadingExport: true });
    const response = await firebase.functions().httpsCallable("dataExport-propertiesSummary")();
    this.setState({ loadingExport: false });

    this.props.onSuccessfulExport();

    // TODO: handle this response
    console.log(response);
  }

  componentDidMount() {
    this.updateBioAuth();
    this.updatePaymentsSetupProgress();
  }
  componentDidUpdate() {
    this.updateBioAuth();
    this.updatePaymentsSetupProgress();
  }

  updateBioAuth() {
    if (Capacitor.isNative) {
      FingerprintAIO.isAvailable()
        .then(async (availableResp) => {
          const { value: bioAuthPreference } = await Storage.get({ key: "bioAuthPreference" });
          const pref = JSON.parse(bioAuthPreference);

          const methodName = getBioAuthMethodName(availableResp);

          this.setState({
            bioAuthAvailable: true,
            bioAuthPreference: pref,
            bioAuthMethodName: methodName,
          });
        })
        // Bio-auth is not available
        .catch((e) => {
          console.log("avail-err", e);
          //this.setState({ bioAuthAvailable: false });
        });
    }
  }

  // Authenticate the user and change their bio-auth preference
  handleUpdateBioAuthPreference(newPreference: boolean) {
    if (this.state.bioAuthAvailable) {
      FingerprintAIO.show({
        description: "Enable payment security",
      })
        .then(async () => {
          // Update preference only after successful auth
          this.setState({ bioAuthPreference: newPreference });
          await Storage.set({
            key: "bioAuthPreference",
            value: JSON.stringify(newPreference),
          });
        })
        // Bio-auth failed
        .catch((e) => {
          console.log("auth-err", e);
        });
    }
  }

  updatePaymentsSetupProgress() {
    let lastCompletedStep = -1;
    let currentStep = -1;
    let currentStepSubtitle = "";
    let currentStepDescription = "";
    let paymentsSetupComplete = false;

    // Update progress and return
    const updateProgress = () => {
      this.setState({
        lastCompletedStep: lastCompletedStep,
        currentStep: currentStep,
        currentStepSubtitle: currentStepSubtitle,
        currentStepDescription: currentStepDescription,
      });
      this.props.onUpdatePaymentsSetupComplete(paymentsSetupComplete);
    };

    let microDepositsMadeInFundingSource = false;
    let fundingSourceVerified = false;
    this.props.fundingSources.forEach((fundingSource) => {
      if (fundingSource._links["verify-micro-deposits"]) microDepositsMadeInFundingSource = true;
      if (fundingSource.status === "verified") fundingSourceVerified = true;
    });

    // All done setting up
    // There is at least one funding source, and it is verified (either through micro-deposits or instant verification)
    if (this.props.fundingSources.length > 0 && fundingSourceVerified) {
      paymentsSetupComplete = true;
      lastCompletedStep = 2;
      currentStep = 2;
      currentStepSubtitle = "You're all set!";
      currentStepDescription =
        "You can now send and receive payments in messages. Your complete payment history is always available in the payments section.";
      updateProgress();
      return;
    }

    // Micro-deposits have been made for at least one funding source
    if (microDepositsMadeInFundingSource) {
      lastCompletedStep = 0;
      currentStep = 1;
      currentStepSubtitle = "Check your account for micro-deposits.";
      currentStepDescription =
        'Please check your bank account for two small deposits. Click "Verify Deposits" on your account below to confirm those amounts.';
      updateProgress();
      return;
    }

    // Customer has at least one funding source
    if (this.props.fundingSources.length > 0) {
      lastCompletedStep = 0;
      currentStep = 1;
      currentStepSubtitle = "We need to verify this account belongs to you.";
      currentStepDescription =
        "Please check your bank account in 1-3 business days for two small deposits. We are going to ask you to confirm those amounts here. Check back here soon!";
      updateProgress();
      return;
    }

    // Customer exists with Dwolla
    // If they're still on the first step, they only need to add a bank account to move on
    if (this.props.dwollaCustomerType !== null) {
      lastCompletedStep = -1;
      currentStep = 0;
      currentStepSubtitle = "Let's set up your bank account! It's fast and secure.";
      currentStepDescription = 'Click the "Add Account" button below to link your bank account.';
      updateProgress();
      return;
    }

    // The customer doesn't exist with Dwolla and doesn't have any accoutns
    lastCompletedStep = -1;
    currentStep = 0;
    currentStepSubtitle = "Let's set up your bank account! It's fast and secure.";
    currentStepDescription =
      "We'll start by verifying your identity and walking you through the process of adding an account.";
    updateProgress();
  }

  render() {
    let tooltipData = "Loading payment status...";
    if (this.props.dwollaCustomerType === null && !this.props.loadingDwolla)
      tooltipData = "Verify your identity and add an account<br>to start sending and receiving payments";
    if (this.props.dwollaCustomerType === "unverified") tooltipData = "You can currently send up to $5,000 per week";
    if (this.props.dwollaCustomerType === "verified")
      tooltipData = "You can currently send up $10,000 per transaction,<br>with no weekly limit.";

    let hasVerifiedFundingSource = false;
    this.props.fundingSources.forEach((fundingSource) => {
      if (fundingSource.status === "verified") hasVerifiedFundingSource = true;
    });

    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflowY: "scroll",
          overflowX: "hidden",
          transform: "translateX(" + (this.props.position === 0 ? "0" : this.props.position * 100 + "%") + ")",
          transition: "transform 200ms",
          paddingTop: "15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* wrapper */}
        <div className="SettingsContentWrapper">
          {/* container */}
          <div className="SettingsContentContainer">
            {!hasVerifiedFundingSource && !this.props.loadingDwolla && (
              <div className="SettingsBanner">
                <div className="SettingsIndicatorBadge"></div>
                <span>
                  Follow the steps below for linking your bank account to start sending and receiving payments on
                  Rentingway
                </span>
              </div>
            )}
            <div className="SettingsSectionHeader">
              Payments
              <FontAwesomeIcon
                icon="question-circle"
                data-tip={tooltipData}
                style={{ paddingLeft: 5 }}
              ></FontAwesomeIcon>
            </div>
            {this.props.balance > 0 && (
              <div
                style={{
                  paddingBottom: "10px",
                  paddingTop: "5px",
                }}
              >
                <div
                  style={{
                    padding: "0 15px",
                    fontWeight: 400,
                    margin: "5px 0",
                  }}
                >
                  Balance: {formatMoneyAmount(this.props.balance)}
                </div>
                <CustomButtonSmall
                  color={"rgba(0,0,0,0.60)"}
                  inverted={false}
                  borderOnly={true}
                  text={"Transfer Balance to Primary Account"}
                  onClick={() => {
                    this.transferBalance();
                  }}
                />
              </div>
            )}
            {!(this.props.paymentSent && hasVerifiedFundingSource) && !this.props.loadingDwolla && (
              <PaymentsSetup
                stepTitles={["Start", "Security", "Complete!"]}
                currentStep={this.state.currentStep}
                lastCompletedStep={this.state.lastCompletedStep}
                currentStepSubtitle={this.state.currentStepSubtitle}
                currentStepDescription={this.state.currentStepDescription}
              />
            )}
            {this.getFundingSourcePreviews()}
            <div className="SettingsButtonWrapper">
              <CustomButton
                loading={this.props.loadingDwolla}
                disabled={this.props.loadingDwolla}
                text="Add Account"
                inverted={true}
                color={"#2A3950"}
                onClick={this.props.onAddBank}
              />
            </div>
            {this.props.fundingSources.length === 0 && (
              <div
                style={{
                  position: "relative",
                  marginTop: "5px",
                  padding: "15px 15px",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "rgba(0,0,0,0.35)",
                }}
              >
                Click "Add Account" to start sending and receiving payments.
              </div>
            )}

            {/* Set up bio-auth */}
            {this.state.bioAuthAvailable && !this.state.bioAuthPreference && (
              <div>
                <div className="SettingsButtonWrapper">
                  <CustomButton
                    loading={false}
                    text={"Set Up " + this.state.bioAuthMethodName}
                    inverted={true}
                    color={"#2A3950"}
                    onClick={() => {
                      this.handleUpdateBioAuthPreference(true);
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    marginTop: "5px",
                    padding: "15px 15px",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "rgba(0,0,0,0.35)",
                  }}
                >
                  Use {this.state.bioAuthMethodName} to send payments more securely.
                </div>
              </div>
            )}

            {/* Turn off bio-auth */}
            {this.state.bioAuthAvailable && this.state.bioAuthPreference && (
              <div>
                <div className="SettingsButtonWrapper">
                  <CustomButton
                    loading={false}
                    text={"Turn Off " + this.state.bioAuthMethodName}
                    inverted={false}
                    color={"#2A3950"}
                    onClick={() => {
                      this.handleUpdateBioAuthPreference(false);
                    }}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    marginTop: "5px",
                    padding: "15px 15px",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "rgba(0,0,0,0.35)",
                  }}
                >
                  {this.state.bioAuthMethodName} is recommended because it can help make sending payments more secure.
                </div>
              </div>
            )}

            {/* Add a property */}
            {!this.state.managesProperty && (
              <div>
                <div className="SettingsSectionHeader">Become a Property Manager</div>
                <div className="SettingsButtonWrapper">
                  <CustomButton
                    loading={this.props.loadingDwolla}
                    disabled={this.props.loadingDwolla}
                    text="Create New Property"
                    inverted={true}
                    color={"#2A3950"}
                    onClick={this.props.onAddProperty}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    marginTop: "5px",
                    padding: "15px 15px",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "rgba(0,0,0,0.35)",
                  }}
                >
                  Click "Create New Property" to become a property manager. You can manage your properties in the Manage
                  section.
                </div>
              </div>
            )}
            {/* Request export */}
            {this.state.managesProperty && (
              <div>
                <div className="SettingsSectionHeader">Data Export</div>
                <div className="SettingsButtonWrapper">
                  <CustomButton
                    loading={this.state.loadingExport}
                    //disabled={this.state.exportRequested}
                    text="Request Data Export"
                    inverted={true}
                    color={"#2A3950"}
                    onClick={this.requestDataExport}
                  />
                </div>
                <div
                  style={{
                    position: "relative",
                    marginTop: "5px",
                    padding: "15px 15px",
                    textAlign: "center",
                    fontSize: "14px",
                    color: "rgba(0,0,0,0.35)",
                  }}
                >
                  We'll email you an automatically-populated spreadsheet with all of your property information.
                </div>
              </div>
            )}

            <div className="SettingsSectionHeader">Account Settings</div>
            <div className="SettingsButtonWrapper">
              <CustomButton
                loading={this.state.loadingSignOut}
                text="Sign Out"
                inverted={false}
                color={"#2A3950"}
                onClick={this.handleSignOut}
              />
            </div>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                fontSize: "14px",
                color: "rgba(0,0,0,0.85)",
                paddingBottom: "40px",
              }}
            >
              <div>
                <span>
                  <Clickable inline={true} onClick={this.props.onShowTermsOfService}>
                    Terms of Service
                  </Clickable>
                </span>
                <span> · </span>
                <span>
                  <Clickable inline={true} onClick={this.props.onShowPrivacyPolicy}>
                    Privacy Policy
                  </Clickable>
                </span>
              </div>
              <div
                style={{
                  position: "relative",
                  marginTop: "2px",
                  padding: "8px 8px",
                  textAlign: "center",
                  fontSize: "12px",
                  color: "rgba(0,0,0,0.35)",
                }}
              >
                {VERSION_STRING}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SettingsPage;
