import React, { PureComponent } from "react";
import { formatTimeString } from "../HelperFunctions";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import Clickable from "../Clickable/Clickable";
import "./ManagePage.css";
import "firebase/auth";

interface Props {
  imageUrls?: string[];
  open: boolean;
  timestamp: number;
  subject: string;
  text: string;
  isManager: boolean;
  onViewImage: (imageUrl: string) => void;
  onUpdateWorkOrderStatus: (open: boolean) => void;
}

interface State {
  loading: boolean;
}

class ManageWorkOrder extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  getImages() {
    return this.props.imageUrls.map((imageUrl, index) => {
      return (
        <div
          key={index}
          style={{
            width: "95px",
            height: "95px",
            flexShrink: 0,
            margin: "0 5px 5px 0",
            backgroundImage: "url(" + imageUrl + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundColor: "#f7f7f7",
            borderRadius: "6px",
          }}
        >
          <Clickable
            onClick={() => {
              this.props.onViewImage(imageUrl);
            }}
          ></Clickable>
        </div>
      );
    });
  }

  componentDidUpdate(nextProps) {
    if (this.props.open !== nextProps.open) {
      this.setState({ loading: false });
    }
  }

  render() {
    const color = this.props.open ? "#eb3b5a" : "rgba(0,0,0,0.85)";
    const statusString = this.props.open ? "Open" : "Closed";

    // Replace consecutive newlines with a single newline
    // https://stackoverflow.com/questions/46548564/how-to-remove-all-adjacent-newlines-and-whitespaces-in-javascript-and-replace-th
    let text = this.props.text.trim();
    text = text.replace(/(?: *[\n\r])\1/g, "\n\n");
    const textBlocks = text.split("\n\n");
    const textBlockEls = textBlocks.map((textBlock, index) => {
      if (textBlock === "") return null;
      return (
        <div
          key={index}
          style={{
            marginTop: index === 0 ? "0" : "10px",
          }}
        >
          {textBlock.trim()}
        </div>
      );
    });

    return (
      <div
        style={{
          margin: "10px 0",
          marginLeft: "15px",
          padding: "10px 10px 10px 20px",
          borderLeft: "2px solid " + color,
        }}
      >
        <div
          className="AllowTextSelection"
          style={{
            fontSize: "18px",
            display: "flex",
            marginBottom: "5px",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              color: color,
              fontWeight: 500,
              marginRight: "10px",
              flexGrow: 0,
            }}
          >
            {statusString}
          </div>
          <div
            style={{
              fontSize: "12px",
              opacity: "0.5",
              marginBottom: "5px",
              fontWeight: 500,
              color: "#8E8E93",
            }}
          >
            {formatTimeString(this.props.timestamp)}
          </div>
        </div>
        <div
          className="AllowTextSelection"
          style={{
            flexGrow: 1,
            fontSize: "18px",
            fontWeight: 500,
            marginBottom: "5px",
          }}
        >
          {this.props.subject}
        </div>
        <div
          className="AllowTextSelection"
          style={{
            fontSize: "14px",
            marginBottom: "5px",
            whiteSpace: "pre",
          }}
        >
          {textBlockEls}
        </div>
        {this.props.imageUrls && this.props.imageUrls.length > 0 && (
          <div
            style={{
              padding: "10px 0 0 0",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {this.getImages()}
          </div>
        )}
        {this.props.isManager && (
          <div
            style={{
              paddingTop: "10px",
            }}
          >
            {this.props.open && (
              <CustomButtonSmall
                color={color}
                inverted={false}
                borderOnly={true}
                text={"Close Work Order"}
                loading={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props.onUpdateWorkOrderStatus(false);
                }}
              />
            )}
            {!this.props.open && (
              <CustomButtonSmall
                color={color}
                inverted={false}
                borderOnly={true}
                text={"Reopen Work Order"}
                loading={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props.onUpdateWorkOrderStatus(true);
                }}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ManageWorkOrder;
