import { IonCol, IonGrid, IonRow } from "@ionic/react";
import {
  parseISO,
  format,
  subMonths,
  subDays,
  differenceInMonths,
  set,
  isAfter,
  addMonths,
  startOfDay,
  setDate,
} from "date-fns";
import React, { PureComponent } from "react";
import { formatMoneyAmount, nth } from "../HelperFunctions";
import { IUnitWithTenants } from "../loadData";
import Modal from "./Modal";

import "./ModalRentInfo.css";

interface State {
  unit: IUnitWithTenants;
}

class ModalRentInfo extends PureComponent<{}, State> {
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      unit: null,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(unit: IUnitWithTenants) {
    this.setState({ unit });
    this.modal.current.show();
  }
  hide() {
    this.modal.current.hide();
  }

  render() {
    const infoLines: string[][] = [];

    if (this.state.unit) {
      const shouldIncludeNextMonthThreshold = startOfDay(
        subDays(setDate(addMonths(new Date(), 1), this.state.unit.rent_due_date), this.state.unit.rent_due_date_offset),
      );

      const shouldIncludeNextMonth = isAfter(new Date(), shouldIncludeNextMonthThreshold);

      infoLines.push([`${this.state.unit.propertyName} - ${this.state.unit.unit_name}`]);
      infoLines.push([this.state.unit.tenants.map((tenant) => tenant.user.full_name).join(", ")]);

      infoLines.push([
        `Rent is due on the ${nth(this.state.unit.rent_due_date)} of the month. Rent is added to totals ${
          this.state.unit.rent_due_date_offset
        } ${this.state.unit.rent_due_date_offset === 1 ? "day" : "days"} in advance.`,
      ]);

      for (const [i, v] of this.state.unit.rent_history.entries()) {
        const startRange = parseISO(v.date);
        const currentMonth = set(new Date(), { date: 1, hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

        let endRangeInclusive: Date;
        if (i < this.state.unit.rent_history.length - 1) {
          endRangeInclusive = subMonths(parseISO(this.state.unit.rent_history[i + 1].date), 1);
        } else {
          if (shouldIncludeNextMonth) {
            endRangeInclusive = addMonths(currentMonth, 1);
          } else {
            endRangeInclusive = currentMonth;
          }
        }

        infoLines.push([
          format(startRange, "MMM. yyyy") === format(endRangeInclusive, "MMM. yyyy")
            ? format(startRange, "MMM. yyyy")
            : `${format(startRange, "MMM. yyyy")} to ${format(endRangeInclusive, "MMM. yyyy")}`,
          `${formatMoneyAmount(v.rent)} (x${differenceInMonths(endRangeInclusive, startRange) + 1})`,
        ]);
      }
      infoLines.push([`Total rent cost`, `${formatMoneyAmount(this.state.unit.allTimeRentOwed)}`]);
      infoLines.push([`Amount paid`, `${formatMoneyAmount(this.state.unit.rentPaid)}`]);
      infoLines.push([
        `Remaining balance`,
        `${formatMoneyAmount(this.state.unit.allTimeRentOwed - this.state.unit.rentPaid)}`,
      ]);
    }

    return (
      <Modal ref={this.modal} title={"Rent History"}>
        <IonGrid>
          {infoLines.map((cols, i) => (
            <IonRow key={i}>
              {cols.map((item, j) => (
                <IonCol key={j}>{item}</IonCol>
              ))}
            </IonRow>
          ))}
        </IonGrid>
      </Modal>
    );
  }
}

export default ModalRentInfo;
