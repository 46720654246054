import {
  addMonths,
  differenceInMonths,
  isBefore,
  parseISO,
  setDate,
  startOfDay,
  startOfMonth,
  subMonths,
} from "date-fns";
import { IUnit } from "../conjure-api";

/**
 * Given a list of rent history, calculates the all time rent owed.
 * Assumes that rent is collected monthly.
 * @param unit.rent_history array of {date: YYYY-MM, rent: number}: IRentHistory objects
 * Expects only one value of rent per month.
 * If there are multiple values for the same month, the earliest one is used for that month.
 * rentHistory is sorted by this function
 * @param unit.rent_due_date day of the month that rent is due,
 * @param unit.rent_due_date_offset days before the due date to add current month's rent to total due.
 * @param unit.rentStart a date from which to start calculating rent, YYYY-MM-DD.
 * @param additionalCharges a number added to the all time total for additional charges. Default value of 0.
 * Useful for pro-rated first month's rent or other one-time charged.
 * @param targetDate a date YYYY-MM-DD to treat as today. Useful for testing
 */

export default function calculateAllTimeRentOwed(unit: IUnit, additionalCharges = 0, targetDate?: string) {
  const today = targetDate ? parseISO(targetDate) : startOfDay(new Date());
  const currentMonth = startOfMonth(today);
  const nextMonth = addMonths(currentMonth, 1);
  const prevMonth = subMonths(currentMonth, 1);

  const firstMonth = startOfMonth(parseISO(unit.first_month));
  const rentHistory = unit.rent_history
    .map((item) => ({ date: startOfMonth(parseISO(item.date)), rent: item.rent }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());
  const rentDueDate = unit.rent_due_date;
  const rentDueDateOffest = unit.rent_due_date_offset;

  let allTimeRentOwed = additionalCharges;

  // Current month is included if today is same or after (!isBefore) rentDueDate of current month minus rentDueDateOffset days
  const shouldIncludeCurrentMonth = !isBefore(today, setDate(currentMonth, rentDueDate - rentDueDateOffest));

  // Next month is included if today is same or after (!isBefore) rentDueDate of next month minus rentDueDateOffset days
  const shouldIncludeNextMonth = !isBefore(today, setDate(nextMonth, rentDueDate - rentDueDateOffest));

  let lastMonthInRangeExclusive: Date;
  if (shouldIncludeNextMonth) {
    lastMonthInRangeExclusive = addMonths(nextMonth, 1);
  } else if (shouldIncludeCurrentMonth) {
    lastMonthInRangeExclusive = addMonths(currentMonth, 1);
  } else {
    lastMonthInRangeExclusive = addMonths(prevMonth, 1);
  }

  // Find all entries before firstMonth
  // Remove all but the last entry
  // Set the date for that entry to firstMonth

  let idxOfNewFirst = -1;
  for (let i = 0; i < rentHistory.length; i++) {
    if (isBefore(rentHistory[i].date, firstMonth)) {
      idxOfNewFirst = i;
    }
  }
  if (idxOfNewFirst !== -1) {
    rentHistory[idxOfNewFirst].date = firstMonth;
  }

  const rentHistorySlice = rentHistory.filter(
    (item) => !isBefore(item.date, firstMonth) && isBefore(item.date, lastMonthInRangeExclusive),
  );
  rentHistorySlice.push({ date: lastMonthInRangeExclusive, rent: 0 });

  for (let i = 0; i < rentHistorySlice.length - 1; i++) {
    const current = rentHistorySlice[i];
    const next = rentHistorySlice[i + 1];

    const startOfRange = current.date;
    const endOfRangeExclusive = next.date;

    allTimeRentOwed += differenceInMonths(endOfRangeExclusive, startOfRange) * current.rent;
  }

  return allTimeRentOwed;
}
