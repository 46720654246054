import React, { PureComponent } from "react";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import "./CustomSelectSmall.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";

interface Props {
  inverted: boolean;
  color: string;
  borderOnly: boolean;
  loading?: boolean;
  prefix?: string;
  defaultValue?: number; // TODO
  placeholder: string;
  options: string[];
  onChange?: (value: number) => void;
}

interface State {
  value: number;
}

class CustomSelectSmall extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      value: typeof this.props.defaultValue === "number" ? this.props.defaultValue : -1,
    };
  }

  clear() {
    const clearVal = this.props.defaultValue !== undefined ? this.props.defaultValue : -1;
    this.setState({ value: clearVal });
    if (typeof this.props.onChange === "function") this.props.onChange(clearVal);
  }

  setValue(value) {
    this.setState((state) => {
      if (value !== state.value && typeof this.props.onChange === "function") this.props.onChange(value);
      return { value: value };
    });
  }

  getOptions() {
    return this.props.options.map((option, index) => {
      return (
        <option key={index} value={index}>
          {option}
        </option>
      );
    });
  }

  render() {
    // Default text to be placeholder
    let text = this.props.placeholder;
    // If an option has been selected, update text
    if (this.state.value !== -1) {
      text = this.props.prefix + this.props.options[this.state.value];
    }

    return (
      <div className="CustomSelectSmallWrapper">
        <div
          className="CustomSelectSmall"
          style={{
            color: this.props.inverted ? "#fff" : this.props.color,
            background: this.props.borderOnly ? "transparent" : this.props.inverted ? this.props.color : "#fff",
            border: this.props.borderOnly ? "1px solid " + this.props.color : "none",
            pointerEvents: this.props.loading ? "none" : "inherit",
          }}
        >
          <div
            style={{
              opacity: this.props.loading ? "0.5" : "1",
              display: "flex",
              fontSize: "12px",
              alignItems: "center",
              transition: "opacity 80ms",
            }}
          >
            <div>{text}</div>
            <div
              style={{
                marginLeft: "7px",
                fontSize: "10px",
              }}
            >
              {this.props.loading ? <LoadingIcon /> : <FontAwesomeIcon icon={faChevronDown} />}
            </div>
          </div>
          <select
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              border: "1px solid red",
              top: "0",
              left: "0",
              opacity: 0,
              cursor: "pointer",
            }}
            value={this.state.value.toString()}
            onChange={(e) => {
              this.setValue(parseInt(e.target.value));
            }}
          >
            <option value={-1} disabled>
              {this.props.placeholder}
            </option>
            {this.getOptions()}
          </select>
        </div>
      </div>
    );
  }
}

export default CustomSelectSmall;
