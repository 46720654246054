import React, { PureComponent } from "react";
import CustomCurrencyInput from "../CustomComponents/CustomCurrencyInput/CustomCurrencyInput";
import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import OptionSwitch from "../CustomComponents/OptionSwitch/OptionSwitch";
import Modal from "./Modal";
import { getCurrentUserUid } from "../auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faQuestion, faMoneyBillWave } from "@fortawesome/pro-solid-svg-icons";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import { ITenantWithUser } from "../loadData";
library.add(faQuestion, faMoneyBillWave);

interface Props {
  onInvalid: () => void;
  onRefresh: () => void;
}

interface State {
  loading: boolean;
  tenant: ITenantWithUser;
  paymentType: number;
  sendToManager: boolean;
  chatId: string;
  linkedPropertyId: string;
  linkedUnitId: string;
  input: {
    amount: number;
    paymentDescription: string;
  };
  errors: {
    amount: string;
    paymentDescription: string;
  };
}

class ModalManualPayment extends PureComponent<Props, State> {
  InputAmount = React.createRef<CustomCurrencyInput>();
  InputDescription = React.createRef<CustomInput>();
  OptionPaymentDirection = React.createRef<OptionSwitch>();
  OptionPaymentType = React.createRef<OptionSwitch>();
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tenant: null,
      paymentType: 0,
      sendToManager: true,
      chatId: null,
      linkedPropertyId: null,
      linkedUnitId: null,
      input: {
        amount: 0,
        paymentDescription: "",
      },
      errors: {
        amount: "",
        paymentDescription: "",
      },
    };

    this.handleRecordManualPayment = this.handleRecordManualPayment.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    this.InputAmount.current.clear();
    this.OptionPaymentDirection.current.setValue(0);
    this.OptionPaymentType.current.setValue(0);
    this.setState({ loading: false });

    if (this.InputDescription.current) this.InputDescription.current.clear();
  }

  async show(tenant: ITenantWithUser) {
    this.clear();
    this.modal.current.show();
    const privateMessagesDoc = await firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("private_messages")
      .doc(tenant.id)
      .get();
    if (!privateMessagesDoc.exists) {
      this.modal.current.hide();
      this.props.onInvalid();
      return;
    }

    const chatDoc = await firebase.firestore().collection("chats").doc(privateMessagesDoc.data().chatId).get();

    this.setState({
      tenant: tenant,
      chatId: chatDoc.id,
      linkedPropertyId: chatDoc.data().property_id,
      linkedUnitId: chatDoc.data().unit_id,
    });
  }

  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  updateErrors() {
    const errors = this.state.errors;
    let encounteredError = false;

    // Make sure they entered valid micro-deposit amounts
    const amount = this.state.input.amount;

    // Amount 1 is too big or too small
    if (amount <= 0) {
      encounteredError = true;
      errors.amount = "Please enter a valid amount";
    }

    if (this.state.paymentType === 1 && this.state.input.paymentDescription.trim() === "") {
      encounteredError = true;
      errors.paymentDescription = "Please enter a description for this payment";
    }

    if (encounteredError) {
      this.setState(
        {
          errors: errors,
          loading: false,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  async handleRecordManualPayment() {
    this.setState({ loading: true });

    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    // 0: "Rent"; 1: "Other"
    const type = this.state.paymentType === 0 ? "rent" : "other";
    const sendToManager = this.state.sendToManager;
    const amount = this.state.input.amount;
    const description = this.state.input.paymentDescription.trim();

    const tenant = this.state.tenant;

    const manualTransfer = {
      value: amount,
      sourceId: sendToManager ? tenant.id : getCurrentUserUid(),
      destinationId: sendToManager ? getCurrentUserUid() : tenant.id,
      chatId: this.state.chatId,
      linkedPropertyId: this.state.linkedPropertyId === undefined ? "-" : this.state.linkedPropertyId,
      linkedUnitId: this.state.linkedUnitId === undefined ? "-" : this.state.linkedUnitId,
      type: type,
      description: description,
    };
    await firebase.functions().httpsCallable("logManualTransfer")(manualTransfer);
    this.hide();
    this.props.onRefresh();
  }

  render() {
    const paymentFromString = "Payment from " + this.state.tenant?.user.full_name;
    // If the type is "rent", button should read "Reimbursement to Jane Doe" (i.e. the landlord is reimbursing a rent payment)
    const paymentToString =
      (this.state.paymentType === 0 ? "Reimbursement" : "Payment") + " to " + this.state.tenant?.user.full_name;

    return (
      <Modal ref={this.modal} title={"Record Manual Payment"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleRecordManualPayment();
          }}
        >
          <div className="ModalText">Record a manual payment for {this.state.tenant?.user.full_name}</div>
          <div className="ModalSectionHeader">Amount</div>
          <div className="ModalInputWrapper">
            <CustomCurrencyInput
              ref={this.InputAmount}
              icon={faMoneyBillWave}
              errorText={this.state.errors.amount}
              inverted={true}
              onChange={(amount) => {
                this.setState((state) => {
                  state.input.amount = amount;
                  state.errors.amount = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalSectionHeader">Payment Information</div>
          <div className="ModalInputWrapper">
            <OptionSwitch
              ref={this.OptionPaymentDirection}
              options={[paymentFromString, paymentToString]}
              onChange={(index) => {
                this.setState({ sendToManager: index === 0 });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <OptionSwitch
              ref={this.OptionPaymentType}
              options={["Rent", "Other"]}
              onChange={(index) => {
                this.setState({ paymentType: index });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputDescription}
              icon={faQuestion}
              errorText={this.state.errors.paymentDescription}
              inverted={true}
              placeholder={"What's it for?"}
              onChange={(paymentDescription) => {
                this.setState((state) => {
                  state.input.paymentDescription = paymentDescription;
                  state.errors.paymentDescription = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalButtonWrapper">
            <CustomButton
              text="Record Payment"
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalManualPayment;
