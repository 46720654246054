import React, { PureComponent } from "react";
import Clickable from "../Clickable/Clickable";
import ProfilePhoto from "../CustomComponents/ProfilePhoto/ProfilePhoto";
import "./ManagePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faChevronRight);

interface Props {
  initiallyShowing?: boolean;
  onClick?: () => void;
  invitationState?: string;
  id: string;
  name: string;
  text?: string;
  imageUrl?: string;
  isSelf: boolean;
  hasContent?: boolean;
}

interface State {
  showingContent: boolean;
}

class ManageUserPreview extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showingContent: props.initiallyShowing ? true : false,
    };
    this.toggleShowingContent = this.toggleShowingContent.bind(this);
  }

  toggleShowingContent() {
    this.setState((state) => {
      return { showingContent: !state.showingContent };
    });
  }

  render() {
    return (
      <div
        className="ManageUserPreview"
        style={{
          position: "relative",
          width: "100%",
          backgroundColor: "#fff",
          padding: "0 0px",
          boxSizing: "border-box",
        }}
      >
        <Clickable
          onClick={() => {
            this.toggleShowingContent();
            if (typeof this.props.onClick === "function") {
              this.props.onClick();
            }
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              padding: "0 5px",
              height: "50px",
              alignItems: "center",
            }}
          >
            {/* User image */}
            <div
              style={{
                position: "relative",
                width: "50px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "46px",
                  height: "46px",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <ProfilePhoto id={this.props.id} name={this.props.name} imageUrl={this.props.imageUrl} size={46} />
              </div>
            </div>
            {/* User name */}
            <div
              style={{
                position: "relative",
                flexGrow: 1,
                padding: "0 0 0 10px",
                fontWeight: this.props.isSelf ? 500 : 400,
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                justifyContent: "space-between",
                lineHeight: 1,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  overflow: "hidden",
                  lineHeight: 1,
                }}
              >
                <div>{this.props.name}</div>
                {this.props.hasContent && (
                  <div
                    style={{
                      position: "relative",
                      padding: "0 12px",
                      transform: "rotate(" + (this.state.showingContent ? "90" : "0") + "deg)",
                      transition: "transform 200ms",
                      alignSelf: "center",
                      fontSize: "12px",
                      color: "rgba(0, 0, 0, 0.70)",
                    }}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </div>
                )}
              </div>
              {this.props.text && (
                <div style={{ color: "rgba(0,0,0,0.35)", fontWeight: 500, fontSize: "12px" }}>{this.props.text}</div>
              )}
            </div>
          </div>
        </Clickable>
        {/* Content assocated with this user */}
        {this.props.hasContent && (
          <div
            style={{
              width: "100%",
              zIndex: 0,
              position: this.state.showingContent ? "relative" : "absolute",
              padding: "0",
              opacity: this.state.showingContent ? 1 : 0,
              pointerEvents: this.state.showingContent ? "auto" : "none",
              transform: "translateY(" + (this.state.showingContent ? "0" : "-10px") + ")",
              transition: "transform 80ms, opacity 80ms",
            }}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

export default ManageUserPreview;
