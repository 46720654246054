import React from "react";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
library.add(faCheck);

interface Props {
  stepTitles: string[];
  currentStep: number;
  lastCompletedStep: number;
}
export function ProgressBar(props: Props) {
  const width = (100 * props.currentStep) / (props.stepTitles.length - 1);
  return (
    <div
      style={{
        overflow: "visible",
        height: "6px",
        backgroundColor: "#f7f7f7",
        borderRadius: "3px",
        position: "relative",
        margin: "55px 15px 0 15px",
      }}
    >
      <div
        style={{
          overflow: "visible",
          height: "100%",
          width: `${width}%`,
          backgroundColor: "#26de81",
          borderRadius: "inherit",
          transition: "width 200ms",
        }}
      ></div>
      {
        // Circles
        props.stepTitles.map((stepTitle, index) => {
          const completed = index <= props.lastCompletedStep;
          const left = (100 * index) / (props.stepTitles.length - 1);
          return (
            <div
              key={index}
              style={{
                width: completed ? "26px" : "16px",
                height: completed ? "26px" : "16px",
                borderRadius: "50%",
                border: completed ? "none" : "2px solid #fff",
                backgroundColor: completed ? "#26de81" : "#eee",
                boxSizing: "border-box",
                position: "absolute",
                left: `${left}%`,
                top: "50%",
                transform: "translate(-50%, -50%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                color: "#fff",
                transition: "background-color 80ms, width 80ms, heighth 80ms",
              }}
            >
              <FontAwesomeIcon
                style={{
                  transform: `scale(${completed ? 1 : 0})`,
                  transition: "transform 80ms",
                }}
                icon={faCheck}
              />
            </div>
          );
        })
      }
      {
        // Titles
        props.stepTitles.map((stepTitle, index) => {
          const current = index === props.currentStep;
          const left = (100 * index) / (props.stepTitles.length - 1);
          return (
            <div
              key={index}
              style={{
                position: "absolute",
                bottom: "100%",
                left: left + "%",
                transform: "translateX(-50%)",
                lineHeight: 3.5,
                fontSize: "16px",
                fontFamily: '"Roboto Slab", sans-serif',
                fontWeight: current ? 500 : "normal",
                opacity: current ? 1 : 0.3,
              }}
            >
              {stepTitle}
            </div>
          );
        })
      }
    </div>
  );
}
