import React from "react";
import Clickable from "../../Clickable/Clickable";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import { Capacitor } from "@capacitor/core";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
library.add(faArrowUp, faShieldCheck);

interface Props {
  active?: boolean;
  preventDefault?: boolean;
  loading?: boolean;
  secure?: boolean;
  inverted?: boolean;
  onSend?: () => void;
}

const SendButton = (props: Props) => (
  <div style={{ position: "relative" }}>
    <Clickable
      preventDefault={props.preventDefault}
      onClick={() => {
        props.onSend && props.active && props.onSend();
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            padding: "15px 10px",
            position: "relative",
            top: "50%",
            transform: Capacitor.isNative ? "translateY(-50%)" : "none",
          }}
        >
          <div
            style={{
              position: "relative",
              fontSize: "16px",
              backgroundColor: props.inverted ? "#fff" : "#4b7bec",
              color: props.inverted ? "#4b7bec" : "#fff",
              width: "36px",
              height: "36px",
              opacity: props.active ? "1.0" : "0.5",
              transition: "opacity 200ms",
              borderRadius: "50%",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {props.loading ? <LoadingIcon /> : <FontAwesomeIcon icon={props.secure ? faShieldCheck : faArrowUp} />}
            </div>
          </div>
        </div>
      </div>
    </Clickable>
  </div>
);

export default SendButton;
