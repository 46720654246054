import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBuilding, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { IProperty, IUnit } from "../conjure-api";
library.add(faBuilding, faMapMarkerAlt);

interface Props {
  onRefresh: () => void;
}
interface State {
  loading: boolean;
  unit: IUnit;
  property: IProperty;
}

class ModalRemoveUnit extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // Passed data
      unit: null,
      property: null,
    };

    this.handleRemoveUnit = this.handleRemoveUnit.bind(this);
  }

  show(property: IProperty, unit: IUnit) {
    this.setState({
      unit: unit,
      property: property,
    });
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async handleRemoveUnit() {
    this.setState({ loading: true });

    await firebase.functions().httpsCallable("removeUnit")({
      propertyId: this.state.property.id,
      unitId: this.state.unit.id,
    });

    this.setState({ loading: false });
    this.hide();
    this.props.onRefresh();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Remove Unit?"}>
        <div className="ModalText">
          Are you sure you want to remove {this.state.unit?.unit_name} from {this.state.property?.address}?
        </div>
        <div className="ModalButtonWrapper">
          <CustomButton
            text={"Remove " + this.state.unit?.unit_name}
            inverted={true}
            loading={this.state.loading}
            color={"#eb3b5a"}
            onClick={this.handleRemoveUnit}
          />
        </div>
      </Modal>
    );
  }
}

export default ModalRemoveUnit;
