import React, { PureComponent } from "react";
import CustomSelectSmall from "../CustomComponents/CustomSelectSmall/CustomSelectSmall";
import { IPropertyWithUnits } from "../loadData";

import firebase from "firebase/app";
import CollapsibleList from "../CustomComponents/CollapsibleList/CollapsibleList";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import { getPhoneURI } from "../HelperFunctions";
import {
  AddUnitCallback,
  MessagePropertyCallback,
  MessageUserCallback,
  RemovePropertyCallback,
  ShowCustomModalCallback,
} from "../App";
import { GetUnitsCallback } from "./ManagePage";

interface Props {
  property: IPropertyWithUnits;
  showing: boolean;
  fundingSources: any[];
  onMessageUser: MessageUserCallback;
  getUnits: GetUnitsCallback;
  showCustomModal: ShowCustomModalCallback;
  onAddUnit: AddUnitCallback;
  onRemoveProperty: RemovePropertyCallback;
  onMessageProperty: MessagePropertyCallback;
}
interface State {
  loading: boolean;
  currentIndex: number;
  defaultFundingSourceValue: number;
}

class RentalsPropertyList extends PureComponent<Props, State> {
  CustomSelectSmall: React.RefObject<CustomSelectSmall>;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentIndex: 0,
      defaultFundingSourceValue: 0,
    };
    this.CustomSelectSmall = React.createRef();

    this.getFundingSourceIds = this.getFundingSourceIds.bind(this);
    this.getFundingSourceNames = this.getFundingSourceNames.bind(this);
  }

  async updatePropertyFundingSource(propertyId: string, newFundingSourceId: string | null) {
    this.setState({ loading: true });
    console.log("Update property", propertyId, "to use funding source", newFundingSourceId);

    await firebase.functions().httpsCallable("updatePropertyFundingSource")({
      propertyId: propertyId,
      fundingSourceId: newFundingSourceId,
    });

    this.setState({ loading: false });
  }

  getFundingSourceIds(fundingSources) {
    return fundingSources.map((fundingSource) => fundingSource.id);
  }

  getFundingSourceNames(fundingSources) {
    return fundingSources.map((fundingSource) => fundingSource.name);
  }

  componentDidUpdate(prevProps) {
    // Property funding source changed, or funding sources were updated
    const changed =
      this.props.property.funding_source_id !== prevProps.property.funding_source_id ||
      this.getFundingSourceIds(this.props.fundingSources).join(", ") !==
        this.getFundingSourceIds(prevProps.fundingSources).join(", ");

    // If funding sources updated, update dropdown value
    if (changed) {
      const propertyFundingSourceId = this.props.property.funding_source_id;
      const fundingSourceIds = this.getFundingSourceIds(this.props.fundingSources);

      const chosenIndex = fundingSourceIds.indexOf(propertyFundingSourceId);
      const defaultValue = chosenIndex === -1 ? 0 : chosenIndex + 1;

      this.setState({ defaultFundingSourceValue: defaultValue });
    }
  }

  render() {
    const property = this.props.property;
    const fundingSourceIds = this.getFundingSourceIds(this.props.fundingSources);
    const fundingSourceNames = this.getFundingSourceNames(this.props.fundingSources);

    const isOwner = property.owner_id === firebase.auth().currentUser?.uid;

    const unitsRented = property.units.filter((unit) => unit.tenants.length > 0).length;
    const totalUnits = property.units.length;

    return (
      <CollapsibleList
        title={property.address} // TODO Full name
        initiallyCollapsed={true}
        completionString={`${unitsRented} / ${totalUnits} rented`}
      >
        {
          // Buttons to add a unit, remove this property, or message the whole property
          isOwner && (
            <div
              style={{
                padding: "5px 0 15px 0",
              }}
            >
              <CustomButtonSmall
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                text={"Add Unit"}
                onClick={() => {
                  this.props.onAddUnit({ property });
                }}
              />
              <CustomButtonSmall
                color={"rgba(0,0,0,0.35)"}
                inverted={false}
                borderOnly={true}
                text={"Remove Property"}
                onClick={() => {
                  this.props.onRemoveProperty({ property });
                }}
              />
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Message Tenants"}
                onClick={() => {
                  this.props.onMessageProperty({ property });
                }}
              />
              <CustomSelectSmall
                ref={this.CustomSelectSmall}
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                placeholder={"Bank Account"}
                defaultValue={this.state.defaultFundingSourceValue}
                prefix={"Bank Account: "}
                options={["Primary", ...fundingSourceNames]}
                loading={this.state.loading}
                onChange={(value) => {
                  const newFundingSourceId = value === 0 ? null : fundingSourceIds[value - 1];
                  this.updatePropertyFundingSource(property.id, newFundingSourceId);
                }}
              />
            </div>
          )
        }
        {
          // Buttons to message or call the property manager
          !isOwner && (
            <div
              style={{
                padding: "5px 0 15px 0",
              }}
            >
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Message " + property.owner.full_name}
                onClick={() => {
                  this.props.onMessageUser({ userId: property.owner_id, unitId: undefined, propertyId: property.id });
                }}
              />
              <a
                href={getPhoneURI(property.owner.phone)}
                style={{
                  position: "relative",
                  display: "inline-block",
                  color: "#4b7bec",
                  textDecoration: "none",
                }}
              >
                <CustomButtonSmall
                  color={"#4b7bec"}
                  inverted={false}
                  borderOnly={true}
                  text={"Call " + property.owner.full_name}
                />
              </a>
            </div>
          )
        }
        {this.props.showing && this.props.getUnits(property)}
      </CollapsibleList>
    );
  }
}

export default RentalsPropertyList;
