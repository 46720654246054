import React, { PureComponent } from "react";
import { Plugins, HapticsImpactStyle, Capacitor } from "@capacitor/core";
import Clickable from "../Clickable/Clickable";
import "./ManagePage.css";

// Font Awesome
import { library, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave, faTools, faKey } from "@fortawesome/pro-regular-svg-icons";
library.add(faMoneyBillWave, faTools, faKey);

const { Haptics } = Plugins;

interface Option {
  icon: IconDefinition;
  title: string;
  subtitle: string;
}

interface Props {
  options: Option[];
  currentPage: number;
  loading: boolean;
  onChange: (index: number) => void;
  scrolled: boolean;
}

interface State {}

class ManageSelector extends PureComponent<Props, State> {
  getOptions() {
    return this.props.options.map((option, index) => {
      return (
        <div
          key={index}
          className="ManageSelectorTab"
          style={{
            width: 100 / this.props.options.length + "%",
          }}
        >
          <Clickable
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              if (typeof this.props.onChange === "function") {
                this.props.onChange(index);
              }
            }}
          >
            <div
              className="ManageSelectorTabContainer"
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
                padding: this.props.scrolled ? "10px 0" : "15px 0",
                borderRadius: this.props.scrolled ? "0" : "6px 6px 0 0",
                position: "relative",
                transition: "padding 200ms, border-radius 200ms",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  height: this.props.currentPage === index ? "100%" : "0",
                  bottom: "0",
                  width: "100%",
                  backgroundColor: "#fff",
                  transition: "height 200ms, border-radius 200ms",
                  borderRadius: this.props.scrolled ? "0" : "6px 6px 0 0",
                  zIndex: 0,
                }}
              ></div>
              <div
                style={{
                  position: "relative",
                  fontSize: this.props.scrolled ? "16px" : "20px",
                  paddingTop: this.props.scrolled ? "5px" : "7px",
                  paddingBottom: "3px",
                  color: this.props.currentPage === index ? "#4b7bec" : "#fff",
                  transition: "color 80ms, font-size 200ms, padding-top 200ms",
                }}
              >
                <FontAwesomeIcon icon={option.icon} />
              </div>
              <div
                style={{
                  width: "100%",
                  padding: this.props.scrolled ? "0" : "3px 0",
                  fontSize: this.props.scrolled ? "0" : "13px",
                  color: this.props.currentPage === index ? "#4b7bec" : "#fff",
                  position: "relative",
                  fontWeight: this.props.currentPage === index ? 500 : 400,
                  transition: "padding 200ms, font-size 200ms",
                }}
              >
                {option.title}
              </div>
              {!this.props.loading && (
                <div
                  style={{
                    width: "100%",
                    fontSize: "10px",
                    position: "relative",
                    color: this.props.currentPage === index ? "#3867d6" : "#fff",
                    opacity: 0.7,
                  }}
                >
                  {option.subtitle}
                </div>
              )}
            </div>
          </Clickable>
        </div>
      );
    });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        {/* Bar */}
        <div
          style={{
            position: "relative",
            overflow: "auto",
            width: "100%",
            paddingTop: this.props.scrolled ? "0" : "10px",
            transition: "padding-top 200ms",
          }}
        >
          {this.getOptions()}
        </div>
        <div
          style={{
            width: "100%",
            height: "0",
            backgroundColor: "#F7F8FA",
          }}
        >
          <div
            style={{
              width: 100 / this.props.options.length + "%",
              transform: "translateX(" + this.props.currentPage * 100 + "%)",
              transition: "transform 200ms",
              height: "100%",
              backgroundColor: "#4b7bec",
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default ManageSelector;
