import React, { PureComponent } from "react";
import "./Chat.css";

import SendButton from "../../CustomComponents/SendButton/SendButton";
import ImagePicker from "./ImagePicker";
import { Member } from "../MessagingInterfaces";
import CustomSelectSmall from "../../CustomComponents/CustomSelectSmall/CustomSelectSmall";

interface Props {
  members: Member[];
  showing: boolean;
  onViewImage: (imageUrl: string) => void;
  onSend: (memberId: string, subject: string, description: string, imageUrls: string[]) => void;
}

interface State {
  subject: string;
  description: string;
  imageUrls: string[];
  // Index of the current recipient, if chat is unlinked
  recipient: number;
}

class WorkOrderInput extends PureComponent<Props, State> {
  SelectRecipient: React.RefObject<CustomSelectSmall>;
  ImagePicker: React.RefObject<ImagePicker>;
  InputSubject: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      description: "",
      imageUrls: [],
      recipient: 0,
    };
    this.handleSend = this.handleSend.bind(this);
    this.SelectRecipient = React.createRef();
    this.ImagePicker = React.createRef();
    this.InputSubject = React.createRef();
  }

  focus() {
    this.InputSubject.current.focus();
  }
  clear() {
    this.setState({
      subject: "",
      description: "",
    });
    this.ImagePicker.current.clear();
  }

  getMemberNames() {
    const names = [];
    for (let i = 0; i < this.props.members.length; i++) {
      names.push(this.props.members[i].name);
    }
    return names;
  }

  componentDidUpdate(prevProps) {
    // Whether the members in this chat changed
    const membersChanged = (oldMembers, newMembers) => {
      const oldIds = [];
      const newIds = [];

      for (let i = 0; i < oldMembers.length; i++) {
        oldIds.push(oldMembers[i].id);
      }
      for (let i = 0; i < newMembers.length; i++) {
        newIds.push(newMembers[i].id);
      }

      const oldString = oldIds.sort().join(", ");
      const newString = newIds.sort().join(", ");
      if (oldString !== newString) return true;
    };

    // If the members changed, reset option fields
    const changed = membersChanged(prevProps.members, this.props.members);
    if (changed) {
      if (this.SelectRecipient.current) this.SelectRecipient.current.setValue(0);
    }
  }

  handleSend() {
    if (this.state.subject.trim() !== "" && this.state.description.trim() !== "") {
      if (typeof this.props.onSend === "function") {
        this.props.onSend(
          this.props.members[this.state.recipient].id,
          this.state.subject,
          this.state.description,
          this.state.imageUrls,
        );
      }
      this.clear();
    }
  }

  render() {
    const otherMembersExist = this.props.members && this.props.members.length > 0;
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          position: this.props.showing ? "relative" : "absolute",
          pointerEvents: this.props.showing ? "auto" : "none",
          opacity: this.props.showing ? 1 : 0,
          zIndex: this.props.showing ? 0 : -1,
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          transition: "opacity 200ms, transform 200ms",
          display: "flex",
          padding: "0 10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            position: "relative",
          }}
        >
          {this.props.members && this.props.members.length > 1 && (
            <div
              style={{
                marginRight: "10px",
                padding: "10px 7px 5px 7px",
              }}
            >
              <CustomSelectSmall
                ref={this.SelectRecipient}
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                placeholder={"Choose Recipient"}
                defaultValue={0}
                prefix={"To: "}
                options={this.getMemberNames()}
                onChange={(value) => {
                  this.setState({
                    recipient: value,
                  });
                }}
              />
            </div>
          )}
          <input
            ref={this.InputSubject}
            maxLength={100}
            placeholder="Work order subject..."
            value={this.state.subject}
            style={{
              border: "none",
              background: "none",
              outline: "none",
              padding: "10px 15px",
              position: "relative",
              width: "100%",
              fontSize: "18px",
              fontFamily: "inherit",
              resize: "none",
            }}
            onChange={(e) => {
              this.setState({ subject: e.target.value });
            }}
          ></input>
          <textarea
            maxLength={1000}
            placeholder="Describe work order and give additional details..."
            value={this.state.description}
            style={{
              border: "none",
              background: "none",
              outline: "none",
              padding: "0 15px 10px 15px",
              position: "relative",
              fontSize: "14px",
              fontFamily: "inherit",
              resize: "none",
              opacity: this.state.subject.trim() !== "" ? "1" : "0",
              transform: "translateY(" + (this.state.subject.trim() !== "" ? "0" : "20px") + ")",
              pointerEvents: this.state.subject.trim() !== "" ? "auto" : "none",
              transition: "opacity 200ms, transform 200ms",
            }}
            rows={3}
            onChange={(e) => {
              this.setState({ description: e.target.value });
            }}
          ></textarea>
          <ImagePicker
            ref={this.ImagePicker}
            onUpdate={(imageUrls) => {
              this.setState({ imageUrls: imageUrls });
              this.forceUpdate();
            }}
            onViewImage={(imageUrl) => {
              this.props.onViewImage(imageUrl);
            }}
          />
        </div>
        <SendButton
          active={this.state.subject.trim() !== "" && this.state.description.trim() !== "" && otherMembersExist}
          onSend={this.handleSend}
        />
      </div>
    );
  }
}

export default WorkOrderInput;
