import React, { PureComponent } from "react";
import { formatMoneyAmount, getPhoneURI } from "../HelperFunctions";
import CollapsibleList from "../CustomComponents/CollapsibleList/CollapsibleList";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import OptionSwitch from "../CustomComponents/OptionSwitch/OptionSwitch";
import { getCurrentUserUid } from "../auth";

import ManageUnitPreview from "./ManageUnitPreview";
import ManageUserPreview from "./ManageUserPreview";
import ManageTransaction from "./ManageTransaction";
import { isCurrentUser } from "../utils";

import {
  IRentingwayUserWithTransfersWorkOrders,
  ITransferWithNames,
  IUnitWithTenants,
  IScheduledTransferWithNames,
} from "../loadData";

import "./ManagePage.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { IPropertyWithUnits } from "../loadData";
import PaymentsPropertyList from "./PaymentsPropertyList";
import CustomRefreshButton from "../CustomComponents/CustomRefreshButton/CustomRefreshButton";
import { PayRentCallback, MessageUserCallback, RecordManualPaymentCallback, RequestRentInfoCallback } from "../App";
import { GetUnitsCallback } from "./ManagePage";
import { markTransferAsRent } from "../utils/transfers";
library.add(faCheck);

interface Props {
  transfers: ITransferWithNames[];
  scheduledTransfers: IScheduledTransferWithNames[];
  properties: IPropertyWithUnits[];
  showing: boolean;
  otherUsers: IRentingwayUserWithTransfersWorkOrders[];
  loading: boolean;
  onCancelTransaction: (transaction: ITransferWithNames) => void;
  onMessageUser: MessageUserCallback;
  onCancelRecurringTransaction: (transactionId: string) => void;
  onRecordManualPayment: RecordManualPaymentCallback;
  onRefresh: () => void;
  onPayRent: PayRentCallback;
  onRequestRentInfo: RequestRentInfoCallback;
}
interface State {
  // Type of sorting for transactions
  // 0: By property/unit/tenant
  // 1: Full transaction history
  sortingType: number;
}

class PaymentsSection extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      sortingType: 0,
    };
    this.getUnits = this.getUnits.bind(this);
    this.markAsRent = this.markAsRent.bind(this);
  }

  async markAsRent(transfer) {
    await markTransferAsRent(transfer, this.props.properties);
    this.props.onRefresh();
  }

  getAllTransactions() {
    return this.props.transfers.map((transfer) => {
      return (
        <ManageTransaction
          key={"payments-" + transfer.transferId}
          transfer={transfer}
          isScheduled={false}
          isOther={false}
          onCancelTransaction={() => this.props.onCancelTransaction(transfer)}
          onCancelRecurringTransaction={() => {
            if (typeof this.props.onCancelRecurringTransaction === "function") {
              this.props.onCancelRecurringTransaction(transfer.transferId);
            }
          }}
          onMarkAsRent={() => Promise.resolve()}
        />
      );
    });
  }

  getManageTransactions(transfers: ITransferWithNames[], isOther = false) {
    return transfers.map((transfer) => {
      return (
        <ManageTransaction
          key={"payment-user-" + transfer.transferId}
          transfer={transfer}
          isScheduled={false}
          isOther={isOther}
          onCancelTransaction={() => this.props.onCancelTransaction(transfer)}
          onCancelRecurringTransaction={() => {
            if (typeof this.props.onCancelRecurringTransaction === "function") {
              this.props.onCancelRecurringTransaction(transfer.transferId);
            }
          }}
          onMarkAsRent={() => this.markAsRent(transfer)}
        />
      );
    });
  }

  getTenants(unit: IUnitWithTenants, propertyId: string, isOwner: boolean, paidRent: boolean) {
    return unit.tenants.map((tenant) => {
      return (
        <ManageUserPreview
          key={"payments-" + tenant.id}
          id={tenant.id}
          name={tenant.user.full_name}
          imageUrl={tenant.user.profile_pic_url}
          isSelf={isCurrentUser(tenant.id)}
          hasContent={!isCurrentUser(tenant.id) || tenant.user.transfers.length > 0}
        >
          {!isCurrentUser(tenant.id) && (
            <div
              style={{
                padding: "5px 5px",
              }}
            >
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Message"}
                onClick={() => {
                  this.props.onMessageUser({ userId: tenant.id, unitId: unit.id, propertyId });
                }}
              />
              <a
                href={getPhoneURI(tenant.user.phone)}
                style={{
                  position: "relative",
                  display: "inline-block",
                  color: "#4b7bec",
                  textDecoration: "none",
                }}
              >
                <div style={{ pointerEvents: "none" }}>
                  <CustomButtonSmall color={"#4b7bec"} inverted={false} borderOnly={true} text={"Call"} />
                </div>
              </a>
              {isOwner && (
                <CustomButtonSmall
                  color={"rgba(0,0,0,0.60)"}
                  inverted={false}
                  borderOnly={true}
                  text={"Record Manual Payment"}
                  onClick={() => {
                    this.props.onRecordManualPayment(tenant);
                  }}
                />
              )}
            </div>
          )}
          {this.getManageTransactions(tenant.user.transfers)}
        </ManageUserPreview>
      );
    });
  }

  getUnits: GetUnitsCallback = function (property) {
    return property.units.map((unit, index) => {
      const allTimeRentOwed = unit.allTimeRentOwed;
      const totalRentPaid = unit.rentPaid;

      const paidRent = totalRentPaid >= allTimeRentOwed;

      // Whether this unit contains this user
      // Used to decide whether to show "Pay Rent" button
      const containsSelf = unit.tenants.findIndex((t) => t.id === getCurrentUserUid()) !== -1;

      const isOwner = property.owner_id === getCurrentUserUid();

      return (
        <ManageUnitPreview key={index} unit={unit} showRent={true} onRequestRentInfo={this.props.onRequestRentInfo}>
          {this.getTenants(unit, property.id, isOwner, paidRent)}
          {!(property.owner_id === getCurrentUserUid()) && containsSelf && !paidRent && (
            <div
              style={{
                padding: "5px 5px",
              }}
            >
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Pay Rent"}
                onClick={() => {
                  this.props.onPayRent({
                    managerId: property.owner_id,
                    propertyId: property.id,
                    unitId: unit.id,
                    amount: allTimeRentOwed,
                  });
                }}
              />
            </div>
          )}
        </ManageUnitPreview>
      );
    });
  };

  getProperties() {
    return this.props.properties.map((property, index) => {
      return (
        <PaymentsPropertyList
          key={index}
          showing={this.props.showing}
          property={property}
          isManager={property.owner_id === getCurrentUserUid()}
          manager={{
            name: property.owner.full_name,
            id: property.owner.id,
            phone: property.owner.phone,
          }}
          onMessageUser={this.props.onMessageUser}
          getUnits={this.getUnits}
        />
      );
    });
  }

  getOtherUsers() {
    return this.props.otherUsers.map((user) => {
      return (
        <ManageUserPreview
          key={"payments-" + user.id}
          id={user.id}
          name={user.full_name}
          imageUrl={user.profile_pic_url}
          isSelf={user.id === getCurrentUserUid()}
          hasContent={true}
        >
          {this.getManageTransactions(user.transfers, true)}
          <div
            style={{
              padding: "5px 5px",
            }}
          >
            <CustomButtonSmall
              color={"#4b7bec"}
              inverted={false}
              borderOnly={true}
              text={"Message " + user.full_name}
              onClick={() => {
                this.props.onMessageUser({ userId: user.id, propertyId: null, unitId: null });
              }}
            />
          </div>
        </ManageUserPreview>
      );
    });
  }

  getOtherUsersList() {
    const otherUsersLength = this.props.otherUsers.length;
    if (otherUsersLength === 0) return undefined;
    return (
      <CollapsibleList title={"Other"} completionString={`${otherUsersLength} other`}>
        {this.props.showing && this.getOtherUsers()}
      </CollapsibleList>
    );
  }

  getScheduledTransfers() {
    return this.props.scheduledTransfers
      .filter((scheduledTransfer) => scheduledTransfer.status !== "cancelled")
      .map((scheduledTransfer) => {
        return (
          <ManageTransaction
            transfer={scheduledTransfer.transfer}
            isScheduled={true}
            isOther={false}
            onCancelTransaction={() => console.error("cannot cancel a scheduled transfer like this!")}
            onCancelRecurringTransaction={() => {
              if (typeof this.props.onCancelRecurringTransaction === "function") {
                this.props.onCancelRecurringTransaction(scheduledTransfer.id);
              }
            }}
            onMarkAsRent={() => Promise.resolve()}
          />
        );
      });
  }

  getScheduledTransfersList() {
    const scheduledTransfersElements = this.getScheduledTransfers();
    if (scheduledTransfersElements.length === 0) return;
    return <CollapsibleList title={"Upcoming Payments"}>{scheduledTransfersElements}</CollapsibleList>;
  }

  render() {
    // Whether this user manages at least one property
    // Used to decide whether to show portfolio summary
    let isManager = false;
    for (let i = 0; i < this.props.properties.length; i++) {
      if (this.props.properties[i].owner_id === getCurrentUserUid()) {
        isManager = true;
        break;
      }
    }

    // Payments
    const totalRent = this.props.properties.reduce(
      (currentVal, property) => currentVal + property.rentOccupiedUnits,
      0,
    );
    const allTimeRentOwed = this.props.properties.reduce(
      (currentVal, property) => currentVal + property.allTimeRentOwed,
      0,
    );
    const allTimeRentPaid = this.props.properties.reduce(
      (currentVal, property) => currentVal + property.rentPaidExcludingOverpayment,
      0,
    );

    const balance = allTimeRentOwed - allTimeRentPaid;

    const labelColor = balance <= 0 ? "#20bf6b" : "#eb3b5a";
    let label = <FontAwesomeIcon icon={faCheck} />;
    if (balance > 0) {
      label = <span>{formatMoneyAmount(balance) + " due"}</span>;
    }

    return (
      <div
        style={{
          top: "0",
          zIndex: this.props.showing ? 1 : -1,
          position: this.props.showing ? "relative" : "absolute",
          height: this.props.showing ? "auto" : "0",
          overflow: "hidden",
          width: "100%",
          opacity: this.props.showing ? "1" : "0",
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          pointerEvents: this.props.showing ? "auto" : "none",
          transition: "opacity 200ms, transform 200ms",
        }}
      >
        <div style={{ float: "right" }}>
          <CustomRefreshButton loading={this.props.loading} onClick={this.props.onRefresh} />
        </div>
        {this.props.scheduledTransfers.length > 0 && (
          <div style={{ marginBottom: "25px" }}>{this.getScheduledTransfersList()}</div>
        )}
        <div style={{ marginBottom: "15px" }}>
          <OptionSwitch
            options={["By User", "All Transactions"]}
            onChange={(index) => {
              this.setState({ sortingType: index });
            }}
          />
        </div>

        {/* Total amount owed/expected from all properties */}
        {this.props.properties.length > 0 && isManager && (
          <div
            style={{
              position: "relative",
              display: "flex",
              boxSizing: "border-box",
              padding: "0px 15px 15px 15px",
              marginBottom: "0px",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            <div>{formatMoneyAmount(totalRent) + " total / month"}</div>
            <div
              style={{
                flexGrow: 1,
                textAlign: "right",
                color: labelColor,
                fontWeight: 500,
              }}
            >
              {label}
            </div>
          </div>
        )}
        {this.state.sortingType === 1 && this.getAllTransactions()}
        {this.state.sortingType === 0 && this.getProperties()}
        {this.state.sortingType === 0 && this.getOtherUsersList()}
        {
          // No properties to display
          this.props.properties.length === 0 && this.props.otherUsers.length === 0 && (
            <div
              style={{
                fontSize: "14px",
                color: "rgba(0,0,0,0.85)",
                marginBottom: "25px",
                textAlign: "center",
                lineHeight: 1.5,
              }}
            >
              Payments sent in Messages will show up here. Link your bank account in Settings to get started.
            </div>
          )
        }
      </div>
    );
  }
}

export default PaymentsSection;
