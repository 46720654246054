import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import "./Chat.css";

interface Props {
  icon: IconDefinition;
  active: boolean;
  onClick: () => void;
}
interface State {}

class ChatComposeIconButton extends PureComponent<Props, State> {
  render() {
    return (
      <div
        className="ChatComposeIconButton"
        style={{
          color: this.props.active ? "#4b7bec" : "rgba(0,0,0,0.85)",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: this.props.active ? "100%" : "0",
            background: "#fff",
            transition: "height 200ms",
            bottom: "0",
          }}
        ></div>
        <Clickable
          onClick={() => {
            if (typeof this.props.onClick === "function") {
              this.props.onClick();
            }
          }}
        >
          <div
            style={{
              position: "relative",
              padding: "10px 20px",
            }}
          >
            <FontAwesomeIcon icon={this.props.icon} />
          </div>
        </Clickable>
      </div>
    );
  }
}

export default ChatComposeIconButton;
