import React, { PureComponent } from "react";
import "./ProfilePhoto.css";

interface Props {
  size: number;
  imageUrl: string | undefined;
  id: string;
  name: string;
}

interface State {}

class ProfilePhoto extends PureComponent<Props, State> {
  // Convert a full name to initials
  // e.g. "John Smith" -> "JS", "Abby " -> "A", "Harry James Potter" => "H"
  getNameInitials(name) {
    let initials = "";

    // Split full name on spaces
    const split = name.split(" ");

    // First and last name both exist
    if (split.length === 2) {
      for (let i = 0; i < split.length; i++) {
        // Remove non-alphanumeric characters
        const t = split[i].replace(/[^a-z0-9]/gi, "");
        if (t.length > 0) initials += t[0];
      }
    }
    // Could not split into two names; only use first initial
    else {
      const t = split[0].replace(/[^a-z0-9]/gi, "");
      if (t.length > 0) initials += t[0];
    }

    // If no initials were parsed, return question mark
    // Else, convert to uppercase and return
    if (initials.length === 0) return "?";
    return initials.toUpperCase();
  }

  // Map UUID to a profile placeholder color
  getIdColor(id) {
    // Color options
    //const colors = ["#4b7bec", "#2a3950", "#ff6584", "#FFDA79", "#33D9B2"];
    const colors = [
      { background: "#4b7bec", text: "rgba(255,255,255,1.0)" },
      { background: "#2a3950", text: "rgba(255,255,255,1.0)" },
      { background: "#ff6584", text: "rgba(255,255,255,1.0)" },
      { background: "#FFDA79", text: "rgba(0,0,0,0.7)" },
      { background: "#33D9B2", text: "rgba(0,0,0,0.7)" },
    ];

    // If ID undefined or length-0
    if (!id) return colors[0];

    const ind = id.charCodeAt(0) % colors.length;
    return colors[ind];
  }

  render() {
    const initials = this.getNameInitials(this.props.name);
    const color = this.getIdColor(this.props.id);

    return (
      <div
        className="ProfilePhoto"
        style={{
          width: this.props.size + "px",
          height: this.props.size + "px",
          backgroundImage: "url(" + this.props.imageUrl + ")",
          backgroundColor: this.props.imageUrl ? "#f7f7f7" : color.background,
        }}
      >
        {!this.props.imageUrl && (
          <div
            className="ProfilePhotoPlaceholder"
            style={{
              fontSize: Math.round(0.35 * this.props.size) + "px",
              color: color.text,
            }}
          >
            {initials}
          </div>
        )}
      </div>
    );
  }
}

export default ProfilePhoto;
