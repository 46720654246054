import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import { formatUserList, getPhoneURI } from "../../HelperFunctions";
import "./Chat.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faPhone, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { Member } from "../MessagingInterfaces";
library.add(faArrowLeft, faPhone, faTrashAlt);

interface Props {
  members: Member[];
  narrowView: boolean;
  isLinked: boolean;
  propertyName?: string;
  unitName?: string;
  onDeleteCurrentChat: () => void;
  onShowMenu: () => void;
}
interface State {
  collapsed: boolean;
}

class ChatTitleBar extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
    };
  }

  getMemberNames(members) {
    return members.map((member) => {
      return member.name;
    });
  }

  componentDidUpdate(prevProps) {
    // When switching groups, re-collapse
    const memberIds = this.props.members.map((member) => member.id).join(", ");
    const prevMemberIds = prevProps.members.map((member) => member.id).join(", ");
    if (memberIds !== prevMemberIds) {
      this.setState({ collapsed: true });
    }
  }

  render() {
    const memberNames = this.getMemberNames(this.props.members);

    // If no chat is selected, don't display bar
    if (!(this.props.members && this.props.members.length > 0)) return null;

    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          padding: "12px 10px",
          zIndex: 1000,
          backgroundColor: "rgba(255,255,255,1.0)",
          opacity: 0.95,
          boxSizing: "border-box",
          overflow: "hidden",
          borderBottom: "1px solid #f7f7f7",
        }}
      >
        {this.props.narrowView && (
          <div
            style={{
              width: "40px",
              //height: "40px",
              flexGrow: 0,
              flexShrink: 0,
            }}
          >
            <Clickable
              onClick={() => {
                if (typeof this.props.onShowMenu === "function") {
                  this.props.onShowMenu();
                }
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
            </Clickable>
          </div>
        )}
        <div
          style={{
            position: "relative",
            minWidth: "0",
            flexGrow: 1,
            //minHeight: "40px",
          }}
        >
          <div
            style={{
              position: "relative",
              top: "50%",
              transform: "translateY(-50%)",
              paddingLeft: "10px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: this.state.collapsed ? "nowrap" : "normal",
            }}
          >
            <Clickable
              onClick={() => {
                this.setState((state) => {
                  return { collapsed: !state.collapsed };
                });
              }}
            >
              <span>{this.props.members && memberNames.length > 0 ? formatUserList(memberNames) : ""}</span>
              {this.props.isLinked && this.props.unitName && this.props.propertyName && (
                <span
                  style={{
                    marginLeft: "10px",
                    color: "rgba(0,0,0,0.35)",
                  }}
                >
                  {this.props.propertyName} · {this.props.unitName}
                </span>
              )}
              {this.state.collapsed && (
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "30px",
                    background: "linear-gradient(90deg, rgba(255,255,255,0.0), rgba(255,255,255,1.0))",
                    right: "0",
                    top: "0",
                  }}
                ></div>
              )}
            </Clickable>
          </div>
        </div>
        {
          // Show delete chat button if this chat exists
          this.props.members && this.props.members.length > 0 && (
            <div
              style={{
                position: "relative",
                width: "40px",
                //height: "40px",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <Clickable
                onClick={() => {
                  if (typeof this.props.onDeleteCurrentChat === "function") this.props.onDeleteCurrentChat();
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </div>
              </Clickable>
            </div>
          )
        }
        {
          // Show call button if there is a single member in this chat
          this.props.members && this.props.members.length === 1 && this.props.members[0].phone && (
            <div
              style={{
                position: "relative",
                width: "40px",
                //height: "40px",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <a
                href={getPhoneURI(this.props.members[0].phone)}
                style={{
                  position: "relative",
                  display: "block",
                  width: "100%",
                  height: "100%",
                  color: "#4b7bec",
                  textDecoration: "none",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </div>
              </a>
            </div>
          )
        }
      </div>
    );
  }
}

export default ChatTitleBar;
