import React, { Component } from "react";

import Clickable from "../../Clickable/Clickable";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import CustomInput from "../../CustomComponents/CustomInput/CustomInput";

// Firebase and input validation
import validator from "validator";
import firebase from "firebase/app";
import "firebase/auth";

import { InitialData } from "../AuthenticationWindow";
import "../AuthenticationWindow.css";

import { createFirebaseUser } from "../../CloudFunctions";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
library.add(faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck);

interface Props {
  showing: boolean;
  initialData: InitialData;
  onShowAddProfilePhoto: () => void;
  onShowRegisterConfirmation: () => void;
  onShowPrivacyPolicy: () => void;
  onShowTermsOfService: () => void;
  onShowLogin: () => void;
}
interface State {
  loading: boolean;
  input: { email: string; password: string; retypePassword: string };
  errors: { email: string; password: string; retypePassword: string };
}
class RegisterComplete extends Component<Props, State> {
  InputEmail = React.createRef<CustomInput>();
  InputPassword = React.createRef<CustomInput>();
  InputRetypePassword = React.createRef<CustomInput>();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      input: {
        email: "",
        password: "",
        retypePassword: "",
      },
      errors: {
        email: "",
        password: "",
        retypePassword: "",
      },
    };
  }

  clear() {
    this.InputEmail.current.clear();
    this.InputPassword.current.clear();
    this.InputRetypePassword.current.clear();
    this.setState({
      errors: {
        // name: "",
        email: "",
        password: "",
        retypePassword: "",
      },
    });
  }

  updateErrors() {
    let encounteredError = false;
    const errors = this.state.errors;
    const input = this.state.input;

    const email = input.email.trim();
    const password = input.password.trim();
    const retypePassword = input.retypePassword.trim();

    if (!validator.isEmail(email)) {
      errors.email = "Please enter a valid email address";
      encounteredError = true;
    }

    const req = this.checkPasswordRequirements(password);
    if (!req.hasAllRequirements) {
      errors.password = "Your password must meet all of the security requirements";
      encounteredError = true;
    }

    if (password !== retypePassword) {
      errors.retypePassword = "Your retyped password does not match";
      encounteredError = true;
    }

    if (encounteredError) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  checkPasswordRequirements(password) {
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = uppercase.toLowerCase();
    const numbers = "1234567890";
    const specials = "~!@#$%^&*()-_+=., ?<>:;";

    let hasUpper = false;
    let hasLower = false;
    let hasNumber = false;
    let hasSpecial = false;
    const isLongEnough = password.length >= 8;

    for (const c of password) {
      if (uppercase.includes(c)) {
        hasUpper = true;
      }
      if (lowercase.includes(c)) {
        hasLower = true;
      }
      if (numbers.includes(c)) {
        hasNumber = true;
      }
      if (specials.includes(c)) {
        hasSpecial = true;
      }
    }

    const hasAllRequirements = hasUpper && hasLower && hasNumber && hasSpecial && isLongEnough;

    return {
      hasUpper: hasUpper,
      hasLower: hasLower,
      hasNumber: hasNumber,
      hasSpecial: hasSpecial,
      isLongEnough: isLongEnough,
      hasAllRequirements: hasAllRequirements,
    };
  }

  getPasswordRequirements() {
    const getRequirement = (text, hasRequirement) => {
      return (
        <div
          style={{
            color: hasRequirement ? "#4b7bec" : "rgba(0,0,0,0.85)",
            padding: "2px 10px",
            display: "flex",
            transition: "color 80ms",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              width: "20px",
              textAlign: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <FontAwesomeIcon icon={hasRequirement ? faCheck : faTimes} />
            </div>
          </div>
          <div
            style={{
              marginLeft: "10px",
              fontSize: "14px",
            }}
          >
            {text}
          </div>
        </div>
      );
    };

    const password = this.state.input.password.trim();
    const req = this.checkPasswordRequirements(password);

    return (
      <div className="AuthenticationRequirementsWrapper">
        <div
          style={{
            fontSize: "14px",
            marginBottom: "5px",
            color: req.hasAllRequirements ? "#4b7bec" : "rgba(0,0,0,0.85)",
          }}
        >
          Your password must include:
        </div>
        {getRequirement("At least 8 characters", req.isLongEnough)}
        {getRequirement("Uppercase letter", req.hasUpper)}
        {getRequirement("Lowercase letter", req.hasLower)}
        {getRequirement("Number", req.hasNumber)}
        {getRequirement("Special character", req.hasSpecial)}
      </div>
    );
  }

  // Handle successful registration
  handleSuccess() {
    this.setState({ loading: false });
    this.props.onShowRegisterConfirmation();
  }

  // Handle email register
  handleRegister() {
    this.setState({ loading: true });

    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    const input = this.state.input;
    const email = input.email.trim();
    const password = input.password.trim();

    createFirebaseUser({ email, password, initialData: this.props.initialData })
      .then(() => this.handleSuccess())
      .catch((error: firebase.functions.HttpsError) => {
        const errors = this.state.errors;
        switch (error.details) {
          case "auth/invalid-email":
            errors.email = "Please enter a valid email address";
            break;
          case "auth/weak-password":
            errors.password = "Please choose a stronger password";
            break;
          case "auth/email-already-in-use":
            errors.email = "This email is already being used by another account";
            break;
          default:
            console.log("Found error while registering user", error, error.code);
            break;
        }

        // Update errors
        this.setState(
          {
            loading: false,
            errors: errors,
          },
          this.forceUpdate,
        );
      });
  }

  render() {
    return (
      <div
        style={{
          position: this.props.showing ? "relative" : "absolute",
          width: "100%",
          boxSizing: "border-box",
          zIndex: this.props.showing ? 1 : 0,
          transform: "translateY(" + (this.props.showing ? "0" : "10px") + ")",
          opacity: this.props.showing ? "1" : "0",
          padding: "25px 10px",
          transition: "transform 80ms, opacity 80ms",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleRegister();
          }}
        >
          <div className="AuthenticationTitle">Create an Account</div>

          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputEmail}
              icon={faEnvelope}
              placeholder="Email"
              errorText={this.state.errors.email}
              type={"email"}
              name={"email"}
              autoComplete={"email"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.email = text;
                  state.errors.email = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputPassword}
              icon={faLock}
              placeholder="Password"
              errorText={this.state.errors.password}
              type={"password"}
              name={"password"}
              autoComplete={"new-password"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.password = text;
                  state.errors.password = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div>{this.getPasswordRequirements()}</div>
          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputRetypePassword}
              icon={faRedoAlt}
              placeholder="Retype password"
              errorText={this.state.errors.retypePassword}
              type={"password"}
              name={"password"}
              autoComplete={"new-password"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.retypePassword = text;
                  state.errors.retypePassword = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>

          <div className="AuthenticationButtonWrapper">
            <CustomButton text="Sign Up" inverted={true} color={"#2A64AD"} loading={this.state.loading} submit={true} />
          </div>
        </form>

        <div className="AuthenticationTextWrapper">
          <span className="AuthenticationTextLight">
            By creating an account, you acknowledge that you have read and agree to our{" "}
          </span>
          <span className="AuthenticationText">
            <Clickable inline={true} onClick={this.props.onShowPrivacyPolicy}>
              Privacy Policy
            </Clickable>
          </span>
          <span className="AuthenticationTextLight"> and </span>
          <span className="AuthenticationText">
            <Clickable inline={true} onClick={this.props.onShowTermsOfService}>
              Terms of Service
            </Clickable>
          </span>
          <span className="AuthenticationTextLight">.</span>
        </div>

        <div className="AuthenticationTextWrapper">
          <Clickable onClick={this.props.onShowLogin}>
            <span className="AuthenticationTextLight">Already have an account?&nbsp;</span>
            <span className="AuthenticationTextBold">Sign in!</span>
          </Clickable>
        </div>
      </div>
    );
  }
}

export default RegisterComplete;
