import React, { PureComponent } from "react";
import Modal from "./Modal";
import firebase from "firebase/app";
import "firebase/analytics";

class ModalPrivacyPolicy extends PureComponent {
  modal = React.createRef<Modal>();

  show() {
    firebase.analytics().logEvent("privacy_policy_viewed");
    this.modal.current.show();
  }
  hide() {
    this.modal.current.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Privacy Policy"}>
        <div className="AllowTextSelection">
          <div className="ModalText">Effective date: September 9, 2019</div>
          <div className="ModalText">
            Rentingway, Inc. ("us", "we", or "our") operates the Rentingway.com website and the Rentingway mobile
            application (hereinafter referred to as the "Service").
          </div>
          <div className="ModalText">
            This page informs you of our policies regarding the collection, use and disclosure of personal data when you
            use our Service and the choices you have associated with that data.
          </div>
          <div className="ModalText">
            We use your data to provide and improve the Service. By using the Service, you agree to the collection and
            use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the
            terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
          </div>
          <div className="ModalSubtitle">Definitions</div>
          <div className="ModalSectionHeader">Service</div>
          <div className="ModalText">
            Service means the Rentingway.com website and the Rentingway mobile application operated by Rentingway, Inc.
          </div>
          <div className="ModalSectionHeader">Personal Data</div>
          <div className="ModalText">
            Personal Data means data about a living individual who can be identified from those data (or from those and
            other information either in our possession or likely to come into our possession).
          </div>
          <div className="ModalSectionHeader">Usage Data</div>
          <div className="ModalText">
            Usage Data is data collected automatically either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
          </div>
          <div className="ModalSectionHeader">Cookies</div>
          <div className="ModalText">Cookies are small files stored on your device (computer or mobile device).</div>
          <div className="ModalSectionHeader">Data Controller</div>
          <div className="ModalText">
            Data Controller means the natural or legal person who (either alone or jointly or in common with other
            persons) determines the purposes for which and the manner in which any personal information are, or are to
            be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
          </div>
          <div className="ModalSectionHeader">Data Processors (or Service Providers)</div>
          <div className="ModalText">
            Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of
            the Data Controller. We may use the services of various Service Providers in order to process your data more
            effectively.
          </div>
          <div className="ModalSectionHeader">Data Subject (or User)</div>
          <div className="ModalText">
            Data Subject is any living individual who is using our Service and is the subject of Personal Data.
          </div>
          <div className="ModalSectionHeader">Information Collection and Use</div>
          <div className="ModalText">
            We collect several different types of information for various purposes to provide and improve our Service to
            you.
          </div>
          <div className="ModalSectionHeader">Types of Data Collected</div>
          <div className="ModalSectionHeader">Personal Data</div>
          <div className="ModalText">
            While using our Service, we may ask you to provide us with certain personally identifiable information that
            can be used to contact or identify you ("Personal Data"). Personally identifiable information may include,
            but is not limited to:
          </div>
          <div className="ModalText">
            <ul>
              <li>Email address</li>
              <li>First name and last name</li>
              <li>Phone number</li>
              <li>Address, State, Province, ZIP/Postal code, City</li>
              <li>Cookies and Usage Data</li>
            </ul>
          </div>
          <div className="ModalText">
            <span>
              You authorize us to collect and share with our payments provider Dwolla, Inc. your personal information
              including full name, date of birth, social security number, physical address, email address and financial
              information, and you are responsible for the accuracy and completeness of that data. Dwolla’s Privacy
              Policy is available{" "}
            </span>
            <a
              className="ModalAnchor"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.dwolla.com/legal/privacy/"
            >
              here
            </a>
            <span>.</span>
          </div>
          <div className="ModalText">
            We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other
            information that may be of interest to you. You may opt out of receiving some of these communications from
            us by following the unsubscribe link or the instructions provided in any email we send. All transactional
            notifications and emails cannot be unsubscribed from.
          </div>
          <div className="ModalSectionHeader">Usage Data</div>
          <div className="ModalText">
            We may also collect information that your browser sends whenever you visit our Service or when you access
            the Service by or through a mobile device ("Usage Data").
          </div>
          <div className="ModalText">
            This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the
            time spent on those pages, unique device identifiers and other diagnostic data.
          </div>
          <div className="ModalText">
            When you access the Service with a mobile device, this Usage Data may include information such as the type
            of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browser you use, unique device identifiers and other
            diagnostic data.
          </div>
          <div className="ModalSectionHeader">Location Data</div>
          <div className="ModalText">
            We may use and store information about your location if you give us permission to do so ("Location Data").
            We use this data to provide features of our Service, to improve and customise our Service.
          </div>
          <div className="ModalText">
            You can enable or disable location services when you use our Service at any time by way of your device
            settings.
          </div>
          <div className="ModalSectionHeader">Tracking Cookies Data</div>
          <div className="ModalText">
            We use cookies and similar tracking technologies to track the activity on our Service and we hold certain
            information.
          </div>
          <div className="ModalText">
            Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are
            sent to your browser from a website and stored on your device. Other tracking technologies are also used
            such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.
          </div>
          <div className="ModalText">
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
            you do not accept cookies, you may not be able to use some portions of our Service.
          </div>
          <div className="ModalText">Examples of Cookies we use:</div>
          <div className="ModalText">
            <ul>
              <li>Session Cookies. We use Session Cookies to operate our Service.</li>
              <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
              <li>Security Cookies. We use Security Cookies for security purposes.</li>
              <li>
                Advertising Cookies. Advertising Cookies are used to serve you with advertisements that may be relevant
                to you and your interests.
              </li>
            </ul>
          </div>
          <div className="ModalSubtitle">Use of Data</div>
          <div className="ModalText">Rentingway, Inc. uses the collected data for various purposes:</div>
          <div className="ModalText">
            <ul>
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
              <li>To provide customer support</li>
              <li>To gather analysis or valuable information so that we can improve our Service</li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent and address technical issues</li>
              <li>
                To provide you with news, special offers and general information about other goods, services and events
                which we offer that are similar to those that you have already purchased or enquired about unless you
                have opted not to receive such information
              </li>
            </ul>
          </div>
          <div className="ModalSubtitle">
            Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)
          </div>
          <div className="ModalText">
            If you are from the European Economic Area (EEA), Rentingway, Inc. legal basis for collecting and using the
            personal information described in this Privacy Policy depends on the Personal Data we collect and the
            specific context in which we collect it.
          </div>
          <div className="ModalText">Rentingway, Inc. may process your Personal Data because:</div>
          <div className="ModalText">
            <ul>
              <li>We need to perform a contract with you</li>
              <li>You have given us permission to do so</li>
              <li>The processing is in our legitimate interests and it is not overridden by your rights</li>
              <li>For payment processing purposes</li>
              <li>To comply with the law</li>
            </ul>
          </div>
          <div className="ModalSubtitle">Retention of Data</div>
          <div className="ModalText">
            Rentingway, Inc. will retain your Personal Data only for as long as is necessary for the purposes set out in
            this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your data to comply with applicable laws),
            resolve disputes and enforce our legal agreements and policies.
          </div>
          <div className="ModalText">
            Rentingway, Inc. will also retain Usage Data for internal analysis purposes. Usage Data is generally
            retained for a shorter period of time, except when this data is used to strengthen the security or to
            improve the functionality of our Service, or we are legally obligated to retain this data for longer
            periods.
          </div>
          <div className="ModalSubtitle">Transfer of Data</div>
          <div className="ModalText">
            Your information, including Personal Data, may be transferred to - and maintained on - computers located
            outside of your state, province, country or other governmental jurisdiction where the data protection laws
            may differ from those of your jurisdiction.
          </div>
          <div className="ModalText">
            If you are located outside United States and choose to provide information to us, please note that we
            transfer the data, including Personal Data, to United States and process it there.
          </div>
          <div className="ModalText">
            Your consent to this Privacy Policy followed by your submission of such information represents your
            agreement to that transfer.
          </div>
          <div className="ModalText">
            Rentingway, Inc. will take all the steps reasonably necessary to ensure that your data is treated securely
            and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
            organisation or a country unless there are adequate controls in place including the security of your data
            and other personal information.
          </div>
          <div className="ModalSubtitle">Disclosure of Data</div>
          <div className="ModalSectionHeader">Business Transaction</div>
          <div className="ModalText">
            If Rentingway, Inc. is involved in a merger, acquisition or asset sale, your Personal Data may be
            transferred. We will provide notice before your Personal Data is transferred and becomes subject to a
            different Privacy Policy.
          </div>
          <div className="ModalSectionHeader">Disclosure for Law Enforcement</div>
          <div className="ModalText">
            Under certain circumstances, Rentingway, Inc. may be required to disclose your Personal Data if required to
            do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
          </div>
          <div className="ModalSectionHeader">Legal Requirements</div>
          <div className="ModalText">
            Rentingway, Inc. may disclose your Personal Data in the good faith belief that such action is necessary to:
          </div>
          <div className="ModalText">
            <ul>
              <li>To comply with a legal obligation</li>
              <li>To protect and defend the rights or property of Rentingway, Inc.</li>
              <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
              <li>To protect the personal safety of users of the Service or the public</li>
              <li>To protect against legal liability</li>
            </ul>
          </div>
          <div className="ModalSubtitle">Security of Data</div>
          <div className="ModalText">
            The security of your data is important to us but remember that no method of transmission over the Internet
            or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to
            protect your Personal Data, we cannot guarantee its absolute security.
          </div>
          <div className="ModalSubtitle">
            Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)
          </div>
          <div className="ModalText">
            We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to
            inform websites that you do not want to be tracked.
          </div>
          <div className="ModalText">
            You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
          </div>
          <div className="ModalSubtitle">
            Your Data Protection Rights under the General Data Protection Regulation (GDPR)
          </div>
          <div className="ModalText">
            If you are a resident of the European Economic Area (EEA), you have certain data protection rights.
            Rentingway, Inc. aims to take reasonable steps to allow you to correct, amend, delete or limit the use of
            your Personal Data.
          </div>
          <div className="ModalText">
            If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from
            our systems, please contact us.
          </div>
          <div className="ModalText">In certain circumstances, you have the following data protection rights:</div>
          <div className="ModalText">
            <ul>
              <li>
                The right to access, update or delete the information we have on you. Whenever made possible, you can
                access, update or request deletion of your Personal Data directly within your account settings section.
                If you are unable to perform these actions yourself, please contact us to assist you.
              </li>
              <li>
                The right of rectification. You have the right to have your information rectified if that information is
                inaccurate or incomplete.
              </li>
              <li>The right to object. You have the right to object to our processing of your Personal Data.</li>
              <li>
                The right of restriction. You have the right to request that we restrict the processing of your personal
                information.
              </li>
              <li>
                The right to data portability. You have the right to be provided with a copy of the information we have
                on you in a structured, machine-readable and commonly used format.
              </li>
              <li>
                The right to withdraw consent. You also have the right to withdraw your consent at any time where
                Rentingway, Inc. relied on your consent to process your personal information.
              </li>
            </ul>
          </div>
          <div className="ModalText">
            Please note that we may ask you to verify your identity before responding to such requests.
          </div>
          <div className="ModalText">
            You have the right to complain to a Data Protection Authority about our collection and use of your Personal
            Data. For more information, please contact your local data protection authority in the European Economic
            Area (EEA).
          </div>
          <div className="ModalSubtitle">Service Providers</div>
          <div className="ModalText">
            We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide
            the Service on our behalf, perform Service-related services or assist us in analysing how our Service is
            used.
          </div>
          <div className="ModalText">
            These third parties have access to your Personal Data only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </div>
          <div className="ModalSubtitle">Analytics</div>
          <div className="ModalSectionHeader">Google Analytics</div>
          <div className="ModalText">
            Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
            Google uses the data collected to track and monitor the use of our Service. This data is shared with other
            Google services. Google may use the collected data to contextualise and personalise the ads of its own
            advertising network. For more information on the privacy practices of Google, please visit the Google
            Privacy Terms web page: https://policies.google.com/privacy?hl=en
          </div>
          <div className="ModalSectionHeader">Firebase</div>
          <div className="ModalText">
            Firebase is an analytics service provided by Google Inc. You may opt-out of certain Firebase features
            through your mobile device settings, such as your device advertising settings or by following the
            instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy?hl=en We also
            encourage you to review the Google's policy for safeguarding your data:
            https://support.google.com/analytics/answer/6004245. For more information on what type of information
            Firebase collects, please visit the Google Privacy Terms web page: https://policies.google.com/privacy?hl=en
          </div>
          <div className="ModalSubtitle">Advertising</div>
          <div className="ModalText">
            We may use third-party Service Providers to show advertisements to you to help support and maintain our
            Service.
          </div>
          <div className="ModalSectionHeader">Google AdSense DoubleClick Cookie</div>
          <div className="ModalText">
            Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick
            cookie enables it and its partners to serve ads to our users based on their visit to our Service or other
            websites on the Internet. You may opt out of the use of the DoubleClick Cookie for interest-based
            advertising by visiting the Google Ads Settings web page: http://www.google.com/ads/preferences/
          </div>
          <div className="ModalSectionHeader">AdMob by Google</div>
          <div className="ModalText">
            AdMob by Google is provided by Google Inc. You can opt-out from the AdMob by Google service by following the
            instructions described by Google: https://support.google.com/ads/answer/2662922?hl=en For more information
            on how Google uses the collected information, please visit the "How Google uses data when you use our
            partners' sites or app" page: http://www.google.com/policies/privacy/partners/ or visit the Privacy Policy
            of Google: http://www.google.com/policies/privacy/
          </div>
          <div className="ModalSubtitle">Behavioral Remarketing</div>
          <div className="ModalText">
            Rentingway, Inc. uses remarketing services to advertise on third party websites to you after you visited our
            Service. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </div>
          <div className="ModalSectionHeader">Google Ads (AdWords)</div>
          <div className="ModalText">
            Google Ads (AdWords) remarketing service is provided by Google Inc. You can opt-out of Google Analytics for
            Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page:
            http://www.google.com/settings/ads Google also recommends installing the Google Analytics Opt-out Browser
            Add-on - https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser
            Add-on provides visitors with the ability to prevent their data from being collected and used by Google
            Analytics. For more information on the privacy practices of Google, please visit the Google Privacy Terms
            web page: https://policies.google.com/privacy?hl=en
          </div>
          <div className="ModalSectionHeader">Facebook</div>
          <div className="ModalText">
            Facebook remarketing service is provided by Facebook Inc. You can learn more about interest-based
            advertising from Facebook by visiting this page: https://www.facebook.com/help/164968693837950 To opt-out
            from Facebook's interest-based ads, follow these instructions from Facebook:
            https://www.facebook.com/help/568137493302217 Facebook adheres to the Self-Regulatory Principles for Online
            Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook
            and other participating companies through the Digital Advertising Alliance in the USA
            http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada
            http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe
            http://www.youronlinechoices.eu/, or opt-out using your mobile device settings. For more information on the
            privacy practices of Facebook, please visit Facebook's Data Policy:
            https://www.facebook.com/privacy/explanation
          </div>
          <div className="ModalSubtitle">Payments</div>
          <div className="ModalText">
            We may provide paid products and/or services within the Service. In that case, we use third-party services
            for payments.
          </div>
          <div className="ModalText">
            We will collect, but not store your payment information. That information is provided directly to our
            third-party payment partners whose use of your personal information is governed by their Privacy Policy.
          </div>
          <div className="ModalText">The payment partners we work with are:</div>
          <div className="ModalSectionHeader">Dwolla</div>
          <div className="ModalText">Their Privacy Policy can be viewed at https://www.dwolla.com/legal/privacy/</div>
          <div className="ModalSectionHeader">Apple Store In-App Payments</div>
          <div className="ModalText">
            Their Privacy Policy can be viewed at https://www.apple.com/legal/privacy/en-ww/
          </div>
          <div className="ModalSectionHeader">Google Play In-App Payments</div>
          <div className="ModalText">
            Their Privacy Policy can be viewed at https://www.google.com/policies/privacy/
          </div>
          <div className="ModalSectionHeader">PayPal / Braintree</div>
          <div className="ModalText">
            Their Privacy Policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full
          </div>
          <div className="ModalSubtitle">Links to Other Sites</div>
          <div className="ModalText">
            Our Service may contain links to other sites that are not operated by us. If you click a third party link,
            you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of
            every site you visit.
          </div>
          <div className="ModalText">
            We have no control over and assume no responsibility for the content, privacy policies or practices of any
            third party sites or services.
          </div>
          <div className="ModalSubtitle">Children's Privacy</div>
          <div className="ModalText">Our Service does not address anyone under the age of 18 ("Children").</div>
          <div className="ModalText">
            We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are
            a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact
            us. If we become aware that we have collected Personal Data from children without verification of parental
            consent, we take steps to remove that information from our servers.
          </div>
          <div className="ModalSubtitle">Changes to This Privacy Policy</div>
          <div className="ModalText">
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page.
          </div>
          <div className="ModalText">
            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming
            effective and update the "effective date" at the top of this Privacy Policy.
          </div>
          <div className="ModalText">
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy
            are effective when they are posted on this page.
          </div>
          <div className="ModalSubtitle">Contact Us</div>
          <div className="ModalText">If you have any questions about this Privacy Policy, please contact us:</div>
          <div className="ModalText">
            <ul>
              <li>By email: support@rentingway.com</li>
            </ul>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPrivacyPolicy;
