import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

class ModalVerificationFailed extends PureComponent {
  modal = React.createRef<Modal>();

  show() {
    this.modal.current.show();
  }
  hide() {
    this.modal.current.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Verification Failed"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.hide();
          }}
        >
          <div className="ModalText">
            Sorry, we weren't able to verify your identity. Until your identity is verified, you won't be able to send
            or receieve payments. Please contact support@rentingway.com.
          </div>
          <div className="ModalButtonWrapper">
            <CustomButton text={"Got it"} inverted={true} color={"#2A3950"} submit={true} />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalVerificationFailed;
