import React, { PureComponent } from "react";
import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import CustomCheckbox from "../CustomComponents/CustomCheckbox/CustomCheckbox";
import Modal from "./Modal";

import { v4 as uuidv4 } from "uuid";

import { getCurrentUserUid } from "../auth";

import firebase from "firebase/app";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import validator from "validator";

library.add(faUser, faEnvelope);

interface Props {
  onVerificationResponse: (status) => void;
}
interface State {
  loading: boolean;
  input: {
    firstName: string;
    lastName: string;
    email: string;
  };
  errors: {
    firstName: string;
    lastName: string;
    email: string;
  };
  agreedToLegal: boolean;
  showingAgreementError: boolean;
}

class ModalVerifyUser extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();
  form = React.createRef<HTMLFormElement>();

  InputFirstName = React.createRef<CustomInput>();
  InputLastName = React.createRef<CustomInput>();
  InputEmail = React.createRef<CustomInput>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      input: {
        firstName: "",
        lastName: "",
        email: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
      },
      agreedToLegal: false,
      showingAgreementError: false,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    // Clear input states and errors
    this.setState({
      input: {
        firstName: "",
        lastName: "",
        email: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
      },
      loading: false,
    });

    this.InputFirstName.current.clear();
    this.InputLastName.current.clear();
    this.InputEmail.current.clear();
  }

  async show() {
    this.clear();
    this.modal.current.show();

    // Get data associated with this user
    const userData = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();
    const data = userData.data();

    // Make sure we're storing first and last names
    if (data.first_name) this.InputFirstName.current.setValue(data.first_name);
    if (data.last_name) this.InputLastName.current.setValue(data.last_name);

    this.InputEmail.current.setValue(data.email);
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async handleVerifyUser() {
    this.setState({
      loading: true,
      showingAgreementError: false,
    });

    const input = this.state.input;
    const errors = this.state.errors;
    let encounteredError = false;

    if (input.firstName.length === 0) {
      errors.firstName = "Please provide your first name";
      encounteredError = true;
    }
    if (input.lastName.length === 0) {
      errors.lastName = "Please provide your last name";
      encounteredError = true;
    }
    if (!validator.isEmail(input.email)) {
      errors.email = "Please enter a valid email address";
      encounteredError = true;
    }
    if (!this.state.agreedToLegal) {
      this.setState({ showingAgreementError: true });
      encounteredError = true;
    }

    // If an error was encountered, display and don't send any data to Dwolla
    if (encounteredError) {
      // Update errors and force re-render
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
      return;
    }

    const customer = {
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
    };

    const result = await firebase.functions().httpsCallable("createCustomer")({
      customer: customer,
      idempotencyKey: uuidv4(),
    });

    this.props.onVerificationResponse(result.data.status);
    this.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Your Information"}>
        <form
          ref={this.form}
          onSubmit={(e) => {
            e.preventDefault();
            this.handleVerifyUser();
          }}
        >
          <div className="ModalText">Please verify your information to send and receive payments.</div>
          <div className="ModalSectionHeader">Personal information</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputFirstName}
              icon={faUser}
              placeholder="Legal first name"
              type={"text"}
              name={"fname"}
              autoComplete={"given-name"}
              errorText={this.state.errors.firstName}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.firstName = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputLastName}
              icon={faUser}
              placeholder="Legal last name"
              type={"text"}
              name={"lname"}
              autoComplete={"family-name"}
              errorText={this.state.errors.lastName}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.lastName = text;
                  return { input: input };
                });
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputEmail}
              icon={faEnvelope}
              placeholder="Email"
              type={"email"}
              name={"email"}
              autoComplete={"email"}
              errorText={this.state.errors.email}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  input.email = text;
                  return { input: input };
                });
              }}
            />
          </div>

          <div>
            <div
              className="ModalText"
              style={{
                marginTop: "25px",
                marginBottom: "10px",
                color: "rgba(0,0,0,0.35)",
              }}
            >
              Transactions on Rentingway are processed by Dwolla, using military-grade encryption. Rentingway does not
              store your payment information.
            </div>
            <div className="ModalText">
              <CustomCheckbox
                onChange={(checked) => {
                  this.setState({
                    agreedToLegal: checked,
                    showingAgreementError: false,
                  });
                }}
              >
                <span>I acknowledge that I have read and agree to the Dwolla </span>
                <span>
                  <a
                    className="ModalAnchor"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.dwolla.com/legal/tos/"
                  >
                    Terms of Service
                  </a>
                </span>
                <span> and </span>
                <span>
                  <a
                    className="ModalAnchor"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.dwolla.com/legal/privacy/"
                  >
                    Privacy Policy
                  </a>
                </span>
              </CustomCheckbox>
            </div>
            {this.state.showingAgreementError && (
              <div className="ModalText">Please agree to the Dwolla Privacy Policy and Terms of Service above.</div>
            )}
          </div>

          <div className="ModalButtonWrapper">
            <CustomButton
              text={"Agree & Continue"}
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalVerifyUser;
