import React, { PureComponent } from "react";
import "./CustomCheckbox.css";

interface Props {
  id?: string;
  onChange: (x: boolean) => any;
}
interface State {}

class CustomRadio extends PureComponent<Props, State> {
  wrapper = React.createRef<HTMLDivElement>();
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  clear() {
    this.setState({ value: undefined });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(false);
    }

    const els = this.wrapper.current.getElementsByTagName("input");
    for (let i = 0; i < els.length; i++) {
      els[i].checked = false;
    }
  }

  setValue(value) {
    this.setState({ value: value });
    this.props.onChange(value);
  }

  render() {
    return (
      <div ref={this.wrapper} className="CustomCheckbox" key={this.props.id}>
        <input
          className="CustomCheckboxInput"
          type="checkbox"
          name={this.props.id}
          id={this.props.id}
          onChange={(e) => {
            this.setValue(e.target.checked);
          }}
        />
        <div className="DisplayCheckbox"></div>
        <label className="CustomCheckboxLabel" htmlFor={this.props.id}>
          {this.props.children}
        </label>
      </div>
    );
  }
}

export default CustomRadio;
