import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";

import { Plugins, Capacitor, CameraResultType } from "@capacitor/core";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { uploadImage, uploadImageWithAutoRotate } from "../../ImageUploadHelpers";
import "./Chat.css";

library.add(faPlus, faTimes);

const { Camera } = Plugins;

interface ImageInputPreviewProps {
  imageUrl: string;
  onViewImage: (arg0: string) => void;
  onRemove: () => void;
}
interface ImageInputPreviewState {}

class ImageInputPreview extends PureComponent<ImageInputPreviewProps, ImageInputPreviewState> {
  render() {
    return (
      <div
        style={{
          position: "relative",
          width: "100px",
          height: "100px",
          borderRadius: "5px",
          marginRight: "10px",
          marginBottom: "10px",
          backgroundImage: "url(" + this.props.imageUrl + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
          }}
        >
          <Clickable
            onClick={() => {
              this.props.onViewImage(this.props.imageUrl);
            }}
          ></Clickable>
        </div>
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "0",
            fontSize: "14px",
            width: "25px",
            height: "25px",
            borderRadius: "0 0 0 5px",
            backgroundColor: "rgba(0,0,0,0.15)",
            color: "#fff",
          }}
        >
          <Clickable
            onClick={() => {
              if (typeof this.props.onRemove === "function") {
                this.props.onRemove();
              }
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </Clickable>
        </div>
      </div>
    );
  }
}

interface ImagePickerProps {
  onUpdate: (imageUrls: Array<string>) => void;
  onViewImage: (imageUrl: string) => void;
}
interface ImagePickerState {
  imageUrls: Array<string>;
  loading: boolean;
}

class ImagePicker extends PureComponent<ImagePickerProps, ImagePickerState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      imageUrls: [],
    };
  }

  clear() {
    this.setState({
      imageUrls: [],
      loading: false,
    });
    if (typeof this.props.onUpdate === "function") {
      this.props.onUpdate([]);
    }
  }

  getImagePreviews() {
    return this.state.imageUrls.map((imageUrl, index) => {
      return (
        <ImageInputPreview
          key={index}
          imageUrl={imageUrl}
          onRemove={() => {
            this.setState((state) => {
              const imageUrls = state.imageUrls;
              imageUrls.splice(index, 1);
              if (typeof this.props.onUpdate === "function") {
                this.props.onUpdate(imageUrls);
              }
              return { imageUrls: imageUrls };
            });
            this.forceUpdate();
          }}
          onViewImage={(imageUrl) => {
            this.props.onViewImage(imageUrl);
          }}
        />
      );
    });
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          flexGrow: 1,
          paddingTop: "10px",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {this.getImagePreviews()}
        <div
          style={{
            position: "relative",
            width: "100px",
            height: "100px",
            marginBottom: "10px",
            borderRadius: "5px",
            backgroundColor: "#F7F8FA",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "rgba(0,0,0,0.85)",
            }}
          >
            {this.state.loading ? <LoadingIcon /> : <FontAwesomeIcon icon={faPlus} />}
          </div>
          {/* Input field */}
          {Capacitor.isNative ? (
            <Clickable
              onClick={async () => {
                this.setState({
                  loading: true,
                });
                const image = await Camera.getPhoto({
                  quality: 90,
                  allowEditing: false,
                  resultType: CameraResultType.Base64,
                  correctOrientation: true,
                });
                const imageURL = await uploadImage(image.base64String, "chat_pictures", "jpeg", (progressDouble) => {});
                this.setState((state) => {
                  const urls = state.imageUrls;
                  urls.push(imageURL);
                  this.props.onUpdate(urls);
                  return {
                    loading: false,
                    imageUrls: urls,
                  };
                });
              }}
            ></Clickable>
          ) : (
            <input
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                opacity: "0",
                outline: "none",
                border: "none",
                cursor: "pointer",
              }}
              type="file"
              accept="image/*"
              onChange={async (e) => {
                if (e.target.files[0] !== undefined) {
                  this.setState({
                    loading: true,
                  });
                  const imageURL = await uploadImageWithAutoRotate(
                    e.target.files[0],
                    "chat_pictures",
                    (progressDouble) => {
                      //TODO: Aidan do something with progress?
                      console.log(progressDouble * 100 + "%");
                    },
                  );

                  this.setState((state) => {
                    const urls = state.imageUrls;
                    urls.push(imageURL);
                    this.props.onUpdate(urls);
                    return {
                      loading: false,
                      imageUrls: urls,
                    };
                  });
                } else {
                  this.setState({
                    loading: false,
                  });
                }
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ImagePicker;
