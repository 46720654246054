import React, { PureComponent } from "react";
import { Plugins, HapticsImpactStyle, Capacitor } from "@capacitor/core";
import Favico from "favico.js";

import "./GlobalNavigation.css";
import { ReactComponent as Logo } from "../assets/logo_blue.svg";

import { getCurrentUserUid } from "../auth";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faBell } from "@fortawesome/pro-regular-svg-icons";
import {
  faUser as farUser,
  faCog as farCog,
  faComments as farComments,
  faHome as farHome,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faUser as fasUser,
  faCog as fasCog,
  faComments as fasComments,
  faHome as fasHome,
} from "@fortawesome/pro-solid-svg-icons";
import IconButton from "./IconButton";
library.add(faBell, farUser, fasUser);

const { Haptics } = Plugins;

interface Props {
  showing: boolean;
  typing: boolean;
  onShowSettings: () => void;
  onShowProfile: () => void;
  onShowMessaging: () => void;
  onShowManage: () => void;
}

interface State {
  currentPage: string;
  notifications: {
    manage: number;
    messaging: number;
    profile: number;
    settings: number;
    feed: number;
  };
  loggedIn: boolean;
  favicoAmount: number;
}

class GlobalNavigation extends PureComponent<Props, State> {
  favico: Favico = new Favico({
    animation: "popFade",
    fontFamily: "Roboto",
    fontStyle: 500,
    type: "rectangle",
  });
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "manage",
      notifications: {
        manage: 0,
        messaging: 0,
        profile: 0,
        settings: 0,
        feed: 0,
      },
      loggedIn: false,
      favicoAmount: 0,
    };
  }

  updateFaviconBadge() {
    if (this.state.loggedIn) {
      let amount = 0;
      const notifs = this.state.notifications;
      Object.keys(notifs).forEach(function (k) {
        amount += notifs[k];
      });
      if (amount !== this.state.favicoAmount) {
        if (amount === 0) {
          this.favico.reset();
        } else {
          this.favico.badge(amount);
        }
        this.setState({ favicoAmount: amount });
      }
    }
  }

  componentDidMount() {
    this.updateFaviconBadge();
  }
  componentDidUpdate() {
    this.updateFaviconBadge();
  }

  handleSuccessfulLogin() {
    firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("chats")
      .where("unseen_count", ">", 0)
      .onSnapshot((snapshot) => {
        let messaging_notifications_sum = 0;
        for (let i = 0; i < snapshot.docs.length; i++) {
          messaging_notifications_sum += snapshot.docs[i].data().unseen_count;
        }
        this.setState((state) => {
          const notifications = state.notifications;
          notifications.messaging = messaging_notifications_sum;
          return {
            notifications: notifications,
            loggedIn: true,
          };
        });
        this.forceUpdate();
      });
  }

  handleProfilePhotoExists(profilePhotoExists) {
    const amount = profilePhotoExists ? 0 : 1;
    this.setState((state) => {
      const notifications = state.notifications;
      notifications.profile = amount;
      return { notifications: notifications };
    });
  }

  handleUpdatePaymentsSetupComplete(complete) {
    const amount = complete ? 0 : 1;
    this.setState((state) => {
      const notifications = state.notifications;
      notifications.settings = amount;
      return { notifications: notifications };
    });
  }

  render() {
    return (
      <div
        style={{
          transform: "translateY(" + (this.props.showing ? "0" : "100%") + ")",
          transition: "transform 300ms",
          position: "absolute",
          boxSizing: "border-box",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="GlobalNavigationTopBar">
          <div style={{ display: "flex" }}>
            <div
              style={{
                height: "50px",
                width: "50px",
                background: "#fff",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  width: "18px",
                  height: "18px",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Logo className="GlobalNavigationLogo" />
              </div>
            </div>
          </div>
          {/* Top navigation bar */}
          <div
            style={{
              boxSizing: "border-box",
              flexGrow: 1,
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                position: "relative",
                top: "50%",
                transform: "translateY(-50%)",
                height: "auto",
                flexGrow: 0,
                fontFamily: '"Roboto Slab", sans-serif',
                color: "#2A64AD",
              }}
            >
              Rentingway {process.env.REACT_APP_ENV === "dev" ? "Development Build" : ""}
            </div>
          </div>
          {/* Items on the right of the navigation bar, only shown if bottom bar is hidden */}
          <div className="GlobalNavigationTopBarBottomItems">
            <IconButton
              constrainWidth={true}
              notifications={this.state.notifications.settings}
              icon={farCog}
              activeIcon={fasCog}
              active={this.state.currentPage === "settings"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "settings" });
                if (typeof this.props.onShowSettings === "function") {
                  this.props.onShowSettings();
                }
              }}
            />
            <IconButton
              constrainWidth={true}
              notifications={this.state.notifications.profile}
              icon={farUser}
              activeIcon={fasUser}
              active={this.state.currentPage === "profile"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "profile" });
                if (typeof this.props.onShowProfile === "function") {
                  this.props.onShowProfile();
                }
              }}
            />
            <IconButton
              constrainWidth={true}
              notifications={this.state.notifications.messaging}
              icon={farComments}
              activeIcon={fasComments}
              active={this.state.currentPage === "messaging"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "messaging" });
                if (typeof this.props.onShowMessaging === "function") {
                  this.props.onShowMessaging();
                }
              }}
            />
            <IconButton
              constrainWidth={true}
              notifications={this.state.notifications.manage}
              icon={farHome}
              activeIcon={fasHome}
              active={this.state.currentPage === "manage"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "manage" });
                if (typeof this.props.onShowManage === "function") {
                  this.props.onShowManage();
                }
              }}
            />
          </div>
        </div>
        {/* Main content, this.props.children is dynamically updated depending on the screen showing */}
        <div
          style={{
            position: "relative",
            boxSizing: "border-box",
            flexGrow: 1,
            display: "flex",
          }}
        >
          {this.props.children}
        </div>
        {/* Bottom bar, only shows on mobile when not typing */}
        {!this.props.typing && (
          <div className="GlobalNavigationBottomBar">
            <IconButton
              constrainWidth={false}
              notifications={this.state.notifications.settings}
              icon={farCog}
              activeIcon={fasCog}
              active={this.state.currentPage === "settings"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "settings" });
                if (typeof this.props.onShowSettings === "function") {
                  this.props.onShowSettings();
                }
              }}
            />
            <IconButton
              constrainWidth={false}
              notifications={this.state.notifications.profile}
              icon={farUser}
              activeIcon={fasUser}
              active={this.state.currentPage === "profile"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "profile" });
                if (typeof this.props.onShowProfile === "function") {
                  this.props.onShowProfile();
                }
              }}
            />
            <IconButton
              constrainWidth={false}
              notifications={this.state.notifications.messaging}
              icon={farComments}
              activeIcon={fasComments}
              active={this.state.currentPage === "messaging"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "messaging" });
                if (typeof this.props.onShowMessaging === "function") {
                  this.props.onShowMessaging();
                }
              }}
            />
            <IconButton
              constrainWidth={false}
              notifications={this.state.notifications.manage}
              icon={farHome}
              activeIcon={fasHome}
              active={this.state.currentPage === "manage"}
              onClick={() => {
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
                this.setState({ currentPage: "manage" });
                if (typeof this.props.onShowManage === "function") {
                  this.props.onShowManage();
                }
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default GlobalNavigation;
