import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import { Member } from "./MessagingPage/MessagingInterfaces";
import firebase from "firebase/app";
import { format, isMatch, isToday, isValid, parse, parseISO } from "date-fns";
import { getCurrentUserUid } from "./auth";

/**
 * 2.5 -> "$2.50"
 */
export const formatMoneyAmount = (amount: number) => {
  const isNegative = amount < 0;
  amount = Math.abs(amount);
  const cents = Math.round((amount - Math.floor(amount)) * 100);
  const centsString = (cents < 10 ? "0" : "") + cents;
  const dollars = Math.floor(amount);
  return (isNegative ? "-" : "") + "$" + dollars.toLocaleString() + (cents === 0 ? "" : "." + centsString);
};
/**
 * "$2.50" -> 2.5
 */
export const parseMoneyAmount = (value: string) => {
  // Remove dollar sign and commas
  value = value.replace("$", "");
  value = value.replace(",", "");

  // A numeric version of the value, or NaN if it's not a numberic string
  const numeric = +value;

  // Return null if it's not valid, or a number representing the amount
  if (isNaN(numeric)) return null;

  // Round down the value to the nearest cent
  return Math.floor(numeric * 100) / 100;
};
/**
 * Parse a phone number to correct E.164 format: +15553451234
 */
export const parsePhone = (value: string) => {
  const phoneNumber = parsePhoneNumberFromString(value, "US");
  return phoneNumber ? phoneNumber.number.toString() : "";
};
/**
 * Formats a phone string (e.g. "5555555555" -> "(555) 555-5555")
 * Suitable for formatting input while typing
 * International format
 */
export const formatPhone = (value: string) => {
  return new AsYouType("US").input(value);
};
/**
 * Formats a phone string (e.g. "5555555555" -> "(555) 555-5555")
 * Not suitable for formatting input while typing
 * US format - does not display country code
 */
export const formatPhoneUS = (value: string) => {
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber ? phoneNumber.formatNational() : "";
};
export const getPhoneURI = (value: string) => {
  if (!value) {
    console.error("Phone number unexpectedly undefined");
    return "";
  }
  const phoneNumber = parsePhoneNumberFromString(value, "US");
  return phoneNumber ? phoneNumber.getURI() : "";
};
export const isValidPhone = (value: string) => {
  if (!value) {
    return false;
  }
  return parsePhoneNumberFromString(value, "US").isValid();
};

const handleTimestampInput = (input: number | firebase.firestore.Timestamp): Date =>
  !!(input as firebase.firestore.Timestamp).toDate
    ? (input as firebase.firestore.Timestamp).toDate()
    : new Date(input as number);

/**
 * Converts timestamp into a human readable time.
 * If timestamp is today, only return the time. e.g. "10:20 AM"
 * If timestamp is not today, return time and date. e.g. "Jun 20, 2019 at 11:30 PM"
 */
export const formatTimeString = (input: number | firebase.firestore.Timestamp) => {
  const d = handleTimestampInput(input);
  if (isToday(d)) {
    return format(d, "p"); // local time
  } else {
    return `${format(d, "PP")} at ${format(d, "p")}`; // local date with year, local time
  }
};
export const formatDateString = (input: number | firebase.firestore.Timestamp) => {
  const d = handleTimestampInput(input);
  return format(d, "PP"); // local date with year
};
export const formatMonthString = (input: number | firebase.firestore.Timestamp) => {
  const d = handleTimestampInput(input);
  return format(d, "MMM yyyy"); // local date with year
};
// e.g. ['Harry', 'Ron', 'Hermoine'] -> "Harry, Ron & Hermoine"
export const formatUserList = (users) => {
  // No users; return empty string
  if (!users || users.length === 0) return "";

  // Single member (e.g. "John")
  if (users.length === 1) return users[0];

  // Create a copy of the users array
  const n = [...users];

  // Multiple members (e.g. "John, Jack & Jason")
  const lastMember = n.pop();
  const str1 = n.join(", ");
  const str2 = " & " + lastMember;
  return str1 + str2;
};
// TODO [Gannon]: Get some amount of users (maybe cap at like 10-20?) given a search query
// Update the searchUsers state accordingly (see example below)
export const getSearchUsers = async (query): Promise<Member[]> => {
  // Search for user
  if (query.length < 3) return [];

  // TODO [long-term] : may need to use 3rd party searching frameworks to improve preformance
  const snapshot = await firebase.firestore().collection("users").where("verified", "==", true).get();
  const result = [];
  for (let i = 0; i < snapshot.docs.length; i++) {
    const user_name = snapshot.docs[i].data()["full_name"].toString().toLowerCase();
    if (user_name.includes(query.toLowerCase()) && snapshot.docs[i].id !== getCurrentUserUid()) {
      result.push({
        id: snapshot.docs[i].id,
        name: snapshot.docs[i].data().full_name.toString(),
        imageUrl: snapshot.docs[i].data().profile_pic_url,
      });
    }
  }
  return result;
};

export const nth = (d: number): string => {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
};

export const dateMatchesAnyOrISO = (dateStr: string, formats: string[]): boolean =>
  isValid(parseISO(dateStr)) || dateMatchesAny(dateStr, formats);

export const dateMatchesAny = (dateStr: string, formats: string[]): boolean =>
  formats.find((fmt) => isMatch(dateStr, fmt)) !== undefined;

export const parseWithAnyOrISO = (dateStr: string, formats: string[]): Date => {
  const isISO = isValid(parseISO(dateStr));
  if (isISO) {
    return parseISO(dateStr);
  } else {
    return parseWithAny(dateStr, formats);
  }
};

export const parseWithAny = (dateStr: string, formats: string[]): Date =>
  parse(
    dateStr,
    formats.find((fmt) => isMatch(dateStr, fmt)),
    new Date(),
  );
