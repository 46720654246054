import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import "./CustomInput.css";

// Font Awesome
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
library.add(faTimes);

interface Props {
  icon: IconProp;
  type?: string;
  inverted?: boolean;
  readOnly?: boolean;
  errorText?: string;
  placeholder?: string;
  name?: string;
  autoComplete?: string;
  onChange?: (value) => void;
}
interface State {
  value: string;
  focused: boolean;
}
class CustomInput extends PureComponent<Props, State> {
  input = React.createRef<HTMLInputElement>();

  constructor(props) {
    super(props);
    this.state = {
      value: "",
      focused: false,
    };
  }

  clear() {
    this.setState({ value: "" });
    if (typeof this.props.onChange === "function") {
      this.props.onChange("");
    }
  }

  focus() {
    this.input.current.focus();
  }

  setValue(value) {
    value = value || "";
    this.setState({ value: value });
    this.props.onChange(value);
  }

  render() {
    const errorColor = this.props.inverted ? "rgba(0,0,0,0.85)" : "rgba(255,255,255,0.85)";
    let backgroundColor = this.props.inverted ? "#f7f7f7" : "rgba(255,255,255,0.05)";
    if (this.props.readOnly) backgroundColor = "transparent";

    return (
      <div>
        <div
          className="CustomInput"
          style={{
            border: this.props.errorText ? "1px solid " + errorColor : "none",
            background: backgroundColor,
            color: this.props.inverted ? "#000" : "#fff",
            transition: "background 200ms",
          }}
        >
          <div className="CustomInputIconWrapper">
            <div className="CustomInputIconContainer">
              <FontAwesomeIcon icon={this.props.icon} />
            </div>
          </div>
          <input
            ref={this.input}
            style={{
              color: this.props.inverted ? "#000" : "#fff",
            }}
            className={"CustomInputField" + (this.props.inverted ? " Inverted" : "")}
            placeholder={this.props.placeholder}
            type={this.props.type}
            name={this.props.name}
            autoComplete={this.props.autoComplete}
            value={this.state.value}
            // Giving a small input size forces input to become smaller when screen resizess
            size={1}
            readOnly={this.props.readOnly}
            onChange={(e) => {
              this.setState({ value: e.target.value });
              if (typeof this.props.onChange === "function") {
                this.props.onChange(e.target.value);
              }
            }}
            onFocus={() => {
              this.setState({ focused: true });
            }}
            onBlur={() => {
              this.setState({ focused: false });
            }}
          ></input>
          {/* Clear button */}
          {!this.props.readOnly && (
            <div
              style={{
                width: "40px",
                height: "40px",
                flexShrink: 0,
                borderRadius: "0 100px 100px 0",
                overflow: "hidden",
                boxSizing: "border-box",
                opacity: this.state.focused && this.state.value !== "" ? "1" : "0",
                transition: "opacity 200ms",
              }}
            >
              <Clickable
                onClick={() => {
                  this.clear();
                  this.input.current.focus();
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    transform: "translateX(" + (this.state.focused && this.state.value !== "" ? "0px" : "20px") + ")",
                    transition: "transform 200ms",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "16px",
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </Clickable>
            </div>
          )}
        </div>
        {this.props.errorText && (
          <div
            className="CustomInputErrorText"
            style={{
              color: this.props.inverted ? "#000" : "#fff",
            }}
          >
            {this.props.errorText}
          </div>
        )}
      </div>
    );
  }
}

export default CustomInput;
