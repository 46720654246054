import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Clickable from "../Clickable/Clickable";

import "./IconButton.css";

interface Props {
  notifications: unknown;
  constrainWidth: boolean;
  active: boolean;
  icon: IconDefinition;
  activeIcon: IconDefinition;
  onClick: () => void;
}

const IconButton: React.FC<Props> = (props) => {
  const notif = (
    <div
      style={{
        position: "absolute",
        height: "15px",
        padding: "0 5px",
        borderRadius: "100px",
        backgroundColor: "#eb3b5a",
        color: "#fff",
        fontSize: "9px",
        top: "0",
        right: "0",
        transform: "translate(50%, -50%)",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {props.notifications}
      </div>
    </div>
  );

  return (
    <div
      className="IconButtonWrapper"
      style={{
        flexGrow: props.constrainWidth ? 0 : 1,
        color: props.active ? "#4b7bec" : "#4b6584",
        transition: "color 80ms",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: props.active ? "100%" : "0",
          background: "#F7F8FA",
          transition: "height 200ms",
          bottom: "0",
        }}
      ></div>
      <Clickable
        // Fire onClick callback when this button is clicked
        onClick={() => {
          // Unfocus active element
          // if ("activeElement" in document) document.activeElement.blur();
          if (typeof props.onClick === "function") {
            props.onClick();
          }
        }}
      >
        <div
          style={{
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            height: "50px",
            width: "56px",
          }}
        >
          {/* Icon, label, and notification icon */}
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              flexDirection: "column",
              fontFamily: '"Roboto", sans-serif',
              alignItems: "center",
              display: "flex",
            }}
          >
            {/* Icon */}
            <div
              style={{
                fontSize: "18px",
              }}
            >
              <FontAwesomeIcon icon={props.active ? props.activeIcon : props.icon} />
            </div>
            {/* Notification count */}
            {
              // Only display if notifications exist
              props.notifications !== 0 && notif
            }
          </div>
        </div>
      </Clickable>
    </div>
  );
};

export default IconButton;
