import React, { PureComponent } from "react";
import Inbox from "./Inbox";
import CreateChat from "./CreateChat";
import { Chat } from "../MessagingInterfaces";
import "./ChatsMenu.css";

interface Props {
  chats: Chat[];
  currentChatId: string;
  loadingInbox: boolean;
  narrowView: boolean;
  loadingCreateChat: boolean;
  onShowChat: (chatId: string) => void;
  onCreateChat: (members: any[]) => void;
}

interface State {
  showingCreateChat: boolean;
}

class ChatsMenu extends PureComponent<Props, State> {
  CreateChat: React.RefObject<CreateChat>;

  constructor(props) {
    super(props);
    this.state = {
      showingCreateChat: false,
    };
    this.CreateChat = React.createRef();
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          borderRight: "1px solid rgba(0,0,0,0.05)",
          overflow: "scroll",
        }}
      >
        <Inbox
          chats={this.props.chats}
          currentChatId={this.props.currentChatId}
          loading={this.props.loadingInbox}
          onShowChat={this.props.onShowChat}
          narrowView={this.props.narrowView}
          onShowCreateChat={() => {
            this.setState({ showingCreateChat: true });
            if (this.CreateChat.current) this.CreateChat.current.focus();
          }}
        />
        <CreateChat
          ref={this.CreateChat}
          showing={this.state.showingCreateChat}
          loading={this.props.loadingCreateChat}
          onHideCreateChat={() => {
            this.setState({ showingCreateChat: false });
          }}
          onCreateChat={(members) => {
            this.setState({ showingCreateChat: false });
            this.props.onCreateChat(members);
          }}
        />
      </div>
    );
  }
}

export default ChatsMenu;
