import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";

import "./CollapsibleList.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
library.add(faPlus, faChevronRight);

interface Props {
  initiallyCollapsed?: boolean;
  completionColor?: "red" | "black" | "green";
  completionString?: string;
  title: string;
}

interface State {
  collapsed: boolean;
}

class CollapsibleList extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.initiallyCollapsed ? true : false,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse() {
    this.setState((state) => {
      return { collapsed: !state.collapsed };
    });
  }

  render() {
    let color = "rgba(0,0,0,0.85)";
    if (this.props.completionColor === "red") color = "#eb3b5a";
    if (this.props.completionColor === "green") color = "#20bf6b";

    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          marginBottom: "15px",
        }}
      >
        <div className="CollapsibleListContainer">
          <Clickable onClick={this.toggleCollapse}>
            {/* Top bar */}
            <div
              style={{
                display: "flex",
              }}
            >
              {/* Left content */}
              <div
                style={{
                  position: "relative",
                  padding: "10px 12px",
                  transform: "rotate(" + (this.state.collapsed ? "0" : "90") + "deg)",
                  transition: "transform 200ms",
                  alignSelf: "center",
                  fontSize: "14px",
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
              {/* Center content */}
              <div
                style={{
                  position: "relative",
                  padding: "10px 0",
                  flexGrow: 1,
                  fontSize: "16px",
                }}
              >
                {this.props.title}
              </div>
              {/* Right content */}
              {this.props.completionString && (
                <div
                  style={{
                    position: "relative",
                    padding: "10px 15px",
                    flexShrink: 0,
                    fontSize: "12px",
                    color: color,
                    fontWeight: 500,
                    alignSelf: "center",
                  }}
                >
                  {this.props.completionString}
                </div>
              )}
            </div>
          </Clickable>
        </div>
        <div
          style={{
            position: "relative",
            zIndex: 0,
          }}
        >
          <div
            style={{
              position: this.state.collapsed ? "absolute" : "relative",
              width: "100%",
              pointerEvents: this.state.collapsed ? "none" : "auto",
              transform: "translateY(" + (this.state.collapsed ? "-20px" : "0") + ")",
              opacity: this.state.collapsed ? "0" : "1",
              transition: "transform 80ms, opacity 80ms",
              padding: "10px 0",
            }}
          >
            {!this.state.collapsed && this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default CollapsibleList;
