import React, { PureComponent } from "react";
import { Plugins, HapticsImpactStyle, Capacitor } from "@capacitor/core";
import ChatComposeIconButton from "./ChatComposeIconButton";
import { ComposedMessage, Member } from "../MessagingInterfaces";

import TextInput from "./TextInput";
import ImageInput from "./ImageInput";
import TransactionInput from "./TransactionInput";
import WorkOrderInput from "./WorkOrderInput";
import "../MessagingPage.css";
import "./Chat.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoneyBillWave, faText, faImage, faTools } from "@fortawesome/pro-solid-svg-icons";
library.add(faMoneyBillWave, faText, faImage, faTools);

const { Haptics } = Plugins;

interface Props {
  members: Member[];
  loadingMessage: boolean;
  isLinked: boolean;
  isManager: boolean;
  dwollaCustomerType: null | "unverified" | "verified";
  primaryFundingSourceId: null | string;
  loadingDwolla: boolean;
  onAddBank: () => void;
  onChangeType: () => void;
  onSendMessage: (message: ComposedMessage) => void;
  onViewImage: (imageUrl: string) => void;
}

interface State {
  currentType: "text" | "image" | "transaction" | "work_order";
}

class ChatCompose extends PureComponent<Props, State> {
  TextInput: React.RefObject<TextInput>;
  ImageInput: React.RefObject<ImageInput>;
  TransactionInput: React.RefObject<TransactionInput>;
  WorkOrderInput: React.RefObject<WorkOrderInput>;

  constructor(props) {
    super(props);
    this.state = {
      currentType: "text",
    };
    this.TextInput = React.createRef();
    this.ImageInput = React.createRef();
    this.TransactionInput = React.createRef();
    this.WorkOrderInput = React.createRef();
    this.canSendPayments = this.canSendPayments.bind(this);
  }

  canSendPayments() {
    return (
      ["unverified", "verified"].includes(this.props.dwollaCustomerType) && this.props.primaryFundingSourceId !== null
    );
  }

  async prefillRentPayment(amount: number) {
    const canSendPayments = await this.canSendPayments();
    this.setState({ currentType: "transaction" });
    if (canSendPayments) {
      this.TransactionInput.current.prefillRentPayment(amount);
    }
  }

  clear() {
    this.TextInput.current.clear();
    this.ImageInput.current.clear();
    this.TransactionInput.current.clear();
    this.WorkOrderInput.current.clear();
  }

  render() {
    const otherMembersExist = this.props.members && this.props.members.length > 0;

    return (
      <div
        style={{
          flexShrink: 0,
          position: "relative",
          display: "flex",
          boxShadow: "0 0 7px rgba(0,0,0,0.05)",
          flexDirection: "column",
          opacity: !otherMembersExist ? 0.3 : 1.0,
          transition: "opacity 80ms",
          overflow: "visible",
        }}
      >
        {
          // Overlay to prevent interaction with ChatCompose if no chat is selected
          !otherMembersExist && (
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 1000,
              }}
            ></div>
          )
        }
        <div
          className="ShimmerLoadingBar"
          style={{
            height: "4px",
            marginTop: "-4px",
            opacity: this.props.loadingMessage ? 1 : 0,
            // Wait before showing (in case the message sends quickly); hide instantly
            transition: "opacity 80ms ease " + (this.props.loadingMessage ? "5000ms" : "0ms"),
          }}
        ></div>
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            padding: "0 10px",
            backgroundColor: "rgba(0,0,0,0.02)",
          }}
        >
          <ChatComposeIconButton
            icon={faText}
            active={this.state.currentType === "text"}
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              this.setState({ currentType: "text" });
              if (typeof this.props.onChangeType === "function") this.props.onChangeType();
            }}
          />
          <ChatComposeIconButton
            icon={faImage}
            active={this.state.currentType === "image"}
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              this.setState({ currentType: "image" });
              if (typeof this.props.onChangeType === "function") this.props.onChangeType();
            }}
          />
          <ChatComposeIconButton
            icon={faMoneyBillWave}
            active={this.state.currentType === "transaction"}
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              this.setState({
                currentType: "transaction",
              });
              if (typeof this.props.onChangeType === "function") this.props.onChangeType();
            }}
          />
          <ChatComposeIconButton
            icon={faTools}
            active={this.state.currentType === "work_order"}
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              this.setState({ currentType: "work_order" });
              if (typeof this.props.onChangeType === "function") this.props.onChangeType();
            }}
          />
        </div>
        <div style={{ position: "relative" }}>
          <TextInput
            ref={this.TextInput}
            members={this.props.members}
            showing={this.state.currentType === "text"}
            onSend={(text) => {
              const message: ComposedMessage = {
                type: "text",
                text: text,
              };
              if (typeof this.props.onSendMessage === "function") {
                this.props.onSendMessage(message);
              }
              this.TextInput.current.focus();
            }}
          />
          <ImageInput
            ref={this.ImageInput}
            members={this.props.members}
            showing={this.state.currentType === "image"}
            onSend={(imageUrls: string[]) => {
              // Split up image URL's into separate messages
              for (let i = 0; i < imageUrls.length; i++) {
                const message: ComposedMessage = {
                  type: "image",
                  image_url: imageUrls[i],
                };
                if (typeof this.props.onSendMessage === "function") {
                  this.props.onSendMessage(message);
                }
              }
            }}
            onViewImage={(imageUrl) => {
              this.props.onViewImage(imageUrl);
            }}
          />
          <TransactionInput
            ref={this.TransactionInput}
            members={this.props.members}
            showing={this.state.currentType === "transaction"}
            isLinked={this.props.isLinked}
            canSendPayments={this.canSendPayments()}
            loadingDwolla={this.props.loadingDwolla}
            isManager={this.props.isManager}
            onAddBank={this.props.onAddBank}
            onSend={(
              recipientId,
              amount,
              recurring,
              recurringType,
              transactionType,
              paymentType,
              paymentDescription,
            ) => {
              // Message object
              const message: ComposedMessage = {
                recipient_id: recipientId,
                type: transactionType === 0 ? "transaction" : "transaction_request",
                amount: amount,
              };

              message.recurring = recurring !== 0;
              if (recurring !== 0) {
                switch (recurringType) {
                  case 0:
                    message.recurring_string = "monthly";
                    break;
                  case 1:
                    message.recurring_string = "bi-weekly";
                    break;
                  case 2:
                    message.recurring_string = "weekly";
                    break;
                  // Default to monthly if recurringType is invalid
                  default:
                    message.recurring_string = "monthly";
                    break;
                }
              }

              message.payment_type = paymentType === 0 ? "rent" : "other";
              if (paymentType !== 0) message.payment_description = paymentDescription;

              if (typeof this.props.onSendMessage === "function") {
                this.props.onSendMessage(message);
              }
              this.TransactionInput.current.clear();
            }}
          />
          <WorkOrderInput
            ref={this.WorkOrderInput}
            members={this.props.members}
            showing={this.state.currentType === "work_order"}
            onSend={(recipientId, subject, description, imageUrls) => {
              const message: ComposedMessage = {
                type: "work_order",
                recipient_id: recipientId,
                subject: subject,
                text: description,
                image_urls: imageUrls,
              };
              if (typeof this.props.onSendMessage === "function") {
                this.props.onSendMessage(message);
              }
            }}
            onViewImage={(imageUrl: string) => {
              this.props.onViewImage(imageUrl);
            }}
          />
        </div>
      </div>
    );
  }
}

export default ChatCompose;
