import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import OptionSwitch from "../../CustomComponents/OptionSwitch/OptionSwitch";
import CurrencyInput from "../../CustomComponents/CurrencyInput/CurrencyInput";
import { formatMoneyAmount } from "../../HelperFunctions";
import "./Chat.css";

import SendButton from "../../CustomComponents/SendButton/SendButton";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faRedoAlt, faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import CustomSelectSmall from "../../CustomComponents/CustomSelectSmall/CustomSelectSmall";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";
import { Member } from "../MessagingInterfaces";
import { bioAuth } from "../../BioAuth";
library.add(faTimes, faRedoAlt, faArrowRight);

const BIO_AUTH_TIMEOUT = 20000;

interface Props {
  members: Array<Member>;
  isLinked: boolean;
  isManager: boolean;
  showing: boolean;
  canSendPayments: boolean;
  loadingDwolla: boolean;
  onAddBank: () => void;
  onSend: (
    recipientId: string,
    amount: number,
    recurring: number,
    recurringType: number,
    transactionType: number,
    paymentType: number,
    paymentDescription: string,
  ) => void;
}

interface State {
  amount: number;
  confirming: boolean;
  loadingConfirmation: boolean;
  recurring: number;
  recurringType: number;
  transactionType: number;
  paymentType: number;
  paymentDescription: string;
  recipient: number;
  value: string;
  feeString: string;
  loadingFee: boolean;
}
class TransactionInput extends PureComponent<Props, State> {
  CurrencyInputAmount = React.createRef<CurrencyInput>();
  SelectRecipient = React.createRef<CustomSelectSmall>();
  OptionRecurring = React.createRef<OptionSwitch>();
  OptionRecurringType = React.createRef<OptionSwitch>();
  OptionPaymentType = React.createRef<OptionSwitch>();

  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      confirming: false,
      loadingConfirmation: false,
      recurring: 0,
      recurringType: 0,
      transactionType: 0,
      paymentType: 0,
      paymentDescription: "",
      recipient: 0,
      value: "",
      feeString: "",
      loadingFee: false,
    };
    this.handleSend = this.handleSend.bind(this);
    this.paymentIsValid = this.paymentIsValid.bind(this);
    this.clear = this.clear.bind(this);
  }

  focus() {
    this.CurrencyInputAmount.current.focus();
  }
  clear() {
    if (this.CurrencyInputAmount.current) this.CurrencyInputAmount.current.clear();
    this.setState({
      paymentDescription: "",
    });
  }

  prefillRentPayment(amount: number) {
    if (this.CurrencyInputAmount.current) {
      this.CurrencyInputAmount.current.setValue(amount);
      this.CurrencyInputAmount.current.focus();
    }
    this.resetOptions();
  }

  resetOptions() {
    if (this.OptionRecurring.current) this.OptionRecurring.current.setValue(0);
    if (this.OptionPaymentType.current) this.OptionPaymentType.current.setValue(0);
    if (this.SelectRecipient.current) this.SelectRecipient.current.setValue(0);
    if (this.OptionRecurringType.current) this.OptionRecurringType.current.setValue(0);
  }

  componentDidUpdate(prevProps) {
    // Whether the members in this chat changed
    const membersChanged = (oldMembers, newMembers) => {
      const oldIds = [];
      const newIds = [];

      for (let i = 0; i < oldMembers.length; i++) {
        oldIds.push(oldMembers[i].id);
      }
      for (let i = 0; i < newMembers.length; i++) {
        newIds.push(newMembers[i].id);
      }

      const oldString = oldIds.sort().join(", ");
      const newString = newIds.sort().join(", ");
      if (oldString !== newString) return true;
    };

    // If the members changed, reset option fields
    const changed = membersChanged(prevProps.members, this.props.members);
    if (changed) {
      this.resetOptions();
    }
  }

  getMemberNames() {
    const names = [];
    for (let i = 0; i < this.props.members.length; i++) {
      names.push(this.props.members[i].name);
    }
    return names;
  }

  handleSend() {
    // Force payment type to be "other" for either of two reasons:
    // 1. the chat is not linked, and therefore rent payments are not supported
    // 2. this user is a manager, and therefore it doesn't make sense for them to send rent payments
    let paymentType = this.state.paymentType;
    if (!this.props.isLinked || this.props.isManager) paymentType = 1;

    if (this.state.amount > 0 && (paymentType === 0 || this.state.paymentDescription.trim() !== "")) {
      if (typeof this.props.onSend === "function") {
        this.props.onSend(
          this.props.members[this.state.recipient].id,
          this.state.amount,
          this.state.recurring,
          this.state.recurringType,
          this.state.transactionType,
          paymentType,
          this.state.paymentDescription,
        );
      }

      this.setState({
        paymentDescription: "",
        amount: 0,
        loadingConfirmation: false,
        confirming: false,
      });
    }
  }

  // Returns whether the current payment is ready to be sent
  paymentIsValid() {
    // Payments must be at least one dollar
    const amountIsValid = this.state.amount >= 1;

    // Force payment type to be "other" for either of two reasons:
    // 1. the chat is not linked, and therefore rent payments are not supported
    // 2. this user is a manager, and therefore it doesn't make sense for them to send rent payments
    let paymentType = this.state.paymentType;
    if (!this.props.isLinked || this.props.isManager) paymentType = 1;

    // The requirement for a description is fulfilled. Either:
    // 1. the payment type is "rent" and therefore no description is required, or
    // 2. the payment type is "other", and a description has been provided
    const descriptionRequirementFulfilled = paymentType === 0 || this.state.paymentDescription.trim() !== "";

    // Gray out the "send" button if the confirmation screen is loading
    // i.e. we don't want the user to click the button again while it's loading
    const confirmationNotLoading = !this.state.loadingConfirmation;

    // This user has a chat selected
    const otherMembersExist = this.props.members && this.props.members.length > 0;

    return amountIsValid && descriptionRequirementFulfilled && confirmationNotLoading && otherMembersExist;
  }

  render() {
    const amountString = formatMoneyAmount(this.state.amount);

    let recurringString = "";
    if (this.state.recurringType === 0) recurringString = "monthly";
    if (this.state.recurringType === 1) recurringString = "bi-weekly";
    if (this.state.recurringType === 2) recurringString = "weekly";

    // e.g. "One-time payment to Jane Doe"
    let confirmationString = "";
    const recipient = this.props.members[this.state.recipient];
    confirmationString += (this.state.recurring !== 0 ? "Recurring " + recurringString : "One-time payment") + " ";
    confirmationString += (this.state.transactionType === 0 ? "to" : "from") + " ";
    confirmationString += recipient ? recipient.name : "";

    const transactionInput = (
      <div>
        <div
          style={{
            display: "flex",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              padding: "10px 10px",
            }}
          >
            {/* Fields for choosing recipient, recurring, payment type, and payment description */}
            <div
              style={{
                display: "block",
              }}
            >
              {this.props.members && this.props.members.length > 1 && (
                <div
                  style={{
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <div style={{ margin: "0 -3px" }}>
                    <CustomSelectSmall
                      ref={this.SelectRecipient}
                      color={"rgba(0,0,0,0.60)"}
                      inverted={false}
                      borderOnly={true}
                      placeholder={"Choose Recipient"}
                      defaultValue={0}
                      prefix={"To: "}
                      options={this.getMemberNames()}
                      onChange={(value) => {
                        this.setState({
                          recipient: value,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                style={{
                  marginRight: "10px",
                  marginBottom: "5px",
                }}
              >
                <OptionSwitch
                  ref={this.OptionRecurring}
                  options={["One-time", "Recurring"]}
                  onChange={(index) => {
                    this.setState({ recurring: index });
                  }}
                />
              </div>
              {this.state.recurring === 1 && (
                <div
                  style={{
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                >
                  <OptionSwitch
                    ref={this.OptionRecurringType}
                    options={["Monthly", "Bi-weekly", "Weekly"]}
                    onChange={(index) => {
                      this.setState({
                        recurringType: index,
                      });
                    }}
                  />
                </div>
              )}
              {
                // Only display option switch for rent/other if:
                // 1. the chat is linked (rent payments are supported), and
                // 2. this user is not the manager (i.e. this user is a tenant, and therefore allowed to send rent payments)
                this.props.isLinked && !this.props.isManager && (
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <OptionSwitch
                      ref={this.OptionPaymentType}
                      options={["Rent", "Other"]}
                      onChange={(index) => {
                        this.setState({ paymentType: index });
                      }}
                    />
                  </div>
                )
              }
            </div>
            {/* Transaction amount */}
            <div
              style={{
                position: "relative",
              }}
            >
              {
                // Ask for a description if:
                // 1. this is not a rent payment
                // 2. this is an unlinked chat (rent payments are not supported)
                // 3. this user is the manager, and therefore cannot send rent payments
                (this.state.paymentType !== 0 || !this.props.isLinked || this.props.isManager) && (
                  <input
                    placeholder="What's it for?"
                    value={this.state.paymentDescription}
                    style={{
                      border: "none",
                      background: "none",
                      outline: "none",
                      padding: "5px 5px 5px 5px",
                      position: "relative",
                      width: "100%",
                      fontSize: "14px",
                      fontFamily: "inherit",
                      resize: "none",
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      this.setState({
                        paymentDescription: value,
                      });
                    }}
                  />
                )
              }
              <CurrencyInput
                ref={this.CurrencyInputAmount}
                style={{
                  border: "none",
                  background: "none",
                  outline: "none",
                  padding: "10px 10px 15px 10px",
                  position: "relative",
                  width: "100%",
                  fontFamily: "inherit",
                  fontSize: "32px",
                  resize: "none",
                }}
                onChange={(amount) => {
                  this.setState({ amount: amount });
                }}
              />
            </div>
          </div>
          <SendButton
            secure={true}
            loading={this.state.loadingConfirmation}
            active={this.paymentIsValid()}
            onSend={async () => {
              this.setState({ loadingConfirmation: true });

              // Load fee asynchronously
              const updateFeeAsync = async () => {
                this.setState({ loadingFee: true });
                const result = await firebase.functions().httpsCallable("getTransferFee")({
                  value: this.state.amount,
                  currency: "USD",
                });
                const feeString = formatMoneyAmount(result.data.value);
                this.setState({
                  feeString: feeString,
                  loadingFee: false,
                });
              };
              updateFeeAsync();

              let timedOut = false;
              const authTimeout = setTimeout(() => {
                this.setState({ loadingConfirmation: false });
                timedOut = true;
              }, BIO_AUTH_TIMEOUT);
              const bioAuthSuccessful = await bioAuth();
              clearTimeout(authTimeout);
              if (!timedOut && bioAuthSuccessful) {
                this.setState({
                  loadingConfirmation: false,
                  confirming: true,
                });
              } else {
                this.setState({ loadingConfirmation: false });
              }
            }}
          />
        </div>
        {/* Confirmation */}
        <div
          style={{
            position: "absolute",
            top: "0",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            transform: "translateY(" + (this.state.confirming ? "0" : "100%") + ")",
            opacity: this.state.confirming ? "1" : "0",
            transition: "transform 200ms, opacity 200ms",
            backgroundColor: "#4b7bec",
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: "100%",
              right: "10px",
              width: "38px",
              height: "38px",
              color: "rgba(0,0,0,0.85)",
              opacity: this.state.confirming || this.state.loadingConfirmation ? 1 : 0,
              transition: "opacity 200ms",
            }}
          >
            <Clickable
              onClick={() => {
                this.setState({
                  loadingConfirmation: false,
                  confirming: false,
                });
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </Clickable>
          </div>
          <Clickable
            onClick={() => {
              if (!this.state.loadingFee) this.handleSend();
            }}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                padding: "0 10px 0 25px",
              }}
            >
              <div
                style={{
                  height: "100%",
                  position: "relative",
                  flexGrow: 1,
                }}
              >
                <div
                  style={{
                    position: "relative",
                    color: "#fff",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "0",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "24px",
                        fontWeight: 500,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 500,
                          flexGrow: 0,
                        }}
                      >
                        {this.state.transactionType === 0 ? "Send" : "Request"} {amountString}
                      </span>
                      <span
                        style={{
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        {this.state.transactionType === 0 && (
                          <span
                            style={{
                              fontWeight: 400,
                              opacity: "0.85",
                              display: "flex",
                              alignItems: "baseline",
                            }}
                          >
                            <span>(+&nbsp;</span>
                            {this.state.loadingFee ? (
                              <span style={{ fontSize: "12px" }}>
                                <LoadingIcon />
                              </span>
                            ) : (
                              this.state.feeString
                            )}
                            <span>&nbsp;Fee)</span>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: 500,
                      opacity: "1.0",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={this.state.recurring !== 0 ? faRedoAlt : faArrowRight}
                      style={{ marginRight: "10px" }}
                    />
                    <span>{confirmationString}</span>
                  </div>
                  {this.state.transactionType === 0 && this.state.recurring !== 0 && (
                    <div
                      style={{
                        fontWeight: 400,
                        opacity: "0.7",
                        marginTop: "5px",
                        fontSize: "12px",
                      }}
                    >
                      <span>You can cancel recurring payments at any time in the Manage section.</span>
                    </div>
                  )}
                  <div
                    style={{
                      fontWeight: 400,
                      opacity: "0.7",
                      marginTop: "5px",
                      fontSize: "12px",
                    }}
                  >
                    <span>Payments may take several minutes to appear in the Manage section.</span>
                  </div>
                </div>
              </div>
              <SendButton inverted={true} active={true} loading={this.state.loadingFee} />
            </div>
          </Clickable>
        </div>
      </div>
    );

    const unverifiedMessage = (
      <div
        style={{
          padding: "35px 35px",
          textAlign: "center",
          display: "flex",
          fontSize: "14px",
          flexDirection: "column",
        }}
      >
        <div style={{ marginBottom: "15px" }}>
          Add a bank account to start sending and receiving payments. You can manage your accounts in Settings.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              maxWidth: "420px",
              flexGrow: 1,
            }}
          >
            <CustomButton
              //loading={this.state.addButtonPressed}
              text="Add Account"
              inverted={true}
              disabled={this.props.loadingDwolla}
              loading={this.props.loadingDwolla}
              color={"#2A3950"}
              onClick={this.props.onAddBank}
            />
          </div>
        </div>
      </div>
    );

    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          position: this.props.showing ? "relative" : "absolute",
          pointerEvents: this.props.showing ? "auto" : "none",
          opacity: this.props.showing ? "1" : "0",
          zIndex: this.props.showing ? 0 : -1,
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          transition: "opacity 200ms, transform 200ms",
        }}
      >
        {this.props.canSendPayments ? transactionInput : unverifiedMessage}
      </div>
    );
  }
}

export default TransactionInput;
