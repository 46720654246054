import React, { PureComponent } from "react";
import { ProgressBar } from "./ProgressBar";

interface Props {
  stepTitles: string[];
  currentStep: number;
  lastCompletedStep: number;
  // Can vary per-step
  currentStepSubtitle: string;
  currentStepDescription: string;
}
interface State {}
class PaymentsSetup extends PureComponent<Props, State> {
  render() {
    return (
      <div>
        <ProgressBar
          stepTitles={this.props.stepTitles}
          currentStep={this.props.currentStep}
          lastCompletedStep={this.props.lastCompletedStep}
        />
        <div
          style={{
            lineHeight: 1.5,
            padding: "25px 0 10px 0",
            fontSize: "14px",
          }}
        >
          <span style={{ fontWeight: 500, opacity: 1 }}>{this.props.currentStepSubtitle}</span>{" "}
          <span style={{ fontWeight: "normal", opacity: 0.35 }}>{this.props.currentStepDescription}</span>
        </div>
      </div>
    );
  }
}

export default PaymentsSetup;
