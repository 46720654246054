import { Capacitor } from "@capacitor/core";
import firebase from "firebase/app";
import { v4 as uuidv4 } from "uuid";

const MEGABYTE = 1000000;
const MAX_IMAGE_SIZE = 3 * MEGABYTE;

/**
 * (Promise) Returns an image file that is rotated by the degrees specified.
 *
 * @param {*} file (must be image file)
 * @param {*} degrees (degrees to rotate, must be in set 90 * x where x in [0,3]
 *     : 0, 90, 180, 270);
 * @param {*} imageType (suffix of filename)
 */
function getRotatedImageDataURL(file: File, degrees: number, imageType: string) {
  return new Promise<File>((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = (degrees / 90) % 2 === 0 ? img.width : img.height;
        canvas.height = (degrees / 90) % 2 === 0 ? img.height : img.width;
        const ctx = canvas.getContext("2d");
        const radianRotation = (degrees * Math.PI) / 180;
        ctx.rotate(radianRotation);
        let dx = 0;
        let dy = 0;
        switch (degrees) {
          case 0:
            dx = 0;
            dy = 0;
            break;
          case 90:
            dx = 0;
            dy = -canvas.width;
            break;
          case 180:
            dx = -canvas.width;
            dy = -canvas.height;
            break;
          case 270:
            dx = -canvas.height;
            dy = 0;
            break;
          default:
            dx = 0;
            dy = 0;
            break;
        }
        ctx.drawImage(img, dx, dy);
        canvas.toBlob((blob) => {
          // this file name is ignored
          const newFile = new File([blob], uuidv4() + "." + imageType);
          resolve(newFile);
        }, "image/" + imageType);
      };
      img.src = fileReader.result as string;
    };

    fileReader.readAsDataURL(file);
  });
}

/**
 *
 * This function was copied from stack overflow post linked below, accessed
 * April 2020 https://stackoverflow.com/a/32490603/7363255
 *
 * @param {*} file
 */
function getImageRotation(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const view = new DataView(e.target.result as ArrayBuffer);
      if (view.getUint16(0, false) !== 0xffd8) {
        resolve(-2);
      }
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) {
          resolve(-1);
        }
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            resolve(-1);
          }

          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      resolve(-1);
    };
    reader.readAsArrayBuffer(file);
  });
}

/**
 * Uploads an image to the path specified with a progress listener.
 *
 * @param file
 * @param path
 * @param progressListener
 */
export function uploadImage(
  file: File | string,
  path: string | null,
  imageType: string,
  progressListener: (x: number) => void,
) {
  return new Promise<string>((resolve, reject) => {
    const imageRef = firebase.storage().ref().child(`${path}/${uuidv4()}.${imageType}`);
    let uploadTask: firebase.storage.UploadTask;
    if (Capacitor.isNative) {
      uploadTask = imageRef.putString(file as string, "base64");
    } else {
      uploadTask = imageRef.put(file as File);
    }

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, {
      next: (result) => {
        if (progressListener !== undefined) {
          progressListener(result.bytesTransferred / result.totalBytes);
        }
      },
      complete: async () => {
        const url = await imageRef.getDownloadURL();
        console.log(url);
        resolve(url);
      },
      error: (e) => {
        console.error("Error uploading image.");
        console.error(e);
        reject(e);
      },
    });
  });
}

/**
 * Upload a storage file to firebase. Checks for any outstanding image rotation
 * and rotates the image before uploading.
 *
 * Use progressListener callback to get updates on file. The update frequency is
 * determined by firebase.
 *
 * @param {*} firebaseStorage
 * @param {*} file
 * @param {*} path
 * @param {*} progressListener
 */
export function uploadImageWithAutoRotate(file: File, path: string, progressListener: (x: number) => void) {
  if (file.size > MAX_IMAGE_SIZE) {
    // TODO SHOW ERROR
    // this cap on image size will provide more storage security
    console.log("max image size exceeded");
    return;
  }
  return new Promise<string>(async (resolve, reject) => {
    const imageRotation = await getImageRotation(file);
    let rotationDegrees = 0;
    switch (imageRotation) {
      case -2:
        // image is not jpeg can't read exif rotate data
        break;
      case -1:
        // image exif rotate data is undefined
        break;
      case 1:
      case 2:
        // image is correctly rotated; do nothing
        break;
      case 7:
      case 8:
        // image is 90deg clockwise
        rotationDegrees = 90;
        break;
      case 4:
      case 3:
        // image is 180 deg clockwise
        rotationDegrees = 180;
        break;
      case 5:
      case 6:
        // image is 270 deg clockwise
        rotationDegrees = 270;
        break;
      default:
        // just handled most common cases above. for more,
        // see the stack overflow post at beginning of function.
        break;
    }

    const imageType = file.type.toString().split("/")[1]; // get the value after image/[type], where type is jpg or png
    // use complementary angle because we want to remove rotation
    const rotatedImageData = await getRotatedImageDataURL(file, 360 - rotationDegrees, imageType);
    const imageURL = await uploadImage(rotatedImageData, path, imageType, progressListener);

    resolve(imageURL);
  });
}
