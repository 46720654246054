import React, { PureComponent } from "react";
import { Plugins, HapticsNotificationType, Capacitor } from "@capacitor/core";

import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomCurrencyInput from "../CustomComponents/CustomCurrencyInput/CustomCurrencyInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

import { isBefore, startOfMonth } from "date-fns";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendar, faHomeAlt, faMoneyBillWave } from "@fortawesome/pro-solid-svg-icons";
import { IProperty, IUnit } from "../conjure-api";
import { dateMatchesAnyOrISO, parseWithAnyOrISO } from "../HelperFunctions";
library.add(faCalendar, faHomeAlt, faMoneyBillWave);

const { Haptics } = Plugins;

interface Props {
  onRefresh: () => void;
}
interface State {
  loading: boolean;
  input: {
    name: string;
    rent: number;
    effectiveMonth: string;
  };
  errors: {
    name: string;
    rent: string;
    effectiveMonth: string;
  };
  // Passed data
  property: IProperty | null;
  unit: IUnit | null;
}

class ModalEditUnit extends PureComponent<Props, State> {
  InputUnitName: React.RefObject<CustomInput>;
  InputMonthlyRent: React.RefObject<CustomCurrencyInput>;
  modal: React.RefObject<Modal>;
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      input: {
        name: "",
        rent: 0,
        effectiveMonth: "",
      },
      errors: {
        name: "",
        rent: "",
        effectiveMonth: "",
      },
      // Passed data
      property: null,
      unit: null,
    };

    this.InputUnitName = React.createRef();
    this.InputMonthlyRent = React.createRef();

    this.handleEditUnit = this.handleEditUnit.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.modal = React.createRef();
  }

  clear() {
    this.InputUnitName.current.clear();
    this.InputMonthlyRent.current.clear();
  }
  show(property: IProperty, unit: IUnit) {
    this.clear();
    this.setState({
      property: property,
      unit: unit,
    });

    this.InputUnitName.current.setValue(unit.unit_name);
    this.InputMonthlyRent.current.setValue(unit.rent);

    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  updateErrors() {
    const errors = this.state.errors;
    let encounteredError = false;

    // Make sure they entered a valid rent payment
    const amount = this.state.input.rent;
    if (amount < 0) {
      errors.rent = "Please enter a valid monthly rent for this unit";
      encounteredError = true;
    }

    if (this.state.input.name.trim() === "") {
      errors.name = "Please enter a name for this unit";
      encounteredError = true;
    }

    if (this.state.input.effectiveMonth) {
      const formatIsCorrect = dateMatchesAnyOrISO(this.state.input.effectiveMonth, ["yyyy-MM", "MM/yyyy"]);
      if (!formatIsCorrect) {
        errors.effectiveMonth = "Bad month format. Please use MM/YYYY";
        encounteredError = true;
      }
      const month = parseWithAnyOrISO(this.state.input.effectiveMonth, ["yyyy-MM", "MM/yyyy"]);
      if (isBefore(month, startOfMonth(new Date()))) {
        errors.effectiveMonth = "Please select this month or a future month";
        encounteredError = true;
      }
    }

    if (encounteredError) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  async handleEditUnit() {
    this.setState({ loading: true });
    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    // Update name if name changed
    if (this.state.input.name.trim() !== this.state.unit.unit_name) {
      await firebase.functions().httpsCallable("updateUnitName")({
        propertyId: this.state.property.id,
        unitId: this.state.unit.id,
        name: this.state.input.name.trim(),
      });
    }

    if (this.state.input.rent !== this.state.unit.rent) {
      await firebase.functions().httpsCallable("updateUnitRent")({
        propertyId: this.state.property.id,
        unitId: this.state.unit.id,
        rent: this.state.input.rent,
        effectiveMonth: this.state.input.effectiveMonth,
      });
    }

    // Success haptic when unit added successfully
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });
    this.setState({ loading: false });
    this.hide();
    this.props.onRefresh();
  }

  render() {
    const unitName = this.state.unit ? this.state.unit.unit_name : "";
    const unitRent = this.state.unit ? this.state.unit.rent : 0;
    const propertyName = this.state.property ? this.state.property.address : ""; //TODO

    return (
      <Modal ref={this.modal} title={"Edit Unit: " + unitName}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleEditUnit();
          }}
        >
          <div className="ModalText">
            Edit the name and monthly rent for <span style={{ fontWeight: 500 }}>{unitName}</span> in{" "}
            <span style={{ fontWeight: 500 }}>{propertyName}</span>
          </div>
          <div className="ModalSectionHeader">Unit name</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputUnitName}
              icon={faHomeAlt}
              placeholder={unitName}
              type={"text"}
              errorText={this.state.errors.name}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.name = text;
                  state.errors.name = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>

          <div className="ModalSectionHeader">Monthly rent</div>
          <div className="ModalInputWrapper">
            <CustomCurrencyInput
              ref={this.InputMonthlyRent}
              icon={faMoneyBillWave}
              errorText={this.state.errors.rent}
              inverted={true}
              placeholder={unitRent}
              onChange={(amount) => {
                this.setState((state) => {
                  state.input.rent = amount;
                  state.errors.rent = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          {this.state.unit?.rent !== this.state.input.rent ? (
            <div>
              {" "}
              <div className="ModalSectionHeader">Effective month</div>
              <div className="ModalInputWrapper">
                <CustomInput
                  icon={faCalendar}
                  placeholder="MM/YYYY"
                  type={"month"}
                  errorText={this.state.errors.effectiveMonth}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      state.input.effectiveMonth = text;
                      state.errors.effectiveMonth = "";
                      console.log(text);
                      return state;
                    }, this.forceUpdate);
                  }}
                />
              </div>
              <div className="ModalText">
                Changes to this unit's rent will take effect at the start of the selected month.
              </div>
            </div>
          ) : (
            <div />
          )}

          <div className="ModalButtonWrapper">
            <CustomButton
              text="Update Unit"
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalEditUnit;
