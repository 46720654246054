import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import { Plugins, Capacitor, HapticsImpactStyle } from "@capacitor/core";
import { Twemoji } from "react-emoji-render";
import "./Chat.css";

const { Haptics } = Plugins;

interface Props {
  onChooseEmoji: (emoji: string) => void;
  position: "top" | "bottom";
}
interface State {
  width: number;
}

class ChatEmojiBar extends PureComponent<Props, State> {
  private wrapper = React.createRef<HTMLDivElement>();

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    };
    this.wrapper = React.createRef();
    this.updateWidth = this.updateWidth.bind(this);
  }

  updateWidth() {
    this.setState({
      width: this.wrapper.current.offsetWidth,
    });
  }
  componentDidMount() {
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  render() {
    const emojis = ["👍", "❤️", "😮", "😎", "👏", "😁"];

    const els = emojis.map((emoji, index) => {
      return (
        <div key={index.toString()} className="ChatEmojiWrapper">
          <Clickable
            preventDefault={true}
            onClick={() => {
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
              this.props.onChooseEmoji(emoji);
            }}
          >
            <div className="ChatEmoji">
              <Twemoji text={emoji} />
            </div>
          </Clickable>
        </div>
      );
    });

    return (
      <div
        ref={this.wrapper}
        className="ChatEmojiBar"
        style={{
          borderBottom: this.props.position === "top" ? "1px solid #f7f7f7" : "none",
          borderTop: this.props.position === "bottom" ? "1px solid #f7f7f7" : "none",
        }}
      >
        {els}
      </div>
    );
  }
}

export default ChatEmojiBar;
