import React, { PureComponent } from "react";
import { Capacitor, CameraResultType, Plugins, CameraDirection } from "@capacitor/core";

import Clickable from "../../Clickable/Clickable";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";

// Firebase and input validation
import firebase from "firebase/app";
import "firebase/auth";

import "../AuthenticationWindow.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { uploadImage, uploadImageWithAutoRotate } from "../../ImageUploadHelpers";
library.add(faUser);

const { Camera } = Plugins;

interface Props {
  showing: boolean;
  onUpdateProgress: (number) => void;
  onShowRegisterConfirmation: () => void;
}

interface State {
  loading: boolean;
  loadingPhoto: boolean;
  photoUrl: string;
  showingError: boolean;
}

class AddProfilePhoto extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingPhoto: false,
      photoUrl: "",
      showingError: false,
    };
    this.handleUpdatePhoto = this.handleUpdatePhoto.bind(this);
  }

  clear() {
    this.setState({
      loading: false,
      loadingPhoto: false,
      photoUrl: "",
      showingError: false,
    });
  }

  // Update user's profile information to hold this new image
  // When completed, show confirmation page
  async handleUpdateInformation() {
    // If there is no photo URL provided, show error and return
    if (!this.state.photoUrl) {
      this.setState({ showingError: true });
      return;
    }

    this.setState({ loading: true });

    // Get this user's user data
    const user = firebase.auth().currentUser;
    const userData = await firebase.firestore().collection("users").doc(user.uid.toString()).get();

    // Make sure user data exists
    if (userData.exists) {
      // Update the user's profile data with this image
      const profileData = userData.data();
      profileData["profile_pic_url"] = this.state.photoUrl;
      profileData["id"] = user.uid;
      const updateProfileFunction = firebase.functions().httpsCallable("updateProfileData");
      await updateProfileFunction(profileData);
    }

    this.setState({ loading: false });
    this.props.onShowRegisterConfirmation();
  }

  // Update profile photo
  async handleUpdatePhoto(file?: File) {
    // Remove error
    this.setState({ showingError: false });

    let imageURL;
    if (Capacitor.isNative) {
      try {
        const image = await Camera.getPhoto({
          quality: 80,
          allowEditing: true,
          resultType: CameraResultType.Base64,
          correctOrientation: true,
          direction: CameraDirection.Front,
        });
        this.setState({ loadingPhoto: true });
        imageURL = await uploadImage(image.base64String, "profile_pictures", "jpeg", this.props.onUpdateProgress);
      } catch (error) {
        return;
      }
    } else {
      this.setState({ loadingPhoto: true });
      imageURL = await uploadImageWithAutoRotate(file, "profile_pictures", this.props.onUpdateProgress);
    }

    this.setState({ photoUrl: imageURL, loadingPhoto: false });
  }

  render() {
    // Image input component; <input> element on web, Clickable to request image selection if native
    let ImageInputComponent = (
      <input
        className="AuthenticationImageInputComponent"
        type="file"
        accept="image/*"
        onChange={(e) => {
          const files = e.target.files;
          const file = files[0];
          this.handleUpdatePhoto(file);
        }}
      />
    );
    if (Capacitor.isNative) {
      ImageInputComponent = (
        <div className="AuthenticationImageInputComponent">
          <Clickable onClick={this.handleUpdatePhoto}></Clickable>
        </div>
      );
    }

    return (
      <div
        style={{
          position: this.props.showing ? "relative" : "absolute",
          width: "100%",
          boxSizing: "border-box",
          zIndex: this.props.showing ? 1 : 0,
          transform: "translateY(" + (this.props.showing ? "0" : "10px") + ")",
          opacity: this.props.showing ? 1 : 0,
          padding: "25px 10px",
          transition: "transform 80ms, opacity 80ms",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleUpdateInformation();
          }}
        >
          <div className="AuthenticationTitle">Add Profile Photo</div>

          <div className="AuthenticationTextWrapper">
            <span className="AuthenticationText">
              Choose a recent photo of yourself. This helps people recognize you and see that they're getting in touch
              with the right person.
            </span>
          </div>

          {/* Profile photo input field */}
          <div className="AuthenticationPhotoWrapper">
            <div className="AuthenticationPhotoContainer">
              {/* User or loading icon */}
              <div
                style={{
                  fontSize: "64px",
                  color: "#d1d8e0",
                }}
              >
                {this.state.loadingPhoto ? <LoadingIcon /> : <FontAwesomeIcon icon={faUser} />}
              </div>
              {
                // Show text prompt if no profile photo exists and profile photo is not loading
                !this.state.photoUrl && !this.state.loadingPhoto && (
                  <div className="AuthenticationPhotoLabel">Choose a profile photo</div>
                )
              }

              {/* Image */}
              <div
                className="AuthenticationPhoto"
                style={{
                  backgroundImage: "url(" + this.state.photoUrl + ")",
                  opacity: this.state.photoUrl && !this.state.loadingPhoto ? "1" : "0",
                  transform: "scale(" + (this.state.photoUrl && !this.state.loadingPhoto ? "1" : "1.2") + ")",
                  transition: "opacity 300ms, transform 500ms",
                }}
              ></div>

              {/* Input element or clickable zone for native image upload */}
              {ImageInputComponent}
            </div>
          </div>

          {this.state.showingError && (
            <div className="AuthenticationTextWrapper">
              <span className="AuthenticationTextLight">Add a profile image above to continue</span>
            </div>
          )}

          <div className="AuthenticationButtonWrapper">
            <CustomButton text="Next" inverted={true} color={"#2A64AD"} loading={this.state.loading} submit={true} />
          </div>
        </form>

        <div className="AuthenticationTextWrapper">
          <Clickable onClick={this.props.onShowRegisterConfirmation}>
            <span className="AuthenticationTextLight">Set Up Later</span>
          </Clickable>
        </div>
      </div>
    );
  }
}

export default AddProfilePhoto;
