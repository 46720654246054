import React, { PureComponent } from "react";
import CustomCurrencyInput from "../CustomComponents/CustomCurrencyInput/CustomCurrencyInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

import firebase from "firebase/app";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHomeAlt, faMoneyBillWave } from "@fortawesome/pro-solid-svg-icons";
library.add(faHomeAlt, faMoneyBillWave);

interface Props {
  onVerifyMicroDeposits: () => void;
}
interface State {
  loading: boolean;
  fundingSourceId: string;
  input: {
    amount1: number;
    amount2: number;
  };
  errors: {
    amount1: string;
    amount2: string;
  };
}

class ModalMicroDeposit extends PureComponent<Props, State> {
  InputAmount1 = React.createRef<CustomCurrencyInput>();
  InputAmount2 = React.createRef<CustomCurrencyInput>();

  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      fundingSourceId: null,
      input: {
        amount1: 0,
        amount2: 0,
      },
      errors: {
        amount1: "",
        amount2: "",
      },
    };

    this.handleVerifyAccount = this.handleVerifyAccount.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    this.InputAmount1.current.clear();
    this.InputAmount2.current.clear();
  }
  show(id: string) {
    this.setState({ fundingSourceId: id });
    this.clear();
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  updateErrors() {
    const errors = this.state.errors;
    let encounteredError = false;

    // Make sure they entered valid micro-deposit amounts
    const amount1 = this.state.input.amount1;
    const amount2 = this.state.input.amount2;

    // Amount 1 is too big or too small
    if (amount1 <= 0 || amount1 > 0.2) {
      encounteredError = true;
      errors.amount1 = "Please enter an amount between $0.01 and $0.20";
    }
    // Amount 2 is too big or too small
    if (amount2 <= 0 || amount2 > 0.2) {
      encounteredError = true;
      errors.amount2 = "Please enter an amount between $0.01 and $0.20";
    }

    if (encounteredError) {
      this.setState(
        {
          errors: errors,
          loading: false,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  handleVerifyAccount() {
    this.setState({ loading: true });

    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    const amount1 = this.state.input.amount1;
    const amount2 = this.state.input.amount2;

    const verifyMicroDeposits = firebase.functions().httpsCallable("verifyMicroDeposits");
    verifyMicroDeposits({
      fundingSourceId: this.state.fundingSourceId,
      value1: amount1,
      value2: amount2,
    })
      .then((resp) => {
        console.log("microdeposits response");
        console.log(resp);
        this.hide();
        this.props.onVerifyMicroDeposits && this.props.onVerifyMicroDeposits();
      })
      .catch((error) => {
        console.error("microdeposit verification failed");
        console.error(error);
      });
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Verify Your Account"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleVerifyAccount();
          }}
        >
          <div className="ModalText">
            Two deposits of $0.10 or less will be made to your account. Please enter the value of these deposits in
            order to verify your account.
          </div>
          <div className="ModalSectionHeader">Amount 1</div>
          <div className="ModalInputWrapper">
            <CustomCurrencyInput
              ref={this.InputAmount1}
              icon={faMoneyBillWave}
              errorText={this.state.errors.amount1}
              inverted={true}
              onChange={(amount) => {
                this.setState((state) => {
                  state.input.amount1 = amount;
                  state.errors.amount1 = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalSectionHeader">Amount 2</div>
          <div className="ModalInputWrapper">
            <CustomCurrencyInput
              ref={this.InputAmount2}
              icon={faMoneyBillWave}
              errorText={this.state.errors.amount2}
              inverted={true}
              onChange={(amount) => {
                this.setState((state) => {
                  state.input.amount2 = amount;
                  state.errors.amount2 = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalButtonWrapper">
            <CustomButton text="Verify" inverted={true} loading={this.state.loading} color={"#4b7bec"} submit={true} />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalMicroDeposit;
