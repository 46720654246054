import React, { PureComponent } from "react";
import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import CustomRadio from "../CustomComponents/CustomRadio/CustomRadio";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

import { getCurrentUserUid } from "../auth";

import firebase from "firebase/app";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMapMarkerAlt,
  faEllipsisH,
  faHashtag,
  faUser,
  faEnvelope,
  faShieldAlt,
  faCandleHolder,
  faBriefcase,
  faBuilding,
  faFileContract,
  faUserHardHat,
} from "@fortawesome/pro-solid-svg-icons";
import { us_states } from "../constants";

library.add(
  faMapMarkerAlt,
  faEllipsisH,
  faHashtag,
  faUser,
  faEnvelope,
  faShieldAlt,
  faCandleHolder,
  faBriefcase,
  faBuilding,
  faFileContract,
  faUserHardHat,
);

// const RENTAL_PROPERTY_MANAGEMENT = "9ed46bb6-7d6f-11e3-b9b2-5404a6144203";
interface Props {}

interface State {
  loading: boolean;
  retrying: boolean;
  question1: number | undefined;
  question2: number | undefined;
  input: {
    firstName: string;
    lastName: string;
    email: string;
    ssn: string;
    dateOfBirth: string;
    address1: string;
    address2: string;
    city: string;
    state: number;
    zipCode: string;
  };
  errors: {
    firstName: string;
    lastName: string;
    email: string;
    ssn: string;
    dateOfBirth: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

class ModalCertifyBusiness extends PureComponent<Props, State> {
  RadioQuestion1 = React.createRef<CustomRadio>();
  RadioQuestion2 = React.createRef<CustomRadio>();
  InputFirstName = React.createRef<CustomInput>();
  InputLastName = React.createRef<CustomInput>();
  InputEmail = React.createRef<CustomInput>();
  InputSSN = React.createRef<CustomInput>();
  InputDateOfBirth = React.createRef<CustomInput>();
  InputAddress1 = React.createRef<CustomInput>();
  InputAddress2 = React.createRef<CustomInput>();
  SelectState = React.createRef<CustomSelect>();
  InputCity = React.createRef<CustomInput>();
  InputZipCode = React.createRef<CustomInput>();

  form = React.createRef<HTMLFormElement>();
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      retrying: false,
      question1: undefined,
      question2: undefined,
      input: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: 0,
        zipCode: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
      },
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    // Clear input states and errors
    this.setState({
      question1: undefined,
      question2: undefined,
      input: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: 0,
        zipCode: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        ssn: "",
        dateOfBirth: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
      },
      loading: false,
    });

    this.RadioQuestion1.current.clear();
    this.RadioQuestion2.current.clear();
    if (this.InputFirstName.current) this.InputFirstName.current.clear();
    if (this.InputLastName.current) this.InputLastName.current.clear();
    if (this.InputEmail.current) this.InputEmail.current.clear();
    if (this.InputSSN.current) this.InputSSN.current.clear();
    if (this.InputAddress1.current) this.InputAddress1.current.clear();
    if (this.InputAddress2.current) this.InputAddress2.current.clear();
  }
  show(retrying) {
    this.setState({ retrying: retrying });
    this.clear();
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async handleCertifyBusiness() {
    this.setState({ loading: true });
    const addBeneficialOwner = firebase.functions().httpsCallable("addBeneficialOwner");
    const certifyBeneficialOwnership = firebase.functions().httpsCallable("certifyBeneficialOwnership");
    const doc = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();
    const dwollaCustomerId = doc.data().dwolla_customer_id;

    // Note: this.state.question1 and this.state.question2 store their answers to the following questions, respectively:
    // - question1: Is your business a publicly traded company or a non-profit?
    // - question2: Does your business have any individual with at least 25% ownership?
    // For both questions, 0 = "Yes" and 1 = "No"
    // If they answered NO to question1 and YES to question2, they must add a beneficial owner
    // The information they provided is stored in this.state.input

    // Make sure they answered both questions
    // Note: This is somewhat unimportant, because the button to submit doesn't show up unless they have answered both questions
    if (this.state.question1 === undefined || this.state.question2 === undefined) return;

    // Only check for additional errors if they were asked to provide additional information
    if (this.state.question1 === 1 && this.state.question2 === 0) {
      // TODO: Error handling (example code for SSN provided below)
      const input = this.state.input;
      const errors = this.state.errors;
      let encounteredError = false;

      // No SSN provided
      if (!input.ssn) {
        errors.ssn = "Please enter the last 4 digits of your SSN";
        encounteredError = true;
      }
      // SSN provided is not 4 digits
      else if (input.ssn.length !== 4 && !this.state.retrying) {
        errors.ssn = "Please enter the last 4 digits of your SSN";
        encounteredError = true;
      }

      // If an error was encountered, display and don't send any data to Dwolla
      if (encounteredError) {
        // Update errors and force re-render
        this.setState(
          {
            loading: false,
            errors: errors,
          },
          this.forceUpdate,
        );
        return;
      }
      addBeneficialOwner({
        dwollaCustomerId: dwollaCustomerId,
        firstName: this.state.input.firstName,
        lastName: this.state.input.lastName,
        ssn: this.state.input.ssn,
        dateOfBirth: this.state.input.dateOfBirth,
        address: {
          address1: this.state.input.address1,
          address2: this.state.input.address2,
          city: this.state.input.city,
          stateProvinceRegion: this.state.input.state,
          country: "US",
          postalCode: this.state.input.zipCode,
        },
      }).then((response) => {
        console.log(response);
        certifyBeneficialOwnership({ dwollaCustomerId: dwollaCustomerId }).then((response) => {
          console.log(response);
        });
      });
    } else {
      // User certifies there are no beneficial owners
      certifyBeneficialOwnership({ dwollaCustomerId: dwollaCustomerId }).then((response) => {
        console.log(response);
      });
    }

    // TODO: handle response

    setTimeout(() => {
      this.hide();
    }, 1000);
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Certify Business"}>
        <form
          ref={this.form}
          onSubmit={(e) => {
            e.preventDefault();
            this.handleCertifyBusiness();
          }}
        >
          <div className="ModalText">
            Please enter additional information to certify your business. This information is required by the U.S.
            Federal Government.
          </div>
          <div className="ModalTextBold">Is your business a publicly traded company or a non-profit?</div>
          <CustomRadio
            ref={this.RadioQuestion1}
            id={"CertifyQuestion1"}
            options={["Yes", "No"]}
            onChange={(value) => {
              this.setState((state) => {
                return { question1: value };
              });
            }}
          />
          <div className="ModalTextBold">Does your business have any individual with at least 25% ownership?</div>
          <CustomRadio
            ref={this.RadioQuestion2}
            id={"CertifyQuestion2"}
            options={["Yes", "No"]}
            onChange={(value) => {
              this.setState((state) => {
                return { question2: value };
              });
            }}
          />
          {this.state.question1 === 1 && this.state.question2 === 0 && (
            <div>
              <div className="ModalText" style={{ marginTop: "25px" }}>
                Please provide information for a beneficial owner (any individual who owns at least 25% of the
                business).
              </div>
              <div className="ModalSectionHeader">Personal information</div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputFirstName}
                  icon={faUser}
                  placeholder="First name"
                  type={"text"}
                  errorText={this.state.errors.firstName}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.firstName = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputLastName}
                  icon={faUser}
                  placeholder="Last name"
                  type={"text"}
                  errorText={this.state.errors.lastName}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.lastName = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputEmail}
                  icon={faEnvelope}
                  placeholder="Email"
                  type={"text"}
                  errorText={this.state.errors.email}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.email = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputSSN}
                  icon={faShieldAlt}
                  placeholder={"Last 4 digits of SSN"}
                  type={"text"}
                  errorText={this.state.errors.ssn}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.ssn = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalSectionHeader">Address</div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputAddress1}
                  icon={faMapMarkerAlt}
                  placeholder="Address (e.g. 346 Elm Street)"
                  type={"text"}
                  errorText={this.state.errors.address1}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.address1 = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputAddress2}
                  icon={faEllipsisH}
                  placeholder="Apartment, floor, suite, etc. (optional)"
                  type={"text"}
                  errorText={this.state.errors.address2}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.address2 = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputCity}
                  icon={faMapMarkerAlt}
                  placeholder="City (e.g. Madison)"
                  type={"text"}
                  errorText={this.state.errors.city}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.city = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomSelect
                  ref={this.SelectState}
                  options={us_states}
                  icon={faMapMarkerAlt}
                  placeholder="State"
                  errorText={this.state.errors.state}
                  inverted={true}
                  readOnly={false}
                  onChange={(value) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.state = value;
                      return { input: input };
                    }, this.forceUpdate);
                  }}
                />
              </div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputZipCode}
                  icon={faHashtag}
                  placeholder="Zip code (e.g. 57042)"
                  type={"text"}
                  errorText={this.state.errors.zipCode}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.zipCode = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
              <div className="ModalSectionHeader">Date of birth</div>
              <div className="ModalInputWrapper">
                <CustomInput
                  ref={this.InputDateOfBirth}
                  icon={faCandleHolder}
                  placeholder="Date of birth"
                  type={"date"}
                  errorText={this.state.errors.dateOfBirth}
                  inverted={true}
                  readOnly={false}
                  onChange={(text) => {
                    this.setState((state) => {
                      const input = state.input;
                      input.dateOfBirth = text;
                      return { input: input };
                    });
                  }}
                />
              </div>
            </div>
          )}
          {this.state.question1 !== undefined && this.state.question2 !== undefined && (
            <div className="ModalButtonWrapper">
              <CustomButton
                text={"Submit"}
                inverted={true}
                loading={this.state.loading}
                color={"#4b7bec"}
                submit={true}
              />
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

export default ModalCertifyBusiness;
