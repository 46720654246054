import React, { PureComponent } from "react";
import { Plugins, HapticsNotificationType, Capacitor } from "@capacitor/core";

import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomCurrencyInput from "../CustomComponents/CustomCurrencyInput/CustomCurrencyInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faHomeAlt, faMoneyBillWave } from "@fortawesome/pro-solid-svg-icons";
import { IProperty } from "../conjure-api";
library.add(faHomeAlt, faMoneyBillWave);

const { Haptics } = Plugins;

interface Props {
  onRefresh: () => void;
}

interface State {
  loading: boolean;
  input: {
    unitName: string;
    monthlyRent: number;
  };
  errors: {
    unitName: string;
    monthlyRent: string;
  };
  property: IProperty;
}

class ModalAddUnit extends PureComponent<Props, State> {
  InputUnitName = React.createRef<CustomInput>();
  InputMonthlyRent = React.createRef<CustomCurrencyInput>();
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      input: {
        unitName: "",
        monthlyRent: 0,
      },
      errors: {
        unitName: "",
        monthlyRent: "",
      },
      // Passed data
      property: null,
    };

    this.handleAddUnit = this.handleAddUnit.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    this.InputUnitName.current.clear();
    this.InputMonthlyRent.current.clear();
  }
  show(property: IProperty) {
    this.clear();
    this.setState({
      property: property,
    });
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  updateErrors() {
    const errors = this.state.errors;
    let encounteredError = false;

    // Make sure they entered a valid rent payment
    const amount = this.state.input.monthlyRent;
    if (amount === 0) {
      errors.monthlyRent = "Please enter a valid monthly rent for this unit";
      encounteredError = true;
    }

    if (this.state.input.unitName.trim() === "") {
      errors.unitName = "Please enter a name for this unit";
      encounteredError = true;
    }

    if (encounteredError) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  async handleAddUnit() {
    this.setState({ loading: true });
    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    await firebase.functions().httpsCallable("addUnit")({
      property_id: this.state.property.id,
      unit_name: this.state.input.unitName,
      rent: this.state.input.monthlyRent,
    });

    // Success haptic when unit added successfully
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });

    this.props.onRefresh();

    firebase.analytics().logEvent("property_unit_added");
    firebase.analytics().setUserProperties({ property_unit_added: true });
    this.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Add New Unit"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleAddUnit();
          }}
        >
          <div className="ModalText">Add a new unit to {this.state.property?.address}</div>
          <div className="ModalSectionHeader">Unit name</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputUnitName}
              icon={faHomeAlt}
              placeholder="Unit 1"
              type={"text"}
              errorText={this.state.errors.unitName}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.unitName = text;
                  state.errors.unitName = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>

          <div className="ModalSectionHeader">Monthly rent</div>
          <div className="ModalInputWrapper">
            <CustomCurrencyInput
              ref={this.InputMonthlyRent}
              icon={faMoneyBillWave}
              errorText={this.state.errors.monthlyRent}
              inverted={true}
              onChange={(amount) => {
                this.setState((state) => {
                  state.input.monthlyRent = amount;
                  state.errors.monthlyRent = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalButtonWrapper">
            <CustomButton
              text="Create Unit"
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalAddUnit;
