import React, { PureComponent } from "react";
import { Plugins, StatusBarStyle, Capacitor, HapticsImpactStyle, AppState } from "@capacitor/core";

import LandingPage from "./LandingPage/LandingPage";
import DedicatedLoginPage from "./DedicatedLoginPage/DedicatedLoginPage";
import MessagingPage from "./MessagingPage/MessagingPage";
import ProfilePage from "./ProfilePage/ProfilePage";
import ManagePage from "./ManagePage/ManagePage";
import SettingsPage from "./SettingsPage/SettingsPage";
import GlobalNavigation from "./GlobalNavigation/GlobalNavigation";
import ImageViewer from "./ImageViewer/ImageViewer";
import PendingAuthOverlay from "./PendingAuthOverlay/PendingAuthOverlay";
import GlobalProgressIndicator from "./GlobalProgressIndicator/GlobalProgressIndicator";

import ModalAddProperty from "./Modal/ModalAddProperty";
import ModalAddUnit from "./Modal/ModalAddUnit";
import ModalAddTenant from "./Modal/ModalAddTenant";
import ModalRemoveTenant from "./Modal/ModalRemoveTenant";
import ModalRemoveUnit from "./Modal/ModalRemoveUnit";
import ModalRemoveProperty from "./Modal/ModalRemoveProperty";
import ModalMessageProperty from "./Modal/ModalMessageProperty";
import ModalCreateVerifiedUser from "./Modal/ModalCreateVerifiedUser";
import ModalCreateUnverifiedUser from "./Modal/ModalCreateUnverifiedUser";
import ModalCertifyBusiness from "./Modal/ModalCertifyBusiness";
import ModalVerificationFailed from "./Modal/ModalVerificationFailed";
import ModalVerificationRetry from "./Modal/ModalVerificationRetry";
import ModalVerificationSuccessful from "./Modal/ModalVerificationSuccessful";
import ModalGeneric from "./Modal/ModalGeneric";
import ModalRemoveFundingSource from "./Modal/ModalRemoveFundingSource";
import ModalTermsOfService from "./Modal/ModalTermsOfService";
import ModalPrivacyPolicy from "./Modal/ModalPrivacyPolicy";
import ModalUploadDocument from "./Modal/ModalUploadDocument";
import ModalAddBank from "./Modal/ModalAddBank";
import ModalMicroDeposit from "./Modal/ModalMicroDeposit";
import ModalPaymentSchedule from "./Modal/ModalPaymentSchedule";
import ModalDeleteChat from "./Modal/ModalDeleteChat";
import ModalManualPayment from "./Modal/ModalManualPayment";
import ModalEditUnit from "./Modal/ModalEditUnit";

import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import ReactTooltip from "react-tooltip";
import {
  IPropertyWithUnits,
  IRentingwayUserWithTransfersWorkOrders,
  IScheduledTransferWithNames,
  ITenantWithUser,
  ITransferWithNames,
  IUnitWithTenants,
  IWorkOrderWithPeer,
  loadManagePageData,
} from "./loadData";
import { IProperty, IUnit } from "./conjure-api";
import ModalRentInfo from "./Modal/ModalRentInfo";
import { getCurrentUserUid, IMPERSONATION_ENABLED } from "./auth";

const { PushNotifications, SplashScreen, Haptics, StatusBar } = Plugins;

interface Props {}

interface State {
  // Note: To show the home page and hide the login page on startup (i.e. for testing or if the user is already logged in),
  // set showingLogin to false and set showingGlobalNavigation to true
  showingLogin: boolean;
  showingGlobalNavigation: boolean;
  // Positions of pages
  // Note: Position decides where a page is inside the GlobalNavigation wrapper:
  // -1: hidden to the left
  // 0: currently showing
  // 1: hidden to the right
  positions: {
    manage: number;
    messaging: number;
    profile: number;
    settings: number;
  };
  typing: boolean;
  // Elements which are listening for typing to bind/unbind event listeners
  typingElements: any;
  // Whether this user manages at least one property
  managesProperty: boolean;
  // Whether we're waiting to find out if this user is logged in
  pendingAuthStateChanged: boolean;
  // Progress of global progress indicator
  // Right now this is only user for image upload
  globalProgress: number;
  isNative?: boolean;
  appVersion?: string;
  // Viewport dimensions
  height: number;
  keyboardHeight: number;
  dwollaCustomerType: null | "verified" | "unverified";
  dwollaCustomerId: null | string;
  loadingDwolla: boolean;
  // Queue whether to add an account or show the create property modal after a Dwolla user is created
  queueAddBank: boolean;
  queueAddProperty: boolean;
  fundingSources: Array<any>;
  balanceId: null | string;
  balance: number;
  primaryFundingSourceId: null | string;
  loadingGlobal: boolean;
  paymentsSetupComplete: boolean;

  loadingFullContent: boolean;
  loadingRefresh: boolean;

  // Manage Page Data
  otherUsers: IRentingwayUserWithTransfersWorkOrders[];
  properties: IPropertyWithUnits[];
  transfers: ITransferWithNames[];
  scheduledTransfers: IScheduledTransferWithNames[];
  workOrders: IWorkOrderWithPeer[];
}

class App extends PureComponent<Props, State> {
  ManagePage = React.createRef<ManagePage>();
  GlobalNavigation = React.createRef<GlobalNavigation>();
  MessagingPage = React.createRef<MessagingPage>();
  ProfilePage = React.createRef<ProfilePage>();
  SettingsPage = React.createRef<SettingsPage>();
  ImageViewer = React.createRef<ImageViewer>();

  // Modals
  ModalAddProperty = React.createRef<ModalAddProperty>();
  ModalAddUnit = React.createRef<ModalAddUnit>();
  ModalAddTenant = React.createRef<ModalAddTenant>();
  ModalRemoveTenant = React.createRef<ModalRemoveTenant>();
  ModalRemoveUnit = React.createRef<ModalRemoveUnit>();
  ModalRemoveProperty = React.createRef<ModalRemoveProperty>();
  ModalMessageProperty = React.createRef<ModalMessageProperty>();
  ModalCreateVerifiedUser = React.createRef<ModalCreateVerifiedUser>();
  ModalCreateUnverifiedUser = React.createRef<ModalCreateUnverifiedUser>();
  ModalCertifyBusiness = React.createRef<ModalCertifyBusiness>();
  ModalVerificationFailed = React.createRef<ModalVerificationFailed>();
  ModalVerificationRetry = React.createRef<ModalVerificationRetry>();
  ModalUploadDocument = React.createRef<ModalUploadDocument>();
  ModalVerificationSuccessful = React.createRef<ModalVerificationSuccessful>();
  ModalGeneric = React.createRef<ModalGeneric>();
  ModalRemoveFundingSource = React.createRef<ModalRemoveFundingSource>();
  ModalTermsOfService = React.createRef<ModalTermsOfService>();
  ModalPrivacyPolicy = React.createRef<ModalPrivacyPolicy>();
  ModalAddBank = React.createRef<ModalAddBank>();
  ModalMicroDeposit = React.createRef<ModalMicroDeposit>();
  ModalPaymentSchedule = React.createRef<ModalPaymentSchedule>();
  ModalDeleteChat = React.createRef<ModalDeleteChat>();
  ModalManualPayment = React.createRef<ModalManualPayment>();
  ModalEditUnit = React.createRef<ModalEditUnit>();
  ModalRentInfo = React.createRef<ModalRentInfo>();

  constructor(props) {
    super(props);

    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

    this.state = {
      showingLogin: true,
      showingGlobalNavigation: false,
      positions: {
        manage: 0,
        messaging: 1,
        profile: 1,
        settings: 1,
      },
      typing: false,
      typingElements: [],
      managesProperty: false,
      pendingAuthStateChanged: true,
      globalProgress: 0,
      isNative: undefined,
      appVersion: undefined,
      height: h,
      keyboardHeight: 0,
      dwollaCustomerType: null,
      dwollaCustomerId: null,
      queueAddBank: false,
      queueAddProperty: false,
      loadingDwolla: true,
      fundingSources: [],
      balanceId: null,
      balance: 0,
      primaryFundingSourceId: null,
      loadingGlobal: false,
      paymentsSetupComplete: false,

      loadingFullContent: false,
      loadingRefresh: false,

      otherUsers: [],
      properties: [],
      transfers: [],
      scheduledTransfers: [],
      workOrders: [],
    };

    // Default title
    document.title = "Rentingway";

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleBeginTyping = this.handleBeginTyping.bind(this);
    this.handleFinishTyping = this.handleFinishTyping.bind(this);
    this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
    this.recievedAuthState = this.recievedAuthState.bind(this);
    this.updateViewportHeight = this.updateViewportHeight.bind(this);
    this.handleUpdateDwollaData = this.handleUpdateDwollaData.bind(this);
    this.handleAddBank = this.handleAddBank.bind(this);
    this.handleMakePrimary = this.handleMakePrimary.bind(this);
    this.handleUpdatePaymentsSetupComplete = this.handleUpdatePaymentsSetupComplete.bind(this);

    if (Capacitor.isNative) {
      // remove badge count and all delivered notifications from storage
      // to see what's stored in deliveredNotifications,
      // see PushNotifcations.getDeliveredNotifcations method
      Plugins.App.addListener("appStateChange", (state: AppState) => {
        if (state.isActive) {
          PushNotifications.removeAllDeliveredNotifications();
        }
      });

      SplashScreen.hide();
    }
  }

  handleUpdatePaymentsSetupComplete(complete: boolean) {
    if (this.GlobalNavigation.current) this.GlobalNavigation.current.handleUpdatePaymentsSetupComplete(complete);
    this.setState({ paymentsSetupComplete: complete });
  }
  handleBeginTyping() {
    this.setState({ typing: true });
  }
  handleFinishTyping() {
    this.setState({ typing: false });
  }

  updateViewportHeight() {
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.setState({ height: h });
  }

  componentDidMount() {
    window.addEventListener("keyboardWillShow", (e) => {
      this.handleBeginTyping();
      this.setState({ keyboardHeight: (e as any).keyboardHeight });
    });
    window.addEventListener("keyboardWillHide", () => {
      this.handleFinishTyping();
      this.setState({ keyboardHeight: 0 });
    });

    this.updateViewportHeight();
    window.addEventListener("resize", this.updateViewportHeight);
  }
  componentWillUnmount() {
    window.removeEventListener("keyboardWillShow", () => {});
    window.removeEventListener("keyboardWillHide", () => {});
    window.removeEventListener("resize", this.updateViewportHeight);
  }

  handleSuccessfulLogin() {
    // Blur active element to un-focus sign-in fields
    if ("activeElement" in document && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }

    // Change the status bar color to be dark text on light background
    if (Capacitor.isNative && Capacitor.platform === "ios") {
      StatusBar.setStyle({
        style: StatusBarStyle.Light,
      });
    }

    // Do not await this, as it causes slow login
    this.handleUpdateDwollaData().then(() => {});

    this.setState({
      showingLogin: false,
      showingGlobalNavigation: true,
    });

    this.loadManageInitial();

    this.MessagingPage.current.handleSuccessfulLogin();
    this.ProfilePage.current.handleSuccessfulLogin();
    this.GlobalNavigation.current.handleSuccessfulLogin();
    this.ModalPaymentSchedule.current.handleSuccessfulLogin();
  }

  async loadManageInitial() {
    this.setState({
      loadingFullContent: true,
    });

    await this.loadManage();

    this.setState({
      loadingFullContent: false,
    });
  }

  async handleRefresh() {
    this.setState({
      loadingRefresh: true,
    });

    await this.loadManage();

    this.setState({
      loadingRefresh: false,
    });
  }

  async loadManage() {
    window.performance.mark("beginManagePageLoad");

    const { properties, transfers, scheduledTransfers, otherUsers, workOrders } = await loadManagePageData();
    this.setState({
      properties,
      transfers,
      scheduledTransfers,
      otherUsers,
      workOrders,
      loadingFullContent: false,
    });

    window.performance.mark("endManagePageLoad");
    window.performance.measure("managePageLoad", "beginManagePageLoad", "endManagePageLoad");
    firebase.analytics().logEvent("manage_page_loaded", {
      num_properties: properties.length,
      num_units: properties.reduce((count, property) => count + property.units.length, 0),
      num_transfers: transfers.length,
      num_work_orders: workOrders.length,
      time_elapsed: window.performance.getEntriesByName("managePageLoad"),
    });
  }

  handleSuccessfulSignOut() {
    window.location.reload(false);
    this.setState({
      showingLogin: true,
      showingGlobalNavigation: false,
    });

    // Change the status bar color to be light text on dark background
    if (Capacitor.isNative) {
      StatusBar.setStyle({
        style: StatusBarStyle.Dark,
      });
    }
  }

  async handleMakePrimary(fundingSourceId) {
    await firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .update({ primary_funding_source_id: fundingSourceId });

    this.setState({ primaryFundingSourceId: fundingSourceId });
  }

  async handleUpdateDwollaData() {
    this.setState({ loadingGlobal: true });

    // Get customer ID from Firebase
    const userSnapshot = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();
    const data = userSnapshot.data();
    const dwollaCustomerId = data.dwolla_customer_id;

    if (data && dwollaCustomerId && !IMPERSONATION_ENABLED) {
      // Update customer ID
      this.setState({ dwollaCustomerId: dwollaCustomerId });

      const retrieveCustomer = firebase.functions().httpsCallable("retrieveCustomer");
      const response = await retrieveCustomer({ customerId: dwollaCustomerId });
      const status = response.data.status;

      // Update customer type
      if (status === "verified" || status === "unverified") {
        this.setState({ dwollaCustomerType: status });
      }

      // Get funding sources
      const listCustomerFundingSources = firebase.functions().httpsCallable("listCustomerFundingSources");
      const result = await listCustomerFundingSources({ customerId: dwollaCustomerId });
      const fundingSources = result.data._embedded["funding-sources"];

      // Get balance
      fundingSources.forEach((item) => {
        if (item.type === "balance") {
          firebase
            .functions()
            .httpsCallable("getDwollaBalance")({ dwollaBalanceId: item.id })
            .then((result) => {
              this.setState({
                balanceId: item.id,
                balance: result.data.balance.value,
              });
            });
        }
      });

      // Remove balance and removed funding sources
      const newFundingSources = [];
      fundingSources.forEach((fundingSource) => {
        if (!fundingSource.removed && fundingSource.type !== "balance") {
          newFundingSources.push(fundingSource);
        }
      });
      this.setState({ fundingSources: newFundingSources });

      // Get the primary funding source ID
      // If there is currently no primary funding source, default to the first source
      let primaryFundingSourceId = data.primary_funding_source_id;
      if (!primaryFundingSourceId && newFundingSources.length > 0) {
        this.handleMakePrimary(newFundingSources[0].id);
        primaryFundingSourceId = newFundingSources[0].id;
      }

      this.setState({ primaryFundingSourceId: primaryFundingSourceId });
    }

    this.setState({ loadingDwolla: false, loadingGlobal: false });
  }

  recievedAuthState() {
    this.setState({ pendingAuthStateChanged: false });
  }

  handleAddBank() {
    const dwollaCustomerId = this.state.dwollaCustomerId;
    const dwollaCustomerType = this.state.dwollaCustomerType;

    // If Dwolla customer exists, show bank modal right away
    if (dwollaCustomerId && dwollaCustomerType) {
      this.ModalAddBank.current.show(dwollaCustomerId);
    }
    // Else, create unverified user and queue account to be added
    else {
      this.setState({ queueAddBank: true });
      this.ModalCreateUnverifiedUser.current.show();
    }
  }

  onShowPage(page: PageName) {
    document.title = `Rentingway - ${getPageTitle(page)}`;
    this.setState({
      positions: {
        manage: getPagePosition("manage", page),
        messaging: getPagePosition("messaging", page),
        profile: getPagePosition("profile", page),
        settings: getPagePosition("settings", page),
      },
    });
    firebase.analytics().logEvent(`page_${page}_viewed`);
    this.MessagingPage.current.updateMarkAsRead(page === "messaging");
  }

  render() {
    const LandingComponent = Capacitor.isNative ? (
      <DedicatedLoginPage
        showing={this.state.showingLogin}
        onSuccessfulLogin={this.handleSuccessfulLogin}
        onSuccessfulSignOut={this.handleSuccessfulSignOut}
        recievedAuthState={this.recievedAuthState}
        onShowPrivacyPolicy={() => {
          this.ModalPrivacyPolicy.current.show();
        }}
        onShowTermsOfService={() => {
          this.ModalTermsOfService.current.show();
        }}
        showGenericModal={(title: string, body: string, unhidable: boolean) => {
          this.ModalGeneric.current.show(title, body, unhidable);
        }}
        onUpdateProgress={(progress) => {
          this.setState({ globalProgress: progress });
        }}
      />
    ) : (
      <LandingPage
        showing={this.state.showingLogin}
        onSuccessfulLogin={this.handleSuccessfulLogin}
        onSuccessfulSignOut={this.handleSuccessfulSignOut}
        recievedAuthState={this.recievedAuthState}
        onShowPrivacyPolicy={() => {
          this.ModalPrivacyPolicy.current.show();
        }}
        onShowTermsOfService={() => {
          this.ModalTermsOfService.current.show();
        }}
        showGenericModal={(title: string, body: string, unhidable?: boolean) => {
          this.ModalGeneric.current.show(title, body, unhidable);
        }}
        onUpdateProgress={(progress) => {
          this.setState({ globalProgress: progress });
        }}
      />
    );

    return (
      <div
        style={{
          position: "relative",
          height: this.state.height - this.state.keyboardHeight + "px",
          overflow: "hidden",
          // Uncomment to emulate the animation of the keyboard showing
          //transition: 'height 300ms ease-out',
        }}
      >
        {/* Show logo in an overlay while waiting for auth response */}
        <PendingAuthOverlay showing={this.state.pendingAuthStateChanged} />

        <GlobalProgressIndicator progress={this.state.globalProgress} />

        <GlobalNavigation
          ref={this.GlobalNavigation}
          showing={this.state.showingGlobalNavigation}
          typing={this.state.typing}
          onShowManage={() => this.onShowPage("manage")}
          onShowMessaging={() => this.onShowPage("messaging")}
          onShowProfile={() => this.onShowPage("profile")}
          onShowSettings={() => this.onShowPage("settings")}
        >
          {/* Shimmering loading bar */}
          <div
            style={{
              position: "absolute",
              height: "4px",
              width: "100%",
              top: "0",
              zIndex: 1000,
            }}
          >
            <div
              className="ShimmerLoadingBar"
              style={{
                height: this.state.loadingGlobal ? "4px" : "0",
                transition: "height 80ms",
              }}
            ></div>
          </div>
          <ManagePage
            ref={this.ManagePage}
            position={this.state.positions.manage}
            loadingFullContent={this.state.loadingFullContent}
            loadingRefresh={this.state.loadingRefresh}
            fundingSources={this.state.fundingSources}
            otherUsers={this.state.otherUsers}
            properties={this.state.properties}
            transfers={this.state.transfers}
            scheduledTransfers={this.state.scheduledTransfers}
            workOrders={this.state.workOrders}
            onRefresh={this.handleRefresh}
            onEditUnit={({ property, unit }) => {
              this.ModalEditUnit.current.show(property, unit);
            }}
            onMessageUser={({ userId, propertyId, unitId }) => {
              firebase.analytics().logEvent("button_message_user");
              this.onShowPage("messaging");
              this.MessagingPage.current.showChatWithUser(userId, propertyId, unitId);
            }}
            onPayRent={({ managerId, propertyId, unitId, amount }) => {
              firebase.analytics().logEvent("button_pay_rent");
              this.onShowPage("messaging");
              this.MessagingPage.current.handlePayRent(managerId, propertyId, unitId, amount);
            }}
            onUpdateWorkOrderStatus={(workOrderId, newStatus) => {
              this.ManagePage.current.onUpdateWorkOrderStatus(workOrderId, newStatus);
            }}
            onRemoveProperty={({ property }) => {
              this.ModalRemoveProperty.current.show(property);
            }}
            onAddProperty={() => {
              this.ModalAddProperty.current.show();
            }}
            onMessageProperty={({ property }) => {
              this.ModalMessageProperty.current.show(property);
            }}
            onRemoveUnit={({ property, unit }) => {
              this.ModalRemoveUnit.current.show(property, unit);
            }}
            onAddUnit={({ property }) => {
              this.ModalAddUnit.current.show(property);
            }}
            onRemoveTenant={({ property, unit, tenant }) => {
              this.ModalRemoveTenant.current.show(property, unit, tenant);
            }}
            onAddTenant={({ property, unit }) => {
              this.ModalAddTenant.current.show(property, unit);
            }}
            showCustomModal={(title, body) => {
              this.ModalGeneric.current.show(title, body);
            }}
            onShowPaymentSchedule={() => {
              this.ModalPaymentSchedule.current.show();
            }}
            onViewImage={(imageUrl) => {
              this.ImageViewer.current.show(imageUrl);
            }}
            onUpdateManagerStatus={(managesProperty) => {
              if (this.SettingsPage.current) this.SettingsPage.current.handleUpdateManagerStatus(managesProperty);
            }}
            onRecordManualPayment={(tenant) => {
              this.ModalManualPayment.current.show(tenant);
            }}
            onRequestRentInfo={(unit) => {
              this.ModalRentInfo.current.show(unit);
            }}
          />
          <MessagingPage
            ref={this.MessagingPage}
            position={this.state.positions.messaging}
            dwollaCustomerType={this.state.dwollaCustomerType}
            primaryFundingSourceId={this.state.primaryFundingSourceId}
            loadingDwolla={this.state.loadingDwolla}
            onAddBank={this.handleAddBank}
            onTransactionFailed={() => {
              firebase.analytics().logEvent("transfer_failed");
              // Error haptic when payment fails
              if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Heavy });
              this.ModalGeneric.current.show("Transaction Failed", "Your payment didn't go through. Please try again.");
            }}
            onExceedUnverifiedTransactionLimit={() => {
              // If thhe user is not already verified, show modal to verify
              // Note: If this function is called, the user should be unverified
              if (this.state.dwollaCustomerType !== "verified") {
                this.ModalCreateVerifiedUser.current.show(false);
              }
              // Else, the user is verified for some reason (this shouldn't be the case)
              // Show a generic error
              else {
                this.ModalGeneric.current.show(
                  "Transaction Failed",
                  "You have exceeded your weekly transaction limit. Please try again later. If the problem persists, please contact support.",
                );
              }
            }}
            showCustomModal={(title, body) => {
              this.ModalGeneric.current.show(title, body);
            }}
            onViewImage={(imageUrl) => {
              firebase.analytics().logEvent("image_viewed");
              this.ImageViewer.current.show(imageUrl);
            }}
            onDeleteChat={(chatId) => {
              this.ModalDeleteChat.current.show(chatId);
            }}
            onRefresh={this.handleRefresh}
          />
          <ProfilePage
            ref={this.ProfilePage}
            position={this.state.positions.profile}
            onUpdateProfilePhotoExists={(profilePhotoExists) => {
              this.GlobalNavigation.current.handleProfilePhotoExists(profilePhotoExists);
            }}
            onUpdateProgress={(progress) => {
              this.setState({ globalProgress: progress });
            }}
          />
          <SettingsPage
            ref={this.SettingsPage}
            onUpdatePaymentsSetupComplete={this.handleUpdatePaymentsSetupComplete}
            paymentsSetupComplete={this.state.paymentsSetupComplete}
            paymentSent={this.state.transfers.length > 0}
            dwollaCustomerId={this.state.dwollaCustomerId}
            dwollaCustomerType={this.state.dwollaCustomerType}
            loadingDwolla={this.state.loadingDwolla}
            position={this.state.positions.settings}
            fundingSources={this.state.fundingSources}
            balance={this.state.balance}
            balanceId={this.state.balanceId}
            primaryFundingSourceId={this.state.primaryFundingSourceId}
            onMakePrimary={this.handleMakePrimary}
            onShowPrivacyPolicy={() => {
              this.ModalPrivacyPolicy.current.show();
            }}
            onShowTermsOfService={() => {
              this.ModalTermsOfService.current.show();
            }}
            onRemoveFundingSource={(id, name) => {
              this.ModalRemoveFundingSource.current.show(id, name);
            }}
            onVerifyMicroDeposits={(id) => {
              this.ModalMicroDeposit.current.show(id);
            }}
            onAddBank={this.handleAddBank}
            onCertifyBusiness={() => {
              this.ModalCertifyBusiness.current.show(false);
            }}
            onSuccessfulSignOut={this.handleSuccessfulSignOut}
            onAddProperty={() => {
              const dwollaCustomerId = this.state.dwollaCustomerId;
              const dwollaCustomerType = this.state.dwollaCustomerType;

              // If Dwolla customer exists and they are verified, show bank modal right away
              if (dwollaCustomerId && dwollaCustomerType === "verified") {
                this.ModalAddProperty.current.show();
              }
              // Else, create verified user and queue property to be added
              else {
                this.setState({ queueAddProperty: true });
                this.ModalCreateVerifiedUser.current.show(false);
              }
            }}
            onSuccessfulExport={() => {
              this.ModalGeneric.current.show(
                "Export Successful",
                "We sent you an email with an automatically-populated spreadsheet containing all of your property information.",
              );
            }}
          />
        </GlobalNavigation>

        {LandingComponent}

        <ImageViewer ref={this.ImageViewer} />

        <ModalAddProperty ref={this.ModalAddProperty} onRefresh={this.handleRefresh} />
        <ModalAddUnit ref={this.ModalAddUnit} onRefresh={this.handleRefresh} />
        <ModalAddTenant
          ref={this.ModalAddTenant}
          messagingPage={this.MessagingPage.current}
          showCustomModal={(title, body) => {
            this.ModalGeneric.current.show(title, body);
          }}
          onRefresh={this.handleRefresh}
        />
        <ModalRemoveTenant ref={this.ModalRemoveTenant} onRefresh={this.handleRefresh} />
        <ModalRemoveUnit ref={this.ModalRemoveUnit} onRefresh={this.handleRefresh} />
        <ModalRemoveProperty ref={this.ModalRemoveProperty} onRefresh={this.handleRefresh} />
        <ModalMessageProperty ref={this.ModalMessageProperty} messagingPage={this.MessagingPage.current} />
        <ModalCreateVerifiedUser
          ref={this.ModalCreateVerifiedUser}
          dwollaCustomerType={this.state.dwollaCustomerType}
          dwollaCustomerId={this.state.dwollaCustomerId}
          onVerificationResponse={async (status: "verified" | "retry" | "document" | "suspended") => {
            // Note: must wait for update to resolve to get new customer ID
            await this.handleUpdateDwollaData();

            switch (status) {
              case "verified":
                // If they've queued creating a property, un-queue it and show modal right away
                if (this.state.queueAddProperty) {
                  this.setState({ queueAddProperty: false });
                  this.ModalAddProperty.current.show();
                }
                // Else, show success modal
                else {
                  this.ModalVerificationSuccessful.current.show();
                }
                break;
              case "retry":
                this.ModalVerificationRetry.current.show();
                break;
              case "document":
                this.ModalUploadDocument.current.show();
                break;
              case "suspended":
                this.ModalVerificationFailed.current.show();
                break;
            }
          }}
        />
        <ModalCreateUnverifiedUser
          ref={this.ModalCreateUnverifiedUser}
          onVerificationResponse={async (status: "unverified" | "failed") => {
            // Note: must wait for update to resolve to get new customer ID
            await this.handleUpdateDwollaData();

            switch (status) {
              case "unverified":
                // If they've queued adding an account, un-queue it and show modal right away
                if (this.state.queueAddBank && this.state.dwollaCustomerId) {
                  this.setState({ queueAddBank: false });
                  this.ModalAddBank.current.show(this.state.dwollaCustomerId);
                }
                // Else, show success modal
                else {
                  this.ModalVerificationSuccessful.current.show();
                }
                break;
              case "failed":
                this.ModalGeneric.current.show(
                  "Verification Failed",
                  "Something went wrong. Please make sure that you are not providing duplicate information. If the problem persists, please contact us.",
                );
                break;
            }
          }}
        />
        <ModalCertifyBusiness ref={this.ModalCertifyBusiness} />
        <ModalMicroDeposit
          ref={this.ModalMicroDeposit}
          onVerifyMicroDeposits={() => {
            this.handleUpdateDwollaData();
          }}
        />
        <ModalUploadDocument ref={this.ModalUploadDocument} />
        <ModalVerificationRetry
          ref={this.ModalVerificationRetry}
          onRetry={() => {
            this.ModalCreateVerifiedUser.current.show(true);
          }}
        />
        <ModalVerificationFailed ref={this.ModalVerificationFailed} />
        <ModalVerificationSuccessful ref={this.ModalVerificationSuccessful} />
        <ModalRemoveFundingSource
          ref={this.ModalRemoveFundingSource}
          onHide={() => {
            this.handleUpdateDwollaData();
          }}
        />
        <ModalGeneric ref={this.ModalGeneric} />
        <ModalTermsOfService ref={this.ModalTermsOfService} />
        <ModalPrivacyPolicy ref={this.ModalPrivacyPolicy} />
        <ModalAddBank
          ref={this.ModalAddBank}
          onIAVClosed={() => {
            this.handleUpdateDwollaData();
          }}
        />
        <ModalPaymentSchedule ref={this.ModalPaymentSchedule} />
        <ModalDeleteChat
          ref={this.ModalDeleteChat}
          onDeleteChat={(chatId) => {
            this.MessagingPage.current.deleteChat(chatId);
          }}
        />
        <ModalManualPayment
          ref={this.ModalManualPayment}
          onInvalid={() => {
            this.ModalGeneric.current.show(
              "Cannot Record Manual Payment",
              "A chat must already exist between the parties involved. Please message the tenant and try again.",
            );
          }}
          onRefresh={this.handleRefresh}
        />
        <ModalEditUnit ref={this.ModalEditUnit} onRefresh={this.handleRefresh} />
        <ModalRentInfo ref={this.ModalRentInfo} />
        <ReactTooltip
          effect="solid"
          type="light"
          clickable={true}
          multiline={true}
          border={true}
          borderColor="#f7f7f7"
          backgroundColor="#fff"
        />
      </div>
    );
  }
}

export default App;

type PageName = "manage" | "messaging" | "profile" | "settings";
const PAGE_ORDER: PageName[] = ["manage", "messaging", "profile", "settings"];

const getPageTitle = (page: PageName) => {
  switch (page) {
    case "manage":
      return "Home";
    case "messaging":
      return "Messages";
    case "profile":
      return "Profile";
    case "settings":
      return "Settings";
  }
};

const getPagePosition = (targetPage: PageName, relativeToPage: PageName) => {
  if (targetPage === relativeToPage) return 0;
  if (PAGE_ORDER.indexOf(targetPage) < PAGE_ORDER.indexOf(relativeToPage)) return -1;
  if (PAGE_ORDER.indexOf(targetPage) > PAGE_ORDER.indexOf(relativeToPage)) return 1;
};

export type AddTenantCallback = (args: { property: IProperty; unit: IUnitWithTenants }) => void;
export type AddUnitCallback = (args: { property: IProperty }) => void;
export type EditUnitCallback = (args: { property: IProperty; unit: IUnit }) => void;
export type MessagePropertyCallback = (args: { property: IProperty }) => void;
export type MessageUserCallback = (args: { userId: string; propertyId: string | null; unitId: string | null }) => void;
export type PayRentCallback = (args: { managerId: string; propertyId: string; unitId: string; amount: number }) => void;
export type RecordManualPaymentCallback = (tenant: ITenantWithUser) => void;
export type RemovePropertyCallback = (args: { property: IProperty }) => void;
export type RemoveTenantCallback = (args: { property: IProperty; unit: IUnit; tenant: ITenantWithUser }) => void;
export type RemoveUnitCallback = (args: { property: IProperty; unit: IUnit }) => void;
export type ShowCustomModalCallback = (title: string, body: string) => void;
export type UpdateManagerStatusCallback = (managesProperty: boolean) => void;
export type UpdateTenantNotesCallback = (args: {
  notes: string;
  property: IProperty;
  tenant: ITenantWithUser;
  unit: IUnit;
}) => Promise<void>;
export type UpdateWorkOrderStatusCallback = (workOrderId: string, newStatus: boolean) => void;
export type ViewImageCallback = (imageUrl: string) => void;
export type RequestRentInfoCallback = (unit: IUnitWithTenants) => void;
