import React, { PureComponent } from "react";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebookF, faInstagram, faTwitter, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
library.add(faFacebookF, faInstagram, faTwitter, faLinkedinIn, faEnvelope);

class Footer extends PureComponent {
  render() {
    return (
      <div className="LandingFooter">
        <div className="LandingFooterSupport">
          <span>Looking for support? We're here for you. Send us an email at </span>
          <a href="mailto:support@rentingway.com">support@rentingway.com</a>
          <span>, or call </span>
          <a href="tel:+18008078321">+1 (800) 807-8321</a>
        </div>
        <div className="LandingFooterBottom">
          <div className="LandingCopyright">
            <a
              style={{
                position: "relative",
                display: "block",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#fff",
                textDecoration: "none",
              }}
              href="https://rentingway.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              &copy; Rentingway {new Date().getFullYear()}
            </a>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <a
              className="LandingSocialButton"
              href="https://www.instagram.com/rentingway/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="LandingSocialButtonInner">
                <FontAwesomeIcon icon={faInstagram} />
              </div>
            </a>
            <a
              className="LandingSocialButton"
              href="https://www.facebook.com/rentingway"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="LandingSocialButtonInner">
                <FontAwesomeIcon icon={faFacebookF} />
              </div>
            </a>
            <a
              className="LandingSocialButton"
              href="https://twitter.com/Rentingway"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="LandingSocialButtonInner">
                <FontAwesomeIcon icon={faTwitter} />
              </div>
            </a>
            <a
              className="LandingSocialButton"
              href="https://www.linkedin.com/company/rentingway/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="LandingSocialButtonInner">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </div>
            </a>
            <a className="LandingSocialButton" href="mailto:support@rentingway.com">
              <div className="LandingSocialButtonInner">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
