import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import "./CustomButtonSmall.css";

interface Props {
  inverted?: boolean;
  borderOnly?: boolean;
  loading?: boolean;
  disabled?: boolean;
  color: string;
  text: string;
  onClick?: () => void;
}

class CustomButtonSmall extends PureComponent<Props> {
  render() {
    return (
      <div
        className="CustomButtonSmallWrapper"
        style={{
          pointerEvents: this.props.disabled ? "none" : "inherit",
          opacity: this.props.disabled ? 0.5 : 1,
        }}
      >
        <Clickable
          onClick={() => {
            !this.props.disabled && this.props.onClick && this.props.onClick();
          }}
        >
          <button
            className="CustomButtonSmall"
            type="button"
            style={{
              color: this.props.inverted ? "#fff" : this.props.color,
              background: this.props.borderOnly ? "transparent" : this.props.inverted ? this.props.color : "#fff",
              border: this.props.borderOnly ? "1px solid " + this.props.color : "none",
            }}
          >
            <div
              style={{
                opacity: this.props.loading ? "0" : "1",
              }}
            >
              {this.props.text}
            </div>
            {this.props.loading && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <LoadingIcon />
              </div>
            )}
          </button>
        </Clickable>
      </div>
    );
  }
}

export default CustomButtonSmall;
