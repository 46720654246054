import React, { PureComponent } from "react";
import CollapsibleList from "../CustomComponents/CollapsibleList/CollapsibleList";
import CustomSelectSmall from "../CustomComponents/CustomSelectSmall/CustomSelectSmall";
import { formatMoneyAmount, getPhoneURI } from "../HelperFunctions";
import { IPropertyWithUnits, IUnitWithTenants } from "../loadData";

import { getCurrentUserUid } from "../auth";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { MessageUserCallback } from "../App";
import { GetUnitsCallback } from "./ManagePage";
library.add(faCheck);

interface Props {
  property: IPropertyWithUnits;
  manager: { name: string; id: string; phone: string };
  isManager: boolean;
  showing: boolean;
  onMessageUser: MessageUserCallback;
  getUnits: GetUnitsCallback;
}
interface State {
  loading: boolean;
  currentIndex: number;
}

export default class PaymentsPropertyList extends PureComponent<Props, State> {
  CustomSelectSmall: React.RefObject<CustomSelectSmall>;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentIndex: 0,
    };
    this.CustomSelectSmall = React.createRef();
  }

  render() {
    const property = this.props.property;

    const balance = property.allTimeRentOwed - property.rentPaidExcludingOverpayment;

    return (
      <CollapsibleList
        title={`${property.address}, ${property.city}, ${property.state}, ${property.zip_code}`}
        completionString={balance > 0 ? formatMoneyAmount(balance) + " due" : undefined}
        completionColor={balance <= 0 ? "black" : "red"}
      >
        {/* Total amount owed/expected from this property */}
        {property.owner_id === getCurrentUserUid() && (
          <div>
            <div
              style={{
                position: "relative",
                display: "flex",
                boxSizing: "border-box",
                padding: "5px 15px 10px 15px",
                borderBottom: "1px solid #f7f7f7",
                marginBottom: "0px",
                fontSize: "12px",
                fontWeight: 500,
                alignItems: "center",
              }}
            >
              <div>{formatMoneyAmount(property.rentOccupiedUnits)} / month</div>
              {balance <= 0 && (
                <div
                  style={{
                    flexGrow: 1,
                    textAlign: "right",
                    color: "#20bf6b",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </div>
              )}
            </div>
          </div>
        )}
        {
          // Buttons to message or call the property manager
          !this.props.isManager && (
            <div
              style={{
                padding: "5px 0 15px 0",
              }}
            >
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Message " + this.props.manager.name}
                onClick={() => {
                  this.props.onMessageUser({
                    userId: this.props.manager.id,
                    unitId: getUnitForUserAndProperty(getCurrentUserUid(), property).id,
                    propertyId: property.id,
                  });
                }}
              />
              <a
                href={getPhoneURI(this.props.manager.phone)}
                style={{
                  position: "relative",
                  display: "inline-block",
                  color: "#4b7bec",
                  textDecoration: "none",
                }}
              >
                <div style={{ pointerEvents: "none" }}>
                  <CustomButtonSmall
                    color={"#4b7bec"}
                    inverted={false}
                    borderOnly={true}
                    text={"Call " + this.props.manager.name}
                  />
                </div>
              </a>
            </div>
          )
        }
        {this.props.showing && this.props.getUnits(property)}
      </CollapsibleList>
    );
  }
}

const getUnitForUserAndProperty = (userId: string, property: IPropertyWithUnits): IUnitWithTenants =>
  property.units.find((unit) => unit.tenants.find((tenant) => tenant.id === userId) !== undefined);
