import React, { PureComponent } from "react";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import LoadingIcon from "../CustomComponents/LoadingIcon/LoadingIcon";
import Modal from "./Modal";

import axios from "axios";
import firebase from "firebase/app";

import { getCurrentUserUid } from "../auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPassport, faFileUpload, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
library.add(faPassport, faFileUpload, faCheckCircle);

interface Props {
  onUploadDocument: (file: File) => void;
}
interface State {
  loading: boolean;
  uploaded: boolean;
  active: boolean;
}
class DocumentPicker extends PureComponent<Props, State> {
  wrapper = React.createRef<HTMLDivElement>();
  input = React.createRef<HTMLInputElement>();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploaded: false,
      active: false,
    };
  }

  // TODO Raymond
  handleUploadImage(file: File) {
    this.setState({
      uploaded: false,
      loading: true,
    });

    console.log(file);

    setTimeout(() => {
      // let url = 'https://www.ncdot.gov/dmv/license-id/nc-real-id/PublishingImages/standard-id.png';

      this.setState({
        uploaded: true,
        loading: false,
      });

      this.props.onUploadDocument(file);
    }, 1000);
  }

  clear() {
    this.setState({
      loading: false,
      uploaded: false,
      active: false,
    });
  }

  componentDidMount() {
    const dropArea = this.wrapper.current;

    // TODO: Unbind event listeners
    dropArea.addEventListener(
      "dragenter",
      () => {
        this.setState({ active: true });
      },
      false,
    );
    dropArea.addEventListener(
      "dragleave",
      () => {
        this.setState({ active: false });
      },
      false,
    );
    dropArea.addEventListener(
      "dragover",
      () => {
        this.setState({ active: true });
      },
      false,
    );
    dropArea.addEventListener(
      "drop",
      () => {
        this.setState({ active: false });
      },
      false,
    );
  }

  render() {
    let backgroundColor = "#f7f7f7";
    let textColor = "#000";

    if (!this.state.loading) {
      if (this.state.uploaded) {
        backgroundColor = "#20bf6b";
        textColor = "#fff";
      }
      if (this.state.active) {
        backgroundColor = "#4b7bec";
        textColor = "#fff";
      }
    }

    return (
      <div
        ref={this.wrapper}
        style={{
          position: "relative",
          backgroundColor: backgroundColor,
          boxSizing: "border-box",
          color: textColor,
          borderRadius: "5px",
          width: "100%",
          height: "250px",
          marginTop: "25px",
          fontSize: "32px",
          transition: "background-color 80ms, color 80ms",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontSize: "32px",
            }}
          >
            {this.state.loading ? (
              <LoadingIcon />
            ) : (
              <FontAwesomeIcon icon={!this.state.uploaded || this.state.active ? faFileUpload : faCheckCircle} />
            )}
          </div>
          {!this.state.loading && (
            <div
              style={{
                fontSize: "14px",
                marginTop: "10px",
              }}
            >
              {!this.state.uploaded || this.state.active ? "Upload file" : "Upload successful!"}
            </div>
          )}
        </div>
        {/* Input field */}
        <input
          ref={this.input}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            opacity: "0",
            outline: "none",
            border: "none",
            cursor: "pointer",
          }}
          type="file"
          accept="image/*"
          onChange={(e) => {
            const files = e.target.files;
            const file = files[0];
            this.handleUploadImage(file);
          }}
        />
      </div>
    );
  }
}

interface _Props {}
interface _State {
  loading: boolean;
  documentType: number;
  documentUrl: string | null;
  documentImage: File | null;
}

class ModalUploadDocument extends PureComponent<_Props, _State> {
  DocumentPicker = React.createRef<DocumentPicker>();
  SelectDocumentType = React.createRef<CustomSelect>();

  modal = React.createRef<Modal>();
  form = React.createRef<HTMLFormElement>();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      documentType: 0,
      documentUrl: null,
      documentImage: null,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.handleUploadDocument = this.handleUploadDocument.bind(this);
  }

  show() {
    this.DocumentPicker.current.clear();
    // TODO: what is this? vvv
    // this.DocumentPicker.current.state.handleUploadDocument = this.handleUploadDocument;
    this.form.current.reset();
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async handleUploadDocument() {
    const type = ["license", "passport", "idCard", "other"][this.state.documentType];
    const user = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();

    if (!user) {
      console.error("Current user undefined");
    }

    this.setState({ loading: true });

    // TODO: Submit photo ID with URL this.state.documentUrl and type of type to Dwolla for verification

    console.log("upload");

    const form = new FormData();
    form.set("filename", this.state.documentImage.name);
    form.set("fileType", this.state.documentImage.type);
    form.set("userId", user.data().dwolla_customer_id);
    form.set("userType", "customers");
    form.set("documentType", type);
    form.append("file", this.state.documentImage);

    const url = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/uploadDocument`;

    axios
      .post(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        this.hide();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Upload Photo ID"}>
        <form
          ref={this.form}
          onSubmit={(e) => {
            e.preventDefault();
            this.handleUploadDocument();
          }}
        >
          <div className="ModalText">
            Please upload a color scan of government-issued identification in order to verify your identity.
          </div>
          <DocumentPicker
            ref={this.DocumentPicker}
            onUploadDocument={(image) => {
              this.setState({ documentImage: image });
            }}
          />
          <div className="ModalSectionHeader">Document type</div>
          <div className="ModalInputWrapper">
            <CustomSelect
              ref={this.SelectDocumentType}
              options={["License", "Passport", "ID Card", "Other"]}
              icon={faPassport}
              placeholder="Type"
              type={"text"}
              inverted={true}
              readOnly={false}
              onChange={(value) => {
                this.setState({ documentType: value });
              }}
            />
          </div>
          {this.state.documentImage && (
            <div className="ModalButtonWrapper">
              <CustomButton
                text={"Upload Document"}
                inverted={true}
                loading={this.state.loading}
                color={"#4b7bec"}
                submit={true}
              />
            </div>
          )}
        </form>
      </Modal>
    );
  }
}

export default ModalUploadDocument;
