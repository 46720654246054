import React, { PureComponent } from "react";
import ChatPreview from "./ChatPreview";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";
import Clickable from "../../Clickable/Clickable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import "./ChatsMenu.css";
import { Chat } from "../MessagingInterfaces";

interface Props {
  chats: Chat[];
  currentChatId: string;
  narrowView: boolean;
  loading: boolean;
  onShowChat: (chatId: string) => void;
  onShowCreateChat: () => void;
}

interface State {}

class Inbox extends PureComponent<Props, State> {
  getChats() {
    return this.props.chats.map((chat) => {
      return (
        <ChatPreview
          key={"ChatPreview" + chat.id}
          active={chat.id === this.props.currentChatId && !this.props.narrowView}
          members={chat.members}
          previewText={chat.previewText}
          unseenCount={chat.unseenCount}
          isDraft={chat.isDraft}
          onClick={() => {
            this.props.onShowChat(chat.id);
          }}
        />
      );
    });
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        {/* Transparent overlay including loading spinner */}
        {this.props.loading && (
          <div
            style={{
              position: "absolute",
              backgroundColor: "rgba(255, 255, 255, 0.85)",
              width: "100%",
              height: "100%",
              zIndex: 100,
              display: this.props.loading ? "block" : "none",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                fontSize: "24px",
                color: "rgba(0,0,0,0.85)",
              }}
            >
              <LoadingIcon />
            </div>
          </div>
        )}
        {/* Content */}
        <div
          style={{
            boxSizing: "border-box",
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              boxSizing: "border-box",
              padding: "20px 20px 10px 20px",
              position: "relative",
              display: "flex",
              flexGrow: 0,
              flexShrink: 0,
              alignItems: "center",
            }}
          >
            <div
              style={{
                flexGrow: 1,
                fontFamily: '"Roboto Slab", sans-serif',
                fontSize: "20px",
                padding: "0 10px",
              }}
            >
              Inbox
            </div>
            <div className="ChatsMenuPlusContainer">
              <Clickable onClick={this.props.onShowCreateChat}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </Clickable>
            </div>
          </div>
          <div
            style={{
              display: "relative",
              width: "100%",
              boxSizing: "border-box",
              flexGrow: 1,
              overflow: "scroll",
            }}
          >
            {!this.props.loading && this.props.chats.length === 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "14px",
                  color: "rgba(0,0,0,0.85)",
                  display: "flex",
                }}
              >
                Click + to start a new chat
              </div>
            )}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "column",
              }}
            >
              {this.getChats()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inbox;
