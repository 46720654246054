import { Capacitor, Modals, Plugins } from "@capacitor/core";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio";

const { Storage } = Plugins;

/**
 * Attempts to authenticate the user using native
 * biometric authentication.
 *
 * Always returns true on web.
 * Returns true if biometric authentication is not available.
 */
export async function bioAuth(): Promise<boolean> {
  if (!Capacitor.isNative) {
    return true;
  }

  const isAvailable = await FingerprintAIO.isAvailable().catch((e) =>
    console.warn("Bio authentication is not available", e),
  );
  if (!isAvailable) {
    return true;
  }

  let bioAuthStatus = await getBioAuthPreference();

  if (bioAuthStatus === "none") {
    const userRequestsBioAuth = await promptEnroll();
    if (userRequestsBioAuth) {
      const enrolled = await enroll();
      bioAuthStatus = enrolled ? "enrolled" : "declined";
    } else {
      await setBioAuthPreference(false);
      bioAuthStatus = "declined";
    }
  }

  if (bioAuthStatus === "enrolled") {
    return authenticate();
  } else {
    return true;
  }
}

async function authenticate(): Promise<boolean> {
  try {
    await FingerprintAIO.show({
      description: "Authorize payment",
    });
    return true;
  } catch (e) {
    console.error("Payment Bioauth failed", e);
    return false;
  }
}

export function getBioAuthMethodName(availableResp: string): string {
  // Get name of authentication method
  // Used to format request string, e.g. "Would you like to use Touch ID to send payments?"
  let authMethodName = "Biometric Authentication";
  if (Capacitor.platform === "ios") {
    if (availableResp === "finger") authMethodName = "Touch ID";
    if (availableResp === "face") authMethodName = "Face ID";
  } else {
    if (availableResp === "finger") authMethodName = "Fingerprint Authentication";
    if (availableResp === "face") authMethodName = "Face Unlock";
  }
  return authMethodName;
}

async function getBioAuthPreference(): Promise<"enrolled" | "declined" | "none"> {
  const { value: bioAuthPreference } = await Storage.get({ key: "bioAuthPreference" });
  const enrolled: boolean | null = JSON.parse(bioAuthPreference);
  switch (enrolled) {
    case true:
      return "enrolled";
    case false:
      return "declined";
    default:
      return "none";
  }
}

async function promptEnroll(): Promise<boolean> {
  const availableResp = await FingerprintAIO.isAvailable();
  const methodName = getBioAuthMethodName(availableResp);

  const message = `Would you like to use ${methodName} to send payments securely?`;
  // Returns true (confirmed) or false (cancelled)
  const promptRet = await Modals.confirm({
    cancelButtonTitle: "No",
    message: message,
    okButtonTitle: "Yes",
    title: methodName,
  });
  return promptRet.value;
}

async function enroll(): Promise<boolean> {
  try {
    await FingerprintAIO.show({
      description: "Enable payment security",
    });
    await setBioAuthPreference(true);
    return true;
  } catch (e) {
    console.error("Enable Bioauth failed", e);
    return false;
  }
}

async function setBioAuthPreference(preference: boolean) {
  await Storage.set({ key: "bioAuthPreference", value: JSON.stringify(preference) });
}
