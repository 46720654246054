import React, { PureComponent } from "react";
import { Plugins, HapticsNotificationType, Capacitor } from "@capacitor/core";

import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

import { us_states } from "../constants";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBuilding, faMapMarkerAlt, faHomeAlt } from "@fortawesome/pro-solid-svg-icons";
library.add(faBuilding, faMapMarkerAlt, faHomeAlt);

const { Haptics } = Plugins;

const UNIT_LIMIT = 100;

interface Props {
  onRefresh: () => void;
}

interface State {
  input: { unitsAmount: string; zipCode: string; address: string; city: string; state: number };
  errors: { unitsAmount: string; zipCode: string; address: string; city: string; state: string };
  loading: boolean;
  propertyType: number; //TODO enum type
}

class ModalAddProperty extends PureComponent<Props, State> {
  InputAddress = React.createRef<CustomInput>();
  InputCity = React.createRef<CustomInput>();
  InputState = React.createRef<CustomSelect>();
  InputZipCode = React.createRef<CustomInput>();
  InputUnitsAmount = React.createRef<CustomInput>();

  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // Type of property
      // 0: apartments
      // 1: house,
      // 2: storage
      // 3: land
      // 4: room
      // 5: commercial
      propertyType: 0,
      input: {
        address: "",
        city: "",
        state: -1,
        zipCode: "",
        unitsAmount: "",
      },
      errors: {
        address: "",
        city: "",
        state: "",
        zipCode: "",
        unitsAmount: "",
      },
    };

    this.handleAddProperty = this.handleAddProperty.bind(this);

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  clear() {
    this.InputAddress.current.clear();
    this.InputCity.current.clear();
    this.InputZipCode.current.clear();
    this.InputUnitsAmount.current.clear();
  }
  show() {
    this.clear();
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  // Returns true if there are errors, or false if there are not
  updateErrors() {
    let encounteredError = false;
    const errors = this.state.errors;

    const unitsAmount = parseInt(this.state.input.unitsAmount);
    if (isNaN(unitsAmount)) {
      errors.unitsAmount = "Please enter a valid amount of units";
      encounteredError = true;
    }
    if (this.state.input.zipCode === "") {
      errors.zipCode = "Please enter a valid zip code";
      encounteredError = true;
    }
    if (this.state.input.zipCode.length !== 5) {
      errors.zipCode = "Please enter a 5-digit zip code";
      encounteredError = true;
    }
    if (this.state.input.address === "") {
      errors.address = "Please enter a valid address";
      encounteredError = true;
    }
    if (this.state.input.city === "") {
      errors.city = "Please enter a valid city";
      encounteredError = true;
    }

    if (this.state.input.state < 0 || this.state.input.state >= us_states.length) {
      errors.state = "Please select a valid state";
      encounteredError = true;
    }

    if (unitsAmount > UNIT_LIMIT) {
      errors.unitsAmount = `Please contact support to add more than ${UNIT_LIMIT} units`;
      encounteredError = true;
    }
    if (encounteredError) {
      this.setState(
        {
          errors: errors,
          loading: false,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  async handleAddProperty() {
    this.setState({ loading: true });

    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    const unitsAmount = parseInt(this.state.input.unitsAmount);

    await firebase.functions().httpsCallable("addProperty")({
      address: this.state.input.address,
      city: this.state.input.city,
      state: us_states[this.state.input.state],
      zip_code: this.state.input.zipCode,
      num_units: unitsAmount,
    });

    // Success haptic when property created successfully
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });

    this.props.onRefresh();

    firebase.analytics().logEvent("property_created");
    firebase.analytics().setUserProperties({ user_has_property: true });
    this.clear();
    this.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Add New Property"} onShow={() => undefined} onHide={() => undefined}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleAddProperty();
          }}
        >
          {/*
          // TODO: Remove when we store property type
          <div className="ModalSectionHeader">Property information</div>
          <div className="ModalInputWrapper">
            <OptionSwitch
              options={["Apartments", "House", "Storage", "Land", "Room", "Commercial"]}
              onChange={(index) => {
                this.setState({ propertyType: index });
              }}
            />
          </div>
          */}
          <div className="ModalSectionHeader">Property information</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputAddress}
              icon={faMapMarkerAlt}
              placeholder="Address (e.g. 346 Elm Street)"
              type={"text"}
              errorText={this.state.errors.address}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  const errors = state.errors;
                  input.address = text;
                  errors.address = "";
                  return { input: input };
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputCity}
              icon={faMapMarkerAlt}
              placeholder="City (e.g. Madison)"
              type={"text"}
              errorText={this.state.errors.city}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  const errors = state.errors;
                  input.city = text;
                  errors.city = "";
                  return { input: input };
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomSelect
              ref={this.InputState}
              options={us_states}
              icon={faMapMarkerAlt}
              placeholder="State"
              errorText={this.state.errors.state}
              inverted={true}
              readOnly={false}
              onChange={(value) => {
                this.setState((state) => {
                  const input = state.input;
                  const errors = state.errors;
                  input.state = value;
                  errors.state = "";
                  return { input: input };
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputZipCode}
              icon={faMapMarkerAlt}
              placeholder="Zip code (e.g. 57042)"
              type={"tel"}
              errorText={this.state.errors.zipCode}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  const errors = state.errors;
                  input.zipCode = text;
                  errors.zipCode = "";
                  return { input: input };
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalSectionHeader">Units</div>
          <div className="ModalInputWrapper">
            <CustomInput
              ref={this.InputUnitsAmount}
              icon={faHomeAlt}
              placeholder="Amount of units"
              type={"tel"}
              errorText={this.state.errors.unitsAmount}
              inverted={true}
              readOnly={false}
              onChange={(text) => {
                this.setState((state) => {
                  const input = state.input;
                  const errors = state.errors;
                  input.unitsAmount = text;
                  errors.unitsAmount = "";
                  return {
                    input: input,
                    errors: errors,
                  };
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="ModalText">You can always add and remove units from this property later.</div>
          <div className="ModalButtonWrapper">
            <CustomButton
              text="Create Property"
              inverted={true}
              loading={this.state.loading}
              color={"#4b7bec"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalAddProperty;
