import firebase from "firebase/app";
import "firebase/auth";

export const getCurrentUserUid = (): string => {
  if (process.env.REACT_APP_IMPERSONATE_UID) {
    console.warn(`IMPERSONATING USER: ${process.env.REACT_APP_IMPERSONATE_UID}`);
    return process.env.REACT_APP_IMPERSONATE_UID;
  } else {
    return firebase.auth().currentUser.uid;
  }
};

export const IMPERSONATION_ENABLED = !!process.env.REACT_APP_IMPERSONATE_UID;
