import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";
import firebase from "firebase/app";
import "firebase/analytics";

import { getCurrentUserUid } from "../auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBuilding, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
library.add(faBuilding, faMapMarkerAlt);

interface Props {
  onHide: () => void;
}

interface State {
  loading: boolean;
  id: string;
  name: string;
}

class ModalRemoveFundingSource extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // Passed data
      id: undefined,
      name: undefined,
    };

    this.handleRemoveFundingSource = this.handleRemoveFundingSource.bind(this);
  }

  show(id: string, name: string) {
    this.setState({
      id: id,
      name: name,
    });
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
    this.props.onHide();
  }

  async handleRemoveFundingSource() {
    this.setState({ loading: true });

    // Remove funding source with ID this.state.id
    const removeFundingSource = firebase.functions().httpsCallable("removeFundingSource");
    await removeFundingSource({ fundingSourceId: this.state.id });

    const currentUser = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();
    if (currentUser.data().primary_funding_source_id === this.state.id) {
      await currentUser.ref.update({ primary_funding_source_id: null });
    }

    // TODO: Iterate through all of this user's properties
    // For any properties using this.state.id as their funding source ID, update ID to be null

    firebase.analytics().logEvent("bank_account_removed");
    firebase.analytics().setUserProperties({ bank_account_removed: true });
    this.hide();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Remove Account?"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleRemoveFundingSource();
          }}
        >
          <div className="ModalText">Are you sure you want to remove the account "{this.state.name}"?</div>
          <div className="ModalButtonWrapper">
            <CustomButton
              text={"Remove Account"}
              inverted={true}
              loading={this.state.loading}
              color={"#eb3b5a"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalRemoveFundingSource;
