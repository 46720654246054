import React, { PureComponent } from "react";

import Clickable from "../../Clickable/Clickable";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import CustomInput from "../../CustomComponents/CustomInput/CustomInput";

// Firebase and input validation
import validator from "validator";
import firebase from "firebase/app";
import "firebase/auth";

import "../AuthenticationWindow.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";

library.add(faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck);

interface Props {
  showing: boolean;
  genericError: boolean;
  onShowForgotPassword: () => void;
  onShowRegister: () => void;
}
interface State {
  loading: boolean;
  input: { email: string; password: string };
  errors: { email: string; password: string };
}
class Login extends PureComponent<Props, State> {
  InputEmail = React.createRef<CustomInput>();
  InputPassword = React.createRef<CustomInput>();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      input: {
        email: "",
        password: "",
      },
      errors: {
        email: "",
        password: "",
      },
    };
    this.handleLogin = this.handleLogin.bind(this);
  }

  clear() {
    this.InputEmail.current.clear();
    this.InputPassword.current.clear();
    this.setState({
      errors: {
        email: "",
        password: "",
      },
    });
  }

  updateErrors() {
    let encounteredError = false;
    const errors = this.state.errors;
    const input = this.state.input;

    const email = input.email.trim();

    if (!validator.isEmail(email)) {
      errors.email = "Please enter a valid email address";
      encounteredError = true;
    }

    if (encounteredError) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  // Handle email login
  handleLogin() {
    this.setState({ loading: true });

    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    const input = this.state.input;
    const email = input.email.trim();
    const password = input.password.trim();

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (user) => {
        if (!user.user.emailVerified) {
          user.user.sendEmailVerification();
          await firebase.auth().signOut();
          throw new Error("email-not-verified");
        }
      })
      .catch((error: firebase.auth.AuthError) => {
        const errors = this.state.errors;
        if (error.message === "email-not-verified") {
          errors.email = "Please check your inbox to verify this email and try again.";
        } else {
          switch (error.code) {
            //set error depending on code
            case "auth/invalid-email":
              errors.password = "Please enter a valid email address";
              break;
            case "auth/wrong-password":
              errors.password = "The email address or password you entered is incorrect";
              break;
            case "auth/user-not-found":
              errors.email = "We couldn't find an account with this email address";
              break;
            case "auth/too-many-requests":
              errors.password = "Too many requests. Please try again later, or reset your password.";
              break;
            case "auth/account-exists-with-different-credential":
              errors.email =
                "You already have an account with a different sign in method. Please use the original sign in method.";
              break;
            case "auth/user-disabled":
              errors.password = "Thank you for signing up. You may sign in once your account has been activated.";
              break;
            default:
              console.log("Unhandled error found", error, error.code);
              errors.password = "Oops! Something went wrong. Please try again later.";
              break;
          }
        }
        // Update errors
        this.setState(
          {
            loading: false,
            errors: errors,
          },
          this.forceUpdate,
        );
      });
  }

  render() {
    return (
      <div
        style={{
          position: this.props.showing ? "relative" : "absolute",
          width: "100%",
          boxSizing: "border-box",
          zIndex: this.props.showing ? 1 : 0,
          transform: "translateY(" + (this.props.showing ? "0" : "10px") + ")",
          opacity: this.props.showing ? "1" : "0",
          padding: "25px 10px",
          transition: "transform 80ms, opacity 80ms",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleLogin();
          }}
        >
          <div className="AuthenticationTitle">Sign in</div>
          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputEmail}
              icon={faEnvelope}
              placeholder="Email"
              errorText={this.state.errors.email}
              type={"email"}
              name={"email"}
              autoComplete={"email"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.email = text;
                  state.errors.email = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>
          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputPassword}
              icon={faLock}
              placeholder="Password"
              errorText={this.state.errors.password}
              type={"password"}
              name={"password"}
              autoComplete={"current-password"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.password = text;
                  state.errors.password = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>

          {this.props.genericError && (
            <div className="AuthenticationTextWrapper">
              <span className="AuthenticationTextBold">{this.props.genericError}</span>
            </div>
          )}

          <div className="AuthenticationButtonWrapper">
            <CustomButton text="Sign In" inverted={true} color={"#2A64AD"} loading={this.state.loading} submit={true} />
          </div>
        </form>

        <div className="AuthenticationTextWrapper">
          <Clickable onClick={this.props.onShowForgotPassword}>
            <span className="AuthenticationTextBold">Forgot password?</span>
          </Clickable>
        </div>
        <div className="AuthenticationTextWrapper">
          <Clickable onClick={this.props.onShowRegister}>
            <span className="AuthenticationTextLight">Don't have an account?&nbsp;</span>
            <span className="AuthenticationTextBold">Sign up!</span>
          </Clickable>
        </div>
      </div>
    );
  }
}

export default Login;
