import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import CurrencyInput from "../CurrencyInput/CurrencyInput";
import "./CustomCurrencyInput.css";

// Font Awesome
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
library.add(faTimes);

interface Props {
  inverted: boolean;
  readOnly?: boolean;
  errorText?: string;
  icon: IconProp;
  placeholder?: number;
  onChange: (x: number) => any;
}
interface State {
  focused: boolean;
  amount: number;
}

class CustomCurrencyInput extends PureComponent<Props, State> {
  CurrencyInput = React.createRef<CurrencyInput>();
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      amount: 0,
    };
  }

  setValue(value) {
    this.CurrencyInput.current.setValue(value);
  }

  clear() {
    this.CurrencyInput.current.clear();
  }

  render() {
    const errorColor = this.props.inverted ? "rgba(0,0,0,0.85)" : "rgba(255,255,255,0.85)";
    let backgroundColor = this.props.inverted ? "#f7f7f7" : "rgba(255,255,255,0.05)";
    if (this.props.readOnly) backgroundColor = "transparent";

    return (
      <div>
        <div
          className="CustomInput"
          style={{
            border: this.props.errorText ? "1px solid " + errorColor : "none",
            background: backgroundColor,
            color: this.props.inverted ? "#000" : "#fff",
            transition: "background 200ms",
          }}
        >
          <div className="CustomInputIconWrapper">
            <div className="CustomInputIconContainer">
              <FontAwesomeIcon icon={this.props.icon} />
            </div>
          </div>
          <CurrencyInput
            ref={this.CurrencyInput}
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly}
            style={{
              color: this.props.inverted ? "#000" : "#fff",
            }}
            className={"CustomInputField" + (this.props.inverted ? " Inverted" : "")}
            // Giving a small input size forces input to become smaller when screen resizess
            size={1}
            onChange={(amount) => {
              this.setState({ amount: amount });
              if (typeof this.props.onChange === "function") {
                this.props.onChange(amount);
              }
            }}
            onFocus={() => {
              this.setState({ focused: true });
            }}
            onBlur={() => {
              this.setState({ focused: false });
            }}
          />
          {/* Clear button */}
          {!this.props.readOnly && (
            <div
              style={{
                width: "40px",
                height: "40px",
                flexShrink: 0,
                borderRadius: "0 100px 100px 0",
                overflow: "hidden",
                boxSizing: "border-box",
                opacity: this.state.focused && this.state.amount ? "1" : "0",
                transition: "opacity 200ms",
              }}
            >
              <Clickable
                onClick={() => {
                  this.clear();
                  this.CurrencyInput.current.focus();
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    transform: "translateX(" + (this.state.focused && this.state.amount ? "0px" : "20px") + ")",
                    transition: "transform 200ms",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      fontSize: "16px",
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                </div>
              </Clickable>
            </div>
          )}
        </div>
        {this.props.errorText && (
          <div
            className="CustomInputErrorText"
            style={{
              color: this.props.inverted ? "#000" : "#fff",
            }}
          >
            {this.props.errorText}
          </div>
        )}
      </div>
    );
  }
}

export default CustomCurrencyInput;
