import React from "react";

import LoadingIcon from "../LoadingIcon/LoadingIcon";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
library.add(faRedoAlt);

interface Props {
  loading: boolean;
  onClick: () => void;
}

const CustomRefreshButton = ({ loading, onClick }: Props) => (
  <div>{loading ? <LoadingIcon /> : <FontAwesomeIcon icon={faRedoAlt} onClick={onClick} />}</div>
);

export default CustomRefreshButton;
