import React, { PureComponent } from "react";
import { getPhoneURI } from "../HelperFunctions";
import CollapsibleList from "../CustomComponents/CollapsibleList/CollapsibleList";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import ManageUnitPreview from "./ManageUnitPreview";
import ManageUserPreview from "./ManageUserPreview";
import ManageWorkOrder from "./ManageWorkOrder";
import "./ManagePage.css";
import { getCurrentUserUid } from "../auth";

import {
  IPropertyWithUnits,
  IRentingwayUserWithTransfersWorkOrders,
  ITenantWithUser,
  IUnitWithTenants,
  IWorkOrderWithPeer,
} from "../loadData";
import CustomRefreshButton from "../CustomComponents/CustomRefreshButton/CustomRefreshButton";
import { MessageUserCallback, RequestRentInfoCallback, UpdateWorkOrderStatusCallback } from "../App";

interface Props {
  showing: boolean;
  loading: boolean;
  properties: IPropertyWithUnits[];
  otherUsers: IRentingwayUserWithTransfersWorkOrders[];
  onUpdateWorkOrderStatus: UpdateWorkOrderStatusCallback;
  onViewImage: (imageUrl: string) => void;
  onMessageUser: MessageUserCallback;
  onRefresh: () => void;
  onRequestRentInfo: RequestRentInfoCallback;
}

interface State {
  // Type of sorting for work orders
  // 0: By property/unit/tenant
  // 1: Full work order history
  sortingType: number;
}

class WorkOrdersSection extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      sortingType: 0,
    };
  }
  getWorkOrders(tenant: ITenantWithUser, isManager) {
    return tenant.user.workOrders.map((workOrder) => {
      return (
        <ManageWorkOrder
          key={"work-orders-" + workOrder.id}
          subject={workOrder.subject}
          text={workOrder.text}
          open={workOrder.open}
          imageUrls={workOrder.image_urls}
          timestamp={workOrder.timestamp}
          isManager={isManager}
          onUpdateWorkOrderStatus={(newStatus) => {
            this.props.onUpdateWorkOrderStatus(workOrder.id, newStatus);
          }}
          onViewImage={(imageUrl) => {
            this.props.onViewImage(imageUrl);
          }}
        />
      );
    });
  }

  getTenants(property: IPropertyWithUnits, unit: IUnitWithTenants) {
    return unit.tenants.map((tenant) => {
      // No work orders tied to this tenant
      if (tenant.user.workOrders === undefined || tenant.user.workOrders.length === 0) return undefined;

      const openWorkOrders = tenant.user.workOrders.filter((x) => x.open).length;
      const tenantIsSelf = tenant.id === getCurrentUserUid();

      return (
        <ManageUserPreview
          key={"work-orders-" + tenant.id}
          id={tenant.id}
          // Show this tenant's work orders if they have any open
          initiallyShowing={openWorkOrders > 0}
          text={openWorkOrders > 0 ? openWorkOrders + " open" : undefined}
          name={tenant.user.full_name}
          imageUrl={tenant.user.profile_pic_url}
          isSelf={tenantIsSelf}
          hasContent={!tenantIsSelf || tenant.user.workOrders.length > 0}
        >
          {!tenantIsSelf && (
            <div
              style={{
                padding: "5px 5px",
              }}
            >
              <CustomButtonSmall
                color={"#4b7bec"}
                inverted={false}
                borderOnly={true}
                text={"Message"}
                onClick={() => {
                  this.props.onMessageUser({ userId: tenant.id, unitId: unit.id, propertyId: property.id });
                }}
              />
              <a
                href={getPhoneURI(tenant.user.phone)}
                style={{
                  position: "relative",
                  display: "inline-block",
                  color: "#4b7bec",
                  textDecoration: "none",
                }}
              >
                <div style={{ pointerEvents: "none" }}>
                  <CustomButtonSmall color={"#4b7bec"} inverted={false} borderOnly={true} text={"Call"} />
                </div>
              </a>
            </div>
          )}
          {this.getWorkOrders(tenant, property.owner_id === getCurrentUserUid())}
        </ManageUserPreview>
      );
    });
  }

  getUnits(property: IPropertyWithUnits) {
    return property.units.map((unit) => {
      const workOrders = unit.tenants.reduce((count, tenant) => count + tenant.user.workOrders.length, 0);
      // If no tenants in this unit have work orders, don't display this unit
      if (workOrders === 0) return undefined;

      return (
        <ManageUnitPreview
          key={"work-orders-unit-" + unit.id}
          unit={unit}
          showRent={false}
          onRequestRentInfo={this.props.onRequestRentInfo}
        >
          {this.getTenants(property, unit)}
        </ManageUnitPreview>
      );
    });
  }

  getProperties() {
    return this.props.properties.map((property, index) => {
      let workOrders = 0;
      let workOrdersOpen = 0;

      // For each unit in this property
      for (let i = 0; i < property.units.length; i++) {
        const unit = property.units[i];
        // For each tenant in this unit
        for (let j = 0; j < unit.tenants.length; j++) {
          const tenant = unit.tenants[j];
          // For each work order by this tenant
          if (tenant.user.workOrders !== undefined) {
            for (let k = 0; k < tenant.user.workOrders.length; k++) {
              const workOrder = tenant.user.workOrders[k];
              workOrders++;
              if (workOrder.open) workOrdersOpen++;
            }
          }
        }
      }

      if (workOrders === 0) return undefined;

      return (
        <CollapsibleList
          key={index}
          title={property.address} //TODO Full name
          // Initially collapse list if there are no open work orders
          initiallyCollapsed={true}
          completionString={workOrdersOpen + " open"}
          completionColor={workOrdersOpen > 0 ? "red" : "black"}
        >
          {
            // Buttons to message or call the property manager
            !(property.owner_id === getCurrentUserUid()) && (
              <div
                style={{
                  padding: "5px 0 15px 0",
                }}
              >
                <CustomButtonSmall
                  color={"#4b7bec"}
                  inverted={false}
                  borderOnly={true}
                  text={"Message " + property.owner.full_name}
                  onClick={() => {
                    this.props.onMessageUser({ userId: property.owner_id, unitId: undefined, propertyId: property.id });
                  }}
                />
                <a
                  href={getPhoneURI(property.owner.phone)}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    color: "#4b7bec",
                    textDecoration: "none",
                  }}
                >
                  <div style={{ pointerEvents: "none" }}>
                    <CustomButtonSmall
                      color={"#4b7bec"}
                      inverted={false}
                      borderOnly={true}
                      text={"Call " + property.owner.full_name}
                    />
                  </div>
                </a>
              </div>
            )
          }
          {this.props.showing && this.getUnits(property)}
        </CollapsibleList>
      );
    });
  }

  getOtherWorkOrders() {
    return this.props.otherUsers.map((user) => {
      const openWorkOrders = user.workOrders.filter((order) => order.open).length;
      return (
        <ManageUserPreview
          key={"other-work-orders-" + user.id}
          id={user.id}
          initiallyShowing={openWorkOrders > 0}
          text={openWorkOrders > 0 ? openWorkOrders + " open" : undefined}
          name={user.full_name}
          imageUrl={user.profile_pic_url}
          isSelf={false}
          hasContent={true}
        >
          <div
            style={{
              padding: "5px 5px",
            }}
          >
            <CustomButtonSmall
              color={"#4b7bec"}
              inverted={false}
              borderOnly={true}
              text={"Message"}
              onClick={() => {
                this.props.onMessageUser({ userId: user.id, propertyId: undefined, unitId: undefined });
              }}
            />
            <a
              href={getPhoneURI(user.phone)}
              style={{
                position: "relative",
                display: "inline-block",
                color: "#4b7bec",
                textDecoration: "none",
              }}
            >
              <div style={{ pointerEvents: "none" }}>
                <CustomButtonSmall color={"#4b7bec"} inverted={false} borderOnly={true} text={"Call"} />
              </div>
            </a>
          </div>
          {this.getTenantOtherWorkOrders(user.workOrders)}
        </ManageUserPreview>
      );
    });
  }

  getTenantOtherWorkOrders(workOrders: IWorkOrderWithPeer[]) {
    return workOrders
      .sort((a, b) => {
        return b.timestamp - a.timestamp;
      })
      .map((workOrder) => {
        return (
          <ManageWorkOrder
            key={"work-orders-" + workOrder.id}
            subject={workOrder.subject}
            text={workOrder.text}
            open={workOrder.open}
            imageUrls={workOrder.image_urls}
            timestamp={workOrder.timestamp}
            isManager={true}
            onUpdateWorkOrderStatus={(newStatus) => {
              this.props.onUpdateWorkOrderStatus(workOrder.id, newStatus);
            }}
            onViewImage={(imageUrl) => {
              this.props.onViewImage(imageUrl);
            }}
          />
        );
      });
  }

  getOtherSection() {
    if (this.props.otherUsers.length === 0) {
      return <div></div>;
    }
    const openWorkOrders = this.props.otherUsers.reduce(
      (currentTotal, user) => currentTotal + user.workOrders.filter((workOrder) => workOrder.open).length,
      0,
    );
    return (
      <CollapsibleList
        key={"other-work-orders-section"}
        title={"Other"}
        // Collapse work orders from others if there are none open
        initiallyCollapsed={true}
        completionString={openWorkOrders + " open"}
        completionColor={openWorkOrders > 0 ? "red" : "black"}
      >
        {this.props.showing && this.getOtherWorkOrders()}
      </CollapsibleList>
    );
  }

  render() {
    const hasWorkOrders =
      this.props.properties.reduce(
        (val, property) =>
          val ||
          property.units.reduce(
            (val, unit) => val || unit.tenants.reduce((val, tenant) => val || tenant.user.workOrders.length > 0, false),
            false,
          ),
        false,
      ) || this.props.otherUsers.reduce((val, user) => val || user.workOrders.length > 0, false);

    return (
      <div
        style={{
          top: "0",
          zIndex: this.props.showing ? 1 : -1,
          position: this.props.showing ? "relative" : "absolute",
          height: this.props.showing ? "auto" : "0",
          overflow: "hidden",
          width: "100%",
          opacity: this.props.showing ? "1" : "0",
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          pointerEvents: this.props.showing ? "auto" : "none",
          transition: "opacity 200ms, transform 200ms",
        }}
      >
        <div style={{ marginBottom: "30px" }}>
          <div />
          <div style={{ float: "right" }}>
            <CustomRefreshButton loading={this.props.loading} onClick={this.props.onRefresh} />
          </div>
        </div>
        {this.state.sortingType === 0 && this.getProperties()}
        {this.state.sortingType === 0 && this.getOtherSection()}
        {
          // No work orders to display
          !hasWorkOrders && (
            <div
              style={{
                fontSize: "14px",
                color: "rgba(0,0,0,0.85)",
                marginBottom: "25px",
                textAlign: "center",
              }}
            >
              Work orders created in Messages will show up here.
            </div>
          )
        }
      </div>
    );
  }
}

export default WorkOrdersSection;
