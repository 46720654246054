import React, { PureComponent } from "react";

import SendButton from "../../CustomComponents/SendButton/SendButton";
import ImagePicker from "./ImagePicker";
import { Member } from "../MessagingInterfaces";
import "./Chat.css";

interface Props {
  members: Member[];
  showing: boolean;
  onSend: (imageUrls: string[]) => void;
  onViewImage: (imageUrl: string) => void;
}

interface State {
  imageUrls: string[];
}

class ImageInput extends PureComponent<Props, State> {
  ImagePicker: React.RefObject<ImagePicker>;
  constructor(props) {
    super(props);
    this.state = {
      imageUrls: [],
    };
    this.handleSend = this.handleSend.bind(this);
    this.ImagePicker = React.createRef();
  }

  clear() {
    this.ImagePicker.current.clear();
  }

  handleSend() {
    if (this.state.imageUrls.length > 0) {
      this.props.onSend(this.state.imageUrls);
    }
    this.clear();
  }

  render() {
    const otherMembersExist = this.props.members && this.props.members.length > 0;
    return (
      <div
        style={{
          width: "100%",
          boxSizing: "border-box",
          position: this.props.showing ? "relative" : "absolute",
          pointerEvents: this.props.showing ? "auto" : "none",
          opacity: this.props.showing ? "1" : "0",
          zIndex: this.props.showing ? 0 : -1,
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          transition: "opacity 200ms, transform 200ms",
          display: "flex",
          padding: "0 10px",
        }}
      >
        <ImagePicker
          ref={this.ImagePicker}
          onUpdate={(imageUrls) => {
            this.setState({ imageUrls: imageUrls });
            this.forceUpdate();
          }}
          onViewImage={(imageUrl) => {
            this.props.onViewImage(imageUrl);
          }}
        />
        {/* Send button */}
        <SendButton active={this.state.imageUrls.length > 0 && otherMembersExist} onSend={this.handleSend} />
      </div>
    );
  }
}

export default ImageInput;
