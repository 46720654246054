import React, { PureComponent } from "react";
import "./CustomSelect.css";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  defaultValue?: number;
  options: string[];
  inverted: boolean;
  readOnly: boolean;
  errorText?: string;
  type?: string;
  icon: IconProp;
  placeholder: string;
  onChange: (x: number) => any;
}
interface State {
  value: number;
}
class CustomSelect extends PureComponent<Props, State> {
  input = React.createRef<HTMLSelectElement>();
  constructor(props) {
    super(props);
    this.state = {
      value: typeof this.props.defaultValue === "number" ? this.props.defaultValue : -1,
    };
  }

  clear() {
    const clearVal = this.props.defaultValue !== undefined ? this.props.defaultValue : -1;
    this.setState({ value: clearVal });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(clearVal);
    }
  }

  setValue(value) {
    this.setState((state) => {
      if (value !== state.value) this.props.onChange(value);
      return { value: value };
    });
  }

  getOptions() {
    return this.props.options.map((option, index) => {
      return (
        <option key={index} value={index}>
          {option}
        </option>
      );
    });
  }

  render() {
    const errorColor = this.props.inverted ? "rgba(0,0,0,0.85)" : "rgba(255,255,255,0.85)";
    let backgroundColor = this.props.inverted ? "#f7f7f7" : "rgba(255,255,255,0.05)";
    if (this.props.readOnly) backgroundColor = "transparent";

    return (
      <div>
        <div
          className="CustomSelect"
          style={{
            border: this.props.errorText ? "1px solid " + errorColor : "none",
            background: backgroundColor,
            color: this.props.inverted ? "#000" : "#fff",
            transition: "background 200ms",
          }}
        >
          <div className="CustomSelectIconWrapper">
            <div className="CustomSelectIconContainer">
              <FontAwesomeIcon icon={this.props.icon} />
            </div>
          </div>
          <div
            style={{
              position: "relative",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <select
              ref={this.input}
              value={this.state.value}
              className="CustomSelectField"
              style={{
                color: this.props.inverted ? "#000" : "#fff",
                opacity: this.state.value === -1 ? "0.5" : "1",
              }}
              onChange={(e) => {
                this.setValue(parseInt(e.target.value));
              }}
            >
              <option value={-1} disabled>
                {this.props.placeholder}
              </option>
              {this.getOptions()}
            </select>
          </div>
        </div>
        {this.props.errorText && (
          <div
            className="CustomSelectErrorText"
            style={{
              color: this.props.inverted ? "#000" : "#fff",
            }}
          >
            {this.props.errorText}
          </div>
        )}
      </div>
    );
  }
}

export default CustomSelect;
