import React from "react";
import { ReactComponent as Logo } from "../assets/logo_white.svg";
import LoadingIcon from "../CustomComponents/LoadingIcon/LoadingIcon";
import "./PendingAuthOverlay.css";

export interface Props {
  showing: boolean;
}

const PendingAuthOverlay: React.FC<Props> = (props) => {
  // Size of the logo, based on viewport dimensions
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  const dim = Math.max(w, h) * 0.09;

  return (
    <div
      className="PendingAuthOverlayWrapper"
      style={{
        top: props.showing ? "0" : "-100%",
        pointerEvents: props.showing ? "auto" : "none",
        touchAction: props.showing ? "auto" : "none",
        opacity: props.showing ? 1 : 0,
        transition: "opacity 300ms ease 200ms, top 0ms linear " + (props.showing ? "0" : "500") + "ms",
      }}
    >
      <Logo
        className="PendingAuthOverlayLogo"
        style={{
          willChange: "transform",
          width: dim + "px",
          height: dim + "px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform:
            "translate(-50%, -50%) scale(" +
            (props.showing ? 1 : 0) +
            ") rotate(" +
            (props.showing ? "0" : "90") +
            "deg)",
          transition: "transform 300ms ease 200ms",
        }}
      />
      {/* Loading spinner */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          fontSize: Math.round(dim * 0.25) + "px",
          color: "#fff",
          paddingTop: dim * 0.95 + "px",
          transform: "translateX(-50%) scale(" + (props.showing ? 1 : 0) + ")",
          transition: "transform 300ms ease 200ms",
        }}
      >
        <LoadingIcon />
      </div>
    </div>
  );
};

export default PendingAuthOverlay;
