import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import { formatUserList } from "../../HelperFunctions";
import "./ChatsMenu.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/pro-solid-svg-icons";
import ProfilePhoto from "../../CustomComponents/ProfilePhoto/ProfilePhoto";
import { Member } from "../MessagingInterfaces";
library.add(faEdit);

interface Props {
  members: Member[];
  active: boolean;
  isDraft: boolean;
  unseenCount: number;
  previewText: string;
  onClick: () => void;
}

interface State {}

class ChatPreview extends PureComponent<Props, State> {
  render() {
    const names = this.props.members.map((member) => member.name);

    return (
      <Clickable onClick={this.props.onClick}>
        <div className="ChatsMenuItem">
          <div
            style={{
              position: "absolute",
              width: this.props.active ? "100%" : "0",
              right: "0",
              height: "100%",
              backgroundColor: "#F7F8FA",
              transition: "width 200ms",
            }}
          ></div>
          <div
            style={{
              position: "relative",
              display: "flex",
              padding: "0 15px",
            }}
          >
            {/* Member image(s) */}
            <div
              style={{
                width: "80px",
                height: "80px",
                flexShrink: 0,
                position: "relative",
              }}
            >
              {/* Single member image (one-on-one chat) */}
              {this.props.members.length === 1 && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "60px",
                      height: "60px",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      border: "3px solid " + (this.props.active ? "#F7F8FA" : "#fff"),
                      borderRadius: "50%",
                    }}
                  >
                    <ProfilePhoto
                      id={this.props.members[0].id}
                      name={this.props.members[0].name}
                      imageUrl={this.props.members[0].imageUrl}
                      size={60}
                    />
                  </div>
                </div>
              )}
              {/* Two member images (group chat) */}
              {this.props.members.length >= 2 && (
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "40px",
                      height: "40px",
                      top: "40%",
                      left: "40%",
                      transform: "translate(-50%, -50%)",
                      border: "3px solid " + (this.props.active ? "#F7F8FA" : "#fff"),
                      borderRadius: "50%",
                    }}
                  >
                    <ProfilePhoto
                      id={this.props.members[0].id}
                      name={this.props.members[0].name}
                      imageUrl={this.props.members[0].imageUrl}
                      size={40}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      width: "40px",
                      height: "40px",
                      top: "60%",
                      left: "60%",
                      transform: "translate(-50%, -50%)",
                      border: "3px solid " + (this.props.active ? "#F7F8FA" : "#fff"),
                      borderRadius: "50%",
                    }}
                  >
                    <ProfilePhoto
                      id={this.props.members[1].id}
                      name={this.props.members[1].name}
                      imageUrl={this.props.members[1].imageUrl}
                      size={40}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Member names and preview text */}
            <div
              style={{
                flexGrow: 1,
                overflow: "hidden",
                padding: "10px 15px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    fontWeight: this.props.active ? 500 : 400,
                    color: this.props.active ? "#3867d6" : "rgba(0,0,0,0.85)",
                    transition: "color 200ms",
                    fontSize: "18px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginBottom: "3px",
                    flexGrow: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {this.props.isDraft && (
                    <div
                      style={{
                        fontSize: "16px",
                        marginRight: "10px",
                        opacity: "0.5",
                      }}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  )}
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formatUserList(names)}
                  </div>
                </div>
                <div
                  style={{
                    padding: "3px 12px",
                    borderRadius: "100px",
                    position: "relative",
                    fontSize: "12px",
                    color: "#fff",
                    backgroundColor: "#eb3b5a",
                    transform: "translateX(" + (this.props.unseenCount > 0 ? "0" : "20px") + ")",
                    opacity: this.props.unseenCount > 0 ? "1" : "0",
                    width: this.props.unseenCount > 0 ? "auto" : "0",
                    transition: "transform 200ms, opacity 200ms",
                  }}
                >
                  {this.props.unseenCount}
                </div>
              </div>
              <div
                style={{
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  color: "#8E8E93",
                }}
              >
                {this.props.previewText}
              </div>
            </div>
          </div>
        </div>
      </Clickable>
    );
  }
}

export default ChatPreview;
