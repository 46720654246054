import React, { PureComponent } from "react";

import CustomButton from "../../CustomComponents/CustomButton/CustomButton";

import "../AuthenticationWindow.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
library.add(faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck);

interface Props {
  showing: boolean;
  onShowLogin: () => void;
}
interface State {}

class ForgotPasswordConfirmation extends PureComponent<Props, State> {
  render() {
    return (
      <div
        style={{
          position: this.props.showing ? "relative" : "absolute",
          width: "100%",
          boxSizing: "border-box",
          zIndex: this.props.showing ? 1 : 0,
          transform: "translateY(" + (this.props.showing ? "0" : "10px") + ")",
          opacity: this.props.showing ? "1" : "0",
          padding: "25px 10px",
          transition: "transform 80ms, opacity 80ms",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.props.onShowLogin();
          }}
        >
          <div className="AuthenticationTitle">Email Sent</div>

          <div className="AuthenticationTextWrapper">
            <span className="AuthenticationText">
              We sent you a link to reset your password. Please check your inbox and follow the provided link.
            </span>
          </div>

          <div></div>

          <div className="AuthenticationButtonWrapper">
            <CustomButton text="Sign In" inverted={true} color={"#2A64AD"} loading={false} submit={true} />
          </div>
        </form>
      </div>
    );
  }
}

export default ForgotPasswordConfirmation;
