import React, { PureComponent } from "react";

import image1 from "../assets/images/app_installation.png";
import image2 from "../assets/images/referral.png";
import image3 from "../assets/images/coming_home.png";

class GettingStarted extends PureComponent {
  render() {
    return (
      <div>
        <div className="LandingGettingStartedBlurb AllowTextSelection">
          The free social network built for rental management
        </div>
        <div className="LandingGettingStartedContainer">
          <div className="LandingGettingStartedItem">
            <div className="LandingGettingStartedImageWrapper">
              <img className="LandingGettingStartedImage" alt="Download the app" src={image1} />
            </div>
            <div className="LandingGettingStartedText">Download the app</div>
          </div>
          <div className="LandingGettingStartedItem">
            <div className="LandingGettingStartedImageWrapper">
              <img className="LandingGettingStartedImage" alt="Create a free account" src={image2} />
            </div>
            <div className="LandingGettingStartedText">Create a free account</div>
          </div>
          <div className="LandingGettingStartedItem">
            <div className="LandingGettingStartedImageWrapper">
              <img className="LandingGettingStartedImage" alt="Connect and start renting" src={image3} />
            </div>
            <div className="LandingGettingStartedText">Connect and start renting</div>
          </div>
        </div>
      </div>
    );
  }
}

export default GettingStarted;
