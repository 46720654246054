import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";
import { formatDateString, formatMoneyAmount, formatMonthString, formatTimeString } from "../../HelperFunctions";
import emojiRegex from "emoji-regex";
import "./Chat.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave, faTools, faRedoAlt, faArrowRight, faHome } from "@fortawesome/pro-solid-svg-icons";
library.add(faMoneyBillWave, faTools, faRedoAlt, faArrowRight, faHome);

interface Props {
  // Metadata
  temporary?: boolean;
  sent: boolean;
  timestamp: number;
  showTimestamp: boolean;
  senderName: string;
  recipientName: string;
  showSenderName: boolean;
  type: "text" | "image" | "transaction" | "transaction_request" | "work_order" | "invitation" | "status";
  // Payments
  status?: "processed" | "pending" | "failed" | "cancelled";
  paymentDescription?: string;
  paymentType?: "rent" | "other";
  recurring?: boolean;
  processing?: boolean;
  recurringString?: "monthly" | "bi-weekly" | "weekly";
  // Appearance
  firstInBlock: boolean;
  lastInBlock: boolean;
  // Content
  text?: string;
  loading?: boolean;
  imageUrl?: string;
  amount?: number;
  subject?: string;
  workOrderImageUrls?: string[];
  // Invitations
  invitationNotes?: string;
  invitationState?: "pending" | "declined" | "accepted";
  invitationUnitName?: string;
  invitationPropertyName?: string;
  invitationRefundableAmount?: number;
  invitationNonRefundableAmount?: number;
  invitationMoveInDate?: number;
  invitationRentAmount?: number;
  invitationFirstMonth?: number;
  onAcceptInvitation?: () => void;
  onDeclineInvitation?: () => void;
  // Other functions
  onViewImage: (imageUrl: string) => void;
}

interface State {
  loading: boolean;
}

class Message extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(nextProps) {
    if (nextProps.invitationState !== "pending") {
      this.setState({ loading: false });
    }
  }

  getWorkOrderImages() {
    return this.props.workOrderImageUrls.map((imageUrl, index) => {
      return (
        <div
          key={index}
          style={{
            position: "relative",
            width: "95px",
            height: "95px",
            flexGrow: 1,
            borderRadius: "12px",
            backgroundColor: "rgba(255,255,255,0.2)",
            marginRight: "5px",
            marginBottom: "5px",
            backgroundImage: "url(" + imageUrl + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          {/* Click to enlarge image */}
          <Clickable
            onClick={() => {
              this.props.onViewImage(imageUrl);
            }}
          ></Clickable>
        </div>
      );
    });
  }

  render() {
    let headerIcon, headerText;
    if (this.props.type === "transaction") {
      headerIcon = faMoneyBillWave;
      headerText = "Payment";

      if (this.props.status === "failed" || this.props.status === "cancelled") {
        headerText = "Payment Failed";
      }
    }
    if (this.props.type === "transaction_request") {
      headerIcon = faMoneyBillWave;
    }
    if (this.props.type === "transaction_request") {
      headerIcon = faMoneyBillWave;
      headerText = "Payment Request";
    }
    if (this.props.type === "work_order") {
      headerIcon = faTools;
      headerText = "Work Order";
    }
    if (this.props.type === "invitation") {
      headerIcon = faHome;
      headerText = "Invitation to Unit";
    }

    let backgroundColor;
    let textColor;

    // Whether the text contains only emojis
    let text = "";
    if (this.props.text) text = this.props.text.trim();

    // Replace consecutive newlines with a single newline
    // https://stackoverflow.com/questions/46548564/how-to-remove-all-adjacent-newlines-and-whitespaces-in-javascript-and-replace-th
    text = text.replace(/(?: *[\n\r])\1/g, "\n\n");
    const textBlocks = text.split("\n\n");
    const textBlockEls = textBlocks.map((textBlock, index) => {
      if (textBlock === "") return null;
      return (
        <div
          key={index}
          style={{
            marginTop: index === 0 ? "0" : "10px",
          }}
        >
          {textBlock.trim()}
        </div>
      );
    });

    const textNoWhitespace = text.replace(/\s+/g, "");
    const textNoWhitespaceOrEmojis = textNoWhitespace.replace(emojiRegex(), "");
    const onlyEmojis =
      emojiRegex().test(textNoWhitespace) &&
      [...textNoWhitespace].length <= 5 &&
      [...textNoWhitespaceOrEmojis].length === 0;
    const onlyEmojisAndText = onlyEmojis && this.props.type === "text";

    if (this.props.type === "transaction" || this.props.type === "transaction_request") {
      backgroundColor = "#1C1C1C";

      if (this.props.status === "failed" || this.props.status === "cancelled") {
        backgroundColor = "#eb3b5a";
      }
    }
    // Transparent background for images
    else if (this.props.type === "image") {
      backgroundColor = "transparent";
    }
    // Transparent background for emoji-only text messages
    else if (onlyEmojisAndText) {
      backgroundColor = "transparent";
    } else {
      backgroundColor = this.props.sent ? "#4b7bec" : "#F1F1F3";
    }

    if (this.props.sent || this.props.type === "transaction" || this.props.type === "transaction_request") {
      textColor = "#fff";
    } else {
      textColor = "#000";
    }

    // Status string of invitation
    let invitationStateString = "";
    switch (this.props.invitationState) {
      case "pending":
        invitationStateString = "Pending response from " + (this.props.sent ? this.props.recipientName : "you") + "...";
        break;
      case "declined":
        invitationStateString = (this.props.sent ? this.props.recipientName : "You") + " declined this invitation.";
        break;
      case "accepted":
        invitationStateString = (this.props.sent ? this.props.recipientName : "You") + " accepted this invitation!";
        break;
      default:
        break;
    }

    // Status of a payment, if not processed
    let statusString = "";
    if (this.props.status === "pending") statusString = "Pending";
    if (this.props.status === "cancelled") statusString = "Cancelled";
    if (this.props.status === "failed") statusString = "Failed";

    // Get the payment description.
    // If the description does not exist and it's a rent payment, default to "Rent payment"
    // Fallback: display nothing
    let paymentDescription = this.props.paymentDescription;
    if (!paymentDescription) paymentDescription = this.props.paymentType === "rent" ? "Rent payment" : "";

    return (
      <div
        className="AllowTextSelection"
        style={{
          fontFamily: '"Roboto", sans-serif',
          paddingTop: this.props.firstInBlock || this.props.showTimestamp ? "12px" : "0",
          // Consider uncommenting to show temporary messages as transparent,
          // to indicate that they haven't been delivered
          //opacity: this.props.temporary ? 0.5 : 1,
          transition: "opacity 200ms",
        }}
      >
        {/* Timestamp of message */}
        {this.props.showTimestamp && (
          <div
            style={{
              fontSize: "12px",
              textAlign: "center",
              fontWeight: 500,
              color: "#8E8E93",
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            {formatTimeString(this.props.timestamp)}
          </div>
        )}
        {/* Sender name */}
        {!this.props.sent && this.props.showSenderName && this.props.firstInBlock && this.props.type !== "status" && (
          <div
            style={{
              fontSize: "12px",
              textAlign: "left",
              padding: "3px 25px",
              color: "#8E8E93",
            }}
          >
            {this.props.senderName}
          </div>
        )}
        {/* Content of message */}
        <div
          style={{
            fontSize: "14px",
            textAlign: "left",
            padding: "1px 15px",
            display: "flex",
            justifyContent: this.props.sent ? "flex-end" : "flex-start",
          }}
        >
          {/* Status text (for type: "status" messages) */}
          {this.props.type === "status" && (
            <div
              style={{
                width: "100%",
                fontSize: "14px",
                textAlign: "center",
                fontWeight: 400,
                color: "#8E8E93",
                padding: "10px 18px 3px 18px",
              }}
            >
              {text}
            </div>
          )}
          {/* Message bubble */}
          {this.props.type !== "status" && (
            <div
              style={{
                //#2A3950
                backgroundColor: backgroundColor,
                //borderRadius: "17px",
                borderRadius: "17px 5px 5px 17px",
                borderTopRightRadius: !this.props.firstInBlock && this.props.sent ? "3px" : "17px",
                borderBottomRightRadius: !this.props.lastInBlock && this.props.sent ? "3px" : "17px",
                borderTopLeftRadius: !this.props.firstInBlock && !this.props.sent ? "3px" : "17px",
                borderBottomLeftRadius: !this.props.lastInBlock && !this.props.sent ? "3px" : "17px",
                color: textColor,
                overflow: "hidden",
                position: "relative",
                maxWidth: "315px",
                // Limit the height of image messages
                maxHeight: this.props.type === "image" ? "400px" : "none",
                // transition: 'border-radius 200ms',
                minWidth:
                  this.props.type === "transaction" ||
                  this.props.type === "transaction_request" ||
                  this.props.type === "work_order"
                    ? "200px"
                    : "auto",
              }}
            >
              {this.props.loading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) scale(" + (this.props.loading ? "1" : "0") + ")",
                    fontSize: "24px",
                    transition: "transform 200ms",
                  }}
                >
                  <LoadingIcon />
                </div>
              )}
              <div
                style={{
                  opacity: this.props.loading ? "0.1" : "1",
                  transition: "opacity 200ms",
                }}
              >
                {/* Message header */}
                {(this.props.type === "transaction" ||
                  this.props.type === "transaction_request" ||
                  this.props.type === "work_order" ||
                  this.props.type === "invitation") && (
                  <div
                    style={{
                      padding: "10px 18px",
                      paddingBottom: "0",
                      fontWeight: 500,
                      fontSize: "16px",
                    }}
                  >
                    <FontAwesomeIcon icon={headerIcon} style={{ marginRight: "12px" }} />
                    <span>{headerText}</span>
                  </div>
                )}
                {/* Payment amount */}
                {(this.props.type === "transaction" || this.props.type === "transaction_request") && (
                  <div
                    style={{
                      padding: "10px 18px",
                      fontSize: "28px",
                      textAlign: "center",
                    }}
                  >
                    {formatMoneyAmount(this.props.amount)}
                  </div>
                )}
                {/* Payment description */}
                {(this.props.type === "transaction" || this.props.type === "transaction_request") &&
                  (this.props.paymentType === "rent" || this.props.paymentDescription) && (
                    <div
                      style={{
                        padding: "0px 18px 5px 18px",
                        paddingTop: "0",
                        fontSize: "12px",
                        opacity: "1.0",
                        fontWeight: 500,
                      }}
                    >
                      <span>{paymentDescription}</span>
                    </div>
                  )}
                {/* Payment type */}
                {(this.props.type === "transaction" || this.props.type === "transaction_request") && (
                  <div
                    style={{
                      padding: "10px 18px",
                      paddingTop: "0",
                      paddingBottom: this.props.processing ? "5px" : "10px",
                      fontSize: "12px",
                      opacity: "0.7",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={this.props.recurring ? faRedoAlt : faArrowRight}
                      style={{ marginRight: "10px" }}
                    />
                    <span>{this.props.recurring ? "Recurring " + this.props.recurringString : "One-time payment"}</span>
                    {this.props.status && this.props.status !== "processed" && <span> · {statusString}</span>}
                  </div>
                )}
                {/* Work order subject */}
                {this.props.type === "work_order" && (
                  <div
                    style={{
                      padding: "10px 18px 0 18px",
                      fontWeight: 500,
                    }}
                  >
                    {this.props.subject}
                  </div>
                )}
                {/* Message text */}
                {(this.props.type === "text" || this.props.type === "work_order") && (
                  <div
                    style={{
                      padding: onlyEmojisAndText ? "8px 5px" : "8px 15px",
                      fontSize: onlyEmojisAndText ? "42px" : "inherit",
                      lineHeight: onlyEmojisAndText ? 1.0 : 1.3,
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {textBlockEls}
                    {/*text*/}
                  </div>
                )}
                {/* Invitation */}
                {this.props.type === "invitation" && (
                  <div
                    style={{
                      padding: "10px 18px",
                    }}
                  >
                    <div
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      <div>
                        <span style={{ fontWeight: 500 }}>Unit: </span>
                        <span>{this.props.invitationUnitName}</span>
                      </div>
                      <div>
                        <span style={{ fontWeight: 500 }}>Property: </span>
                        <span>{this.props.invitationPropertyName}</span>
                      </div>
                      {this.props.invitationFirstMonth && (
                        <div style={{ paddingTop: "10px" }}>
                          <span style={{ fontWeight: 500 }}>First month of rent: </span>
                          <span>{formatMonthString(this.props.invitationFirstMonth)}</span>
                        </div>
                      )}
                      {this.props.invitationMoveInDate && (
                        <div style={{ paddingTop: "10px" }}>
                          <span style={{ fontWeight: 500 }}>Move-in: </span>
                          <span>{formatDateString(this.props.invitationMoveInDate)}</span>
                        </div>
                      )}
                      {this.props.invitationRentAmount > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <span style={{ fontWeight: 500 }}>Rent: </span>
                          <span>{formatMoneyAmount(this.props.invitationRentAmount)}</span>
                        </div>
                      )}
                      {this.props.invitationRefundableAmount > 0 && (
                        <div style={{ paddingTop: "10px" }}>
                          <span style={{ fontWeight: 500 }}>Security Deposit: </span>
                          <span>{formatMoneyAmount(this.props.invitationRefundableAmount)}</span>
                        </div>
                      )}
                      {this.props.invitationNonRefundableAmount > 0 && (
                        <div>
                          <span style={{ fontWeight: 500 }}>Fees: </span>
                          <span>{formatMoneyAmount(this.props.invitationNonRefundableAmount)}</span>
                        </div>
                      )}
                      {this.props.invitationNotes && (
                        <div style={{ paddingTop: "10px" }}>
                          <span style={{ fontWeight: 500 }}>Notes: </span>
                          <span>{this.props.invitationNotes}</span>
                        </div>
                      )}
                    </div>
                    {
                      // If not showing the buttons
                      !(this.props.invitationState === "pending" && !this.props.sent) && (
                        <div
                          style={{
                            marginTop: "10px",
                            opacity: "0.7",
                          }}
                        >
                          {invitationStateString}
                        </div>
                      )
                    }
                  </div>
                )}
                {/* Invitation accept/decline buttons */}
                {this.props.type === "invitation" && this.props.invitationState === "pending" && !this.props.sent && (
                  <div
                    style={{
                      position: "relative",
                      borderTop: "1px solid rgba(0,0,0,0.15)",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        display: this.state.loading ? "block" : "none",
                        zIndex: 100,
                      }}
                    >
                      <LoadingIcon />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        opacity: this.state.loading ? "0" : "1",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <Clickable
                          onClick={() => {
                            this.setState({ loading: true });
                            this.props.onAcceptInvitation();
                          }}
                        >
                          <div
                            style={{
                              padding: "10px 18px",
                              textAlign: "center",
                              fontWeight: 500,
                              borderRight: "0.5px solid rgba(0,0,0,0.15)",
                            }}
                          >
                            Accept
                          </div>
                        </Clickable>
                      </div>
                      <div style={{ width: "50%" }}>
                        <Clickable
                          onClick={() => {
                            this.setState({ loading: true });
                            this.props.onDeclineInvitation();
                          }}
                        >
                          <div
                            style={{
                              padding: "10px 18px",
                              textAlign: "center",
                              fontWeight: 500,
                              borderLeft: "0.5px solid rgba(0,0,0,0.15)",
                            }}
                          >
                            Decline
                          </div>
                        </Clickable>
                      </div>
                    </div>
                  </div>
                )}
                {/* Message image */}
                {this.props.type === "image" && (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <img
                      src={this.props.imageUrl}
                      alt={""}
                      style={{
                        position: "relative",
                        width: "100%",
                        display: "block",
                      }}
                    />
                    {/* Click to enlarge image */}
                    <div
                      style={{
                        top: "0",
                        left: "0",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Clickable
                        onClick={() => {
                          this.props.onViewImage(this.props.imageUrl);
                        }}
                      ></Clickable>
                    </div>
                  </div>
                )}
                {/* Work order images */}
                {this.props.type === "work_order" &&
                  this.props.workOrderImageUrls &&
                  this.props.workOrderImageUrls.length > 0 && (
                    <div
                      style={{
                        padding: "0px 0px 0px 5px",
                        display: "flex",
                        flexWrap: "wrap",
                        height: "auto",
                        position: "relative",
                      }}
                    >
                      {this.getWorkOrderImages()}
                    </div>
                  )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Message;
