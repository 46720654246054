import React, { PureComponent } from "react";
import LoadingIcon from "../../CustomComponents/LoadingIcon/LoadingIcon";
import CustomButtonSmall from "../../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import ChatCompose from "./ChatCompose";
import Message from "./Message";
import ChatTitleBar from "./ChatTitleBar";
import "./Chat.css";
import { Member, ComposedMessage, Message as MessageObject } from "../MessagingInterfaces";
import Swipeable from "../../utils/Swipeable";

interface Props {
  messages: MessageObject[]; // TODO
  members: Member[];
  isManager: boolean;
  isLinked: boolean;
  unitName?: string;
  propertyName?: string;
  loading: boolean;
  loadingMessage: boolean;
  narrowView: boolean;
  moreMessagesExist: boolean;
  dwollaCustomerType: null | "unverified" | "verified";
  primaryFundingSourceId: null | string;
  loadingDwolla: boolean;
  onAddBank: () => void;
  onAcceptInvitation: (
    messageId: string,
    unitId: string,
    propertyId: string,
    moveInDate: number,
    firstMonth: number,
    nonRefundableAmount: number,
    refundableAmount: number,
    rentAmount: number,
    notes: string,
  ) => void;
  onSendMessage: (message: ComposedMessage) => void;
  onDeclineInvitation: (messageId: string) => void;
  onViewImage: (imageUrl: string) => void;
  onDeleteCurrentChat: () => void;
  onShowMenu: () => void;
  onLoadMoreMessages: () => void;
}

interface State {
  loadingMoreMessages: boolean;
  moreMessagesLoaded: boolean;
}

class Chat extends PureComponent<Props, State> {
  MessageContainer: React.RefObject<HTMLDivElement>;
  ChatCompose: React.RefObject<ChatCompose>;
  Wrapper: React.RefObject<HTMLDivElement>;

  focusEls: (HTMLInputElement | HTMLTextAreaElement)[];
  imageEls: HTMLImageElement[];

  constructor(props) {
    super(props);
    this.state = {
      loadingMoreMessages: false,
      // Only scroll to compensate for images if no new messages have been loaded
      moreMessagesLoaded: false,
    };
    this.MessageContainer = React.createRef();
    this.ChatCompose = React.createRef();
    this.Wrapper = React.createRef();
    this.instantScrollToBottom = this.instantScrollToBottom.bind(this);
    this.smoothScrollToBottom = this.smoothScrollToBottom.bind(this);
    this.updateFocusListeners = this.updateFocusListeners.bind(this);
    this.removeFocusListeners = this.removeFocusListeners.bind(this);
    this.instantScrollImageHeight = this.instantScrollImageHeight.bind(this);

    this.focusEls = [];
    this.imageEls = [];
  }

  removeFocusListeners() {
    // First, remove existing listeners
    for (let i = 0; i < this.focusEls.length; i++) {
      const el = this.focusEls[i];
      el.removeEventListener("focus", this.smoothScrollToBottom);
    }
  }
  updateFocusListeners() {
    this.removeFocusListeners();
    // Bind new listeners
    const inputEls = Array.from(this.Wrapper.current.getElementsByTagName("input"));
    const textareaEls = Array.from(this.Wrapper.current.getElementsByTagName("textarea"));
    const els = [...inputEls, ...textareaEls];
    for (let i = 0; i < els.length; i++) {
      const el = els[i];
      el.addEventListener("focus", this.smoothScrollToBottom);
    }
    this.focusEls = els;
  }

  instantScrollImageHeight(e) {
    const imageEl = e.target;
    let height = imageEl.getBoundingClientRect().height;
    height = Math.min(height, 400); // Maximum image height is 400px

    const container = this.MessageContainer.current;
    container.scrollTop = container.scrollTop + height;
  }
  removeImageListeners() {
    // First, remove existing listeners
    for (let i = 0; i < this.imageEls.length; i++) {
      const el = this.imageEls[i];
      el.removeEventListener("load", this.instantScrollImageHeight);
    }
  }
  updateImageListeners() {
    this.removeImageListeners();
    // Bind new listeners
    const imageEls = Array.from(this.MessageContainer.current.getElementsByTagName("img"));
    for (let i = 0; i < imageEls.length; i++) {
      const el = imageEls[i];
      el.addEventListener("load", this.instantScrollImageHeight);
    }
    this.imageEls = imageEls;
  }

  // When the page resizes or the soft keyboard pops up, scroll to the bottom of the current chat
  componentDidMount() {
    window.addEventListener("resize", this.instantScrollToBottom);
    window.addEventListener("keyboardDidShow", this.instantScrollToBottom);
    this.updateFocusListeners();
    this.updateImageListeners();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.instantScrollToBottom);
    window.removeEventListener("keyboardDidShow", this.instantScrollToBottom);
    this.removeFocusListeners();
  }

  clear() {
    this.ChatCompose.current.clear();
  }

  instantScrollToBottom() {
    const container = this.MessageContainer.current;
    container.scrollTop = container.scrollHeight;
  }

  smoothScrollToBottom() {
    setTimeout(() => {
      const container = this.MessageContainer.current;
      container.scrollTo({
        behavior: "smooth",
        left: 0,
        top: container.scrollHeight,
      });
    }, 0);
  }

  prefillRentPayment(amount: number) {
    setTimeout(() => {
      this.ChatCompose.current.prefillRentPayment(amount);
    }, 500);
  }

  // Convert this.props.messages into an array of <Message /> components we can display
  getMessages() {
    return this.props.messages.map((message, index) => {
      return (
        <Message
          key={index}
          temporary={message.temporary}
          loading={message.loading}
          sent={message.sent}
          type={message.type}
          text={message.text}
          status={message.status}
          imageUrl={message.imageUrl}
          amount={message.amount}
          timestamp={message.timestamp}
          showTimestamp={message.showTimestamp}
          senderName={message.senderName}
          recipientName={message.recipientName}
          showSenderName={message.showSenderName}
          firstInBlock={message.firstInBlock}
          lastInBlock={message.lastInBlock}
          recurring={message.recurring}
          recurringString={message.recurringString}
          processing={message.processing}
          paymentType={message.paymentType}
          paymentDescription={message.paymentDescription}
          subject={message.subject}
          workOrderImageUrls={message.workOrderImageUrls}
          invitationState={message.invitationState}
          invitationUnitName={message.invitationUnitName}
          invitationPropertyName={message.invitationPropertyName}
          invitationRentAmount={message.invitationRentAmount}
          invitationRefundableAmount={message.invitationRefundableAmount}
          invitationNonRefundableAmount={message.invitationNonRefundableAmount}
          invitationMoveInDate={message.invitationMoveInDate}
          invitationFirstMonth={message.invitationFirstMonth}
          invitationNotes={message.invitationNotes}
          onAcceptInvitation={() => {
            this.props.onAcceptInvitation(
              message.id,
              message.invitationUnitId,
              message.invitationPropertyId,
              message.invitationMoveInDate,
              message.invitationFirstMonth,
              message.invitationNonRefundableAmount,
              message.invitationRefundableAmount,
              message.invitationRentAmount,
              message.invitationNotes,
            );
          }}
          onDeclineInvitation={() => {
            this.props.onDeclineInvitation(message.id);
          }}
          onViewImage={(imageUrl) => {
            this.props.onViewImage(imageUrl);
          }}
        />
      );
    });
  }

  componentDidUpdate(prevProps) {
    this.updateFocusListeners();
    this.updateImageListeners();

    // Amount of messages was updated
    // messages can sometimes be undefined when a deleted chat is re-added
    if (prevProps.messages && this.props.messages && prevProps.messages.length !== this.props.messages.length) {
      this.setState({
        loadingMoreMessages: false,
      });
    }
  }

  render() {
    return (
      <div
        ref={this.Wrapper}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "hidden",
          flexDirection: "column",
        }}
      >
        <ChatTitleBar
          members={this.props.members}
          narrowView={this.props.narrowView}
          isLinked={this.props.isLinked}
          propertyName={this.props.propertyName}
          unitName={this.props.unitName}
          onShowMenu={this.props.onShowMenu}
          onDeleteCurrentChat={this.props.onDeleteCurrentChat}
        />
        <Swipeable onSwipedRight={this.props.onShowMenu}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 0,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              ref={this.MessageContainer}
              style={{
                position: "relative",
                flexGrow: 1,
                paddingTop: "50px",
                paddingBottom: "20px",
                overflow: "scroll",
              }}
            >
              {this.props.loading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "24px",
                    color: "rgba(0,0,0,0.85)",
                  }}
                >
                  <LoadingIcon />
                </div>
              )}
              {this.props.moreMessagesExist && (
                <div
                  style={{
                    position: "relative",
                    boxSizing: "border-box",
                    padding: "10px 15px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomButtonSmall
                    color={"#4b7bec"}
                    inverted={false}
                    borderOnly={true}
                    text={"Load More Messages"}
                    loading={this.state.loadingMoreMessages}
                    onClick={async () => {
                      // Store the height before messages were loaded
                      const container = this.MessageContainer.current;
                      const heightBefore = container.scrollHeight;

                      this.setState({ loadingMoreMessages: true });
                      await this.props.onLoadMoreMessages();

                      this.setState({ loadingMoreMessages: false });

                      // Get the height after new messages are loaded
                      const heightAfter = container.scrollHeight;
                      // The difference is the combined height of all loaded messages; scroll to offset
                      const heightDifference = heightAfter - heightBefore;
                      container.scrollTop = container.scrollTop + heightDifference;
                    }}
                  />
                </div>
              )}
              {!this.props.loading && this.getMessages()}
            </div>
            <ChatCompose
              ref={this.ChatCompose}
              members={this.props.members}
              isLinked={this.props.isLinked}
              loadingMessage={this.props.loadingMessage}
              isManager={this.props.isManager}
              dwollaCustomerType={this.props.dwollaCustomerType}
              loadingDwolla={this.props.loadingDwolla}
              primaryFundingSourceId={this.props.primaryFundingSourceId}
              onAddBank={this.props.onAddBank}
              onSendMessage={(message) => {
                if (typeof this.props.onSendMessage === "function") {
                  this.props.onSendMessage(message);
                }
              }}
              onChangeType={() => {
                // Blur active element
                if ("activeElement" in document && document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur();
                }
                this.updateFocusListeners();

                // Scroll to bottom when ChatCompose changes height
                setTimeout(() => {
                  this.smoothScrollToBottom();
                }, 0);
              }}
              onViewImage={(imageUrl) => {
                this.props.onViewImage(imageUrl);
              }}
            />
          </div>
        </Swipeable>
      </div>
    );
  }
}

export default Chat;
