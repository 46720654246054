import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import Modal from "./Modal";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { getCurrentUserUid } from "../auth";

import * as CloudFunctions from "../CloudFunctions";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt, faArrowFromRight } from "@fortawesome/pro-solid-svg-icons";
library.add(faCalendarAlt, faArrowFromRight);

const dueDateToDB = (index: number): number => {
  switch (index) {
    case 0:
      return 1;
    case 1:
      return 15;
  }
};

function dueDateFromDB(value: number): number {
  switch (value) {
    case 1:
      return 0;
    case 15:
      return 1;
    default:
      console.warn(`Invalid due date from db: ${value}`);
      return 0;
  }
}

interface Props {}

interface State {
  rentDue: number;
  rentDueOffset: number;
  originalRentOffset: number;
  originalRentDue: number;
  loading: boolean;
}

class ModalPaymentSchedule extends PureComponent<Props, State> {
  modal: React.RefObject<Modal>;
  SelectRentDue: React.RefObject<CustomSelect>;
  SelectRentOffset: React.RefObject<CustomSelect>;

  constructor(props) {
    super(props);
    this.state = {
      // Initial rent schedule (on page load)
      rentDue: 0,
      rentDueOffset: 0,
      originalRentOffset: 0,
      originalRentDue: 0,
      loading: true,
    };

    this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
    this.modal = React.createRef();

    this.SelectRentDue = React.createRef();
    this.SelectRentOffset = React.createRef();

    this.handleUpdateRentDueSetting = this.handleUpdateRentDueSetting.bind(this);
    this.handleUpdateRentOffsetSetting = this.handleUpdateRentOffsetSetting.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    /* RENT OFFSET 
          index:
          0: 'On due date',
          1: '1 day before due',
          2: '2 days before due',
          3: '3 days before due',
          4: '4 days before due',
          5: '5 days before due',
          6: '6 days before due',
          7: '7 days before due',
          8: '8 days before due',
          9: '9 days before due',
          10: '10 days before due',
      */

    /* RENT DUE 
          index:
          0: 'First of the month',
          1: '15th day of the month'
      */
  }

  show() {
    this.SelectRentDue.current.setValue(this.state.originalRentDue);
    this.SelectRentOffset.current.setValue(this.state.originalRentOffset);
    this.setState(
      (state) => {
        return {
          rentDue: state.originalRentDue,
          rentDueOffset: state.originalRentOffset,
        };
      },
      () => {
        this.modal.current.show();
      },
    );
  }

  hide() {
    this.modal.current.hide();
  }

  async handleSubmit() {
    this.setState({
      loading: true,
    });

    const newSettings: { rentDueDate?: number; rentDueDateOffset?: number } = {};
    if (this.state.originalRentDue !== this.state.rentDue) {
      newSettings.rentDueDate = dueDateToDB(this.state.rentDue);
    }
    if (this.state.originalRentOffset !== this.state.rentDueOffset) {
      newSettings.rentDueDateOffset = this.state.rentDueOffset;
    }

    if (Object.keys(newSettings).length > 0) {
      await CloudFunctions.updateRentPreferences(newSettings);
      this.setState({ originalRentDue: this.state.rentDue, originalRentOffset: this.state.rentDueOffset });
    }

    this.setState({
      loading: false,
    });
    this.hide();
  }

  // Get the initial rent schedule
  async getInitialRentSchedule() {
    const userDoc = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();
    const userData = userDoc.data();

    const rentDue = dueDateFromDB(userData.rent_due_date_preference);
    const rentDueOffset = userData.rent_due_date_offset_preference;

    this.SelectRentDue.current.setValue(rentDue);
    this.SelectRentOffset.current.setValue(rentDueOffset);

    this.setState({
      originalRentDue: rentDue,
      originalRentOffset: rentDueOffset,
      rentDue: rentDue,
      rentDueOffset: rentDueOffset,
      loading: false,
    });
  }

  handleSuccessfulLogin() {
    this.getInitialRentSchedule();
  }

  handleUpdateRentDueSetting(newRentDueDate) {
    this.setState({
      rentDue: newRentDueDate,
    });
  }

  handleUpdateRentOffsetSetting(newRentOffset) {
    this.setState({
      rentDueOffset: newRentOffset,
    });
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Configure Payment Schedule"} onHide={() => {}} onShow={() => {}}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div
            style={{
              fontWeight: 500,
              fontSize: "14px",
              color: "rgba(0,0,0,0.85)",
              padding: "10px 10px 10px 10px",
            }}
          >
            <span>When are rent payments due?</span>
          </div>
          <CustomSelect
            ref={this.SelectRentDue}
            options={["First of the month", "15th day of the month"]}
            icon={faCalendarAlt}
            placeholder="Rent due on"
            inverted={true}
            readOnly={false}
            onChange={(value) => {
              this.handleUpdateRentDueSetting(value);
            }}
          />
          <div
            style={{
              fontWeight: 500,
              fontSize: "14px",
              color: "rgba(0,0,0,0.85)",
              padding: "10px 10px 10px 10px",
            }}
          >
            <span>When do you expect to start receiving rent payments? Payment totals will reset on this day.</span>
          </div>
          <CustomSelect
            ref={this.SelectRentOffset}
            options={[
              "On due date",
              "1 day before due",
              "2 days before due",
              "3 days before due",
              "4 days before due",
              "5 days before due",
              "6 days before due",
              "7 days before due",
              "8 days before due",
              "9 days before due",
              "10 days before due",
            ]}
            icon={faArrowFromRight}
            placeholder="Expect rent"
            inverted={true}
            readOnly={false}
            onChange={(value) => {
              this.handleUpdateRentOffsetSetting(value);
            }}
          />
          <div className="ModalButtonWrapper">
            <CustomButton
              loading={this.state.loading}
              text={"Submit"}
              inverted={true}
              color={"#2A3950"}
              submit={true}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

export default ModalPaymentSchedule;
