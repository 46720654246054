import React, { PureComponent } from "react";
import { Capacitor, Plugins, DeviceInfo } from "@capacitor/core";
import { FCM } from "@capacitor-community/fcm";
// Pages
import Login from "./Pages/Login";
import RegisterInitial from "./Pages/RegisterInitial";
import RegisterComplete from "./Pages/RegisterComplete";
import RegisterConfirmation from "./Pages/RegisterConfirmation";
import ForgotPassword from "./Pages/ForgotPassword";
import ForgotPasswordConfirmation from "./Pages/ForgotPasswordConfirmation";
import AddProfilePhoto from "./Pages/AddProfilePhoto";

import { getCurrentUserUid } from "../auth";

// Firebase and input validation
import firebase from "firebase/app";
import "firebase/auth";

import "./AuthenticationWindow.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
library.add(faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck);

const { PushNotifications, Device } = Plugins;
const fcm = new FCM();

export interface InitialData {
  name: string;
  firstName: string;
  lastName: string;
  phone: string;
}

interface Props {
  showGenericModal: (title: string, body: string, unhidable?: boolean) => void;
  recievedAuthState: () => void;
  onSuccessfulLogin: () => void;
  onSuccessfulSignOut: () => void;
  onShowPrivacyPolicy: () => void;
  onShowTermsOfService: () => void;
  onUpdateProgress: (progress: number) => void;
}
interface State {
  currentPage: string;
  initialData: InitialData;
  genericError: boolean;
  registerType: "normal" | "google" | "facebook";
  info: DeviceInfo;
}

class AuthenticationWindow extends PureComponent<Props, State> {
  Login = React.createRef<Login>();
  RegisterInitial = React.createRef<RegisterInitial>();
  RegisterComplete = React.createRef<RegisterComplete>();
  RegisterConfirmation = React.createRef<RegisterConfirmation>();
  ForgotPassword = React.createRef<ForgotPassword>();
  ForgotPasswordConfirmation = React.createRef<ForgotPasswordConfirmation>();
  AddProfilePhoto = React.createRef<AddProfilePhoto>();

  authStateChanged: boolean;

  constructor(props) {
    super(props);
    this.state = {
      currentPage: "login",
      initialData: null,
      genericError: undefined,
      registerType: "normal",
      info: undefined,
    };

    this.handleSuccessfulLogin = this.handleSuccessfulLogin.bind(this);
    this.registerPushNotifications = this.registerPushNotifications.bind(this);
    this.getDeviceInfo = this.getDeviceInfo.bind(this);
    this.isSupportedConfiguration = this.isSupportedConfiguration.bind(this);

    this.authStateChanged = false; // TODO: Should this be part of state?
  }

  async getDeviceInfo() {
    if (!this.state.info) {
      const info = await Device.getInfo();
      this.setState({ info: info });
    }
    return this.state.info;
  }

  /**
   * Sets user field verified to true.
   * Call this during login flow, after confirming
   * user is verified from auth.
   */
  setUserAsVerified() {
    firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .update({
        verified: true,
      })
      .then(() => {});
  }

  registerPushNotifications() {
    if (Capacitor.isNative) {
      PushNotifications.requestPermission().then(async (result) => {
        if (result.granted) {
          await PushNotifications.register();
          fcm.getToken().then(async (token) => {
            await firebase.functions().httpsCallable("registerFCMToken")({ token: token.token });
          });
          firebase.analytics().logEvent("push_notifications_approved");
        } else {
          firebase.analytics().logEvent("push_notifications_declined");
        }
      });
    }
  }

  async isSupportedConfiguration() {
    const deviceInfo = await this.getDeviceInfo();
    const response = await firebase.functions().httpsCallable("verifyDeviceInfoSupported")(deviceInfo);
    return response.data;
  }

  componentDidMount() {
    // Listen for authentication state change
    this.isSupportedConfiguration().then((resp) => {
      if (!resp.success) {
        this.props.showGenericModal(resp.error.title, resp.error.body, true);
        if (this.authStateChanged && firebase.auth().currentUser !== null) {
          firebase.auth().signOut();
          this.props.onSuccessfulSignOut();
        }
      }
    });
    firebase.auth().onAuthStateChanged(
      async (user) => {
        this.authStateChanged = true;
        if (user) {
          const userData = await firebase.firestore().collection("users").doc(user.uid).get();
          // Make sure user data exists
          if (userData.exists) {
            const data = userData.data();
            const full_name = data.full_name;
            const phone = data.phone;

            // Check for email verification and initial data fields
            if (user.emailVerified && full_name && phone) {
              this.handleSuccessfulLogin();
            }
          }
        }
        // Handle automatic authentication response
        this.props.recievedAuthState();
      },
      // Catch error
      (error) => {
        // Immediately hide loading screen if an error is encountered
        this.props.recievedAuthState();
      },
    );

    // After 5000ms with no response, assume the user isn't logged in
    setTimeout(() => {
      if (!this.authStateChanged) this.props.recievedAuthState();
    }, 5000);

    // Set authentication persistence
    const persistenceLevel = Capacitor.isNative
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
    firebase
      .auth()
      .setPersistence(persistenceLevel)
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Persistence could not be set", errorCode, errorMessage);
      })
      .then((result) => {
        //console.log(result);
      });
  }

  handleSuccessfulLogin() {
    this.setUserAsVerified();
    this.registerPushNotifications();
    this.props.onSuccessfulLogin();
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          borderRadius: "10px",
          padding: "10px 20px 0px 20px",
          backgroundColor: "#fff",
          width: "300px",
          boxShadow: "0 3px 10px rgba(0,0,0,0.05)",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <Login
            ref={this.Login}
            showing={this.state.currentPage === "login"}
            genericError={this.state.genericError}
            onShowRegister={() => {
              this.RegisterInitial.current.clear();
              this.setState({
                initialData: undefined,
                currentPage: "register_initial",
              });
            }}
            onShowForgotPassword={() => {
              this.ForgotPassword.current.clear();
              this.setState({ currentPage: "forgot_password" });
            }}
          />
          <RegisterInitial
            ref={this.RegisterInitial}
            showing={this.state.currentPage === "register_initial"}
            registerType={this.state.registerType}
            onShowLogin={() => {
              this.Login.current.clear();
              this.setState({
                registerType: "normal",
                currentPage: "login",
              });
            }}
            onShowRegisterComplete={(initialData) => {
              this.RegisterComplete.current.clear();
              this.setState({
                initialData: initialData,
                currentPage: "register_complete",
              });
            }}
            onGoogleAuth={(initialData) => {
              throw new Error("Google Authentication Not Supported");
            }}
            onFacebookAuth={(initialData) => {
              throw new Error("Facebook Authentication Not Supported");
            }}
          />
          <RegisterComplete
            ref={this.RegisterComplete}
            showing={this.state.currentPage === "register_complete"}
            initialData={this.state.initialData}
            onShowLogin={() => {
              this.Login.current.clear();
              this.setState({
                registerType: "normal",
                currentPage: "login",
              });
            }}
            onShowAddProfilePhoto={() => {
              this.AddProfilePhoto.current.clear();
              this.setState({ currentPage: "add_profile_photo" });
            }}
            onShowRegisterConfirmation={() => {
              this.setState({ currentPage: "register_confirmation" });
            }}
            onShowPrivacyPolicy={() => {
              if (typeof this.props.onShowPrivacyPolicy === "function") {
                this.props.onShowPrivacyPolicy();
              }
            }}
            onShowTermsOfService={() => {
              if (typeof this.props.onShowTermsOfService === "function") {
                this.props.onShowTermsOfService();
              }
            }}
          />
          <RegisterConfirmation
            ref={this.RegisterConfirmation}
            showing={this.state.currentPage === "register_confirmation"}
            onShowLogin={() => {
              this.Login.current.clear();
              this.setState({
                registerType: "normal",
                currentPage: "login",
              });
            }}
          />
          <ForgotPassword
            ref={this.ForgotPassword}
            showing={this.state.currentPage === "forgot_password"}
            onShowLogin={() => {
              this.Login.current.clear();
              this.setState({
                registerType: "normal",
                currentPage: "login",
              });
            }}
            onShowForgotPasswordConfirmation={() => {
              this.setState({ currentPage: "forgot_password_confirmation" });
            }}
          />
          <ForgotPasswordConfirmation
            ref={this.ForgotPasswordConfirmation}
            showing={this.state.currentPage === "forgot_password_confirmation"}
            onShowLogin={() => {
              this.Login.current.clear();
              this.setState({
                registerType: "normal",
                currentPage: "login",
              });
            }}
          />
          <AddProfilePhoto
            ref={this.AddProfilePhoto}
            showing={this.state.currentPage === "add_profile_photo"}
            onShowRegisterConfirmation={() => {
              this.setState({ currentPage: "register_confirmation" });
            }}
            onUpdateProgress={this.props.onUpdateProgress}
          />
        </div>
      </div>
    );
  }
}

export default AuthenticationWindow;
