import React, { PureComponent } from "react";
import { formatMoneyAmount, formatTimeString } from "../HelperFunctions";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";
import { ITransferWithNames } from "../loadData";

import "./ManagePage.css";

import { getCurrentUserUid } from "../auth";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faRedoAlt } from "@fortawesome/pro-solid-svg-icons";
library.add(faArrowLeft, faArrowRight, faRedoAlt);

interface Props {
  transfer: ITransferWithNames;
  isScheduled: boolean;
  isOther: boolean;
  onCancelTransaction: () => void;
  onCancelRecurringTransaction: () => void;
  onMarkAsRent: () => Promise<void>;
}

interface State {
  loading: boolean;
  expanded: boolean;
}

class ManageTransaction extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expanded: false,
    };
  }

  getSentAndPeerName(transfer: ITransferWithNames): { sent: boolean; peerName: string } {
    // Transaction "peerName" and "sent" are assigned as follows
    //  - if source is the current user: destination name, sent=true
    //  - if the destination is the current user: source name, sent=false
    //  - otherwise: the destination name, sent=true
    let peerName = "";
    let sent: boolean;

    if (transfer.source_rentingway_uid === getCurrentUserUid()) {
      peerName = transfer.names.destination;
      sent = true;
    } else if (transfer.destination_rentingway_uid === getCurrentUserUid()) {
      peerName = transfer.names.source;
      sent = false;
    } else {
      peerName = transfer.names.destination;
      sent = true;
    }
    return { sent, peerName };
  }

  // componentDidUpdate(nextProps) {
  //   if (this.props.status !== nextProps.status) {
  //     this.setState({ loading: false });
  //   }
  // }

  render() {
    const transfer = this.props.transfer;
    let statusString = undefined;
    let cancelledOrFailed = false;
    if (transfer.status === "pending") {
      statusString = "Pending";
    } else if (transfer.status === "cancelled") {
      statusString = "Cancelled";
      cancelledOrFailed = true;
    } else if (transfer.status === "failed") {
      statusString = "Failed";
      cancelledOrFailed = true;
    }
    const { sent, peerName } = this.getSentAndPeerName(transfer);

    const isScheduled = this.props.isScheduled;
    const isOther = this.props.isOther;

    // Get the payment description.
    // If the description does not exist and it's a rent payment, default to "Rent payment"
    // Fallback: display nothing
    let paymentDescription = transfer.payment_description;
    if (!paymentDescription) paymentDescription = transfer.payment_type === "rent" ? "Rent payment" : "";

    let color = "rgba(0,0,0,0.85)";
    if (cancelledOrFailed) color = "#eb3b5a";
    return (
      <div
        className="ManageTransaction"
        onClick={() =>
          this.setState((prevState) => ({
            expanded: !prevState.expanded,
          }))
        }
        style={{
          position: "relative",
          display: "flex",
          boxSizing: "border-box",
          padding: "10px 5px 10px 10px",
          alignItems: "center",
        }}
      >
        {/* Left content */}
        <div
          style={{
            flexGrow: 1,
          }}
        >
          {/* Payment description */}
          <div
            className="AllowTextSelection"
            style={{
              marginRight: "10px",
              fontSize: "14px",
              marginBottom: "5px",
            }}
          >
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {paymentDescription}
            </span>
            <span
              style={{
                fontWeight: 400,
                color: "rgba(0,0,0,0.85)",
              }}
            >
              {" " + (sent ? "to " : "from ") + peerName}
            </span>
          </div>
          <div
            className="AllowTextSelection"
            style={{
              fontSize: "12px",
              marginRight: "10px",
              fontWeight: 500,
              color: "#8e8e93",
            }}
          >
            {isScheduled ? "Scheduled for" : "Created"} {formatTimeString(transfer.timestamp)}
          </div>
          <div
            className="AllowTextSelection"
            style={{
              marginTop: "5px",
              fontSize: "12px",
              opacity: "0.7",
            }}
          >
            <FontAwesomeIcon
              icon={transfer.recurring === "1" ? faRedoAlt : faArrowRight}
              style={{ marginRight: "10px" }}
            />
            <span>{transfer.recurring === "1" ? "Recurring " + transfer.recurring_string : "One-time payment"}</span>
            {statusString && <span style={{ fontWeight: 500, color: color }}> · {statusString}</span>}
          </div>
          {transfer.status === "pending" && sent && !isScheduled && (
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <CustomButtonSmall
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                text={"Cancel Payment"}
                loading={this.state.loading}
                disabled={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props.onCancelTransaction();
                }}
              />
            </div>
          )}
          {isScheduled && (
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <CustomButtonSmall
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                text={"Cancel Recurring Payment"}
                loading={this.state.loading}
                disabled={this.state.loading}
                onClick={() => {
                  this.setState({ loading: true });
                  this.props.onCancelRecurringTransaction();
                }}
              />
            </div>
          )}
          {isOther && this.state.expanded && (
            <div
              style={{
                marginTop: "10px",
              }}
              onClick={(evt) => evt.stopPropagation()}
            >
              <CustomButtonSmall
                color={"rgba(0,0,0,0.60)"}
                inverted={false}
                borderOnly={true}
                text={"Mark as Rent"}
                loading={this.state.loading}
                disabled={this.state.loading}
                onClick={async () => {
                  this.setState({ loading: true });
                  await this.props.onMarkAsRent();
                  this.setState({ loading: false });
                }}
              />
            </div>
          )}
        </div>
        {/* Right content */}
        <div
          className="AllowTextSelection"
          style={{
            flexGrow: 0,
            fontSize: "12px",
            paddingLeft: "10px",
            whiteSpace: "nowrap",
            color: cancelledOrFailed ? "#8e8e93" : sent ? "#eb3b5a" : "#20bf6b",
          }}
        >
          <span>{(sent ? "-" : "+") + " "}</span>
          <span style={{ textDecoration: cancelledOrFailed ? "line-through" : undefined }}>
            {formatMoneyAmount(+transfer.amount.value)}
          </span>
        </div>
      </div>
    );
  }
}

export default ManageTransaction;
