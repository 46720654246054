import React from "react";
import "./GlobalProgressIndicator.css";

export interface Props {
  progress: number;
}

const GlobalProgressIndicator: React.FC<Props> = (props) => {
  // Bound progress between value [0, 1]
  let progress = Math.min(props.progress, 1);
  progress = Math.max(progress, 0);

  // Only show when progress is between (0, 1)
  const showing = progress > 0 && progress < 1;

  return (
    <div
      className="GlobalProgressIndicatorWrapper"
      style={{
        transform: "translateY(" + (showing ? "0" : "-100%") + ")",
        // Wait an additiona 200ms for animation to complete before hiding
        transition: "transform 80ms ease " + (showing ? "200ms" : "0"),
      }}
    >
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "4px",
          backgroundColor: "rgba(0, 0, 0, 0.02)",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            backgroundColor: "#4b7bec",
            width: "100%",
            transform: "translateX(" + (progress * 100 - 100) + "%)",
            transition: "transform 200ms ease",
          }}
        ></div>
      </div>
    </div>
  );
};

export default GlobalProgressIndicator;
