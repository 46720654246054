import React, { PureComponent } from "react";
import "./LandingPage.css";

import Header from "./Header";
import GettingStarted from "./GettingStarted";
import Content from "./Content";
import Footer from "./Footer";

interface Props {
  showing: boolean;
  onSuccessfulLogin: () => void;
  onSuccessfulSignOut: () => void;
  recievedAuthState: () => void;
  onShowPrivacyPolicy: () => void;
  onShowTermsOfService: () => void;
  showGenericModal: (title: string, body: string, unhidable?: boolean) => void;
  onUpdateProgress: (progress: number) => void;
}
class LandingPage extends PureComponent<Props> {
  render() {
    return (
      <div
        className="LandingWrapper"
        style={{
          // Hides the page above the top of the screen when login page is not showing
          transform: "translateY(" + (this.props.showing ? "0" : "-100%") + ")",
          transition: "background-color 200ms, transform 200ms, background-position 200ms",
        }}
      >
        <Header
          onSuccessfulLogin={this.props.onSuccessfulLogin}
          onSuccessfulSignOut={this.props.onSuccessfulSignOut}
          recievedAuthState={this.props.recievedAuthState}
          onShowPrivacyPolicy={() => {
            this.props.onShowPrivacyPolicy();
          }}
          onShowTermsOfService={() => {
            this.props.onShowTermsOfService();
          }}
          showGenericModal={this.props.showGenericModal}
          onUpdateProgress={this.props.onUpdateProgress}
        />
        <GettingStarted />
        <Content />
        <Footer />
      </div>
    );
  }
}

export default LandingPage;
