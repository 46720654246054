import firebase from "firebase/app";

export default class FirestoreConverter<T> {
  toFirestore = (data: T) => data;
  fromFirestore = (snapshot: firebase.firestore.DocumentSnapshot) => {
    const data = snapshot.data();
    if (data === undefined) {
      return undefined;
    }
    data.id = snapshot.id;
    return data as T;
  };
}
