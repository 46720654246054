import React, { PureComponent } from "react";

import Clickable from "../../Clickable/Clickable";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import CustomInput from "../../CustomComponents/CustomInput/CustomInput";
import { formatPhone, isValidPhone, parsePhone } from "../../HelperFunctions";

// Firebase
import "firebase/auth";

import { InitialData } from "../AuthenticationWindow";
import "../AuthenticationWindow.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faGoogle, faFacebookF } from "@fortawesome/free-brands-svg-icons";
library.add(faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck, faGoogle, faFacebookF);

interface Props {
  showing: boolean;
  registerType: "normal" | "google" | "facebook";
  onShowLogin: () => void;
  onShowRegisterComplete: (initialData: InitialData) => void;
  onGoogleAuth: (initialData: InitialData) => void;
  onFacebookAuth: (initialData: InitialData) => void;
}
interface State {
  loading: boolean;
  input: { firstName: string; lastName: string; phone: string };
  errors: { firstName: string; lastName: string; phone: string };
}
class RegisterInitial extends PureComponent<Props, State> {
  InputFirstName = React.createRef<CustomInput>();
  InputLastName = React.createRef<CustomInput>();
  InputPhone = React.createRef<CustomInput>();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      input: {
        firstName: "",
        lastName: "",
        phone: "",
      },
      errors: {
        firstName: "",
        lastName: "",
        phone: "",
      },
    };
  }

  clear() {
    this.InputPhone.current.clear();
    this.InputFirstName.current.clear();
    this.InputLastName.current.clear();
    this.setState({
      errors: {
        firstName: "",
        lastName: "",
        phone: "",
      },
    });
  }

  updateErrors() {
    let encounteredError = false;
    const errors = this.state.errors;

    const phone = parsePhone(this.state.input.phone);
    const firstName = this.state.input.firstName.trim();
    const lastName = this.state.input.lastName.trim();

    if (firstName.length === 0) {
      errors.firstName = "Please enter your first name";
      encounteredError = true;
    }
    if (lastName.length === 0) {
      errors.lastName = "Please enter your last name";
      encounteredError = true;
    }
    if (!isValidPhone(phone)) {
      errors.phone = "Please enter a valid phone number";
      encounteredError = true;
    }

    if (encounteredError) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  handleSubmit() {
    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    const firstName = this.state.input.firstName.trim();
    const lastName = this.state.input.lastName.trim();
    const name = firstName + " " + lastName;

    // Data collected from this page to be passed to the second registration page
    const initialData = {
      name: name,
      firstName: firstName,
      lastName: lastName,
      phone: parsePhone(this.state.input.phone),
    };

    const registerType = this.props.registerType;

    if (registerType === "facebook") {
      this.props.onFacebookAuth(initialData);
    } else if (registerType === "google") {
      this.props.onGoogleAuth(initialData);
    } else {
      this.props.onShowRegisterComplete(initialData);
    }
  }

  render() {
    let buttonText = "Next";
    if (this.props.registerType === "facebook") buttonText = "Continue With Facebook";
    if (this.props.registerType === "google") buttonText = "Continue With Google";

    return (
      <div
        style={{
          position: this.props.showing ? "relative" : "absolute",
          width: "100%",
          boxSizing: "border-box",
          zIndex: this.props.showing ? 1 : 0,
          transform: "translateY(" + (this.props.showing ? "0" : "10px") + ")",
          opacity: this.props.showing ? "1" : "0",
          padding: "25px 10px",
          transition: "transform 80ms, opacity 80ms",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit();
          }}
        >
          <div className="AuthenticationTitle">Create an Account</div>

          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputFirstName}
              icon={faUser}
              placeholder="First name"
              errorText={this.state.errors.firstName}
              type={"text"}
              name={"fname"}
              autoComplete={"given-name"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.firstName = text;
                  state.errors.firstName = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>

          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputLastName}
              icon={faUser}
              placeholder="Last name"
              errorText={this.state.errors.lastName}
              type={"text"}
              name={"lname"}
              autoComplete={"family-name"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.lastName = text;
                  state.errors.lastName = "";
                  return state;
                }, this.forceUpdate);
              }}
            />
          </div>

          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputPhone}
              icon={faPhone}
              placeholder="Phone number"
              errorText={this.state.errors.phone}
              type={"tel"}
              name={"phone"}
              autoComplete={"tel"}
              inverted={true}
              onChange={(text) => {
                const formatted = formatPhone(text);
                let changed = false;
                // Update the phone number to be formatted
                this.setState(
                  (state) => {
                    if (state.input.phone !== formatted) {
                      state.input.phone = formatted;
                      changed = true;
                    }
                    state.errors.phone = "";
                    return state;
                  },
                  () => {
                    this.forceUpdate();
                    // If the phone number changed, update the input value
                    if (changed) {
                      this.InputPhone.current.setValue(formatted);
                    }
                  },
                );
              }}
            />
          </div>

          <div className="AuthenticationButtonWrapper">
            <CustomButton text={buttonText} inverted={true} color={"#2A64AD"} loading={false} submit={true} />
          </div>
        </form>

        <div className="AuthenticationTextWrapper">
          <Clickable onClick={this.props.onShowLogin}>
            <span className="AuthenticationTextLight">Already have an account?&nbsp;</span>
            <span className="AuthenticationTextBold">Sign in!</span>
          </Clickable>
        </div>
      </div>
    );
  }
}

export default RegisterInitial;
