import React, { PureComponent } from "react";
import CustomButtonSmall from "../CustomComponents/CustomButtonSmall/CustomButtonSmall";

interface Props {
  notes: string;
  onUpdateTenantNotes: (notes: string) => Promise<void>;
}
interface State {
  notes: string;
  savedNotes: string;
  loading: boolean;
}

class TenantNotesField extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      notes: props.notes,
      savedNotes: props.notes,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    // Name was updated
    if (prevProps.notes !== this.props.notes) {
      this.setState({
        savedNotes: this.props.notes,
      });
    }
  }

  render() {
    return (
      <div
        style={{
          padding: "10px 10px 5px 0px",
        }}
      >
        <textarea
          value={this.state.notes}
          placeholder={"Notes"}
          rows={2}
          style={{
            width: "100%",
            position: "relative",
            padding: "2px 10px",
            fontFamily: "inherit",
            border: "none",
            outline: "none",
            borderRadius: "5px",
            fontWeight: 500,
            fontSize: "14px",
            background: "#f7f7f7",
            marginBottom: "5px",
            resize: "vertical",
          }}
          onChange={(e) => {
            this.setState({ notes: e.target.value });
          }}
        />
        {this.state.notes !== this.state.savedNotes && (
          <div
            style={{
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <CustomButtonSmall
              color={"#4b7bec"}
              inverted={false}
              borderOnly={true}
              text={"Save"}
              loading={this.state.loading}
              onClick={async () => {
                this.setState({ loading: true });
                await this.props.onUpdateTenantNotes(this.state.notes);
                this.setState({ loading: false });
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default TenantNotesField;
