import React, { PureComponent } from "react";
import AuthenticationWindow from "../AuthenticationWindow/AuthenticationWindow";
import { ReactComponent as Logo } from "../assets/logo_white.svg";
import "./DedicatedLoginPage.css";

interface Props {
  showing: boolean;
  onSuccessfulLogin: () => void;
  onSuccessfulSignOut: () => void;
  recievedAuthState: () => void;
  onShowPrivacyPolicy: () => void;
  onShowTermsOfService: () => void;
  showGenericModal: (title: string, body: string, unhidable?: boolean) => void;
  onUpdateProgress: (progress: number) => void;
}

class DedicatedLoginPage extends PureComponent<Props> {
  render() {
    return (
      <div
        className="DedicatedLoginWrapper"
        style={{
          //backgroundImage: 'url(' + pattern + ')',
          // Hides the page above the top of the screen when login page is not showing
          transform: "translateY(" + (this.props.showing ? "0" : "-100%") + ")",
          transition: "background-color 200ms, transform 200ms, background-position 200ms",
        }}
      >
        <div className="DedicatedLoginContainer">
          <div
            style={{
              width: "100%",
              position: "relative",
              boxSizing: "border-box",
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                maxWidth: "800px",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginBottom: "35px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  position: "relative",
                  padding: "25px 10px 15px 10px",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <div
                    style={{
                      fontSize: "32px",
                      color: "#fff",
                      fontFamily: '"Roboto Slab", sans-serif',
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 500,
                    }}
                  >
                    <Logo className="DedicatedLoginLogo" />
                    <div>Rentingway</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                  boxSizing: "border-box",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-50%)",
                    padding: "15px 10px",
                    display: "inline-block",
                  }}
                >
                  <AuthenticationWindow
                    onSuccessfulLogin={this.props.onSuccessfulLogin}
                    onSuccessfulSignOut={this.props.onSuccessfulSignOut}
                    recievedAuthState={this.props.recievedAuthState}
                    onShowPrivacyPolicy={this.props.onShowPrivacyPolicy}
                    onShowTermsOfService={this.props.onShowTermsOfService}
                    showGenericModal={this.props.showGenericModal}
                    onUpdateProgress={this.props.onUpdateProgress}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DedicatedLoginPage;
