import React, { PureComponent } from "react";
import Clickable from "../Clickable/Clickable";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Swipeable from "../utils/Swipeable";
import "./ImageViewer.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faExternalLink } from "@fortawesome/pro-solid-svg-icons";
library.add(faTimes, faExternalLink);

export interface Props {}

export interface State {
  showing: boolean;
  imageUrl: string | undefined;
  width: number;
  height: number;
  scale: number;
}

class ImageViewer extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      showing: false,
      imageUrl: undefined,
      width: 0,
      height: 0,
      scale: 1,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  // Hide image viewer
  hide() {
    this.setState({
      showing: false,
    });
  }

  // Show enlarged image in image viewer
  show(imageUrl: string) {
    this.setState({
      showing: true,
      imageUrl: imageUrl,
    });
  }

  updateDimensions() {
    const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    this.setState({ width: w, height: h });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  render() {
    return (
      <div
        className="ImageViewerOverlay"
        style={{
          pointerEvents: this.state.showing ? "auto" : "none",
          top: this.state.showing ? "0" : "-100%",
          touchAction: this.state.showing ? "auto" : "none",
          boxSizing: "border-box",
          willChange: "opacity",
          opacity: this.state.showing ? 1 : 0,
          transition: "opacity 200ms, top 0ms linear " + (this.state.showing ? "0" : "200") + "ms",
        }}
      >
        {/* Top bar */}
        <div className="ImageViewerTopBarWrapper">
          <div
            style={{
              padding: "5px 10px",
              display: "flex",
              flexDirection: "row-reverse",
              position: "relative",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* Close button */}
            <div className="ImageViewerButton">
              <Clickable
                onClick={() => {
                  this.hide();
                }}
              >
                <div className="ImageViewerButtonIcon">
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </Clickable>
            </div>
            {/* Download button */}
            <div className="ImageViewerButton">
              <a
                style={{
                  position: "relative",
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
                href={this.state.imageUrl}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="ImageViewerButtonIcon">
                  <FontAwesomeIcon icon={faExternalLink} />
                </div>
              </a>
            </div>
          </div>
        </div>
        <TransformWrapper
          wheel={{
            touchPadEnabled: true,
            step: 100,
          }}
          onZoomChange={(zoom) => {
            this.setState({ scale: zoom.scale });
          }}
        >
          <TransformComponent>
            <Swipeable
              onSwiped={(e) => {
                const { dir } = e;
                // If fully zoomed out, hide
                if ((dir === "Up" || dir === "Down") && this.state.scale <= 1) this.hide();
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: this.state.width + "px",
                  height: this.state.height + "px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundImage: "url(" + this.state.imageUrl + ")",
                  transform: "translateY(" + (this.state.showing ? "0" : "-20") + "px)",
                  willChange: "transform",
                  transition: "transform 200ms",
                }}
              ></div>
            </Swipeable>
          </TransformComponent>
        </TransformWrapper>
      </div>
    );
  }
}

export default ImageViewer;
