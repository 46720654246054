import React, { PureComponent } from "react";
import LoadingIcon from "../LoadingIcon/LoadingIcon";
import "./CustomButton.css";

interface Props {
  disabled?: boolean;
  submit?: boolean;
  inverted: boolean;
  color: string;
  loading?: boolean;
  text: string;
  onClick?: () => any;
}
interface State {}

class CustomButton extends PureComponent<Props, State> {
  render() {
    return (
      <div
        className="CustomButtonWrapper"
        style={{
          pointerEvents: this.props.disabled || this.props.loading ? "none" : "inherit",
        }}
      >
        <button
          type={this.props.submit ? "submit" : "button"}
          className="CustomButton"
          style={{
            opacity: this.props.disabled || this.props.loading ? "0.3" : "1.0",
            pointerEvents: this.props.disabled || this.props.loading ? "none" : "inherit",
            color: this.props.inverted ? "#fff" : this.props.color,
            backgroundColor: this.props.inverted ? this.props.color : "#fff",
          }}
          onClick={() => {
            if (typeof this.props.onClick === "function") this.props.onClick();
          }}
        >
          {this.props.loading ? <LoadingIcon /> : this.props.text}
        </button>
      </div>
    );
  }
}

export default CustomButton;
