import React, { PureComponent } from "react";

import screenshot1 from "../assets/images/screenshot1.png";
import screenshot2 from "../assets/images/screenshot2.png";
import screenshot3 from "../assets/images/screenshot3.png";
import screenshot4 from "../assets/images/screenshot4.png";
import screenshot5 from "../assets/images/screenshot5.png";
import screenshot6 from "../assets/images/screenshot6.png";

class Content extends PureComponent {
  render() {
    return (
      <div
        className="AllowTextSelection"
        style={{
          width: "100%",
          position: "relative",
          boxSizing: "border-box",
          backgroundColor: "#f7f7f7",
        }}
      >
        <div className="LandingContentContainer">
          <div className="LandingContentItem">
            <div className="LandingContentSmall">
              <img className="LandingContentScreenshot" alt="Connect" src={screenshot1} />
            </div>
            <div className="LandingContentLarge">
              <div className="LandingContentTitle">Connect</div>
              <div className="LandingContentText">
                Add your tenants, landlords, property managers, real estate agents, and everyone else!
              </div>
            </div>
          </div>
        </div>
        <div className="LandingContentContainer">
          <div className="LandingContentItem">
            <div className="LandingContentSmall">
              <img className="LandingContentScreenshot" alt="Just chat" src={screenshot2} />
            </div>
            <div className="LandingContentLarge">
              <div className="LandingContentTitle">Just chat</div>
              <div className="LandingContentText">
                Send messages, payments, photos, work orders, calls, and more right from your chat window. All of your
                rental communication in one convenient place.
              </div>
            </div>
          </div>
        </div>
        <div className="LandingContentContainer">
          <div className="LandingContentItem">
            <div className="LandingContentSmall">
              <img className="LandingContentScreenshot" alt="We’ll organize" src={screenshot3} />
            </div>
            <div className="LandingContentLarge">
              <div className="LandingContentTitle">We’ll organize</div>
              <div className="LandingContentText">
                Payments, work orders, and rentals are automatically organized and tracked in the background. All of
                your communication and rental history are neatly available for easy reference.
              </div>
            </div>
          </div>
        </div>
        <div className="LandingContentContainer">
          <div className="LandingContentItem">
            <div className="LandingContentSmall">
              <img className="LandingContentScreenshot" alt="Pay and get paid" src={screenshot4} />
            </div>
            <div className="LandingContentLarge">
              <div className="LandingContentTitle">Pay and get paid</div>
              <div className="LandingContentText">
                Send and receive all of your payments securely and with ease. We organize and display details and
                statuses in real time to make management easy. All transactions are processed by Dwolla using
                military-grade encryption, so we never see or store your payment information.
              </div>
            </div>
          </div>
        </div>
        <div className="LandingContentContainer">
          <div className="LandingContentItem">
            <div className="LandingContentSmall">
              <img className="LandingContentScreenshot" alt="Made just for you (and everyone else)" src={screenshot5} />
            </div>
            <div className="LandingContentLarge">
              <div className="LandingContentTitle">Made just for you (and everyone else)</div>
              <div className="LandingContentText">
                Whether you manage one unit or hundreds—on your own or part of a company—we were designed to make
                renting fast, easy, and better than ever before. Our system handles residential, commercial, storage,
                and just about anything else.
              </div>
            </div>
          </div>
        </div>
        <div className="LandingContentContainer">
          <div className="LandingContentItem">
            <div className="LandingContentSmall">
              <img className="LandingContentScreenshot" alt="Your online rental identity" src={screenshot6} />
            </div>
            <div className="LandingContentLarge">
              <div className="LandingContentTitle">Your online rental identity</div>
              <div className="LandingContentText">
                Build your online rental profile. Meet new people, review potential new tenants, or learn about your
                landlord before you sign the lease!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Content;
