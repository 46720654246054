import React, { PureComponent } from "react";
import Clickable from "../../Clickable/Clickable";
import ProfilePhoto from "../ProfilePhoto/ProfilePhoto";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle, faPlus, faTimes, faUser } from "@fortawesome/pro-solid-svg-icons";
library.add(faPlusCircle, faMinusCircle, faPlus, faTimes, faUser);

interface Props {
  id: string;
  name: string;
  imageUrl: string;
  adding: boolean;
  onClick: () => any;
}
interface State {}
class AddableUserPreview extends PureComponent<Props, State> {
  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <Clickable
          onClick={() => {
            if (typeof this.props.onClick === "function") {
              this.props.onClick();
            }
          }}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              padding: "0 10px",
              height: "50px",
            }}
          >
            {/* Recipient image */}
            <div
              style={{
                position: "relative",
                width: "50px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "40px",
                  height: "40px",
                }}
              >
                <ProfilePhoto id={this.props.id} name={this.props.name} imageUrl={this.props.imageUrl} size={40} />
              </div>
            </div>
            {/* Recipient name */}
            <div
              style={{
                position: "relative",
                flexGrow: 1,
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%)",
                  padding: "0 10px",
                  fontWeight: this.props.adding ? 400 : 500,
                }}
              >
                {this.props.name}
              </div>
            </div>
            {/* Add/remove symbol */}
            <div
              style={{
                position: "relative",
                padding: "0 5px",
                fontSize: "16px",
                color: this.props.adding ? "#4b7bec" : "#fc5c65",
                transition: "color 200ms",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-50%) rotate(" + (this.props.adding ? "0deg" : "45deg") + ")",
                  transition: "transform 200ms",
                }}
              >
                <FontAwesomeIcon icon={faPlusCircle} />
              </div>
            </div>
          </div>
        </Clickable>
      </div>
    );
  }
}

export default AddableUserPreview;
