import React, { PureComponent } from "react";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Modal from "./Modal";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faBuilding, faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { ITenantWithUser } from "../loadData";
import { IProperty, IUnit } from "../conjure-api";
library.add(faBuilding, faMapMarkerAlt);

interface Props {
  onRefresh: () => void;
}

interface State {
  loading: boolean;
  tenant: ITenantWithUser;
  unit: IUnit;
  property: IProperty;
}

class ModalRemoveTenant extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      // Passed data
      tenant: null,
      unit: null,
      property: null,
    };

    this.handleRemoveTenant = this.handleRemoveTenant.bind(this);
  }

  show(property: IProperty, unit: IUnit, tenant: ITenantWithUser) {
    this.setState({
      tenant: tenant,
      unit: unit,
      property: property,
    });
    this.modal.current.show();
  }
  hide() {
    this.setState({ loading: false });
    this.modal.current.hide();
  }

  async handleRemoveTenant() {
    this.setState({ loading: true });
    await firebase.functions().httpsCallable("removeTenant")({
      propertyId: this.state.property.id,
      unitId: this.state.unit.id,
      tenantId: this.state.tenant.id,
    });
    this.hide();
    this.props.onRefresh();
  }

  render() {
    return (
      <Modal ref={this.modal} title={"Remove Tenant?"}>
        <div className="ModalText">
          Are you sure you want to remove {this.state.tenant?.user.full_name} from {this.state.unit?.unit_name}?
        </div>
        <div className="ModalButtonWrapper">
          <CustomButton
            text={"Remove " + this.state.tenant?.user.full_name}
            inverted={true}
            loading={this.state.loading}
            color={"#eb3b5a"}
            onClick={this.handleRemoveTenant}
          />
        </div>
      </Modal>
    );
  }
}

export default ModalRemoveTenant;
