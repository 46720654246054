import React, { PureComponent } from "react";
import { Plugins, HapticsNotificationType, Capacitor } from "@capacitor/core";

import Modal from "./Modal";
import LoadingIcon from "../CustomComponents/LoadingIcon/LoadingIcon";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/analytics";

// Dwolla IAV UI
import Dwolla from "react-dwolla-iav";

const { Haptics } = Plugins;

interface Props {
  onIAVClosed(): void;
}

interface State {
  ready: boolean;
  showing: boolean;
  dwollaConfig?: {
    backButton?: boolean;
    customerToken: string;
    environment: "prod" | "sandbox";
    fallbackToMicroDeposits?: boolean;
    microDeposits?: boolean;
    stylesheets?: string[];
    subscriber: (mixed: any) => void;
  };
}

class ModalAddBank extends PureComponent<Props, State> {
  modal = React.createRef<Modal>();
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      showing: false,
      dwollaConfig: undefined,
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  show(dwollaCustomerId) {
    //  this.clear();
    this.modal.current.show();
    this.handleIavFlow(dwollaCustomerId);
  }
  hide() {
    this.setState({ ready: false });
    this.modal.current.hide();

    this.props.onIAVClosed();
  }

  async handleIavFlow(dwollaCustomerId) {
    const getCustomerIavToken = firebase.functions().httpsCallable("getCustomerIavToken");
    const response = await getCustomerIavToken({ customerId: dwollaCustomerId });
    const dwollaConfig = {
      backButton: false,
      customerToken: response.data.token,
      environment: response.data.environment,
      fallbackToMicroDeposits: true,
      microDeposits: true,
      stylesheets: [],
      subscriber: () => {},
    };
    this.setState({ ready: true, dwollaConfig: dwollaConfig });
  }

  onSuccess(response: any) {
    // Success haptic when bank account added successfully
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });
    firebase.analytics().logEvent("bank_account_added");
    firebase.analytics().setUserProperties({ bank_account_added: true });
  }

  onError(response: any) {
    // Error haptic when adding bank account fails
    if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.ERROR });

    firebase.analytics().logEvent("bank_account_error");
  }

  render() {
    return (
      <Modal
        ref={this.modal}
        title={"Add New Bank Account"}
        onShow={() => {
          this.setState({ showing: true });
        }}
        onHide={() => {
          this.setState({ showing: false });
          this.props.onIAVClosed();
        }}
      >
        {
          /* Dwolla IAV UI */
          this.state.showing && this.state.ready && (
            <Dwolla onSuccess={this.onSuccess} onError={this.onError} dwollaConfig={this.state.dwollaConfig} />
          )
        }
        {
          /* Loading icon */
          !this.state.ready && (
            <div
              style={{
                position: "relative",
                padding: "10px 0",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              <LoadingIcon />
            </div>
          )
        }
      </Modal>
    );
  }
}

export default ModalAddBank;
