import React, { PureComponent } from "react";
import { Plugins, HapticsImpactStyle, HapticsNotificationType, Capacitor } from "@capacitor/core";

import ChatsMenu from "./ChatsMenu/ChatsMenu";
import Chat from "./Chat/Chat";
import { formatMoneyAmount } from "../HelperFunctions";

import { v4 as uuidv4 } from "uuid";

import { getCurrentUserUid, IMPERSONATION_ENABLED } from "../auth";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMoneyBillWave,
  faTools,
  faRedoAlt,
  faArrowRight,
  faArrowLeft,
  faSearch,
  faPaperPlane,
} from "@fortawesome/pro-solid-svg-icons";
import { Chat as ChatObject, Message as MessageObject } from "./MessagingInterfaces";
library.add(faMoneyBillWave, faTools, faRedoAlt, faArrowRight, faArrowLeft, faSearch, faPaperPlane);

const { Haptics } = Plugins;

// Minimum time delta between messages to show timestamp
const timeDeltaForTimestamp = 30 * 60 * 1000; // 30 minutes
const NO_MESSAGES_TEXT = "No messages";
const MESSAGES_PAGINATION_LENGTH = 30;

interface Props {
  position: number;
  dwollaCustomerType: null | "unverified" | "verified";
  loadingDwolla: boolean;
  primaryFundingSourceId: null | string;
  onAddBank: () => void;
  onTransactionFailed: () => void;
  onExceedUnverifiedTransactionLimit: () => void;
  showCustomModal: (title: string, body: string) => void;
  onViewImage: (imageUrl: string) => void;
  onDeleteChat: (chatId: string) => void;
  onRefresh: () => void;
}

interface State {
  // Whether the screen is narrow enough to put the inbox page (ChatsMenu)
  // and the current chat on separate pages
  narrowView: boolean;
  // Whether or not the inbox (ChatsMenu) is showing
  // Only relevant when narrowView is true
  menuShowing: boolean;
  // Current chat
  currentChats: ChatObject[];
  currentChatId?: string;
  // Whether a chat is being created
  loadingCreateChat: boolean;
  // Loading states
  loadingInbox: boolean;
  loadingChat: boolean;
  loadingMessage: boolean;
  shouldMarkAsRead: boolean;
  waitingForDraft: boolean;
}

// Copied from "Message" interface used by server (functions/src/interfaces.ts)
export interface MessageDocData {
  // I changed this from "recipient_id: any" to "recipient_id?: string"
  // If this is correct, we should also change it on the server side as well
  recipient_id?: string;
  timestamp: number;
  sender_id: string;
  destination_id: string;
  sender_name: string;
  chat_id: string;
  idempotency_key: string;
  // "any" for simplicity, originally:
  // TextMessageData | InvitationMessageData | TransactionMessageData | WorkOrderMessageData | ImageMessageData;
  // As mentioned above, we'll want to use the same interfaces on client and server
  // Relevant server interfaces are in functions/src/interfaces.ts
  data: any;
}

class MessagingPage extends PureComponent<Props, State> {
  cachedUsers: any;
  timeDeltaForTimestamp: number;
  NO_MESSAGES_TEXT: string;
  CHATS_PAGINATION_LENGTH: number;
  MESSAGES_PAGINATION_LENGTH: number;

  ChatsMenu: React.RefObject<ChatsMenu>;
  Chat: React.RefObject<Chat>;

  constructor(props) {
    super(props);
    this.state = {
      narrowView: true,
      menuShowing: true,
      currentChats: [],
      currentChatId: undefined,
      loadingCreateChat: false,
      loadingInbox: true,
      loadingChat: false,
      loadingMessage: false,
      shouldMarkAsRead: false,
      waitingForDraft: false,
    };

    this.ChatsMenu = React.createRef();
    this.Chat = React.createRef();

    // One function to add correct listeners, and another function
    // that the listener will call when data is changed
    // this way the page reflects a live view of the database

    this.addChatsListener = this.addChatsListener.bind(this);
    this.updateChatsForSnapshot = this.updateChatsForSnapshot.bind(this);

    this.updateChatMessagesForSnapshot = this.updateChatMessagesForSnapshot.bind(this);

    this.updateCurrentChatId = this.updateCurrentChatId.bind(this);

    this.sendMessage = this.sendMessage.bind(this);
    this.createChatDraft = this.createChatDraft.bind(this);

    this.getDescriptionOfMessage = this.getDescriptionOfMessage.bind(this);

    this.cachedUsers = {};

    // Dictionary that stores listeners by chatIds,
    // Call the value to unsubscribe
    // Note from Aidan: this is never used; do we want to keep it for any reason?
    // this.unsubscribeListenerForChatId = {};

    this.removeUnseenCountForChatId = this.removeUnseenCountForChatId.bind(this);
    this.localRemoveUnseenCountChat = this.localRemoveUnseenCountChat.bind(this);
    this.createWorkOrder = this.createWorkOrder.bind(this);

    this.updateMarkAsRead = this.updateMarkAsRead.bind(this);
    this.addChatToInbox = this.addChatToInbox.bind(this);
    this.removeChatFromInbox = this.removeChatFromInbox.bind(this);
    this.updateChatInformation = this.updateChatInformation.bind(this);

    this.loadChatItem = this.loadChatItem.bind(this);

    this.loadMoreMessagesForCurrentChat = this.loadMoreMessagesForCurrentChat.bind(this);

    this.addMessagesToChat = this.addMessagesToChat.bind(this);
    this.updateMessagesForChat = this.updateMessagesForChat.bind(this);
  }

  async loadMoreMessagesForCurrentChat() {
    const messages = this.state.currentChats.filter((c) => c.id === this.state.currentChatId)[0].messages;
    const currentOldestTimestamp = messages[0].timestamp;
    const nextMessageBatch = await firebase
      .firestore()
      .collection("chats")
      .doc(this.state.currentChatId)
      .collection("messages")
      .orderBy("timestamp", "desc")
      .startAfter(currentOldestTimestamp)
      .limit(MESSAGES_PAGINATION_LENGTH)
      .get();

    // There is no easy way to check the number of docs in a collection without loading
    // all of them, which defeats the purpose of pagination. The solution to this
    // would be to keep a cloud listener that would keep track of a counter,
    // but I also think this behavior is acceptable. The below logic will
    // show moreMessagesExist as true until it is clicked and an amount of messages
    // less than the maximum is returned, indicating that all messages have been loaded.
    const moreMessagesExistUpdate = nextMessageBatch.docs.length === MESSAGES_PAGINATION_LENGTH;
    this.setState((state) => {
      const chatIndex = state.currentChats.findIndex((chat) => chat.id === this.state.currentChatId);
      const updatedChat = state.currentChats[chatIndex];
      updatedChat.moreMessagesExist = moreMessagesExistUpdate;
      state.currentChats[chatIndex] = updatedChat;
      return state;
    });
    await this.addMessagesToChat(this.state.currentChatId, nextMessageBatch.docs, false);
  }

  async updateMarkAsRead(newBool) {
    await this.setState({ shouldMarkAsRead: newBool }, this.forceUpdate);
  }

  async showChatWithUser(userId: string, propertyId: string, unitId: string) {
    this.setState({ menuShowing: false, loadingChat: true });
    const private_chat_doc_snapshot = await firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("private_messages")
      .doc(userId)
      .get();
    if (private_chat_doc_snapshot.exists) {
      this.updateCurrentChatId(private_chat_doc_snapshot.data().chatId);
    } else {
      await this.createChatDraft([userId], unitId, propertyId, false);
    }
  }

  async handlePayRent(managerId: string, propertyId: string, unitId: string, amount: number) {
    this.setState({ menuShowing: false, loadingChat: true });
    await this.showChatWithUser(managerId, propertyId, unitId);
    this.Chat.current.prefillRentPayment(amount);
  }

  /**
   * Attaches the appropriate listeners to start listening
   * to chats and messages
   */
  addChatsListener() {
    if (firebase.auth().currentUser === null) {
      //no user is logged in
      // Update UI
      this.setState({
        currentChats: [],
        currentChatId: null,
        loadingChat: true,
        loadingInbox: true,
      });
      return;
    }

    const inboxRef = firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("chats")
      .orderBy("last_used", "desc");
    inboxRef.onSnapshot(this.updateChatsForSnapshot);
  }

  /**
   * Updates chats based on the changes detected in the user chats snapshot
   *
   * @param {*} userChatsSnapshot
   */
  async updateChatsForSnapshot(userChatsSnapshot) {
    for (const change of userChatsSnapshot.docChanges()) {
      if (change.type === "added") {
        // Ignore chat if it is deleted
        if (change.doc.data().is_deleted) {
          // Ignoring deleted doc A
          continue;
        } else {
          await this.addChatToInbox(change.doc.id);

          // After chats are loaded in:
          // If no chat is selected, automatically select the first (most recent) chat
          // Make sure not narrow view
          if (!this.state.currentChatId && this.state.currentChats.length > 0 && !this.state.narrowView) {
            this.updateCurrentChatId(this.state.currentChats[0].id);
          }
        }
      } else if (change.type === "modified") {
        //ignore chat if it is deleted
        if (change.doc.data().is_deleted) {
          await this.removeChatFromInbox(change.doc.id);
        } else {
          await this.updateChatInformation(change.doc.id);
        }
      } else if (change.type === "removed") {
        await this.removeChatFromInbox(change.doc.id);
      }
    }

    this.setState({ loadingInbox: false });
  }

  /**
   * Loads a chat given a chatId. Returns all information
   * that could be gathered from just chat document
   * (excluding message collection)
   *
   * @param {*} chatId
   */
  async loadChatItem(chatId) {
    if (!chatId) {
      console.warn("chatId not defined");
      return null;
    }

    const chatDoc = await firebase.firestore().collection("chats").doc(chatId).get();
    // Hack: stop error when chat document does not exist
    if (!chatDoc.exists) {
      console.warn(`Chat ${chatId} does not exist.`);
      return null;
    }
    const chatDocData = chatDoc.data();

    const chatItem: ChatObject = {
      id: chatId,
      members: [],
      isDraft: chatDocData.isDraft,
      isLinked: chatDocData.property_id && chatDocData.unit_id,
      messages: [],
      propertyId: chatDocData.property_id,
      unitId: chatDocData.unit_id,
      ownerId: chatDocData.owner_id,
      unseenCount: 0,
      previewText: "",
      moreMessagesExist: false,
      lastUsed: 0,
    };

    const members = chatDocData.members;
    for (let i = 0; i < members.length; i++) {
      //iterate through members
      const memberId = members[i];
      if (!(memberId in this.cachedUsers)) {
        //not in cache, look up database
        const userData = (await firebase.firestore().collection("users").doc(memberId).get()).data(); //todo use adduser to cache
        this.cachedUsers[memberId] = userData;
      }
      if (memberId !== getCurrentUserUid() && this.cachedUsers[memberId] !== undefined) {
        chatItem.members.push({
          id: memberId,
          imageUrl: this.cachedUsers[memberId].profile_pic_url,
          name: this.cachedUsers[memberId].full_name,
          phone: chatItem.isLinked ? this.cachedUsers[memberId].phone : null,
        });
      }
    }

    // Load other linked information
    if (chatDocData.property_id && chatDocData.unit_id) {
      chatItem.propertyId = chatDocData.property_id;
      chatItem.unitId = chatDocData.unit_id;
    }

    chatItem.propertyName = undefined;
    chatItem.unitName = undefined;
    chatItem.isDraft = chatDocData.isDraft;

    // Load user-specific unseen count
    const userDoc = await firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("chats")
      .doc(chatDoc.id)
      .get();
    chatItem.unseenCount = userDoc.data().unseen_count;

    //check for property link
    if (chatItem.propertyId !== undefined && chatItem.propertyId !== null) {
      const propSnapshot = await firebase.firestore().collection("properties").doc(chatItem.propertyId).get();

      let shouldRemoveLink = false;
      if (propSnapshot.exists) {
        chatItem.propertyName = propSnapshot.data().address;
        chatItem.propertyManagerId = propSnapshot.data().owner_id;

        const unitSnapshot = await firebase
          .firestore()
          .collection("properties")
          .doc(chatItem.propertyId)
          .collection("units")
          .doc(chatItem.unitId)
          .get();
        if (unitSnapshot.exists) {
          chatItem.unitName = unitSnapshot.data().unit_name;
        } else {
          shouldRemoveLink = true;
        }
      } else {
        shouldRemoveLink = true;
      }
      if (shouldRemoveLink) {
        await firebase.firestore().collection("chats").doc(chatItem.id).update({ property_id: null, unit_id: null });
      }
    }

    //if the chatDocument is a draft, have the preview text be 'draft'
    if (!chatDocData.isDraft) {
      const chatMessageDocs = await firebase
        .firestore()
        .collection("chats")
        .doc(chatItem.id)
        .collection("messages")
        .orderBy("timestamp", "desc")
        .limit(1)
        .get();
      const previewText =
        chatMessageDocs.docs.length === 0
          ? NO_MESSAGES_TEXT
          : this.getDescriptionOfMessage(chatMessageDocs.docs[0].data());

      chatItem.lastUsed = parseInt(chatMessageDocs.docs[0].id); // ID of a message is a timestamp.
      chatItem.previewText = previewText;
    } else {
      chatItem.previewText = ""; // "Draft";
      chatItem.lastUsed = Infinity; // Put drafts at top (always most recent)
      chatItem.messages = []; // If draft, initialize messages to empty.
    }

    return chatItem;
  }

  /**
   * Adds a chat to the inbox. Is only
   * called when a completely new chat
   * is detected.
   *
   * @param {*} chatId
   */
  async addChatToInbox(chatId) {
    let currentChatId = this.state.currentChatId;
    if (currentChatId === undefined) {
      // If the currentChatId is null, set it to the first chat.
      currentChatId = chatId;
    }

    const chatItem = await this.loadChatItem(chatId);

    if (!chatItem) {
      return null;
    }

    // Update UI
    this.setState(
      (state) => {
        // If chat already exists (could've been updated concurrently)
        if (state.currentChats.findIndex((chat) => chat.id === chatId) !== -1) return;

        const newState = { ...state };
        if (!(chatItem.isDraft === true && chatItem.ownerId !== getCurrentUserUid())) {
          newState.currentChats.push(chatItem);
          newState.currentChats.sort((a, b) => (a.lastUsed < b.lastUsed ? 1 : -1));
        }
        newState.loadingInbox = false;
        return newState;
      },
      async () => {
        await firebase
          .firestore()
          .collection("chats")
          .doc(chatItem.id)
          .collection("messages")
          .orderBy("timestamp", "desc")
          .limit(MESSAGES_PAGINATION_LENGTH)
          .onSnapshot(async (querySnapshot) => {
            await this.updateChatMessagesForSnapshot(querySnapshot);
          });
      },
    );

    if (this.state.waitingForDraft) {
      this.updateCurrentChatId(chatItem.id);
      this.setState({
        waitingForDraft: false,
      });
    }
  }

  /**
   * Updates an existing chat with new information.
   *
   * @param {*} chatId
   */
  async updateChatInformation(chatId) {
    let chatIndex = this.state.currentChats.findIndex((chat) => chat.id === chatId);
    if (chatIndex === -1) {
      // If chat doesn't currently exist, call addChatToInbox instead.
      await this.addChatToInbox(chatId);
      return;
    }
    const updatedChat = await this.loadChatItem(chatId);

    if (!updatedChat) {
      return null;
    }

    // Update UI
    this.setState(
      (state) => {
        // Loading the chat does not include the messages. Carry over the old version's messages
        chatIndex = this.state.currentChats.findIndex((chat) => chat.id === chatId);
        const oldChat = this.state.currentChats[chatIndex];
        updatedChat.messages = oldChat.messages;

        state.currentChats[chatIndex] = updatedChat;
        return state;
      },
      () => {
        this.forceUpdate();
      },
    );
  }

  /**
   * Removes a chat from the inbox given a chatId
   * @param {*} chatId
   */
  removeChatFromInbox(chatId) {
    this.setState((state) => {
      const newState = { ...state };
      const chatIndex = this.state.currentChats.findIndex((chat) => chat.id === chatId);
      newState.currentChats.splice(chatIndex, 1);
      newState.menuShowing = true;
      return newState;
    }, this.forceUpdate);
  }

  /**
   * Gets the text description for a message object.
   *
   * @param {*} message
   */
  getDescriptionOfMessage(message) {
    let sent;
    switch (message.data.type) {
      case "text":
        return message.data.text;
      case "image":
        sent = message.sender_id === getCurrentUserUid();
        return (sent ? "You " : message.sender_name) + " sent an image";
      case "transaction":
        sent = message.sender_id === getCurrentUserUid();
        return (sent ? "You " : message.sender_name) + " sent " + formatMoneyAmount(message.data.amount);
      case "transaction-request":
        sent = message.sender_id === getCurrentUserUid();
        return (sent ? "You " : message.sender_name) + "requested " + formatMoneyAmount(message.data.amount);
      case "work_order":
        const workOrder_sent = message.sender_id === getCurrentUserUid();
        return (workOrder_sent ? "You" : message.sender_name) + " sent a work order.";
      case "invitation":
        sent = message.sender_id === getCurrentUserUid();
        return (sent ? "You " : message.sender_name) + " sent an invitation";
      case "status":
        sent = message.sender_id === getCurrentUserUid();
        return message.data.text;
      default:
        console.error('Message type "' + message.data.type + '" not implemented');
        return "Error parsing message";
    }
  }

  /**
   * Convert message doc to UI-ready object
   * Returns a MessageObject, or null if could not translate
   *
   * @param {*} messageDoc
   */
  async parseMessageDocData(messageData: MessageDocData, messageId?: string): Promise<MessageObject | null> {
    // const messageData: MessageDocData = messageDoc.data();

    const sent = messageData.sender_id === getCurrentUserUid();
    const temporary = messageId ? false : true;
    const newMessage: MessageObject = {
      // id: messageDoc.id,
      id: messageId,
      idempotencyKey: messageData.idempotency_key,
      timestamp: messageData.timestamp,
      sent: sent,
      showTimestamp: false,
      showSenderName: !sent,
      senderName: messageData.sender_name,
      senderId: messageData.sender_id,
      type: messageData.data.type,
      recipientName: messageData.data.recipientName,
      // Calculated after all messages have been loaded
      firstInBlock: false,
      lastInBlock: false,
      // If no ID is provided, mark as temporary
      temporary: temporary,
    };

    const data = messageData.data;
    switch (newMessage.type) {
      case "text":
        newMessage.text = data["text"];
        break;
      case "transaction":
        newMessage.text = data["text"];
        newMessage.amount = data["amount"];
        newMessage.recurring = data["recurring"];
        newMessage.recurringString = data["recurring_string"];
        newMessage.paymentType = data["payment_type"];
        newMessage.paymentDescription = data["payment_description"];
        break;
      case "image":
        newMessage.imageUrl = data["image_url"];
        break;
      case "work_order":
        // If not a temporary message, get work order data from server
        if (!temporary) {
          const workOrderSnapshot = await firebase
            .firestore()
            .collection("work_orders")
            .doc(messageData.data.work_order_id)
            .get();
          newMessage.subject = workOrderSnapshot.data()["subject"];
          newMessage.text = workOrderSnapshot.data()["text"];
          newMessage.workOrderImageUrls = workOrderSnapshot.data()["image_urls"];
        }
        // Temporary message; parse data from composed doc
        // TODO: Should we always do it this way?
        // Only reason I can think not to is if we add support for updating work orders independenly of their corresponding message
        else {
          newMessage.subject = data["subject"];
          newMessage.text = data["text"];
          newMessage.workOrderImageUrls = data["image_urls"];
        }

        // Neither of these are currently used anywhere:
        // newMessage.workOrderState = workOrderSnapshot.data()["state"];
        // newMessage.showButtons = true;
        // TODO: Consider changing work order messages to allow closing/reopening work orders
        break;
      case "invitation":
        newMessage.invitationPropertyName = data["propertyName"];
        newMessage.invitationUnitName = data["unitName"];
        newMessage.invitationUnitId = data["unitId"];
        newMessage.invitationPropertyId = data["propertyId"];
        newMessage.invitationRentAmount = data["rentAmount"];
        newMessage.invitationRefundableAmount = data["refundableAmount"];
        newMessage.invitationNonRefundableAmount = data["nonrefundableAmount"];
        newMessage.invitationMoveInDate = data["moveInDate"];
        newMessage.invitationFirstMonth = data["firstMonth"];
        newMessage.invitationNotes = data["notes"];
        newMessage.invitationState = data["state"];
        break;
      case "status":
        newMessage.text = data["text"];
        break;
      default:
        console.error(
          'Unimplemented error: message of type "' + newMessage.type + '" was found, which is not implemented',
        );
        return null;
    }
    return newMessage;
  }

  /**
   * Add the message docs to the chat specified
   *
   * @param {string} chatId
   * @param {MessageDoc[]} messageDocs
   */
  async addMessagesToChat(chatId, messageDocs, shouldAutoScroll) {
    let chatIndex = this.state.currentChats.findIndex((c) => c.id === chatId);
    if (chatIndex === -1) {
      console.warn("Aborted message update due to chat not found in current chats; chat may have been deleted.");
      return;
    }

    const newMessages = [];
    for (let i = 0; i < messageDocs.length; i++) {
      const newMessage = await this.parseMessageDocData(messageDocs[i].data(), messageDocs[i].id);
      newMessages.unshift(newMessage);
    }

    //in case has changed since last index grab
    chatIndex = this.state.currentChats.findIndex((c) => c.id === chatId);
    const targetChat = this.state.currentChats[chatIndex];
    if (targetChat.messages === undefined || targetChat.messages.length === 0) {
      // see logic explanation for moreMessagesExist in loadMoreMessagesForCurrentChat function
      targetChat.moreMessagesExist = newMessages.length === MESSAGES_PAGINATION_LENGTH;
      targetChat.messages = [];
    }

    // Combine new messages with existing messages
    targetChat.messages = targetChat.messages.concat(newMessages);

    // Removes duplicate messages https://medium.com/dailyjs/how-to-remove-array-duplicates-in-es6-5daa8789641c
    // TODO: stop using this
    // We should not have to remove duplicates,
    // but messages get added twice when recovering a chat that was just deleted
    targetChat.messages = targetChat.messages.filter(
      (msg_a, index) => targetChat.messages.findIndex((msg_b) => msg_a.id === msg_b.id) === index,
    );

    // messages array is least timestamp to greatest
    // messages[-1] is latest message
    for (let i = 0; i < targetChat.messages.length - 1; i++) {
      const isNewBlockBasedOnTime =
        targetChat.messages[i + 1].timestamp - targetChat.messages[i].timestamp > timeDeltaForTimestamp;
      const isNewBlockBasedOnSender = targetChat.messages[i].senderId !== targetChat.messages[i + 1].senderId;
      targetChat.messages[i + 1].firstInBlock = isNewBlockBasedOnTime || isNewBlockBasedOnSender;
      targetChat.messages[i + 1].showTimestamp = isNewBlockBasedOnTime;
    }

    // Remove temporary messages
    targetChat.messages = this.removeTemporaryMessages(targetChat.messages);
    targetChat.messages = this.updateBlocks(targetChat.messages);

    // Last message's timestamp
    targetChat.lastUsed = targetChat.messages[targetChat.messages.length - 1].timestamp;

    this.setState(
      (state) => {
        const newState = { ...state };
        newState.loadingChat = false;
        newState.currentChats[chatIndex] = targetChat;
        newState.currentChats = state.currentChats.sort((a, b) => {
          return a.lastUsed < b.lastUsed ? 1 : -1;
        });
        return newState;
      },
      () => {
        this.forceUpdate();
        this.Chat.current.forceUpdate();
        if (shouldAutoScroll && chatId === this.state.currentChatId) {
          // If currently visible, smooth scroll to bottom
          this.Chat.current.smoothScrollToBottom();
        }
      },
    );
  }

  /**
   * Update the message docs to the chat specified
   *
   * @param {string} chatId
   * @param {MessageDoc[]} messageDocs
   */

  async updateMessagesForChat(chatId, messageDocs) {
    let chatIndex = this.state.currentChats.findIndex((c) => c.id === chatId);
    if (chatIndex === -1) {
      console.warn("Aborted message update due to chat not found in current chats; chat may have been deleted.");
      return;
    }

    let messages = [];
    for (let i = 0; i < messageDocs.length; i++) {
      const newMessage = await this.parseMessageDocData(messageDocs[i].data(), messageDocs[i].id);
      messages.unshift(newMessage);
    }
    // Remove temporary messages
    messages = this.removeTemporaryMessages(messages);

    //in case has changed since last index grab
    chatIndex = this.state.currentChats.findIndex((c) => c.id === chatId);
    const targetChat = this.state.currentChats[chatIndex];

    for (const updatedMessage of messages) {
      const oldMessageIndex = targetChat.messages.findIndex(
        (message) => message.idempotencyKey === updatedMessage.idempotencyKey,
      );
      targetChat.messages[oldMessageIndex] = updatedMessage;
    }

    // Remove temporary messages
    targetChat.messages = this.removeTemporaryMessages(targetChat.messages);
    targetChat.messages = this.updateBlocks(targetChat.messages);

    this.setState(
      (state) => {
        const newState = { ...state };
        newState.loadingChat = false;
        newState.currentChats[chatIndex] = targetChat;
        return newState;
      },
      () => {
        this.forceUpdate();
        this.Chat.current.forceUpdate();
      },
    );
  }

  //takes a snapshot of a chat and updates the messages
  async updateChatMessagesForSnapshot(snapshot) {
    if (snapshot.docs.length === 0) {
      return;
    } //no messages, just return. this can happen in draft mode.
    const chatId = snapshot.docs[0].ref.parent.parent.id;
    const newMessages = snapshot
      .docChanges()
      .filter((changeData) => changeData.type === "added")
      .map((changeData) => changeData.doc);
    await this.addMessagesToChat(chatId, newMessages, true);

    const updatedMessages = snapshot
      .docChanges()
      .filter((changeData) => changeData.type === "modified")
      .map((changeData) => changeData.doc);
    await this.updateMessagesForChat(chatId, updatedMessages);

    /**
     * Deleting messages is not currently supported to ignoring
     * docChanges with "removed"
     */
  }

  /**
   * Sends a message to a propertyId specified. This
   * function is called from ModalMessageProperty.
   * @param {*} propertyId
   * @param {*} text
   */
  async sendMessageToProperty(propertyId, text) {
    const unitsCollectionSnapshot = await firebase
      .firestore()
      .collection("properties")
      .doc(propertyId)
      .collection("units")
      .get();
    const tenants = [];
    for (const doc of unitsCollectionSnapshot.docs) {
      const querySnapshot = await firebase
        .firestore()
        .collection("properties")
        .doc(propertyId)
        .collection("units")
        .doc(doc.id)
        .collection("tenants")
        .get();

      for (const doc of querySnapshot.docs) {
        tenants.push(doc.id);
      }
    }
    for (const t of tenants) {
      const chatId = await this.createChatDraft([t], null, null, true);
      await this.sendMessage(chatId, { type: "text", text: text });
    }
  }

  // Remove temporary messages from the current chat, if one is selected
  removeCurrentChatTemporaryMessages() {
    if (this.state.currentChatId) {
      this.setState((state) => {
        const chatIndex = state.currentChats.findIndex((chat) => chat.id === this.state.currentChatId);
        const updatedChat = state.currentChats[chatIndex];
        updatedChat.messages = this.removeTemporaryMessages(updatedChat.messages);
        state.currentChats[chatIndex] = updatedChat;
        return state;
      }, this.forceUpdate);
    }
  }

  /**
   * Given a data object (TODO state requirements) and a
   * chatId, send a message to the chat
   *
   * @param {*} chatId
   * @param {*} data
   */
  async sendMessage(chatId, data) {
    const d = new Date();
    const time = d.getTime();
    const chatData = await firebase.firestore().collection("chats").doc(chatId.toString()).get();

    const destinationId =
      chatData.data().members[0] === getCurrentUserUid() ? chatData.data().members[1] : chatData.data().members[0];

    const messageDocData: MessageDocData = {
      timestamp: time,
      sender_id: getCurrentUserUid(),
      destination_id: destinationId,
      sender_name: firebase.auth().currentUser.displayName,
      chat_id: chatId.toString(),
      idempotency_key: uuidv4(),
      data: data,
    };

    // Locally add message to chat for better UX
    try {
      await this.localAddMessageToCurrentChat(messageDocData);
    } catch (error) {
      console.warn("Could not add message locally", error);
    }

    firebase.analytics().logEvent("messaged_" + data.type);
    this.setState({ loadingMessage: true });
    const result = await firebase.functions().httpsCallable("sendMessage")(messageDocData);
    this.setState({ loadingMessage: false });
    if (!result.data.success) {
      // TODO: Catch case where unverified user exceeds weekly limit
      if (false) {
        if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.ERROR });
        this.props.onExceedUnverifiedTransactionLimit();
        this.removeCurrentChatTemporaryMessages();
      }

      if (result.data.error.showModal) {
        // Error haptic when message failed to send for a known reason
        if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.ERROR });
        this.props.showCustomModal(result.data.error.title, result.data.error.body);
        this.removeCurrentChatTemporaryMessages();
      }
    } else {
      // Success haptic when a payment is sent successfully
      if (data.type === "transaction") {
        if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.SUCCESS });
      }
    }
  }

  /**
   * Calls the cloud function createWorkOrder to create a work
   * order with the given parameters
   * @param {} data {subject: string, text: string, image_urls: [string]}
   * @param {*} chat_id string
   * @param {*} message_id string
   * @param {*} destination_id string
   * @param {*} property_id string?
   * @param {*} unit_id string?
   */
  async createWorkOrder(data, chat_id, message_id, destination_id, property_id, unit_id) {
    // Create a work order in the work order database
    // Attach the work order to the chat message path, and return the work order ID
    // let workOrderDoc = await firebase.firestore().collection("work_orders").doc();
    const workOrder = {
      subject: data.subject === undefined ? null : data.subject,
      text: data.text === undefined ? null : data.text,
      image_urls: data.image_urls === undefined ? null : data.image_urls,
      sender_id: getCurrentUserUid(),
      chat_id: chat_id,
      message_id: message_id,
      timestamp: parseInt(message_id),
      destination_id: destination_id,
      property_id: property_id,
      unit_id: unit_id,
      status: "sent", // State is sent, working, complete
      open: true,
    };
    const result = await firebase.functions().httpsCallable("createWorkOrder")(workOrder);
    return result.data.id;
  }

  // Handle successful login, passed from LoginPage.js to App.js
  handleSuccessfulLogin() {
    this.setState({ menuShowing: true });
    // Get current chats
    this.addChatsListener();
  }

  /**
   * Locally adds a message to the current chat
   * improves UX while message is loading.
   * @param {*} messageDocData
   */
  async localAddMessageToCurrentChat(messageDocData: MessageDocData) {
    // Parse temporary messages
    const parsedMessage = await this.parseMessageDocData(messageDocData);

    const currentChats: ChatObject[] = this.state.currentChats;
    const currentChatIndex: number = this.state.currentChats.findIndex((c) => c.id === this.state.currentChatId);
    const currentChat: ChatObject = this.state.currentChats[currentChatIndex];
    let currentChatMessages: MessageObject[] = currentChat.messages;
    currentChatMessages.push(parsedMessage);
    currentChatMessages = this.updateBlocks(currentChatMessages);

    this.setState({ currentChats }, () => {
      this.forceUpdate();
      this.Chat.current.smoothScrollToBottom();
    });
  }

  // Remove temporary messages when getting an update from the server
  removeTemporaryMessages(messages: MessageObject[]): MessageObject[] {
    const newMessages = [];
    messages.forEach((message) => {
      if (!message.temporary) newMessages.push(message);
    });
    return newMessages;
  }

  // Update blocks and timestamps
  updateBlocks(messages: MessageObject[]) {
    // Make a copy of messages to return
    messages = [...messages];

    // messages array is least timestamp to greatest
    // messages[-1] is latest message
    for (let i = 0; i < messages.length - 1; i++) {
      const isNewBlockBasedOnTime = messages[i + 1].timestamp - messages[i].timestamp > timeDeltaForTimestamp;
      const isNewBlockBasedOnSender = messages[i].senderId !== messages[i + 1].senderId;
      messages[i + 1].firstInBlock = isNewBlockBasedOnTime || isNewBlockBasedOnSender;
      messages[i + 1].showTimestamp = isNewBlockBasedOnTime;
    }

    // Update lastInBlock values based on whether the next message is the first in its block
    for (let i = 0; i < messages.length - 1; i++) {
      messages[i].lastInBlock = messages[i + 1].firstInBlock;
    }

    if (messages.length > 0) {
      // Show the timestamp of the first message
      messages[0].showTimestamp = true;
      messages[0].firstInBlock = true;

      // Make the last message is the last in its block
      messages[messages.length - 1].lastInBlock = true;
    }

    // Sort messages by timestamp
    messages.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
    return messages;
  }

  /**
   * Locally removes unseen notification of current chat to
   * improve UX while message is loading
   */
  localRemoveUnseenCountChat(chatId: string) {
    this.setState(
      (state) => {
        const chats = state.currentChats.map((chat) => (chat.id === chatId ? { ...chat, unseenCount: 0 } : chat));
        return { ...state, chats };
        // const chatIndex = state.currentChats.findIndex((chat) => chat.id === chatId);
        // const updatedChat = state.currentChats[chatIndex];
        // console.log(updatedChat);
        // updatedChat.unseenCount = 0;
        // state.currentChats[chatIndex] = updatedChat;
        // return state;
      },
      () => {
        this.forceUpdate();
        this.ChatsMenu.current.forceUpdate();
        this.Chat.current.forceUpdate();
      },
    );
  }

  // Create new chat and send a message
  // A chat is in 'draft' state until the owner of the chat sends a message.
  // This allows for easy use of standard UI, because it is another chat getting
  // pulled from server, but it won't show up as an empty chat for other users
  // included in the draft until a message is sent.
  async createChatDraft(otherMemberIds, unitId, propertyId, dontUpdateChatId) {
    // First check if private message that already is created
    this.setState({ loadingInbox: true });
    if (otherMemberIds.length === 1) {
      const privateMessageDoc = await firebase
        .firestore()
        .collection("users")
        .doc(getCurrentUserUid())
        .collection("private_messages")
        .doc(otherMemberIds[0])
        .get();

      if (privateMessageDoc.exists) {
        // If chat was never found, make a new chat
        const privateChatId = privateMessageDoc.data().chatId;
        if (this.state.currentChats.findIndex((chat) => chat.id === privateChatId) === -1) {
          // Current chat exists but is not present in current inbox
          await firebase
            .firestore()
            .doc(`users/${getCurrentUserUid()}/chats/${privateChatId}`)
            .update({ is_deleted: false });

          await this.addChatToInbox(privateChatId);
        }
        if (!dontUpdateChatId) {
          await this.updateCurrentChatId(privateChatId);
        }
        this.setState({ loadingInbox: false });
        return privateChatId;
      }
    }
    const chatDoc = firebase.firestore().collection("chats").doc();
    if (otherMemberIds.length === 1) {
      // Add to private messages
      await firebase
        .firestore()
        .collection("users")
        .doc(getCurrentUserUid())
        .collection("private_messages")
        .doc(otherMemberIds[0])
        .set({ chatId: chatDoc.id });
    }

    const allMembers = otherMemberIds;
    allMembers.push(getCurrentUserUid());

    // TODO: Is there a server-side ChatDoc interface we can use?
    const chatData = {
      members: allMembers,
      owner_id: getCurrentUserUid(),
      type: otherMemberIds.length > 1 ? "group" : "private",
      isDraft: true,
      unit_id: unitId ? unitId : null,
      property_id: propertyId ? unitId : null,
    };

    chatDoc.set(chatData);

    if (otherMemberIds.length === 1) {
      // Add to private messages
      await firebase
        .firestore()
        .collection("users")
        .doc(getCurrentUserUid())
        .collection("private_messages")
        .doc(otherMemberIds[0])
        .set({ chatId: chatDoc.id });
    }
    const d = new Date();
    const time = d.getTime();

    // Add this draft to the owner's chats, but don't add to the other members yet
    await firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("chats")
      .doc(chatDoc.id)
      .set({ last_used: time, unseen_count: 0 });

    if (dontUpdateChatId !== undefined && !dontUpdateChatId) {
      this.setState({
        waitingForDraft: true,
      });
    }
    this.setState({ loadingInbox: false });
    return chatDoc.id;
  }

  async handleCreateChat(members) {
    this.setState({ loadingCreateChat: true, loadingChat: true });

    // Create a new chat draft
    const memberIds = members.map((v) => v.id);
    await this.createChatDraft(memberIds, null, null, false);

    this.setState({ loadingCreateChat: false, loadingChat: false });
  }

  async deleteChat(chatId) {
    const chatIndex = this.state.currentChats.findIndex((c) => c.id === chatId);
    if (chatIndex === -1) {
      console.error("could not delete chat because not currently loaded.");
      return;
    }
    if (chatId === this.state.currentChatId) {
      this.setState({
        currentChatId: null,
      });
    }

    await firebase
      .firestore()
      .collection("users")
      .doc(getCurrentUserUid())
      .collection("chats")
      .doc(chatId)
      .update({ is_deleted: true, unseen_count: 0 });
  }
  /**
   *
   * @param {*} chatId
   */
  updateCurrentChatId(chatId) {
    const newChatIndex = this.state.currentChats.findIndex((chat) => chat.id === chatId);
    const newChatMessagesNotReady =
      this.state.currentChats[newChatIndex]?.messages === undefined && !this.state.currentChats[newChatIndex]?.isDraft;

    // Set new currentChatId
    this.setState(
      {
        loadingChat: newChatMessagesNotReady,
        currentChatId: chatId,
        menuShowing: false,
      },
      () => {
        this.Chat.current.clear();
        this.Chat.current.instantScrollToBottom();
      },
    );

    this.removeUnseenCountForChatId(chatId);
  }

  removeUnseenCountForChatId(chatId) {
    this.localRemoveUnseenCountChat(chatId);
    if (!IMPERSONATION_ENABLED) {
      firebase.functions().httpsCallable("removeSelfUnseenCount")({
        chatId: chatId,
        uid: getCurrentUserUid(),
      });
    }
  }

  // Determine whether the window is narrow enough to use the narrow view
  updateDimensions() {
    if (window.innerWidth < 860) {
      this.setState({ narrowView: true });
    } else {
      this.setState({ narrowView: false });
    }
  }

  // Listen for window resize
  componentDidMount() {
    // Handle updating dimensions
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    window.addEventListener("orientationchange", this.updateDimensions.bind(this));

    if (this.state.currentChatId) {
      this.removeUnseenCountForChatId(this.state.currentChatId);
    }
  }

  // Remove resize event listener
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
    window.removeEventListener("orientationchange", this.updateDimensions.bind(this));
  }

  render() {
    // Default to blank data if no chat is selected (currentChat is undefined)
    let messages = [];
    let moreMessagesExist = false;
    let isLinked = false;
    let propertyName = undefined;
    let unitName = undefined;
    let members = [];
    let isManager = false;

    // Update data if the current chat exists
    const currentChat = this.state.currentChats.filter((chat) => chat.id === this.state.currentChatId)[0];
    if (currentChat) {
      messages = currentChat.messages;
      moreMessagesExist = currentChat.moreMessagesExist;
      isLinked = currentChat.isLinked;
      propertyName = currentChat.propertyName;
      unitName = currentChat.unitName;
      members = currentChat.members;
      isManager = firebase.auth().currentUser && currentChat.propertyManagerId === getCurrentUserUid();
    }

    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          boxSizing: "border-box",
          overflow: "hidden",
          transform: "translateX(" + (this.props.position === 0 ? "0" : this.props.position * 100 + "%") + ")",
          transition: "transform 200ms",
          zIndex: 0,
        }}
      >
        <div
          style={{
            width: this.state.narrowView ? "200%" : "100%",
            transform: "translate(" + (!this.state.narrowView || this.state.menuShowing ? "0" : "-50%") + ")",
            willChange: "transform",
            transition: "transform 200ms",
            height: "100%",
            position: "absolute",
            display: "flex",
            overflow: "hidden",
            borderWidth: "1px solid green",
          }}
        >
          {/* Inbox */}
          <div
            style={{
              position: "relative",
              width: this.state.narrowView ? "50%" : "400px",
              height: "100%",
            }}
          >
            <ChatsMenu
              ref={this.ChatsMenu}
              narrowView={this.state.narrowView}
              chats={this.state.currentChats}
              currentChatId={this.state.currentChatId}
              loadingCreateChat={this.state.loadingCreateChat}
              loadingInbox={this.state.loadingInbox}
              onShowChat={(chatId) => {
                this.updateCurrentChatId(chatId);
                this.Chat.current.clear();
              }}
              onCreateChat={(members) => {
                this.handleCreateChat(members);
              }}
            />
          </div>
          {/* Current conversation */}
          <div
            style={{
              position: "relative",
              flexGrow: 1,
              height: "100%",
            }}
          >
            <Chat
              ref={this.Chat}
              narrowView={this.state.narrowView}
              messages={messages}
              moreMessagesExist={moreMessagesExist}
              isLinked={isLinked}
              propertyName={propertyName}
              unitName={unitName}
              members={members}
              isManager={isManager}
              loading={this.state.loadingChat}
              loadingMessage={this.state.loadingMessage}
              dwollaCustomerType={this.props.dwollaCustomerType}
              loadingDwolla={this.props.loadingDwolla}
              primaryFundingSourceId={this.props.primaryFundingSourceId}
              onAddBank={this.props.onAddBank}
              onShowMenu={() => {
                this.setState({ menuShowing: true });
                // @ts-ignore
                if ("activeElement" in document) document.activeElement.blur();
              }}
              onDeleteCurrentChat={() => {
                this.props.onDeleteChat(this.state.currentChatId);
              }}
              onSendMessage={(message) => {
                if (this.state.currentChatId === undefined) {
                  console.error("An error occurred, chatId was undefined");
                }
                this.sendMessage(this.state.currentChatId, message);
              }}
              onAcceptInvitation={async (
                messageId,
                unitId,
                propertyId,
                moveInDate,
                firstMonth,
                nonRefundableAmount,
                refundableAmount,
                rentAmount,
                notes,
              ) => {
                const respondToInvitationFunction = await firebase.functions().httpsCallable("respondToInvitation");
                await respondToInvitationFunction({
                  messageId: messageId,
                  chatId: this.state.currentChatId,
                  unitId: unitId,
                  propertyId: propertyId,
                  moveInDate: moveInDate,
                  firstMonth: firstMonth,
                  nonRefundableAmount: nonRefundableAmount,
                  refundableAmount: refundableAmount,
                  rentAmount: rentAmount,
                  notes: notes,
                  accepted: true,
                });

                this.props.onRefresh();

                // Success haptic when user accepts an invitation
                if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Medium });

                firebase.analytics().logEvent("property_invitation_accepted");
                firebase.analytics().setUserProperties({ has_accepted_invitation: true });
              }}
              onDeclineInvitation={async (messageId) => {
                const respondToInvitationFunction = await firebase.functions().httpsCallable("respondToInvitation");
                await respondToInvitationFunction({
                  messageId: messageId,
                  chatId: this.state.currentChatId,
                  accepted: false,
                });
                firebase.analytics().logEvent("property_invitation_declined");
                firebase.analytics().setUserProperties({ has_declined_invitation: true });
              }}
              onViewImage={(imageUrl) => {
                this.props.onViewImage(imageUrl);
              }}
              onLoadMoreMessages={async () => {
                await this.loadMoreMessagesForCurrentChat();
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MessagingPage;
