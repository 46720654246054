import {
  IProperty,
  IRentingwayUser,
  ITenant,
  ITransfer as _ITransfer,
  IUnit,
  IWorkOrder,
  IScheduledTransfer,
} from "./conjure-api";
import FirestoreConverter from "./utils/FirestoreConverter";

import { orderBy } from "natural-orderby";
import firebase from "firebase/app";
import calculateAllTimeRentOwed from "./ManagePage/CalculateAllTimeRentOwed";
import { getCurrentUserUid } from "./auth";

export interface ITransfer extends _ITransfer {
  timestamp: firebase.firestore.Timestamp;
}
export interface IPropertyWithUnits extends IProperty {
  units: IUnitWithTenants[];
  owner: IRentingwayUser;
  allTimeRentOwed: number;
  rentPaidExcludingOverpayment: number; // Excludes overpayments by unit
  rentOccupiedUnits: number;
}
export interface IUnitWithTenants extends IUnit {
  allTimeRentOwed: number;
  rentPaid: number;
  propertyName: string;
  tenants: ITenantWithUser[];
}
export interface ITenantWithUser extends ITenant {
  rentPaid: number;
  user: IRentingwayUserWithTransfersWorkOrders;
}
export interface IRentingwayUserWithTransfersWorkOrders extends IRentingwayUser {
  transfers: ITransferWithNames[];
  workOrders: IWorkOrderWithPeer[];
}
export interface ITransferWithNames extends ITransfer {
  names: { source: string; destination: string };
}

export interface IScheduledTransferWithNames extends IScheduledTransfer {
  transfer: ITransferWithNames;
}
export interface IWorkOrderWithPeer extends IWorkOrder {
  peer: IRentingwayUser;
}

/**
 * Load all Property, Unit, and Tenant documents visible to this user.
 * This includes all units in which this user is a tenant
 * and all properties owned by this user.
 * @param userId
 */
export async function loadManagePageData(): Promise<{
  properties: IPropertyWithUnits[];
  transfers: ITransferWithNames[];
  scheduledTransfers: IScheduledTransferWithNames[];
  otherUsers: IRentingwayUserWithTransfersWorkOrders[];
  workOrders: IWorkOrderWithPeer[];
}> {
  const userId = getCurrentUserUid();
  if (userId === null) {
    console.warn("current user is null");
  }
  const db = firebase.firestore();

  const propertiesAndUnits = await getPropertiesToUnitsForUserAsTenant(userId);
  const allTransfers: ITransferWithNames[] = await loadTransfers(propertiesAndUnits);
  const matchedTransferIds: string[] = [];
  const allWorkOrders: IWorkOrderWithPeer[] = await loadWorkOrders(propertiesAndUnits);
  const matchedWorkOrderIds: string[] = [];

  async function getTenantsForUnit(
    unitDoc: firebase.firestore.QueryDocumentSnapshot<IUnit>,
  ): Promise<ITenantWithUser[]> {
    const propertyId = unitDoc.ref.parent.parent.id;
    const unitId = unitDoc.id;
    const tenantsCollection = await unitDoc.ref
      .collection("tenants")
      .withConverter(new FirestoreConverter<ITenant>())
      .get();
    return Promise.all(
      tenantsCollection.docs.map(async (tenantDoc) => {
        const user = await getUserData(tenantDoc.data().id);

        const transfers = allTransfers.filter((x) => transferMatchesTenant(x, propertyId, unitId, tenantDoc.id));
        matchedTransferIds.push(...transfers.map((transfer) => transfer.transferId));

        const workOrders = allWorkOrders.filter((x) => workOrderMatchesTenant(x, propertyId, unitId, tenantDoc.id));
        matchedWorkOrderIds.push(...workOrders.map((workOrder) => workOrder.id));

        const rentPaid = getRentPaidFromTransfersForTenant(transfers, tenantDoc.id);
        return { ...tenantDoc.data(), rentPaid, user: { ...user, transfers, workOrders } };
      }),
    );
  }

  const properties: IPropertyWithUnits[] = await Promise.all(
    Array.from(propertiesAndUnits.entries()).map(async ([propertyId, unitIds]) => {
      const propertyRef = db
        .collection("properties")
        .doc(propertyId)
        .withConverter(new FirestoreConverter<IProperty>());
      const propertyDoc = await propertyRef.get();
      const units = await Promise.all(
        unitIds.map(async (unitId) => {
          const unitRef = propertyRef.collection("units").doc(unitId).withConverter(new FirestoreConverter<IUnit>());
          const unitDoc = await unitRef.get();
          const unitData = unitDoc.data();
          const tenants = await getTenantsForUnit(unitDoc);

          const rentPaid = getRentPaidFromTenants(tenants);
          const allTimeRentOwed = tenants.length > 0 ? calculateAllTimeRentOwed(unitData) : 0;
          const propertyName = getPropertyName(propertyDoc.data());

          return { ...unitData, allTimeRentOwed, rentPaid, tenants, propertyName };
        }),
      );
      return {
        ...propertyDoc.data(),
        owner: await getUserData(propertyDoc.data().owner_id),
        units,
        allTimeRentOwed: units.reduce((currentVal, unit) => currentVal + unit.allTimeRentOwed, 0),
        rentPaidExcludingOverpayment: units.reduce(
          (currentVal, unit) => currentVal + Math.min(unit.rentPaid, unit.allTimeRentOwed),
          0,
        ),
        rentOccupiedUnits: units
          .filter((unit) => unit.tenants.length > 0)
          .reduce((currentVal, unit) => currentVal + unit.rent, 0),
      };
    }),
  );

  const propertiesOwned = await db
    .collection("properties")
    .where("owner_id", "==", userId)
    .withConverter(new FirestoreConverter<IProperty>())
    .get();

  properties.push(
    ...(await Promise.all(
      propertiesOwned.docs.map(async (property) => {
        const units = await property.ref.collection("units").withConverter(new FirestoreConverter<IUnit>()).get();
        const unitsWithTenants = await Promise.all(
          units.docs.map(async (unitDoc) => {
            const unitData = unitDoc.data();
            const tenants = await getTenantsForUnit(unitDoc);

            const allTimeRentOwed = tenants.length > 0 ? calculateAllTimeRentOwed(unitData) : 0;
            const rentPaid = getRentPaidFromTenants(tenants);
            const propertyName = getPropertyName(property.data());

            return { ...unitData, allTimeRentOwed, rentPaid, tenants, propertyName };
          }),
        );
        return {
          ...property.data(),
          owner: await getUserData(property.data().owner_id),
          units: unitsWithTenants,
          allTimeRentOwed: unitsWithTenants.reduce((currentVal, unit) => currentVal + unit.allTimeRentOwed, 0),
          rentPaidExcludingOverpayment: unitsWithTenants.reduce(
            (currentVal, unit) => currentVal + Math.min(unit.rentPaid, unit.allTimeRentOwed),
            0,
          ),
          rentOccupiedUnits: unitsWithTenants
            .filter((unit) => unit.tenants.length > 0)
            .reduce((currentVal, unit) => currentVal + unit.rent, 0),
        };
      }),
    )),
  );

  const otherTransfers: ITransferWithNames[] = allTransfers.filter(
    (transfer) => !matchedTransferIds.includes(transfer.transferId),
  );
  const otherWorkOrders: IWorkOrderWithPeer[] = allWorkOrders.filter(
    (workOrder) => !matchedWorkOrderIds.includes(workOrder.id),
  );
  const otherUsers = await loadOtherUsers(otherTransfers, otherWorkOrders);

  const scheduledTransfers = await Promise.all(
    (
      await db
        .collection("scheduled-transfers")
        .where("owner_id", "==", userId)
        .withConverter(new FirestoreConverter<IScheduledTransfer>())
        .get()
    ).docs.map(async (doc) => ({
      ...doc.data(),
      transfer: {
        ...doc.data().transfer.metadata, // TODO document what's going on with metadat
        names: {
          source: (await getUserData(doc.data().transfer.metadata.source_rentingway_uid)).full_name,
          destination: (await getUserData(doc.data().transfer.metadata.destination_rentingway_uid)).full_name,
        },
      },
    })),
  );

  return {
    properties: sortProperties(properties),
    transfers: allTransfers,
    scheduledTransfers,
    otherUsers,
    workOrders: allWorkOrders,
  };
}

async function loadTransfers(propertiesAndUnits: Map<string, string[]>): Promise<ITransferWithNames[]> {
  const db = firebase.firestore();
  const transfers: ITransferWithNames[] = [];

  for (const [propertyId, unitIds] of propertiesAndUnits.entries()) {
    for (const unitId of unitIds) {
      transfers.push(
        ...(await Promise.all(
          (
            await db
              .collection("transfers")
              .where("linked_property_id", "==", propertyId)
              .where("linked_unit_id", "==", unitId)
              .withConverter(new FirestoreConverter<ITransfer>())
              .get()
          ).docs.map(async (doc) => ({
            ...doc.data(),
            names: {
              source: (await getUserData(doc.data().source_rentingway_uid)).full_name,
              destination: (await getUserData(doc.data().destination_rentingway_uid)).full_name,
            },
          })),
        )),
      );
    }
  }

  transfers.push(
    ...(await Promise.all(
      (
        await db
          .collection("transfers")
          .where("user_ids", "array-contains", getCurrentUserUid())
          .withConverter(new FirestoreConverter<ITransfer>())
          .get()
      ).docs.map(async (doc) => ({
        ...doc.data(),
        names: {
          source: (await getUserData(doc.data().source_rentingway_uid)).full_name,
          destination: (await getUserData(doc.data().destination_rentingway_uid)).full_name,
        },
      })),
    )),
  );

  transfers.sort((a, b) => (a.timestamp <= b.timestamp ? 1 : -1));

  return transfers.filter((v, i, a) => a.findIndex((t) => t.transferId === v.transferId) === i);
}

async function loadWorkOrders(propertiesAndUnits: Map<string, string[]>): Promise<IWorkOrderWithPeer[]> {
  const workOrders: IWorkOrderWithPeer[] = [];

  for (const [propertyId, unitIds] of propertiesAndUnits.entries()) {
    for (const unitId of unitIds) {
      workOrders.push(
        ...(await Promise.all(
          (
            await firebase
              .firestore()
              .collection("work_orders")
              .where("property_id", "==", propertyId)
              .where("unit_id", "==", unitId)
              .withConverter(new FirestoreConverter<IWorkOrder>())
              .get()
          ).docs.map(async (doc) => ({
            ...doc.data(),
            peer: await getUserData(
              doc.data().sender_id === getCurrentUserUid() ? doc.data().destination_id : doc.data().sender_id,
            ),
          })),
        )),
      );
    }
  }

  workOrders.push(...(await loadOtherWorkOrders("sender_id")), ...(await loadOtherWorkOrders("destination_id")));

  return workOrders;
}

async function loadOtherWorkOrders(field: "sender_id" | "destination_id"): Promise<IWorkOrderWithPeer[]> {
  return Promise.all(
    (
      await firebase
        .firestore()
        .collection("work_orders")
        .where(field, "==", getCurrentUserUid())
        .withConverter(new FirestoreConverter<IWorkOrder>())
        .get()
    ).docs.map(async (doc) => ({
      ...doc.data(),
      peer: await getUserData(
        doc.data().sender_id === getCurrentUserUid() ? doc.data().destination_id : doc.data().sender_id,
      ),
    })),
  );
}

/**
 * Load user records not associated with any property, but who have transfers or work orders
 * with the current user.
 */
async function loadOtherUsers(
  otherTransfers: ITransferWithNames[],
  otherWorkOrders: IWorkOrderWithPeer[],
): Promise<IRentingwayUserWithTransfersWorkOrders[]> {
  const otherUserIds: string[] = [
    ...new Set(
      otherTransfers
        .flatMap((transfer) => [transfer.source_rentingway_uid, transfer.destination_rentingway_uid])
        .concat(otherWorkOrders.flatMap((workOrder) => [workOrder.sender_id, workOrder.destination_id]))
        .filter((id) => id !== getCurrentUserUid()),
    ),
  ];
  const otherUsers: IRentingwayUserWithTransfersWorkOrders[] = await Promise.all(
    otherUserIds.map(async (otherUserId) => {
      const user = await getUserData(otherUserId);
      const transfers = otherTransfers.filter(
        (transfer) =>
          transfer.source_rentingway_uid === otherUserId || transfer.destination_rentingway_uid === otherUserId,
      );
      const workOrders = otherWorkOrders.filter(
        (workOrder) => workOrder.sender_id === otherUserId || workOrder.destination_id === otherUserId,
      );
      return { ...user, transfers, workOrders };
    }),
  );
  return otherUsers;
}

/**
 * Gets propertyId, unitId pairs for units where this user is a tenant.
 * @param userId
 */
async function getPropertiesToUnitsForUserAsTenant(userId: string): Promise<Map<string, string[]>> {
  const db = firebase.firestore();

  // const user = await getUserData(userId);
  const tenantDocsForUser = await db.collectionGroup("tenants").where("id", "==", userId).get();

  const output = new Map<string, string[]>();

  for (const tenantDoc of tenantDocsForUser.docs) {
    const tenantRef = tenantDoc.ref;
    const unitRef = tenantRef.parent.parent;
    const propertyRef = unitRef.parent.parent;
    if (output.has(propertyRef.id)) {
      output.get(propertyRef.id).push(unitRef.id);
    } else {
      output.set(propertyRef.id, [unitRef.id]);
    }
  }
  return output;
}

async function getUserData(userId: string): Promise<IRentingwayUser> {
  const db = firebase.firestore();

  const userRef = await db
    .collection("users")
    .doc(userId)
    .withConverter(new FirestoreConverter<IRentingwayUser>())
    .get();
  return userRef.data();
}

/**
 * Sorts all properties by property names, unit names, and tenant names
 */
function sortProperties(properties: IPropertyWithUnits[]) {
  // Sort property names
  const sortedProperties = orderBy(properties, (v) => v.address, "asc");
  // Sort unit names
  for (let i = 0; i < sortedProperties.length; i++) {
    sortedProperties[i].units = orderBy(sortedProperties[i].units, (v) => v.unit_name, "asc");
    // Sort tenant names
    for (let j = 0; j < sortedProperties[i].units.length; j++) {
      sortedProperties[i].units[j].tenants = orderBy(
        sortedProperties[i].units[j].tenants,
        (v) => v.user.full_name,
        "asc",
      );
    }
  }
  return sortedProperties;
}

function transferMatchesTenant(transfer: ITransfer, propertyId: string, unitId: string, tenantId: string) {
  const tenantInvolved =
    transfer.source_rentingway_uid === tenantId || transfer.destination_rentingway_uid === tenantId;
  return transfer.linked_property_id === propertyId && transfer.linked_unit_id === unitId && tenantInvolved;
}

function workOrderMatchesTenant(workOrder: IWorkOrder, propertyId: string, unitId: string, tenantId: string) {
  const tenantInvolved = workOrder.sender_id === tenantId || workOrder.destination_id === tenantId;
  return workOrder.property_id === propertyId && workOrder.unit_id === unitId && tenantInvolved;
}

const getRentPaidFromTenants = (tenants: ITenantWithUser[]): number =>
  tenants.reduce((sum, tenant) => sum + tenant.rentPaid, 0);

const getRentPaidFromTransfersForTenant = (transfers: ITransfer[], tenantId: string): number =>
  transfers.reduce((sum, transfer) => {
    if (transfer.payment_type === "rent" && (transfer.status === "pending" || transfer.status === "processed")) {
      if (transfer.source_rentingway_uid === tenantId) {
        return sum + Number(transfer.amount.value);
      } else if (transfer.destination_rentingway_uid === tenantId) {
        return sum - Number(transfer.amount.value);
      }
    }
    return sum;
  }, 0);

const getPropertyName = (property: IProperty) => `${property.address}, ${property.city}, ${property.state}`;
