import React, { PureComponent } from "react";
import "./Clickable.css";

interface Props {
  inline?: boolean;
  preventDefault?: boolean;
  onClick: () => any;
}
interface State {
  active: boolean;
  touchMoved: boolean;
  touched: boolean;
}
class Clickable extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      // Whether the clickable is active (i.e. clicked down on)
      active: false,
      // Whether the touch has moved
      // If it has, don't fire onClick (i.e. could have been scrolling or swiped away from clickable)
      touchMoved: false,
      // Whether a touch has been started
      // If true, don't bother firing onClick because touch events are supported
      touched: false,
    };
  }

  handleClick() {
    // Unfocus active element
    if ("activeElement" in document) (document.activeElement as HTMLElement).blur();
    if (typeof this.props.onClick === "function") this.props.onClick();
  }

  render() {
    return (
      <div
        className="ClickableButton"
        style={{
          display: this.props.inline ? "inline" : "block",
        }}
        onTouchStart={(e) => {
          if (this.props.preventDefault) {
            e.preventDefault();
          }
          this.setState({ active: true, touchMoved: false, touched: true });
        }}
        onTouchMove={(e) => {
          // Prevent default action if specificied
          if (this.props.preventDefault) {
            e.preventDefault();
          }
          this.setState({ touchMoved: true });
        }}
        onTouchEnd={(e) => {
          e.preventDefault();
          // Touch did not move, register tap
          if (!this.state.touchMoved && this.state.touched) {
            this.handleClick();
          }
          this.setState({ active: false, touched: false });
        }}
        onTouchCancel={() => {
          this.setState({ active: false, touched: false });
        }}
        onMouseDown={() => {
          this.setState({ active: true });
        }}
        onMouseUp={() => {
          this.setState({ active: false });
        }}
        onMouseLeave={() => {
          this.setState({ active: false });
        }}
        onClick={() => {
          if (!this.state.touched) {
            this.handleClick();
          }
          this.setState({ active: false, touched: false });
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Clickable;
