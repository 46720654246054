import React, { PureComponent } from "react";
import LoadingIcon from "../CustomComponents/LoadingIcon/LoadingIcon";
import { Plugins, HapticsImpactStyle, HapticsNotificationType, Capacitor } from "@capacitor/core";
import ManageSelector from "./ManageSelector";
import PaymentsSection from "./PaymentsSection";
import RentalsSection from "./RentalsSection";
import WorkOrdersSection from "./WorkOrdersSection";
import Swipeable from "../utils/Swipeable";
import {
  IPropertyWithUnits,
  IRentingwayUserWithTransfersWorkOrders,
  ITransferWithNames,
  IWorkOrderWithPeer,
  IScheduledTransferWithNames,
} from "../loadData";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";

import "./ManagePage.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBillWave, faTools, faKey, faCheck } from "@fortawesome/pro-solid-svg-icons";
import {
  PayRentCallback,
  MessageUserCallback,
  UpdateWorkOrderStatusCallback,
  RemovePropertyCallback,
  MessagePropertyCallback,
  AddUnitCallback,
  RemoveUnitCallback,
  RemoveTenantCallback,
  AddTenantCallback,
  ShowCustomModalCallback,
  ViewImageCallback,
  UpdateManagerStatusCallback,
  RecordManualPaymentCallback,
  EditUnitCallback,
  RequestRentInfoCallback,
} from "../App";
import { formatMoneyAmount } from "../HelperFunctions";
library.add(faMoneyBillWave, faTools, faKey, faCheck);

const { Haptics } = Plugins;

interface Props {
  loadingFullContent: boolean;
  loadingRefresh: boolean;
  position: number;
  fundingSources: any[];

  otherUsers: IRentingwayUserWithTransfersWorkOrders[];
  properties: IPropertyWithUnits[];
  transfers: ITransferWithNames[];
  scheduledTransfers: IScheduledTransferWithNames[];
  workOrders: IWorkOrderWithPeer[];

  onRefresh: () => void;

  onPayRent: PayRentCallback;
  onMessageUser: MessageUserCallback;
  onUpdateWorkOrderStatus: UpdateWorkOrderStatusCallback;
  onRemoveProperty: RemovePropertyCallback;
  onAddProperty: () => void;
  onMessageProperty: MessagePropertyCallback;
  onAddUnit: AddUnitCallback;

  onRemoveUnit: RemoveUnitCallback;
  onRemoveTenant: RemoveTenantCallback;
  onAddTenant: AddTenantCallback;
  showCustomModal: ShowCustomModalCallback;
  onShowPaymentSchedule: () => void;
  onViewImage: ViewImageCallback;
  onUpdateManagerStatus: UpdateManagerStatusCallback;
  onRecordManualPayment: RecordManualPaymentCallback;
  onEditUnit: EditUnitCallback;
  onRequestRentInfo: RequestRentInfoCallback;
}

interface State {
  // The current page in Manage
  // 0: payments
  // 1: work orders
  // 2: rentals
  currentPage: number;
  // Whether the name or rent of a unit is being changed
  loadingUnitName: boolean;
  loadingUnitRent: boolean;
  // Array of properties
  // Transactions will be added to otherTenants if the transaction's property/unit/tenant
  // path no longer exists; this can happen if a tenant was remvoed from the unit.
  // It does allow the same tenant to be in a different property/unit, but the
  // transaction that was in the old unit will be listed as other.
  // Full transaction history
  scrolled: boolean;
}

class ManagePage extends PureComponent<Props, State> {
  PaymentsSection: React.RefObject<PaymentsSection>;
  WorkOrdersSection: React.RefObject<WorkOrdersSection>;
  RentalsSection: React.RefObject<RentalsSection>;
  updateWorkOrder_CloudFunction: firebase.functions.HttpsCallable;
  cancelTransfer_CloudFunction: firebase.functions.HttpsCallable;

  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      loadingUnitName: false,
      loadingUnitRent: false,
      scrolled: false,
    };

    this.PaymentsSection = React.createRef();
    this.WorkOrdersSection = React.createRef();
    this.RentalsSection = React.createRef();

    this.handleUpdateUnitName = this.handleUpdateUnitName.bind(this);
    this.handleUpdateUnitRent = this.handleUpdateUnitRent.bind(this);

    this.getManagesProperty = this.getManagesProperty.bind(this);

    this.updateWorkOrder_CloudFunction = firebase.functions().httpsCallable("updateWorkOrderStatus");
    this.cancelTransfer_CloudFunction = firebase.functions().httpsCallable("cancelTransfer");
  }

  //updates name for given property-unit path
  async handleUpdateUnitName(propertyId: string, unitId: string, name: string) {
    this.setState({ loadingUnitName: true });
    await firebase.functions().httpsCallable("updateUnitName")({
      propertyId: propertyId,
      unitId: unitId,
      name: name,
    });
    await this.props.onRefresh();
    this.setState({ loadingUnitName: false });
    // await this.loadProperty(await firebase.firestore().collection("properties").doc(property.id).get(), null);
    // this.forceUpdate();
  }

  //updates name for given property-unit path
  async handleUpdateUnitRent(unit, property, rent) {
    this.setState({ loadingUnitRent: true });
    await firebase.functions().httpsCallable("updateUnitRent")({
      propertyId: property.id,
      unitId: unit.id,
      rent: rent,
    });
    this.setState({ loadingUnitRent: false });
  }

  // Updates the work order on the server
  async onUpdateWorkOrderStatus(workOrderId, newStatus) {
    await this.updateWorkOrder_CloudFunction({
      id: workOrderId,
      status: newStatus,
    });
    this.props.onRefresh();
  }

  async handleUpdateTenantNotes(property, unit, tenant, notes) {
    await firebase.functions().httpsCallable("updateTenantNotes")({
      propertyId: property.id,
      unitId: unit.id,
      tenantId: tenant.id,
      notes: notes,
    });
  }

  getManagesProperty(properties: IPropertyWithUnits[]): boolean {
    const userId = firebase.auth().currentUser?.uid;
    return properties.reduce((val, property) => val || property.owner_id === userId, false);
  }

  render() {
    // Payments
    const totalRentOwed = this.props.properties.reduce(
      (currentVal, nextProperty) => currentVal + nextProperty.allTimeRentOwed,
      0,
    );

    const totalRentPaid = this.props.properties.reduce(
      (currentVal, nextProperty) => currentVal + nextProperty.rentPaidExcludingOverpayment,
      0,
    );

    const totalRentDue = totalRentOwed - totalRentPaid;

    // Whether this user manages at least one property
    const managesProperty = this.getManagesProperty(this.props.properties);

    // Rentals
    const totalUnitsRented = this.props.properties.reduce((currentVal, nextProperty) => {
      return currentVal + nextProperty.units.filter((unit) => unit.tenants.length > 0).length;
    }, 0);

    const totalUnits = this.props.properties.reduce((currentVal, nextProperty) => {
      return currentVal + nextProperty.units.length;
    }, 0);

    const openWorkOrders = this.props.workOrders.filter((workOrder) => workOrder.open).length;
    const loadingUpdate = this.state.loadingUnitName || this.state.loadingUnitRent;

    const selectorOptions = [
      {
        icon: faMoneyBillWave,
        title: "Payments",
        subtitle: formatMoneyAmount(totalRentDue) + " due",
      },
      {
        icon: faTools,
        title: "Work Orders",
        subtitle: openWorkOrders + " open",
      },
    ];

    if (managesProperty) {
      selectorOptions.push({
        icon: faKey,
        title: "Details",
        subtitle: totalUnitsRented + " / " + totalUnits + " rented",
      });
    }

    return (
      <Swipeable
        onSwipedLeft={() => {
          let newPage = this.state.currentPage + 1;
          const max = selectorOptions.length - 1;
          if (newPage > max) {
            newPage = max;
            if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.WARNING });
          } else {
            if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
          }
          this.setState({ currentPage: newPage });
        }}
        onSwipedRight={() => {
          let newPage = this.state.currentPage - 1;
          if (newPage < 0) {
            newPage = 0;
            if (Capacitor.isNative) Haptics.notification({ type: HapticsNotificationType.WARNING });
          } else {
            if (Capacitor.isNative) Haptics.impact({ style: HapticsImpactStyle.Light });
          }
          this.setState({ currentPage: newPage });
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            overflow: "hidden",
            transform: "translateX(" + (this.props.position === 0 ? "0" : this.props.position * 100 + "%") + ")",
            transition: "transform 200ms",
            zIndex: 1,
          }}
        >
          <div
            style={{
              width: "100%",
              position: "relative",
              boxSizing: "border-box",
              height: "100%",
              overflow: "hidden",
            }}
          >
            {/* Shimmering loading bar */}
            <div
              style={{
                position: "absolute",
                height: "4px",
                top: "0",
                zIndex: 100,
              }}
            >
              <div
                className="ShimmerLoadingBar"
                style={{
                  height: this.props.loadingRefresh ? "4px" : "0",
                  transition: "height 80ms",
                }}
              ></div>
            </div>
            {/* Saving/saved indicator */}
            <div
              style={{
                position: "sticky",
                height: "0",
                top: "15px",
                zIndex: 100,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  opacity: loadingUpdate ? "1" : "0",
                  left: "50%",
                  backgroundColor: "#f7f7f7",
                  color: "rgba(0,0,0,0.85)",
                  width: "200px",
                  borderRadius: "100px",
                  boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
                  transform: "translateX(-50%) translateY(" + (loadingUpdate ? "0" : "-20px") + ")",
                  // Only show loading indicator if loading is taking longer than 500ms:
                  transitionDelay: loadingUpdate ? "0ms" : "1000ms",
                  transition: "transform 200ms, opacity 200ms",
                  display: "flex",
                  padding: "5px 0",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <span>{loadingUpdate ? <LoadingIcon /> : <FontAwesomeIcon icon={faCheck} />}</span>
                  <span style={{ marginLeft: "10px" }}>{loadingUpdate ? "Saving..." : "Saved"}</span>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                width: "100%",
                height: "100%",
                flexDirection: "column",
              }}
            >
              {/* Static top section */}
              <div
                style={{
                  boxSizing: "border-box",
                  position: "absolute",
                  width: "100%",
                  backgroundColor: "#4b7bec",
                  boxShadow: this.state.scrolled ? "0 0 8px rgba(0, 0, 0, 0.1)" : "none",
                  zIndex: 1001,
                }}
              >
                {/* Wrapper */}
                <div className="ManageContentWrapper">
                  {/* Container */}
                  <div className="ManageContentContainer">
                    <ManageSelector
                      loading={this.props.loadingFullContent}
                      options={selectorOptions}
                      currentPage={this.state.currentPage}
                      onChange={(index) => {
                        this.setState({ currentPage: index });
                      }}
                      scrolled={this.state.scrolled}
                    />
                  </div>
                </div>
              </div>
              {/* Scrollable bottom section */}
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "scroll",
                  position: "relative",
                  zIndex: 1000,
                  paddingTop: "106px",
                }}
                onScroll={(e) => {
                  this.setState({ scrolled: e.target["scrollTop"] > 15 });
                }}
              >
                {/* Wrapper */}
                <div className="ManageContentWrapper" style={{ marginBottom: "60px", marginTop: "25px" }}>
                  {/* Container */}
                  <div className="ManageContentContainer">
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      {/* Sections wrapper */}
                      {!this.props.loadingFullContent && (
                        <div
                          style={{
                            position: "relative",
                            display: "flex",
                          }}
                        >
                          <PaymentsSection
                            showing={this.state.currentPage === 0}
                            properties={this.props.properties}
                            scheduledTransfers={this.props.scheduledTransfers}
                            transfers={this.props.transfers}
                            otherUsers={this.props.otherUsers}
                            loading={this.props.loadingRefresh}
                            onMessageUser={({ userId, propertyId, unitId }) => {
                              this.props.onMessageUser({ userId, propertyId, unitId });
                            }}
                            onPayRent={({ managerId, propertyId, unitId, amount }) => {
                              this.props.onPayRent({ managerId, propertyId, unitId, amount });
                            }}
                            onCancelTransaction={async (transaction) => {
                              const amountString = formatMoneyAmount(+transaction.amount.value);
                              console.log("cancel");
                              console.log(transaction);
                              const result = await this.cancelTransfer_CloudFunction({
                                transferId: transaction.transferId,
                                chatId: transaction.chat_id,
                                amountString: amountString,
                              });
                              if (result.data.status === "cancelled") {
                                this.props.showCustomModal(
                                  "Payment Cancelled",
                                  `Your payment of ${amountString} to ${transaction.names.destination} was cancelled successfully.`,
                                );
                              } else {
                                this.props.showCustomModal(
                                  "Unable to Cancel",
                                  "Unfortuantely we were not able to cancel this payment. Please try again later or contact support for more information.",
                                );
                              }
                              this.props.onRefresh();
                            }}
                            onCancelRecurringTransaction={async (id) => {
                              console.log("begin cancel scheduled transfer");
                              const result = await firebase.functions().httpsCallable("cancelScheduledTransfer")({
                                scheduledTransferId: id,
                              });
                              console.log(result);
                              if (result.data) {
                                this.props.showCustomModal(
                                  "Scheduled Payment Cancelled",
                                  "Payment series cancelled successfully. Future payments will not be sent. If a payment in this series is pending, you must cancel that payment separately.",
                                );
                              } else {
                                this.props.showCustomModal(
                                  "Unable to Cancel",
                                  "Unfortuantely we were not able to cancel this payment series. Please try again later or contact support for more information.",
                                );
                              }
                              this.props.onRefresh();
                            }}
                            onRecordManualPayment={(tenant) => {
                              this.props.onRecordManualPayment(tenant);
                            }}
                            onRefresh={this.props.onRefresh}
                            onRequestRentInfo={this.props.onRequestRentInfo}
                          />
                          <WorkOrdersSection
                            showing={this.state.currentPage === 1}
                            loading={this.props.loadingRefresh}
                            properties={this.props.properties}
                            otherUsers={this.props.otherUsers}
                            // * workOrderId, newStatus
                            onUpdateWorkOrderStatus={(workOrderId, newStatus) => {
                              this.onUpdateWorkOrderStatus(workOrderId, newStatus);
                            }}
                            onViewImage={(imageUrl) => {
                              this.props.onViewImage(imageUrl);
                            }}
                            onMessageUser={({ userId, unitId, propertyId }) => {
                              this.props.onMessageUser({ userId, propertyId, unitId });
                            }}
                            onRefresh={this.props.onRefresh}
                            onRequestRentInfo={this.props.onRequestRentInfo}
                          />
                          {managesProperty && (
                            <RentalsSection
                              showing={this.state.currentPage === 2}
                              loading={this.props.loadingRefresh}
                              properties={this.props.properties}
                              fundingSources={this.props.fundingSources}
                              showCustomModal={this.props.showCustomModal}
                              onEditUnit={(args) => {
                                this.props.onEditUnit(args);
                              }}
                              onRemoveProperty={(args) => {
                                this.props.onRemoveProperty(args);
                              }}
                              onAddProperty={() => {
                                this.props.onAddProperty();
                              }}
                              onMessageProperty={(args) => {
                                this.props.onMessageProperty(args);
                              }}
                              onRemoveUnit={(args) => {
                                this.props.onRemoveUnit(args);
                              }}
                              onAddUnit={(args) => {
                                this.props.onAddUnit(args);
                              }}
                              onRemoveTenant={(args) => {
                                this.props.onRemoveTenant(args);
                              }}
                              onAddTenant={(args) => {
                                this.props.onAddTenant(args);
                              }}
                              onMessageUser={(args) => {
                                this.props.onMessageUser(args);
                              }}
                              onShowPaymentSchedule={() => {
                                this.props.onShowPaymentSchedule();
                              }}
                              onUpdateTenantNotes={({ property, unit, tenant, notes }) => {
                                return this.handleUpdateTenantNotes(property, unit, tenant, notes);
                              }}
                              onRefresh={this.props.onRefresh}
                              onRequestRentInfo={this.props.onRequestRentInfo}
                            />
                          )}
                        </div>
                      )}
                      {this.props.loadingFullContent && (
                        <div
                          style={{
                            position: "relative",
                            paddingTop: "40px",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              position: "relative",
                              fontSize: "24px",
                            }}
                          >
                            <LoadingIcon />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Swipeable>
    );
  }
}

export default ManagePage;

export type GetUnitsCallback = (property: IPropertyWithUnits) => JSX.Element[];
