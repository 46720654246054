import firebase from "firebase/app";
import "firebase/functions";

import { IPropertyWithUnits, ITransferWithNames } from "../loadData";

export async function markTransferAsRent(
  transfer: ITransferWithNames,
  properties: IPropertyWithUnits[],
): Promise<boolean> {
  // find property id and unit id for the sender of this payment

  const senderId = transfer.source_rentingway_uid;

  // find tenant in properties

  let propertyId: string | undefined;
  let unitId: string | undefined;

  outerloop: for (const property of properties) {
    for (const unit of property.units) {
      if (unit.tenants.findIndex((tenant) => tenant.id === senderId) !== -1) {
        propertyId = property.id;
        unitId = unit.id;
        break outerloop;
      }
    }
  }

  if (propertyId === undefined || unitId === undefined) {
    return false;
  }

  try {
    await firebase.functions().httpsCallable("setPropertyAndUnitOfTransfer")({
      transferId: transfer.id,
      propertyId: propertyId,
      unitId: unitId,
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
