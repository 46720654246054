import React, { PureComponent } from "react";

import Clickable from "../../Clickable/Clickable";
import CustomButton from "../../CustomComponents/CustomButton/CustomButton";
import CustomInput from "../../CustomComponents/CustomInput/CustomInput";

// Firebase and input validation
import validator from "validator";
import firebase from "firebase/app";
import "firebase/auth";

import "../AuthenticationWindow.css";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck } from "@fortawesome/pro-solid-svg-icons";
library.add(faUser, faLock, faEnvelope, faRedoAlt, faPhone, faTimes, faCheck);

interface Props {
  showing: boolean;
  onShowForgotPasswordConfirmation: () => void;
  onShowLogin: () => void;
}

interface State {
  loading: boolean;
  input: { email: string };
  errors: { email: string };
}

class ForgotPassword extends PureComponent<Props, State> {
  InputEmail = React.createRef<CustomInput>();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      input: {
        email: "",
      },
      errors: {
        email: "",
      },
    };
  }

  clear() {
    this.InputEmail.current.clear();
    this.setState({
      errors: {
        email: "",
      },
    });
  }

  updateErrors() {
    const errors = this.state.errors;
    let encounteredError = false;

    const email = this.state.input.email.trim();
    if (!validator.isEmail(email)) {
      errors.email = "Please enter a valid email address";
      encounteredError = true;
    }

    if (encounteredError) {
      this.setState(
        {
          loading: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }

    return encounteredError;
  }

  async handleResetPassword() {
    this.setState({ loading: true });

    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    const errors = this.state.errors;
    const email = this.state.input.email.trim();

    await firebase
      .auth()
      .sendPasswordResetEmail(email)
      // Success
      .then(() => {
        this.setState({ loading: false });
        this.props.onShowForgotPasswordConfirmation();
      })
      // Catch error
      .catch((error) => {
        switch (error.code) {
          case "auth/invalid-email":
            errors.email = "Please enter a valid email address";
            break;
          case "auth/user-not-found":
            errors.email = "Couldn't find an account with this email address";
            break;
          default:
            errors.email = "An error was encountered. Please try again.";
            break;
        }

        this.setState(
          {
            loading: false,
            errors: errors,
          },
          this.forceUpdate,
        );
      });
  }

  render() {
    return (
      <div
        style={{
          position: this.props.showing ? "relative" : "absolute",
          width: "100%",
          boxSizing: "border-box",
          zIndex: this.props.showing ? 1 : 0,
          transform: "translateY(" + (this.props.showing ? "0" : "10px") + ")",
          opacity: this.props.showing ? "1" : "0",
          padding: "25px 10px",
          transition: "transform 80ms, opacity 80ms",
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            this.handleResetPassword();
          }}
        >
          <div className="AuthenticationTitle">Forgot Password?</div>

          <div className="AuthenticationTextWrapper">
            <span className="AuthenticationText">No worries—we'll send you a link to reset it</span>
          </div>

          <div className="AuthenticationInputWrapper">
            <CustomInput
              ref={this.InputEmail}
              icon={faEnvelope}
              placeholder="Email"
              errorText={this.state.errors.email}
              type={"email"}
              name={"email"}
              autoComplete={"email"}
              inverted={true}
              onChange={(text) => {
                this.setState((state) => {
                  state.input.email = text;
                  state.errors.email = "";
                  return state;
                });
              }}
            />
          </div>

          <div className="AuthenticationButtonWrapper">
            <CustomButton
              text="Send Email"
              inverted={true}
              color={"#2A64AD"}
              loading={this.state.loading}
              submit={true}
            />
          </div>
        </form>

        <div className="AuthenticationTextWrapper">
          <Clickable onClick={this.props.onShowLogin}>
            <span className="AuthenticationTextLight">All set?&nbsp;</span>
            <span className="AuthenticationTextBold">Sign in!</span>
          </Clickable>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
