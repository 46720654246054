import React, { PureComponent } from "react";
import CustomInput from "../CustomComponents/CustomInput/CustomInput";
import CustomButton from "../CustomComponents/CustomButton/CustomButton";
import Clickable from "../Clickable/Clickable";
import LoadingIcon from "../CustomComponents/LoadingIcon/LoadingIcon";
import { formatPhone, isValidPhone, parsePhone } from "../HelperFunctions";
import "./ProfilePage.css";
import { uploadImage, uploadImageWithAutoRotate } from "../ImageUploadHelpers";

import { Plugins, Capacitor, CameraResultType, CameraDirection } from "@capacitor/core";

import { getCurrentUserUid } from "../auth";

//Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEnvelope, faMapMarkerAlt, faInfo, faPhone } from "@fortawesome/pro-solid-svg-icons";
library.add(faUser, faEnvelope, faMapMarkerAlt, faInfo, faPhone);

const { Camera } = Plugins;

interface Props {
  position: number;
  onUpdateProfilePhotoExists: (string) => void;
  onUpdateProgress: (number) => void;
}

interface State {
  editing: boolean;
  loadingInformation: boolean;
  loadingPhoto: boolean;
  photoUrl: string;
  loggedIn: boolean;

  input: {
    fullName: string;
    email: string;
    phone: string;
    location: string;
    aboutMe: string;
  };
  errors: {
    hasErrors: boolean;
    fullName: string;
    email: string;
    phone: string;
    location: string;
    aboutMe: string;
  };
}

class ProfilePage extends PureComponent<Props, State> {
  InputFullName = React.createRef<CustomInput>();
  InputEmail = React.createRef<CustomInput>();
  InputPhone = React.createRef<CustomInput>();
  InputLocation = React.createRef<CustomInput>();
  InputAboutMe = React.createRef<CustomInput>();

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      loadingInformation: false,
      loadingPhoto: false,
      photoUrl: undefined,
      loggedIn: false,

      input: {
        fullName: "",
        email: "",
        phone: "",
        location: "",
        aboutMe: "",
      },
      errors: {
        hasErrors: false,
        fullName: "",
        email: "",
        phone: "",
        location: "",
        aboutMe: "",
      },
    };

    this.startEditing = this.startEditing.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.handleUpdatePhoto = this.handleUpdatePhoto.bind(this);
  }

  startEditing() {
    this.setState({ editing: true });
  }

  finishEditing() {
    if (!this.state.errors.hasErrors) {
      // Unfocus active element
      // TODO
      // @ts-ignore
      if ("activeElement" in document) document.activeElement.blur();
      this.setState({ editing: false });
    }
  }

  updateErrors() {
    let encounteredError = false;
    const errors = this.state.errors;
    const input = this.state.input;

    const phone = parsePhone(input.phone);

    if (phone === "") {
      errors.phone = "Please enter a phone number";
      encounteredError = true;
    }
    if (!isValidPhone(phone)) {
      errors.phone = "Please enter a valid phone number";
      encounteredError = true;
    }

    if (input.fullName.length < 5) {
      errors.fullName = "Name must be at least 5 characters";
      encounteredError = true;
    }

    if (encounteredError) {
      console.log("encountered with", errors);
      this.setState(
        {
          loadingInformation: false,
          errors: errors,
        },
        this.forceUpdate,
      );
    }
    return encounteredError;
  }

  async handleUpdateInformation() {
    this.setState({ loadingInformation: true });
    const encounteredError = this.updateErrors();
    if (encounteredError) return;

    // No errors; finish editing
    this.finishEditing();

    const parsedPhone = parsePhone(this.state.input.phone);

    // User data to be updated
    const data = {
      full_name: this.state.input.fullName,
      phone: parsedPhone,
      location: this.state.input.location,
      about_me: this.state.input.aboutMe,
    };

    // Update user data
    await firebase.firestore().collection("users").doc(getCurrentUserUid()).update(data);

    // Update user's display name
    await firebase.auth().currentUser.updateProfile({
      displayName: this.state.input.fullName,
    });

    const newName = this.state.input.fullName === undefined ? "" : this.state.input.fullName;
    const newPhone = parsedPhone === undefined ? "" : parsedPhone;
    const newAboutMe = this.state.input.aboutMe === undefined ? "" : this.state.input.aboutMe;
    const newLocation = this.state.input.location === undefined ? "" : this.state.input.location;
    const newProfilePicURL = this.state.photoUrl === undefined ? null : this.state.photoUrl;

    const updateProfileFunction = firebase.functions().httpsCallable("updateProfileData");
    const profileData = {
      id: getCurrentUserUid(),
      full_name: newName,
      about_me: newAboutMe,
      phone: newPhone,
      location: newLocation,
      email: this.state.input.email,
      profile_pic_url: newProfilePicURL,
    };
    await updateProfileFunction(profileData);
    this.setState({ loadingInformation: false });
  }

  /**
   * Uploads and displays new profile photo.
   * @param file Required in web environment. Not required in Capacitor environment.
   */
  async handleUpdatePhoto(file?: File) {
    let imageURL;
    if (Capacitor.isNative) {
      try {
        const image = await Camera.getPhoto({
          quality: 80,
          allowEditing: true,
          resultType: CameraResultType.Base64,
          correctOrientation: true,
          direction: CameraDirection.Front,
        });
        this.setState({ loadingPhoto: true });
        imageURL = await uploadImage(image.base64String, "profile_pictures", "jpeg", this.props.onUpdateProgress);
      } catch (error) {
        return;
      }
    } else {
      this.setState({ loadingPhoto: true });
      imageURL = await uploadImageWithAutoRotate(file, "profile_pictures", this.props.onUpdateProgress);
    }

    this.setState({ photoUrl: imageURL });
    await this.handleUpdateInformation();
    this.setState({ loadingPhoto: false });
  }

  async handleSuccessfulLogin() {
    const userData = await firebase.firestore().collection("users").doc(getCurrentUserUid()).get();

    this.InputFullName.current.setValue(userData.data().full_name);
    this.InputEmail.current.setValue(firebase.auth().currentUser.email);

    this.InputPhone.current.setValue(
      userData.data()["phone"] !== undefined ? formatPhone(userData.data()["phone"]) : "none",
    );
    this.InputLocation.current.setValue(userData.data()["location"]);
    const photoUrl = userData.data()["profile_pic_url"];
    this.setState({
      photoUrl: photoUrl,
    });
    this.InputAboutMe.current.setValue(userData.data()["about_me"]);
  }

  componentDidUpdate() {
    this.props.onUpdateProfilePhotoExists(this.state.photoUrl);
  }

  render() {
    // Image input component; <input> element on web, Clickable to request image selection if native
    let ImageInputComponent = (
      <input
        className="ProfileImageInputComponent"
        type="file"
        accept="image/*"
        onChange={(e) => {
          const files = e.target.files;
          const file = files[0];
          this.handleUpdatePhoto(file);
        }}
      />
    );
    if (Capacitor.isNative) {
      ImageInputComponent = (
        <div className="ProfileImageInputComponent">
          <Clickable onClick={this.handleUpdatePhoto}></Clickable>
        </div>
      );
    }

    return (
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1,
          boxSizing: "border-box",
          overflowY: "scroll",
          overflowX: "hidden",
          transform: "translateX(" + (this.props.position === 0 ? "0" : this.props.position * 100 + "%") + ")",
          transition: "transform 200ms",
          paddingBottom: "40px",
          paddingTop: "50px",
        }}
      >
        {/* Wrapper */}
        <div className="ProfileContentWrapper">
          {/* Container */}
          <div className="ProfileContentContainer">
            <div className="ProfilePhotoWrapper">
              <div className="ProfilePhotoContainer">
                {/* User or loading icon */}
                <div
                  style={{
                    fontSize: "64px",
                    color: "#d1d8e0",
                  }}
                >
                  {this.state.loadingPhoto ? <LoadingIcon /> : <FontAwesomeIcon icon={faUser} />}
                </div>
                {
                  // Show text prompt if no profile photo exists and profile photo is not loading
                  !this.state.photoUrl && !this.state.loadingPhoto && (
                    <div className="ProfilePhotoLabel">Choose a profile photo</div>
                  )
                }
                {
                  // Show red indicator badge if no profile photo exists
                  !this.state.photoUrl && !this.state.loadingPhoto && <div className="ProfilePhotoIndicatorBadge"></div>
                }

                {/* Image */}
                <div
                  className="ProfilePhoto"
                  style={{
                    backgroundImage: "url(" + this.state.photoUrl + ")",
                    opacity: this.state.photoUrl && !this.state.loadingPhoto ? "1" : "0",
                    transform: "scale(" + (this.state.photoUrl && !this.state.loadingPhoto ? "1" : "1.2") + ")",
                    transition: "opacity 300ms, transform 500ms",
                  }}
                ></div>

                {/* Input element or clickable zone for native image upload */}
                {ImageInputComponent}
              </div>
            </div>

            <div className="ProfileInputWrapper">
              <CustomInput
                ref={this.InputFullName}
                icon={faUser}
                placeholder="Full name"
                errorText={this.state.errors.fullName}
                type={"text"}
                inverted={true}
                readOnly={!this.state.editing}
                onChange={(text) => {
                  this.setState((state) => {
                    state.input.fullName = text;
                    state.errors.fullName = "";
                    return state;
                  }, this.forceUpdate);
                }}
              />
            </div>
            <div className="ProfileInputWrapper">
              <CustomInput
                ref={this.InputEmail}
                icon={faEnvelope}
                placeholder="Email"
                errorText={this.state.errors.email}
                type={"email"}
                inverted={true}
                readOnly={true}
                onChange={(text) => {
                  this.setState((state) => {
                    state.input.email = text;
                    state.errors.email = "";
                    return state;
                  }, this.forceUpdate);
                }}
              />
            </div>
            <div className="ProfileInputWrapper">
              <CustomInput
                ref={this.InputPhone}
                icon={faPhone}
                placeholder="Phone"
                errorText={this.state.errors.phone}
                type={"text"}
                inverted={true}
                readOnly={!this.state.editing}
                onChange={(text) => {
                  const formatted = formatPhone(text);
                  let changed = false;
                  // Update the phone number to be formatted
                  this.setState(
                    (state) => {
                      if (state.input.phone !== formatted) {
                        state.input.phone = formatted;
                        changed = true;
                      }
                      state.errors.phone = "";
                      return state;
                    },
                    () => {
                      this.forceUpdate();
                      // If the phone number changed, update the input value
                      if (changed) {
                        this.InputPhone.current.setValue(formatted);
                      }
                    },
                  );
                }}
              />
            </div>
            <div className="ProfileInputWrapper">
              <CustomInput
                ref={this.InputLocation}
                icon={faMapMarkerAlt}
                placeholder="Location"
                errorText={this.state.errors.location}
                type={"text"}
                inverted={true}
                readOnly={!this.state.editing}
                onChange={(text) => {
                  this.setState((state) => {
                    state.input.location = text;
                    state.errors.location = "";
                    return state;
                  }, this.forceUpdate);
                }}
              />
            </div>
            <div className="ProfileInputWrapper">
              <CustomInput
                ref={this.InputAboutMe}
                icon={faInfo}
                placeholder="About me"
                errorText={this.state.errors.aboutMe}
                type={"text"}
                inverted={true}
                readOnly={!this.state.editing}
                onChange={(text) => {
                  this.setState((state) => {
                    state.input.aboutMe = text;
                    state.errors.aboutMe = "";
                    return state;
                  }, this.forceUpdate);
                }}
              />
            </div>
            {
              // Show "Edit Profile" button if not editing and not loading
              !this.state.editing && !this.state.loadingInformation && (
                <div className="ProfileButtonWrapper">
                  <CustomButton
                    loading={false}
                    text="Edit Profile"
                    inverted={true}
                    color={"#2A3950"}
                    onClick={this.startEditing}
                  />
                </div>
              )
            }
            {
              // Show "Save Changes" button if editing or loading
              (this.state.editing || this.state.loadingInformation) && (
                <div className="ProfileButtonWrapper">
                  <CustomButton
                    text="Save Changes"
                    inverted={true}
                    color={"#20bf6b"}
                    loading={this.state.loadingInformation}
                    onClick={() => {
                      this.handleUpdateInformation();
                    }}
                  />
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ProfilePage;
