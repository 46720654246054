import React, { PureComponent } from "react";

import { Capacitor } from "@capacitor/core";
import SendButton from "../../CustomComponents/SendButton/SendButton";
import emojiRegex from "emoji-regex";
import ChatEmojiBar from "./ChatEmojiBar";
import "./Chat.css";
import { Member } from "../MessagingInterfaces";

interface Props {
  members: Member[];
  showing: boolean;
  onSend: (text: string) => void;
}

interface State {
  value: string;
  focused: boolean;
}

class TextInput extends PureComponent<Props, State> {
  InputMessage: React.RefObject<HTMLTextAreaElement>;
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      focused: false,
    };
    this.handleSend = this.handleSend.bind(this);
    this.clear = this.clear.bind(this);
    this.InputMessage = React.createRef();
  }

  focus() {
    this.InputMessage.current.focus();
  }
  clear() {
    this.setState({ value: "" });
  }

  handleSend() {
    if (this.state.value.trim() !== "") {
      if (typeof this.props.onSend === "function") {
        this.props.onSend(this.state.value);
      }
      this.clear();
    }
  }

  render() {
    const otherMembersExist = this.props.members && this.props.members.length > 0;

    const chatEmojiBar = (
      <ChatEmojiBar
        onChooseEmoji={(emoji) => {
          this.setState((state) => {
            return { value: state.value + emoji };
          });
          this.focus();
        }}
        position={Capacitor.isNative ? "bottom" : "top"}
      />
    );

    const text = this.state.value.trim();
    const textNoWhitespace = text.replace(/\s+/g, "");
    const textNoWhitespaceOrEmojis = textNoWhitespace.replace(emojiRegex(), "");
    const onlyEmojis =
      emojiRegex().test(textNoWhitespace) &&
      [...textNoWhitespace].length <= 5 &&
      [...textNoWhitespaceOrEmojis].length === 0;

    // Make the textarea larger on web, and if there is more text
    let rows = onlyEmojis ? 1 : Capacitor.isNative ? 2 : 4;
    const newLines = this.state.value.split("\n").length - 1;
    if (this.state.value.length > 80 || newLines >= rows) rows *= 2;

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          position: this.props.showing ? "relative" : "absolute",
          pointerEvents: this.props.showing ? "auto" : "none",
          opacity: this.props.showing ? "1" : "0",
          zIndex: this.props.showing ? 0 : -1,
          transform: "translateY(" + (this.props.showing ? "0" : "20px") + ")",
          transition: "opacity 200ms, transform 200ms",
        }}
      >
        {!Capacitor.isNative && chatEmojiBar}
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            padding: "0 10px",
            fontSize: "14px",
            fontFamily: "inherit",
            resize: "none",
          }}
        >
          <textarea
            ref={this.InputMessage}
            maxLength={10000}
            placeholder="Type message..."
            value={this.state.value}
            style={{
              border: "none",
              background: "none",
              outline: "none",
              padding: "10px 15px",
              position: "relative",
              width: "100%",
              fontSize: onlyEmojis ? "32px" : "14px",
              fontFamily: "inherit",
              resize: "none",
            }}
            rows={rows}
            onChange={(e) => {
              this.setState({ value: e.target.value });
            }}
            onKeyPress={(e) => {
              // Send message if the user pressed "Return" without the shift key pressed
              // only on the web version
              if (e.which === 13 && !e.shiftKey && !Capacitor.isNative) {
                e.preventDefault();
                this.handleSend();
              }
            }}
            onFocus={() => {
              this.setState({ focused: true });
            }}
            onBlur={() => {
              this.setState({ focused: false });
            }}
          ></textarea>
          <SendButton
            active={this.state.value.trim() !== "" && otherMembersExist}
            preventDefault={true}
            onSend={this.handleSend}
          />
        </div>
        {Capacitor.isNative && this.state.focused && chatEmojiBar}
      </div>
    );
  }
}

export default TextInput;
