import React, { Component } from "react";
import { formatMoneyAmount, parseMoneyAmount } from "../../HelperFunctions";

interface Props {
  value?: number;
  placeholder?: number;
  style?: React.CSSProperties;
  className?: string;
  readOnly?: boolean;
  size?: number;
  onChange: (n: number) => any;
  onBlur?: () => any;
  onFocus?: () => any;
}

interface State {
  amount: number;
  value: string;
}

class CurrencyInput extends Component<Props, State> {
  input = React.createRef<HTMLInputElement>();
  constructor(props) {
    super(props);

    const amount = this.props.value || 0;
    const value = amount === 0 ? "" : formatMoneyAmount(amount);

    this.state = {
      amount: amount,
      value: value,
    };
  }

  clear() {
    this.setState({
      value: "",
      amount: 0,
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(0);
    }
  }

  setValue(amount: number) {
    const value = amount === 0 ? "" : formatMoneyAmount(amount);
    this.setState({
      amount: amount,
      value: value,
    });
    if (typeof this.props.onChange === "function") {
      this.props.onChange(amount);
    }
  }

  focus() {
    this.input.current.focus();
  }

  render() {
    return (
      <input
        ref={this.input}
        placeholder={formatMoneyAmount(this.props.placeholder ?? 0)}
        type={"text"}
        value={this.state.value}
        style={this.props.style}
        className={this.props.className}
        readOnly={this.props.readOnly}
        size={this.props.size}
        onChange={(e) => {
          let value = e.target.value;
          let amount = parseMoneyAmount(value);
          if (!amount) amount = 0;

          // Remove dollar sign and commas
          value = value.replace("$", "");
          value = value.replace(",", "");

          // A numeric version of the value, or NaN if it's not a numeric string
          const numeric = +value;

          // Valid numeric input
          let newValue = "$";
          if (!isNaN(numeric)) {
            newValue = "$" + value;
          }

          const changed = this.state.amount !== amount;
          this.setState(
            {
              value: newValue,
              amount: amount,
            },
            () => {
              if (typeof this.props.onChange === "function" && !this.props.readOnly && changed) {
                this.props.onChange(amount);
              }
            },
          );
        }}
        // Truncate value to 2 decimals on blur
        onBlur={() => {
          if (typeof this.props.onBlur === "function") this.props.onBlur();

          const value = this.state.value;
          let amount = parseMoneyAmount(value);
          const formatted = formatMoneyAmount(amount);
          if (!amount) amount = 0;
          const changed = this.state.amount !== amount;
          this.setState(
            {
              value: formatted,
              amount: amount,
            },
            () => {
              if (typeof this.props.onChange === "function" && !this.props.readOnly && changed) {
                this.props.onChange(amount);
              }
            },
          );
        }}
        onFocus={() => {
          if (typeof this.props.onFocus === "function") this.props.onFocus();
        }}
      ></input>
    );
  }
}

export default CurrencyInput;
