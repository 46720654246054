import React from "react";
import "./LoadingIcon.css";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons";
library.add(faCircleNotch);

// A simple spinning loading icon
function LoadingIcon() {
  return <FontAwesomeIcon className="LoadingIcon" icon={faCircleNotch} />;
}

export default LoadingIcon;
